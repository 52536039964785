import React, { useState } from "react";
import { Divider, Typography, useMediaQuery } from "@material-ui/core";
import {
  ArrowForward,
  PermIdentityOutlined,
  DirectionsCarOutlined,
  ScheduleOutlined,
  PlaceOutlined,
} from "@material-ui/icons";
import ViewEstimateModal from "../../components/ViewEstimateModal";
import ContactInfo from "../../components/ContactInfo";
import { useHistory } from "react-router-dom";

const TodayJobCard = ({ job }) => {
  const [open, setOpen] = useState(false);

  const {
    car,
    customer_name,
    time_for_mechanic,
    apartment_number,
    apartment_code,
    garage,
    covered,
    arrival_instructions,
    status,
    appointment_type,
    estimate,
    diag_note_left,
    member_job,
    notes_from_office,
    fleet_job,
    address_display,
    diagnosis_input,
    install_dongle,
  } = job.attributes;

  let labor = appointment_type !== "repair" ? 1 : 0;

  const diagAndRepair = appointment_type === "diagnosis" && estimate;

  if (estimate) {
    labor += estimate.approved_labor;
  }

  const notPayable = job.attributes.not_payable;

  const completed = status === "completed";

  const diagCompleted =
    diag_note_left && job.attributes.status === "diagnosis_complete";

  const { id } = job;

  const DiagTag = () => {
    return (
      <div
        style={{
          padding: "5px 15px",
          margin: "0 4px",
          backgroundColor: "#FF9C17",
          borderRadius: 26,
        }}
      >
        <Typography style={{ fontFamily: "Inter", color: "#FFF" }}>
          Diagnosis
        </Typography>
      </div>
    );
  };

  const RepairTag = () => {
    return (
      <div
        style={{
          padding: "5px 15px",
          backgroundColor: "#1A5B74",
          borderRadius: 26,
        }}
      >
        <Typography style={{ fontFamily: "Inter", color: "#FFF" }}>
          Repair
        </Typography>
      </div>
    );
  };

  const MemberTag = () => {
    return (
      <p
        style={{
          background: "#16C444",
          color: "#fff",
          width: "max-content",
          padding: "5px 15px",
          margin: "0 4px",
          borderRadius: 26,
        }}
        className="text-md"
      >
        Member
      </p>
    );
  };

  const FleetTag = () => {
    return (
      <p
        style={{
          background: "#A39CEF",
          color: "#fff",
          width: "max-content",
          padding: "5px 15px",
          margin: "0 4px",
          borderRadius: 26,
        }}
        className="text-md"
      >
        Fleet
      </p>
    );
  };

  const DongleTag = () => {
    return (
      <p
        style={{
          background: "#ddd",
          color: "#000",
          width: "max-content",
          padding: "5px 15px",
          margin: "0 4px",
          borderRadius: 26,
        }}
        className="text-md"
      >
        Dongle Install
      </p>
    );
  };

  const getBgColor = () => {
    if (notPayable) {
      return "border-fmc-orange";
    }

    if (completed || diagCompleted) {
      return "border-fmc-dark-green";
    }

    return "border-grey-300";
  };

  const getColor = (status) => {
    if (status === "approved" || status === "locked") {
      return "#16C444";
    }

    if (status === "pending") {
      return "#FF9C17";
    }

    return "#FF0000";
  };

  const completedText = () => {
    if (diagCompleted) {
      return `Diag Completed`;
    }

    if (completed) {
      return `Completed`;
    }

    return ``;
  };

  const history = useHistory();
  const handleClick = () => {
    history.push(`/mechanic/job/${id}`);
  };
  const isSm = useMediaQuery("(max-width: 600px)");

  return (
    <div
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
        maxWidth: 500,
        marginLeft: isSm ? 7 : "auto",
        marginRight: isSm ? 7 : "auto",
        marginBottom: 20,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        border: "1px",
        borderStyle: "solid",
        borderRadius: "14px",
      }}
      className={getBgColor()}
    >
      <div className="flex-1 flex flex-row justify-between">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ color: "#646867" }}>#{id}</Typography>
        </div>
        <p
          className={"flex-1 text-sm text-right text-fmc-dark-green"}
          style={{ margin: 8 }}
        >
          {completedText()}
        </p>
        <div
          style={{
            padding: 10,
            borderRadius: "50%",
            backgroundColor: "#E6EEEE",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <ArrowForward style={{ color: "#005959" }} />
        </div>
      </div>

      <div style={{ display: "flex", margin: "10px 0" }}>
        {(appointment_type === "diagnosis" || diagAndRepair) && <DiagTag />}
        {(appointment_type === "repair" || diagAndRepair) && <RepairTag />}
        {member_job && <MemberTag />}
        {fleet_job && <FleetTag />}
      </div>

      {install_dongle && (
        <div style={{ display: "flex", margin: "10px 0" }}>
          <DongleTag />
        </div>
      )}

      {notes_from_office && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ color: "#ff0000" }}>
            Notes From Office: {notes_from_office}
          </Typography>
        </div>
      )}

      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <div style={{ display: "flex", flexDirection: "row", gap: 15 }}>
          <PermIdentityOutlined
            style={{ width: 25, height: 25, color: "#4C4F4E" }}
          />
          <Typography style={{ fontSize: 16, marginTop: 2, color: "#1F2322" }}>
            {customer_name}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            marginTop: 15,
          }}
        >
          <DirectionsCarOutlined
            style={{ width: 25, height: 25, color: "#4C4F4E" }}
          />

          <Typography style={{ fontSize: 16, marginTop: 2, color: "#1F2322" }}>
            {`${car.year} ${car.make} ${car.model}${
              car.color && car.color !== "" ? ` (${car.color})` : ""
            }`}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            marginTop: 15,
          }}
        >
          <ScheduleOutlined
            style={{ width: 25, height: 25, color: "#1F2322" }}
          />
          <Typography style={{ fontSize: 16, marginTop: 2, color: "#1F2322" }}>
            {time_for_mechanic}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            marginTop: 15,
          }}
        >
          <PlaceOutlined style={{ width: 25, height: 25, color: "#1F2322" }} />
          <Typography style={{ fontSize: 16, color: "#1F2322" }}>
            {address_display}
          </Typography>
        </div>

        {(apartment_number ||
          arrival_instructions ||
          apartment_code ||
          garage ||
          covered) && (
          <div
            style={{
              gap: 15,
              marginTop: 20,
            }}
          >
            <Typography style={{ color: "#646867", marginBottom: 20 }}>
              ARRIVAL INFO
            </Typography>
            {apartment_number && (
              <div
                style={{
                  gap: 15,
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{ marginLeft: 8, fontSize: 16, color: "#1F2322" }}
                >
                  Apartment: {apartment_number}
                </Typography>
              </div>
            )}

            {apartment_code && (
              <div
                style={{
                  gap: 15,
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{ marginLeft: 8, fontSize: 16, color: "#1F2322" }}
                >
                  Apartment code: {apartment_code}
                </Typography>
              </div>
            )}

            {garage && (
              <div
                style={{
                  gap: 15,
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{ marginLeft: 8, fontSize: 16, color: "#1F2322" }}
                >
                  Has Garage: {garage}
                </Typography>
              </div>
            )}

            {covered && (
              <div
                style={{
                  gap: 15,
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{ marginLeft: 8, fontSize: 16, color: "#1F2322" }}
                >
                  Covered Parking: {covered}
                </Typography>
              </div>
            )}

            {arrival_instructions && (
              <div
                style={{
                  gap: 15,
                  marginTop: 15,
                }}
              >
                <Typography
                  style={{ marginLeft: 8, fontSize: 16, color: "#1F2322" }}
                >
                  Arrival instructions: {arrival_instructions}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        style={{
          backgroundColor: "#D4D5D5",
          margin: "10px 2px",
          marginBottom: 20,
          height: 1,
        }}
      />

      {(appointment_type === "warranty_job" ||
        appointment_type === "diagnosis") && (
        <>
          <div>
            <Typography style={{ color: "#646867", marginBottom: 20 }}>
              {appointment_type === "diagnosis"
                ? "CUSTOMER STATES"
                : "WARRANTY CONCERN"}
            </Typography>

            <Typography
              style={{ fontSize: 16, maxWidth: 200, marginBottom: 10 }}
            >
              {diagnosis_input}
            </Typography>
          </div>

          <div
            style={{
              backgroundColor: "#D4D5D5",
              margin: "10px 2px",
              marginBottom: 20,
              height: 1,
            }}
          />
        </>
      )}

      {estimate ? (
        <div>
          <Typography style={{ color: "#646867", marginBottom: 20 }}>
            REPAIRS
          </Typography>

          {Object.entries(estimate.services).map(
            ([serviceName, serviceData], index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <div>
                  <Typography style={{ fontSize: 16, maxWidth: 200 }}>
                    {serviceName}{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        color: getColor(serviceData?.status),
                      }}
                    >
                      (
                      {serviceData?.status === "locked"
                        ? "approved"
                        : serviceData?.status}
                      )
                    </span>
                  </Typography>

                  <Typography style={{ fontSize: 12, color: "grey" }}>
                    {serviceData?.customer_to_supply_parts
                      ? "*Customer to supply parts"
                      : ""}
                  </Typography>

                  <Typography
                    style={{
                      fontSize: 12,
                      color: "grey",
                    }}
                  >
                    {serviceData?.is_warranty ? "Potential Warranty" : ""}
                  </Typography>
                </div>
                <Typography style={{ fontSize: 16, color: "#646867" }}>
                  {serviceData?.labor_hours}hr(s)
                </Typography>
              </div>
            )
          )}

          {estimate && (
            <div
              style={{
                padding: "5px 15px",
                backgroundColor: "#E6EEEE",
                borderRadius: 26,
                width: "fit-content",
                marginTop: 20,
                marginBottom: 20,
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              <Typography style={{ fontFamily: "Inter", color: "#005959" }}>
                View Estimate
              </Typography>
            </div>
          )}

          <div
            style={{
              backgroundColor: "#D4D5D5",
              margin: "10px 2px",
              marginBottom: 20,
              height: 1,
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {status !== "completed" && (
        <ContactInfo
          jobId={id}
          exactAddress={address_display}
          fleet={fleet_job}
        />
      )}

      <ViewEstimateModal
        open={open}
        appointment_id={id}
        className=""
        onClose={setOpen}
        reload={true}
        setReload={() => {}}
        handleTextEstimate={() => {}}
        textedEstimate={false}
        afterText="Back To Jobs"
        afterTextAction={() => setOpen(false)}
        shouldSchedule={false}
      />
    </div>
  );
};

export { TodayJobCard };
