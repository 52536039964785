import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { step4FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const Step4 = ({ styles, handleNext, onUpdate, inspection, goBack }) => {
  const [step4Fields, setStep4Fields] = useState({
    wiper_fluid: inspection.wiper_fluid || "",
    wiper_fluid_text_describe: inspection.wiper_fluid_text_describe || "",
    coolant: inspection.coolant || "",
    coolant_text_describe: inspection.coolant_text_describe || "",
    coolant_service_due: inspection.coolant_service_due || "",
    coolant_likely_approve_service:
      inspection.coolant_likely_approve_service || "",
    oil_level: inspection.oil_level || "",
    oil_level_text_describe: inspection.oil_level_text_describe || "",
    oil_level_service_due: inspection.oil_level_service_due || "",
    oil_level_likely_approve_service:
      inspection.oil_level_likely_approve_service || "",
    brake_fluid: inspection.brake_fluid || "",
    brake_fluid_text_describe: inspection.brake_fluid_text_describe || "",
    brake_fluid_service_due: inspection.brake_fluid_service_due || "",
    brake_fluid_likely_approve_service:
      inspection.brake_fluid_likely_approve_service || "",
    hoses: inspection.hoses || "",
    hoses_text_describe: inspection.hoses_text_describe || "",
    power_steering: inspection.power_steering || "",
    power_steering_text_describe: inspection.power_steering_text_describe || "",
    power_steering_service_due: inspection.power_steering_service_due || "",
    power_steering_likely_approve_service:
      inspection.power_steering_likely_approve_service || "",
    cooling_system: inspection.cooling_system || "",
    cooling_system_text_describe: inspection.cooling_system_text_describe || "",
    engine_air_filter: inspection.engine_air_filter || "",
    engine_air_filter_text_describe:
      inspection.engine_air_filter_text_describe || "",
    engine_air_filter_service_due:
      inspection.engine_air_filter_service_due || "",
    engine_air_filter_likely_approve_service:
      inspection.engine_air_filter_likely_approve_service || "",
    battery: inspection.battery || "",
    battery_tags: inspection.battery_tags || [],
    battery_text_describe: inspection.battery_text_describe || "",
    battery_service_due: inspection.battery_service_due || "",
    battery_likely_approve_service:
      inspection.battery_likely_approve_service || "",
    engine: inspection.engine || "",
    engine_text_describe: inspection.engine_text_describe || "",
    engine_tags: inspection.engine_tags || [],
    transmission_fluid: inspection.transmission_fluid || "",
    serpentine_belt: inspection.serpentine_belt || "",
  });

  const done =
    step4Fields.coolant !== "" &&
    step4Fields.oil_level !== "" &&
    step4Fields.brake_fluid !== "" &&
    step4Fields.hoses !== "" &&
    step4Fields.power_steering !== "" &&
    step4Fields.engine_air_filter !== "" &&
    step4Fields.battery !== "" &&
    step4Fields.engine !== "" &&
    step4Fields.serpentine_belt !== "";

  const updateField = (field, value) => {
    setStep4Fields({
      ...step4Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  return (
    <Box>
      <Typography className={styles.heading}>Under the Hood</Typography>

      {Object.keys(step4FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step4FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step4Fields}
          />
        );
      })}

      <FmcFullWidthButton
        text="Save & Next"
        onClick={handleNext}
        variant="contained"
        disabled={!done}
      />

      <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
    </Box>
  );
};

export { Step4 };
