export const gaHit = async (
  gaClientId,
  amt,
  transactionId,
  userId,
  productName
) => {
  let googleClientId = "";

  if (gaClientId) {
    googleClientId = gaClientId;
  }

  var requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  const googleUrl = `https://www.google-analytics.com/collect?v=1&t=pageview&tid=UA-129195253-1&cid=${googleClientId}&dh=fixmycar.io&dp=/payments&dt=Purchase&ti=${transactionId}&ta=FixMyCar%20Booking%20Completed&tr=${amt}&pa=purchase&pr1id=${transactionId}&pr1nm=${productName}&uid=${userId}`;

  await fetch(googleUrl, requestOptions)
    .then((response) => {})
    .catch((error) => {});
};
