import React, { useState } from "react";
import {
  ThemeProvider,
  Modal,
  Box,
  TextField,
  IconButton,
} from "@material-ui/core";
import { FMCTheme } from "../../../../_constants/theme";
import { DateRangePicker } from "@material-ui/lab";
import moment from "moment";
import { callApiV2 } from "../../../../_apis/api_base";
import { Close } from "@material-ui/icons";

const DayOffModal = ({ classNames, boxClassNames, open, onClose }) => {
  const [dayOff, setDayOff] = useState([
    moment().add(2, "days"),
    moment().add(2, "days"),
  ]);
  const [reason, setReason] = useState("");
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState();

  const onSubmit = () => {
    setSubmitting(true);

    const day_off_requested = dayOff[0].format("YYYY-MM-D");
    const day_off_requested_end = dayOff[1].format("YYYY-MM-D");

    callApiV2({
      url: "/support_tickets",
      method: "POST",
      data: {
        support_ticket: {
          issue_details: reason,
          day_off_requested,
          day_off_requested_end,
        },
      },
    })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
      })
      .catch(() => {
        setError(true);
        setSubmitting(false);
      });
  };

  return (
    <Modal className={classNames} open={open} onClose={onClose}>
      <Box className="px-4 pt-24 mb-10 pb-24 max-h-full overflow-scroll">
        <div className={boxClassNames}>
          <IconButton
            style={{
              padding: "2px",
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <ThemeProvider theme={FMCTheme}>
            <div className="flex flex-col">
              <h2 className="font-tiempos text-lg flex-1 my-2">
                Request Time Off
              </h2>

              <div className="my-6">
                <DateRangePicker
                  label="Select Dates"
                  value={dayOff}
                  minDate={moment().add(5, "days")}
                  onChange={(newValue) => setDayOff(newValue)}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </div>

              <div className="my-6">
                <TextField
                  onChange={(event) => setReason(event.target.value)}
                  label="What do you need this time off for?"
                  variant="outlined"
                  fullWidth
                  value={reason}
                />
              </div>

              <button
                type="button"
                className="p-2 rounded-md text-white text-artegra bg-fmc-primary mt-5"
                onClick={onSubmit}
                disabled={submitting}
              >
                Request Time Off
              </button>

              {error && (
                <div className="my-3 text-center text-fmc-red">
                  <p>Unable to submit request, contact us.</p>
                </div>
              )}

              {success && (
                <div className="my-3 text-center text-fmc-dark-green">
                  <p>
                    These days have been permanently removed from your schedule.
                  </p>
                </div>
              )}
            </div>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export { DayOffModal };
