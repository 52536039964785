import React, { useState, useEffect } from "react";
import { Box, Container, OutlinedInput } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../App/routes";
import { apiCreateMechanicWarrantyNote, apiGetJob } from "../../../_apis";
import AfterNoteModal from "../components/AfterNoteModal";
import JobDetails from "../components/JobDetails";
import FmcFullWidthButton from "../components/FmcFullWidthButton";

const WarrantyNote = () => {
  const [warrantyResult, setWarrantyResult] = useState();
  const [error, setError] = useState(false);
  const [jobInfo, setJobInfo] = useState();
  const [warranty_qualified, setWarrantyQualified] = useState();
  const query = new URLSearchParams(useLocation().search);
  const appointment_id = query.get("appointment_id");
  const history = useHistory();
  const [isSubmittingNote, setIsSubmittingNote] = useState(false);
  const [open, setOpen] = useState(false);

  if (!appointment_id) {
    history.push(routes.INDEX());
  }

  useEffect(() => {
    apiGetJob(appointment_id).then((response) => {
      if (response && response.data) {
        setJobInfo(response.data);
      }
    });
  }, []);

  const submitNote = () => {
    setIsSubmittingNote(true);

    const note = {
      appointment_id,
      symptoms: warrantyResult,
      warranty_qualified,
    };

    apiCreateMechanicWarrantyNote(note)
      .then((res) => {
        setOpen(true);
      })
      .catch((e) => {
        setError(true);
        setIsSubmittingNote(false);
      });
  };

  const hasInvalidField = () => {
    return !warrantyResult;
  };

  return (
    <Box className="px-4 pt-24 mb-8">
      {jobInfo && (
        <>
          <div>
            <JobDetails
              jobInfo={jobInfo}
              appointmentTime={jobInfo.attributes.appointment_time}
              job_id={appointment_id}
              expand={false}
            />
          </div>

          <Container className="mb-32">
            <Box className="my-3">
              <h2 className="my-3 font-tiempos text-2xl text-center">
                Warranty Result
              </h2>
            </Box>

            <OutlinedInput
              className="my-3"
              multiline
              minRows={3}
              value={warrantyResult}
              type="text"
              fullWidth
              placeholder="What was the conclusion of your warranty inspection?"
              onChange={(e) => setWarrantyResult(e.target.value)}
            />

            <FmcFullWidthButton
              text="Submit"
              onClick={submitNote}
              variant="contained"
              disabled={hasInvalidField() || isSubmittingNote}
            />
          </Container>
        </>
      )}

      <AfterNoteModal
        open={open}
        onClose={setOpen}
        job_id={appointment_id}
        noteType="Warranty"
        afterPath={`tel:469-840-3990`}
        needsEstimate={false}
      />
    </Box>
  );
};

export { WarrantyNote };

export default WarrantyNote;
