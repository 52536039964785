import React from "react";
import { Route } from "react-router-dom";
import EmptyLayout from "./EmptyLayout";
import MechanicLayout from "./MechanicLayout";
import MechanicAuthLayout from "./MechanicAuthLayout";
import { StyledEngineProvider } from "@material-ui/core";

const LayoutRoute = ({
  render,
  component: Component,
  layout: Layout = EmptyLayout,
  ...rest
}) => {
  return (
    <StyledEngineProvider injectFirst>
      <Route
        {...rest}
        render={
          render
            ? (props) => <Layout path={rest.path}>{render(props)}</Layout>
            : (props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )
        }
      />
    </StyledEngineProvider>
  );
};

export { EmptyLayout, MechanicLayout, MechanicAuthLayout };

export default LayoutRoute;
