import React, {
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import PartList from "./PartList";
import useStyles from "../../components/styles";
import IconTrash from "@material-ui/icons/Delete";
import IconExpand from "@material-ui/icons/ExpandMore";
import IconShrink from "@material-ui/icons/ExpandLess";
import ConfirmationDialog from "./ConfirmationDialog";
import { MASTER_SERVICE_LIST } from "../../../../_constants/constants";
import { TabPanel, a11yProps } from "../../../../_components/Tabs";

const getFlatServices = (fleetPricing, admin, euro) => {
  const list = [
    {
      name: "Pre-Purchase Inspection",
      labor_price: 120,
      labor_hours: 1.0,
      is_canned_service: true,
    },
    {
      name: "Fuel Induction Service",
      labor_price: 190,
      labor_hours: 1.5,
      is_canned_service: true,
    },
    {
      is_canned_service: true,
      name: "Engine Air Filter",
      labor_price: euro ? 65 : 45,
      labor_hours: 0.3,
      parts: [
        {
          name: "Engine Air Filter",
          price: euro ? 49.99 : 29.99,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Brake System",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Brake Fluid",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Cooling System",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Coolant",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Power Steering",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Fluid - Power Steering",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Cabin Air Filter",
      labor_hours: 0.3,
      labor_price: euro ? 65 : 45,
      parts: [
        {
          name: "Cabin Air Filter",
          price: euro ? 59.99 : 39.99,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Wiper Blades",
      labor_hours: 0.3,
      labor_price: 60.0,
      parts: [
        {
          name: "Wiper Blades",
          price: 35.0,
          quantity: 1,
        },
      ],
    },
  ];

  if (fleetPricing) {
    if (fleetPricing.fleet_pads_rotors_labor_price) {
      list.unshift({
        is_canned_service: true,
        name: "Rear Pads And Rotors",
        labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
        labor_hours: 1.5,
        parts: [
          {
            name: "Passenger Car Brake Kit - Rear",
            price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
            quantity: 1,
          },
        ],
      });
      list.unshift({
        name: "Front Pads And Rotors",
        labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
        labor_hours: 1.5,
        is_canned_service: true,
        parts: [
          {
            name: "Passenger Car Brake Kit - Front",
            price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
            quantity: 1,
          },
        ],
      });
    }

    return list;
  } else if (!euro) {
    list.unshift({
      is_canned_service: true,
      name: "Front Pads And Rotors",
      labor_price: 155,
      labor_hours: 1.5,
      parts: [
        {
          name: "Passenger Car Brake Kit - Front",
          price: 240,
          quantity: 1,
        },
      ],
    });
    list.unshift({
      is_canned_service: true,
      name: "Rear Pads And Rotors",
      labor_price: 155,
      labor_hours: 1.5,
      parts: [
        {
          name: "Passenger Car Brake Kit - Rear",
          price: 240,
          quantity: 1,
        },
      ],
    });
  }

  return list;
};

const MEMBER_OIL = {
  free_member_service: true,
  name: "Membership Oil Change",
  is_canned_service: true,
  labor_price: 0.0,
  labor_hours: 0.3,
};

const getRegularOil = (fleetPricing, car) => {
  if (fleetPricing) {
    const priceCol = car.european_make
      ? fleetPricing.fleet_euro_oil_change_price
      : fleetPricing.fleet_oil_change_price;
    if (!priceCol) {
      return null;
    } else {
      return {
        is_canned_service: true,
        name: "Oil Change",
        labor_price: priceCol,
        labor_hours: 0.3,
        parts: [],
      };
    }
  }

  return {
    is_canned_service: true,
    name: "Oil Change",
    labor_price: 90,
    labor_hours: 0.3,
    parts: [],
  };
};

const Service = ({ serviceId, EstimateFormContext }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [laborError, setLaborError] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const {
    data,
    appointment,
    onUpdateServiceField,
    onRemoveService,
    onAddPart,
    onUpdatePartField,
    isFleet,
    isAdmin,
  } = useContext(EstimateFormContext);
  const service = useMemo(() => data.services[serviceId], [data, serviceId]);

  const [laborRate, setLaborRate] = useState();
  const [customerSupplyingParts, setCustomerSupplyingParts] = useState(
    service.customer_to_supply_parts
  );
  const [approved, setApproved] = useState(
    service.status === "approved" || service.status === "locked"
  );
  const [value, setValue] = useState(0);
  const [flatServiceList, setFlatServiceList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLaborRate(appointment.hourly_rate);

    const serviceList = getFlatServices(
      appointment.fleet_pricing,
      isAdmin,
      appointment.car?.european_make
    );
    const oilChange = getRegularOil(appointment.fleet_pricing, appointment.car);

    if (appointment.member_job && !serviceList.includes(MEMBER_OIL)) {
      serviceList.push(MEMBER_OIL);
    } else if (
      oilChange &&
      !serviceList.includes(oilChange) &&
      !appointment.car.european_make
    ) {
      serviceList.unshift(oilChange);
    }

    setFlatServiceList(serviceList);
    if(!service.name && !service.tag && !service.labor_hours){
      setExpanded(!expanded)
    }
  }, [appointment]);

  const handleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleOpenConfirm = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleConfirmationCancel = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmationOk = useCallback(() => {
    setConfirmationModalOpen(false);
    onRemoveService(serviceId);
  }, [onRemoveService, serviceId]);

  const onUpdateLabor = (labor) => {
    if (labor < 0.2) {
      setLaborError(true);
    } else {
      setLaborError(false);
    }
  };

  if (service.id && Object.keys(service).length === 1) return null;

  const getLaborVal = () => {
    if (service.labor_hours === undefined || !laborRate) {
      return "";
    } else if (service.free_member_service) {
      return 0;
    } else if (
      (service.labor_price &&
        service.name &&
        service.name.toLowerCase().includes("oil change")) ||
      service.is_canned_service
    ) {
      return service.labor_price;
    }

    return (service.labor_hours * laborRate).toFixed(2);
  };

  const handleTagChange = (event) => {
    onUpdateServiceField(serviceId, "tag", event.target.value);
  };

  return (
    <Card className={classes.serviceCard}>
      <CardHeader
        title={service.name || "New Service"}
        action={
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.deleteButton}
              onClick={handleOpenConfirm}
            >
              <IconTrash />
            </IconButton>
            <IconButton onClick={handleExpand}>
              {expanded ? <IconShrink /> : <IconExpand />}
            </IconButton>
          </Box>
        }
        className={classes.serviceCardHeader}
      />
      <Collapse in={expanded}>
        <CardContent>
          <Box>   
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="tag"
                name="tag"
                value={service.tag}
                onChange={handleTagChange}
              >
                <span className="text-fmc-red italic mr-5"> * </span>
                <FormControlLabel
                  value="urgent"
                  control={<Radio />}
                  label="Urgent"
                />
                <FormControlLabel
                  value="recommended"
                  control={<Radio />}
                  label="Recommended"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs variant="scrollable" value={value} onChange={handleChange}>
              <Tab label="Parts & Labor" {...a11yProps(0)} />
              <Tab label="Fixed Services" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box className="my-3">
              <Autocomplete
                freeSolo
                disabled={service.free_member_service}
                options={MASTER_SERVICE_LIST}
                value={service.name || ""}
                id="services-autocomplete"
                renderInput={(params) => (
                  <TextField {...params} label="Service Name" />
                )}
                onInputChange={(_, newInputValue) => {
                  onUpdateServiceField(serviceId, "name", newInputValue);
                }}
                noOptionsText="No options."
              />

              <Box mt={3}>
                <PartList
                  serviceId={serviceId}
                  EstimateFormContext={EstimateFormContext}
                />
              </Box>
              <Box mt={3}>
                <Card className={classes.noBorderCard}>
                  <CardHeader title="Labor" sx={{ marginBottom: 0 }} />
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <OutlinedInput
                          fullWidth
                          placeholder="Labor Hours"
                          value={
                            service.free_member_service
                              ? service.labor_hours
                              : service.labor_hours || ""
                          }
                          type="number"
                          disabled={
                            service.free_member_service ||
                            service.is_canned_service
                          }
                          onChange={(ev) => {
                            onUpdateLabor(Number(ev.target.value));
                            onUpdateServiceField(
                              serviceId,
                              "labor_hours",
                              Number(ev.target.value)
                            );
                          }}
                          inputProps={{
                            min: 0,
                          }}
                        />
                        {laborError && (
                          <p className="text-fmc-red italic m-2">
                            Must be at least 0.2 hrs
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <OutlinedInput
                          fullWidth
                          disabled
                          placeholder="Labor Price"
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          value={getLaborVal()}
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>

              <Box mt={3}>
                <Card className={classes.noBorderCard}>
                  <CardHeader title="" sx={{ marginBottom: 0 }} />
                  <CardContent>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => {
                              setCustomerSupplyingParts(
                                !customerSupplyingParts
                              );
                              onUpdateServiceField(
                                serviceId,
                                "customer_to_supply_parts",
                                !customerSupplyingParts
                              );
                            }}
                            checked={customerSupplyingParts}
                          />
                        }
                        label="Customer to supply parts"
                      />
                    </FormGroup>

                    {isFleet && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={() => {
                                setApproved(!approved);
                                onUpdateServiceField(
                                  serviceId,
                                  "status",
                                  !approved ? "approved" : "pending"
                                );
                              }}
                              checked={approved}
                            />
                          }
                          label="Approve service"
                        />
                      </FormGroup>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box className="my-3">
              <FormControl fullWidth>
                <InputLabel id="flat-service-select-label">
                  Select Canned Service
                </InputLabel>
                <Select
                  labelId="flat-service-select-label"
                  id="flat-service-select"
                  style={{ width: "100%" }}
                  variant="outlined"
                  onChange={(e) => {
                    const service = flatServiceList.filter(
                      (i) => i.name == e.target.value
                    )[0];

                    onUpdateServiceField(serviceId, "name", service.name);
                    onUpdateServiceField(
                      serviceId,
                      "labor_hours",
                      service.labor_hours
                    );

                    onUpdateServiceField(
                      serviceId,
                      "labor_price",
                      service.labor_price
                    );
                    onUpdateServiceField(
                      serviceId,
                      "free_member_service",
                      service.free_member_service
                    );
                    onUpdateServiceField(
                      serviceId,
                      "is_canned_service",
                      service.is_canned_service
                    );

                    if (service.parts && service.parts.length > 0) {
                      onAddPart(serviceId);

                      const partToAdd = service.parts[0];

                      onUpdatePartField(serviceId, 0, "name", partToAdd.name);

                      onUpdatePartField(serviceId, 0, "price", partToAdd.price);
                      onUpdatePartField(
                        serviceId,
                        0,
                        "quantity",
                        partToAdd.quantity
                      );

                      onUpdatePartField(serviceId, 0, "flat_part", true);
                    }

                    handleChange(null, 0);
                  }}
                >
                  {flatServiceList.map((item, i) => (
                    <MenuItem key={i} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </TabPanel>
        </CardContent>
      </Collapse>

      <ConfirmationDialog
        open={confirmationModalOpen}
        onCancel={handleConfirmationCancel}
        onOk={handleConfirmationOk}
      />
    </Card>
  );
};

Service.propTypes = {
  serviceId: PropTypes.number.isRequired,
};

export default Service;
