import React from "react";
import { EventAvailable } from "@material-ui/icons";

const RecurringSchedule = (props) => {
  const { recurringItems } = props;

  return (
    <>
      {recurringItems.length > 0 ? (
        <div className="my-6">
          {recurringItems.map((item) => {
            return (
              <div className="items-center">
                <div
                  style={{
                    borderBottom: "1px solid #ddd",
                  }}
                  className="py-2"
                >
                  <div className="m-3 flex">
                    <p className="flex-1 text-left">
                      <EventAvailable color="primary" /> {item.day_of_week}'s
                    </p>
                    <p className="flex-1 text-right">
                      {item.first_time} - {item.last_time}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export { RecurringSchedule };
