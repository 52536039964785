import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Typography, Box } from "@material-ui/core";
import { step7FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import TyreDetailImg from "../../../../../src/images/tiredetail.png"

const Step7 = ({
  styles,
  onUpdate,
  inspection,
  submitting,
  handleSave,
  goBack,
}) => {
  const [step7Fields, setStep7Fields] = useState({
    front_left_tire_tread: inspection.front_left_tire_tread || "",
    front_right_tire_tread: inspection.front_right_tire_tread || "",
    rear_left_tire_tread: inspection.rear_left_tire_tread || "",
    rear_right_tire_tread: inspection.rear_right_tire_tread || "",
  });

  const done =
    step7Fields.front_left_tire_tread !== "" &&
    step7Fields.front_right_tire_tread !== "" &&
    step7Fields.rear_left_tire_tread !== "" &&
    step7Fields.rear_right_tire_tread !== "";

  const updateField = (field, value) => {
    setStep7Fields({
      ...step7Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  const handleSubmit = () => {
    handleSave({ status: "completed" });
  };

  const options = ["11/32", "10/32", "9/32","8/32","7/32","6/32","5/32","4/32 (Low)","3/32 (Low)","2/32 (Urgent)","1/32 (Urgent)"]

  if(inspection.customer_type == 'fleet'){
    return (
      
      <Box>
        <Typography className={styles.heading}>Tires</Typography>
          <Box display="flex" justifyContent="center">

          <img
            src={TyreDetailImg}
            alt="Note attachment"
            style={{ width: '40%' }}
          />
          </Box>

          <Typography className="text-lg mt-5">Please measure & input accurately</Typography>
          {Object.keys(step7FieldToLabelMap).map((field) => {
            return (
            <Box style={{marginTop: "2rem"}}>
              <FormControl style={{width: "50%"}}>
                <InputLabel style={{marginBottom: "2rem"}}>{step7FieldToLabelMap[field].label}</InputLabel>
                <Select
                  value={step7Fields[field]}
                  onChange={(event) => updateField(field, event.target.value)}
                >
                  {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      <Typography className="text-sm">{option}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            );
          })}
        <FmcFullWidthButton
          text="Save"
          onClick={handleSubmit}
          variant="contained"
          disabled={submitting || !done}
        />
  
        <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
      </Box>
    );
  }else{

    return (
      
      <Box>
        <Typography className={styles.heading}>Tires</Typography>
  
        {Object.keys(step7FieldToLabelMap).map((field) => {
          return (
            <InspectionQuestion
              labelMap={step7FieldToLabelMap}
              styles={styles}
              onUpdate={updateField}
              field={field}
              fieldState={step7Fields}
            />
          );
        })}
  
        <FmcFullWidthButton
          text="Save"
          onClick={handleSubmit}
          variant="contained"
          disabled={submitting || !done}
        />
  
        <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
      </Box>
    );
  }

  
};

export { Step7 };
