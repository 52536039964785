export const quoteConstants = {
  LOADING: "QUOTE_LOADING",

  RESET: "QUOTE_RESET",

  SHOW_MESSAGE_BOX: "QUOTE_SHOW_MESSAGE_BOX",
  HIDE_MESSAGE_BOX: "QUOTE_HIDE_MESSAGE_BOX",

  NEXT_STEP: "QUOTE_NEXT_STEP",
  PREV_STEP: "QUOTE_PREV_STEP",

  CAR: "QUOTE_CAR",
  APPOINTMENT: "QUOTE_APPOINTMENT",
  MECHANIC: "QUOTE_MECHANIC",

  CAR_SYMPTOMS: "QUOTE_CAR_SYMPTOMS",

  SEARCH_VEHICLE_SUCCESS: "QUOTE_SEARCH_VEHICLE_SUCCESS",

  SEARCHING_VEHICLE_ERROR: "QUOTE_SEARCHING_VEHICLE_ERROR",
  SEARCHING_VEHICLE: "QUOTE_SEARCHING_VEHICLE",

  GET_SERVICES_SUCCESS: "QUOTE_GET_SERVICES_SUCCESS",
  GET_SERVICES_ERROR: "QUOTE_GET_SERVICES_ERROR",

  ADD_SERVICE: "QUOTE_ADD_SERVICE",
  DELETE_SERVICE: "QUOTE_DELETE_SERVICE",
  SET_ESTIMATE_AMOUNT: "QUOTE_SET_ESTIMATE_AMOUNT",

  ADD_CUSTOMER_DATA: "ADD_CUSTOMER_DATA",

  DISABLE_CONTINUE: "DISABLE_CONTINUE",

  CAR_IDENTIFICATION_METHOD: "CAR_IDENTIFICATION_METHOD",
};
