import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import AfterNoteModal from "../components/AfterNoteModal";
import DiagNoteInPayment from "../Payments/components/DiagNoteInPayment";
import callApi from "../../../_apis/api_base";

const DiagnosisNote = () => {
  const query = new URLSearchParams(useLocation().search);
  const appointment_id = query.get("appointment_id");
  const [diagNotes, setDiagNotes] = useState({
    appointment_id,
    diagResult: "",
    nextSteps: "",
    allCodes: "",
    testsPerformed: "",
    concerns: "",
    symptoms: "",
    diagConfidence: "",
    diagConfidenceReason: "",
    fixOnSiteExplain: "",
    couldntDiagnoseExplain: "",
  });
  const [estimate, setEstimate] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    callApi({
      url: `/mechanic/diag_notes/${appointment_id}`,
      method: "GET",
    }).then((res) => {
      if (res) {
        setDiagNotes({
          ...diagNotes,
          diagResult: res.data.attributes.diag_result,
          nextSteps: res.data.attributes.next_steps,
          allCodes: res.data.attributes.all_codes,
          testsPerformed: res.data.attributes.tests_performed,
          concerns: res.data.attributes.concerns,
          symptoms: res.data.attributes.symptoms,
        });

        setEstimate(res.data.attributes.estimate);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Box className="px-4 pt-24 pb-24">
      {!loading ? (
        <Container>
          <Typography variant="h4">Diagnosis Summary</Typography>
          <DiagNoteInPayment
            diagNotes={diagNotes}
            setDiagNotes={setDiagNotes}
            estimate={estimate}
            nextStep={() => setOpen(true)}
            appointment_id={appointment_id}
          />
        </Container>
      ) : (
        <Box style={{ padding: "20px", textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}

      <AfterNoteModal
        open={open}
        onClose={setOpen}
        job_id={appointment_id}
        noteType="Diagnosis"
        afterPath={`/mechanic/job/${appointment_id}`}
      />
    </Box>
  );
};

export { DiagnosisNote };

export default DiagnosisNote;
