import React from "react";
import moment from "moment";
import { Link, Box } from "@material-ui/core";
import clsx from "clsx";

const SearchJobCard = ({ job }) => {
  const {
    car,
    customer_name,
    time_for_mechanic,
    appointment_day,
    problem_name,
  } = job.attributes;
  const completed =
    job.attributes.status === "diagnosis_complete" ||
    job.attributes.status === "completed";
  const { id } = job;

  const getBorderClass = () => {
    if (completed) {
      return "border-fmc-dark-green";
    }

    if (job.attributes.status === "cancelled") {
      return "border-fmc-red";
    }

    return "border-grey-300";
  };

  const getDateText = () => {
    if (job.attributes.status === "cancelled") {
      return "CANCELLED";
    }

    const dateFormatted = moment(appointment_day).format("MMM Do");

    let text = "";

    if (dateFormatted === "Invalid date") {
      text += `${appointment_day}`;
    } else {
      text += `${dateFormatted}`;
    }

    text += ` ${time_for_mechanic}`;

    return text;
  };

  const getDateTextClass = () => {
    if (job.attributes.status === "cancelled") {
      return "text-fmc-red";
    }

    if (completed) {
      return "text-fmc-dark-green";
    }

    return "text-fmc-gray-dark";
  };

  const contentDiv = () => {
    return (
      <div
        className={clsx(
          "flex flex-col m-4 border rounded-lg shadow p-4 text-lato-regular",
          getBorderClass()
        )}
      >
        <div className="flex-1 flex flex-row justify-between">
          <a className="text-blue-600 text-xl flex-1"># {id}</a>
          <p className={clsx("flex-1 text-sm text-right", getDateTextClass())}>
            {getDateText()}
          </p>
        </div>
        <div className="flex-1">
          <p className="text-xl my-6">{`${customer_name}`}</p>
          <p className="text-lg my-6">{`${car.year} ${car.make} ${car.model}`}</p>
          <p className="text-sm my-6">{`${problem_name.substring(0, 70)} ${
            problem_name.length > 70 ? "..." : ""
          }`}</p>
        </div>
      </div>
    );
  };

  return job.attributes.status === "cancelled" ? (
    <Box>{contentDiv()}</Box>
  ) : (
    <Link
      style={{ textDecoration: "none", color: "black" }}
      href={`/mechanic/job/${id}`}
    >
      {contentDiv()}
    </Link>
  );
};

export { SearchJobCard };
