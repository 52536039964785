import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  CircularProgress,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { apiGetAppointment } from "../../../_apis";
import { apiAddMechanicMsgEstimate } from "../../../_apis/mechanics/estimates";
import clsx from "clsx";
import { Close, ExpandMore } from "@material-ui/icons";
import config from "config";

export default function ViewEstimateModal({
  className,
  open,
  appointment_id,
  onClose,
  reload,
  setReload,
  handleTextEstimate,
  textedEstimate,
  afterText,
  afterTextAction,
  shouldTextEstimate,
}) {
  const [loading, setLoading] = useState(true);
  const [estimate, setEstimate] = useState(null);
  const [services, setServices] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [member, setMember] = useState();
  const [warranty, setWarranty] = useState();
  const [walkthrough, setWalkthrough] = useState("");
  const [reason, setReason] = useState("");
  const [isReasonSubmitted, setIsReasonSubmitted] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const func = async () => {
      const { data: rspAppointment } = await apiGetAppointment(appointment_id);

      setEstimate(rspAppointment.attributes.estimate);

      setServices(rspAppointment.attributes.estimate?.services);

      setMember(rspAppointment.attributes.member_job);

      setWarranty(
        rspAppointment.attributes.appointment_type === "warranty_job"
      );

      setLoading(false);
      setReload(false);
    };

    if (reload) {
      func();
    }
  }, [appointment_id, reload]);

  const handleSubmitReason = async (reason) => {
    const str = walkthrough === "yes" ? "walked through" : reason;
    const res = await apiAddMechanicMsgEstimate(estimate.id, str);
    if (res.success === true) {
      setIsReasonSubmitted(true);
    }
  };

  const getBorder = (status) => {
    switch (status) {
      case "pending":
        return "1px solid #FF9C17";
      case "rejected":
        return "2px solid #FF0000";
      default:
        return "2px solid #005959";
    }
  };

  return (
    <Dialog
      styles={{ padding: "10px" }}
      className={className}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle style={{ fontWeight: 700 }}>
        Job #{appointment_id} Summary
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div className="w-full h-full justify-center items-center flex">
            <CircularProgress color="primary" />
          </div>
        )}

        {estimate && services && !isContinue && (
          <>
            <Box>
              {Object.keys(services).map((service) => {
                return (
                  <Accordion
                    className="my-6"
                    style={{
                      border: getBorder(services[service].status),
                      borderRadius: 8,
                      marginTop: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={
                        isMobile
                          ? "flex my-1 text-sm font-heavy "
                          : "flex my-1 text-lg font-heavy"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          className={clsx(
                            "text-sm",
                            services[service].status === "rejected" &&
                              "line-through"
                          )}
                        >
                          {services[service].status === "rejected" && (
                            <Close color="error" />
                          )}{" "}
                          {service}
                          <>
                            <br />
                            <span
                              style={{
                                color:
                                  services[service].tag === "urgent"
                                    ? "red"
                                    : "#FF9C17",
                              }}
                            >
                              ({services[service].tag})
                            </span>
                          </>
                          {services[service].is_warranty && (
                            <>
                              <br />
                              <small className="italic">{` (Potential Warranty)`}</small>
                            </>
                          )}
                          {services[service].status === "pending" && (
                            <>
                              <br />
                              <small className="italic">{` (Pending Approval)`}</small>
                            </>
                          )}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ marginTop: "5px", fontWeight: "bold" }}
                          >
                            $
                            {parseFloat(services[service].labor_price).toFixed(
                              2
                            )}
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {services[service].parts.length > 0 && (
                        <Typography
                          className="text-sm"
                          style={{
                            color: "#A5A7A7",
                            fontFamily: "Inter",
                          }}
                        >
                          PARTS
                        </Typography>
                      )}
                      {services[service].parts.length > 0 ? (
                        services[service].parts.map((part) => {
                          return (
                            <div className="my-2">
                              <div className="flex">
                                <p className="flex-1 text-sm">
                                  {part.name}
                                  {part.part_number && ` (${part.part_number})`}
                                </p>
                                <p className="flex-2 text-sm">
                                  ${part.unit_price}
                                  {part.quantity && ` (x ${part.quantity})`}
                                </p>
                              </div>
                              <hr
                                style={{
                                  background: " #D4D5D5",

                                  height: 1,
                                }}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      <Typography
                        className="text-sm"
                        style={{
                          color: "#A5A7A7",
                          fontFamily: "Inter",
                        }}
                      >
                        LABOR
                      </Typography>
                      <div className="my-2">
                        <div className="flex">
                          <p className="flex-1 text-sm">Labor hours:</p>
                          <p className="flex-2  text-sm">
                            {services[service].labor_hours}
                          </p>
                        </div>
                      </div>

                      <div className="my-2">
                        <div className="flex">
                          <p className="flex-1 text-sm">Labor price:</p>
                          <p className="flex-2 text-sm ">
                            ${services[service].labor_price}
                          </p>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </>
        )}
      </DialogContent>

      {estimate && !isContinue && (
        <Container>
          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
            }}
          />

          <div
            className={
              isMobile
                ? "flex my-1 text-sm font-heavy "
                : "flex my-1 text-lg font-heavy"
            }
          >
            <p className="flex-1  " style={{ minWidth: "75%" }}>
              {member ? "Member Price" : "Non-Member Price"}:
            </p>
            <p className="flex-2 ">
              $
              {member
                ? parseFloat(estimate.total_member_price).toFixed(2)
                : parseFloat(estimate.non_member_price).toFixed(2)}
            </p>
          </div>

          <div className="flex my-1 text-sm ">
            <p className="flex-1" style={{ minWidth: "75%" }}>
              Services:
            </p>
            <p className="flex-2 ">
              ${parseFloat(estimate.subtotal).toFixed(2)}
            </p>
          </div>

          {estimate.convenience_fee > 0 ? (
            <div className="flex my-1">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Convenience Fee:
              </p>
              <p className="flex-2 text-sm"> ${estimate.convenience_fee}</p>
            </div>
          ) : (
            <></>
          )}

          {estimate.tax > 0 ? (
            <div className="flex my-1 text-sm">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Taxes:
              </p>
              <p className="flex-2 text-sm">${estimate.tax}</p>
            </div>
          ) : (
            <div className="flex my-1 text-sm">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Labor Fee:
              </p>
              <p className="flex-2 text-sm">${90.0}</p>
            </div>
          )}

          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
            }}
          />

          {!member && !warranty && (
            <>
              <div className="flex my-1 text-sm ">
                <p className="flex-1" style={{ minWidth: "75%" }}>
                  Member Price:
                </p>
                <p className="flex-2 ">
                  {!isMobile && <s>${estimate.non_member_price}</s>}
                  {"  "}${parseFloat(estimate.total_member_price).toFixed(2)}
                </p>
              </div>

              <div className="flex my-1  text-sm ">
                <p className="flex-1" style={{ minWidth: "75%" }}>
                  Members Save
                </p>
                <p className="flex-2">
                  {" "}
                  ${parseFloat(estimate.member_savings).toFixed(2)}{" "}
                </p>
              </div>
            </>
          )}
        </Container>
      )}

      {isReasonSubmitted ? (
        <Box style={{ margin: "10px auto", textAlign: "center" }}>
          <Typography
            color="primary"
            style={{ margin: "8px auto", width: "80%" }}
          >
            Thank you for your response
          </Typography>
          <Button
            style={{ margin: "8px auto", width: "95%" }}
            onClick={() => {
              window.location.href = `/mechanic/job/${appointment_id}`;
            }}
            variant="contained"
          >
            Back to Job
          </Button>
        </Box>
      ) : (
        <>
          {shouldTextEstimate && isContinue && (
            <>
              <Typography
                className="italic"
                style={{
                  textAlign: "center",
                  marginTop: "4px",
                  marginBottom: "4px",
                }}
              >
                * Please bring the customer out and review the services
              </Typography>

              {!isSending && (
                <Button
                  style={{ margin: "8px auto", width: "95%" }}
                  onClick={() => {
                    setIsSending(true);
                    handleTextEstimate();
                  }}
                  variant="contained"
                  disabled={isSending}
                >
                  Text Customer Estimate
                </Button>
              )}
              {isSending && !isReasonSubmitted && (
                <>
                  <FormControl
                    component="fieldset"
                    style={{ margin: "8px auto", width: "90%" }}
                  >
                    <FormLabel component="legend">
                      Were you able to walk through the Estimate with the
                      customer in person?
                    </FormLabel>
                    <RadioGroup
                      aria-label="walkthrough"
                      name="walkthrough"
                      value={walkthrough}
                      onChange={(e) => setWalkthrough(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  {walkthrough && (
                    <>
                      {walkthrough === "no" && (
                        <div
                          style={{ margin: "inherit", alignItems: "center" }}
                        >
                          <TextField
                            label="Please input why"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                          />
                        </div>
                      )}
                      <div style={{ margin: "auto" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ margin: "8px auto", width: "100%" }}
                          onClick={() => handleSubmitReason(reason)}
                          disabled={walkthrough === "no" && !reason}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )}

              <Button
                style={{ margin: "8px auto", width: "95%" }}
                onClick={() => {
                  setIsContinue(false);
                }}
                variant="outlined"
              >
                Back
              </Button>

              <Button
                variant="text"
                color="primary"
                style={{ margin: "8px auto", width: "95%" }}
                onClick={() =>
                  window.open(
                    `${config.newAppPath}/appointment/estimate/${appointment_id}`,
                    "_blank"
                  )
                }
              >
                See what the customer sees
              </Button>

              <DialogContentText
                className="italic"
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              >
                Above link will open another tab on your browser.
              </DialogContentText>
            </>
          )}

          {!isReasonSubmitted && !isContinue && shouldTextEstimate && (
            <Button
              style={{ margin: "8px auto", width: "95%" }}
              onClick={() => {
                setIsContinue(true);
              }}
              variant="contained"
              disabled={loading}
            >
              Continue
            </Button>
          )}

          {!isContinue && (
            <Button
              style={{ margin: "8px auto", width: "95%" }}
              onClick={afterTextAction}
              variant="outlined"
            >
              {afterText}
            </Button>
          )}
        </>
      )}
    </Dialog>
  );
}
