import { callApiV2 } from "../api_base";

export const apiSearchCustomer = (data) => {
  return callApiV2({
    url: "/mechanic/search_customers",
    method: "POST",
    data,
  });
};

export const apiCreateCustomerWithCar = (data) => {
  return callApiV2({
    url: "/user",
    method: "POST",
    data,
  });
};

export const apiCreateCarForCustomer = (data, query = "/") => {
  return callApiV2({
    url: `/user/cars${query}`,
    method: "POST",
    data,
  });
};

export const apiUpdateCarAppointment = (id, data) => {
  return callApiV2({
    url: `/user/cars/${id}`,
    method: "PUT",
    data,
  });
};

export const apiGetCardOnFile = (id) => {
  return callApiV2({
    url: `user/card_on_file/${id}`,
    method: "GET",
  });
};
