import { combineReducers } from "redux";
import { mechanic_user } from "./mechanic_user.reducer";
import mechanic_appointments from "./mechanic_appointments";
import authReducer from "./auth";
import apiReducer from "./api";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({
  api: apiReducer,
  auth: authReducer,
  mechanic_user,
  mechanic_appointments,
});

export default persistReducer(persistConfig, rootReducer);
