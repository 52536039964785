import ImgBadgeAdvMoney from '../../src/images/badges/advantage-money.svg';
import ImgBadgeAdvRing from '../../src/images/badges/advantage-ring.svg';
import ImgBadgeAdvTime from '../../src/images/badges/advantage-time.svg';
import ImgBadgeShopMoney from '../../src/images/badges/advantage-shop-money.svg';
import ImgBadgeShopLocation from '../../src/images/badges/advantage-shop-location.svg';
import ImgBadgeShopRigid from '../../src/images/badges/advantage-shop-rigid.svg';
import imgBadge from '../../src/images/badges';

export const itemsAdvantage = [
  {
    img: ImgBadgeAdvMoney,
    title: 'Bring the Auto Repair Shop To You',
    subtitle: [
      'Master & ASE-Certified mobile mechanics',
      '7 Days a Week, 9 AM - 9 PM',
      'We come to you on your schedule'
    ],
  },
  {
    img: ImgBadgeAdvRing,
    title: "Affordable & Honest Car Repair",
    subtitle: [
      'Upfront & transparent fixed-priced quotes',
      'No hidden fees or surprise charges'
    ],
  },
  {
    img: ImgBadgeAdvTime,
    title: 'Satisfaction Guaranteed',
    subtitle: [
      'We only charge once you\'re satisfied',
      '2 year / 24K Mile Warranty'
    ],
  },
];

export const itemsShop = [
  {
    img: ImgBadgeShopMoney,
    title: 'High overhead = Expensive',
    subtitle: '30% more expensive and come with additional fees & upsells.',
  },
  {
    img: ImgBadgeShopLocation,
    title: 'Lack of Convenience',
    subtitle: 'Repairs must be done at a physical auto repair shop.',
  },
  {
    img: ImgBadgeShopRigid,
    title: 'Rigid Hours',
    subtitle: 'Open limited hours and at times closed on weekends.',
  },
];

export const itemsLocation = [
  {
    state: 'Michigan',
    description: 'Metro Detroit',
    city: [
      'detroit',
      'sterling_heights',
      'warren',
      'ann_arbor',
      'dearborn',
      'livonia',
      'westland',
      'troy',
      'farmington_hills',
      'southfield',
      'taylor',
      'canton',
      'northville',
      'plymouth',
      'downriver',
      'grosse_pointe',
      'grand_rapids',
      'kentwood',
      'wyoming',
      'ypsilanti',
      'st_claire_shores',
      'shelby_township',
      'clinton_township',
      'rochester_hills',
      'novi',
      'pontiac',
      'waterford',
      'west_bloomfield',
      'royal oak',
      'oak_park',
      'ferndale',
      'clarkston',
      'kalamazoo',
      'battle_creek'
    ],
  },
  {
    state: 'Texas',
    description: 'Dallas Fort Worth & Houston',
    city: [
      'addison',
      'balch_springs',
      'carrollton',
      'cockrell_hill',
      'combine',
      'coppell',
      'dallas',
      'desoto',
      'duncanville',
      'farmers_branch',
      'ferris',
      'garland',
      'glenn_heights',
      'highland_park',
      'hutchins',
      'irving',
      'lancaster',
      'lewisville',
      'mesquite',
      'ovilla',
      'richardson',
      'rowlett',
      'sachse',
      'seagonville',
      'sunnyvale',
      'university_park',
      'wilmer',
      'wylie',
      'arlington',
      'azle',
      'bedford',
      'benbrook',
      'blue_mound',
      'burleson',
      'colleyville',
      'crowley',
      'dalworthington_gardens',
      'edgecliff_village',
      'euless',
      'everman',
      'flower_mound',
      'forest_hill',
      'fort_worth',
      'grand_prairie',
      'grapevine',
      'haltom_city',
      'haslet',
      'hurst',
      'keller',
      'kennedale',
      'lakeside',
      'lake_worth',
      'mansfield',
      'newark',
      'north_richardson_hills',
      'pantego',
      'pelican_bay',
      'plano',
      'reno',
      'richland_hills',
      'river_oaks',
      'saginaw',
      'sansom_park',
      'southlake',
      'trophy_club',
      'watauga',
      'westlake',
      'westover_hills',
      'westworth_village',
      'white_settlement',
      'sugar_land',
      'katy',
      'fresno',
      'bellaire',
      'brookshire',
      'richmond',
      'rosenberg',
      'houston',
      'kingwood',
      'cleveland',
      'missouri_city',
      'jersey_village',
      'cypress',
      'conroe',
      'humble',
      'atascocita',
      'aldine',
      'liberty',
      'dayton',
      'sheldon',
      'fifth_street',
      'dewalt',
      'greatwood',
      'booth',
      'crabb',
      'pecan_grove',
      'meadows_place',
      'four_corners',
      'mission_bend',
      'cumings',
      'pattison',
      'sunny_side',
      'cinco_ranch',
      'fulshear',
      'simonton',
      'splendora',
      'shenandoah',
      'spring',
      'westfield',
      'crosby',
      'rayford',
      'midline',
      'williams',
      'porter',
      'new_caney',
      'porter_heights',
      'timberlane_acres',
      'grangerland',
      'woodloch',
      'kenefick',
      'eastgate',
      'harmaston'
    ],
  },
];

export const mechanicBadges = [
  {
    name: 'Brakes',
    img: imgBadge.brakes,
  },
  {
    name: 'Suspension',
    img: imgBadge.suspension,
  },
  {
    name: 'Engine',
    img: imgBadge.engine,
  },
  {
    name: 'Electrical',
    img: imgBadge.electrical,
  },
  {
    name: 'Diagnosis',
    img: imgBadge.diagnosis,
  },
  {
    name: 'European Cars',
    img: imgBadge.european_cars,
  },
];

export const itemFooter = {
  menu: [
    {
      id: 1,
      text: "Blogs",
      link: "https://blog.fixmycar.io",
    },
    {
      id: 2,
      text: "Services",
      link: "/services",
    },
    {
      id: 3,
      text: "Testimonials",
      link: "/customers/video-testimonials",
    },
    {
      id: 4,
      text: "FAQ",
      link: "/faq",
    },
    {
      id: 5,
      text: "Membership",
      link: "/membership",
    },
    {
      id: 6,
      text: "Fleet Maintenance",
      link: "/fleet",
    },
    {
      id: 7,
      text: "Login",
      link: "/login",
    },
  ],
};
