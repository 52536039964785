import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({ open, onCancel, onOk }) => {
  return (
    <Dialog maxWidth="sm" open={open} onClose={onCancel}>
      <DialogTitle>
        Delete
      </DialogTitle>
      <DialogContent dividers>
        Are you sure you want to delete this?
      </DialogContent>
      <DialogActions>
        <Button style={{ marginRight: "5px" }} variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={onOk}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

ConfirmationDialog.defaultProps = {
  open: false,
  onCancel: () => { },
  onOk: () => { }
};

export default ConfirmationDialog;
