import { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

const ScrollToTop = ({ children, history }) => {
  let location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }, 500)
  }, [location]);

  return children;
};

export default withRouter(ScrollToTop);
