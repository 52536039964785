import React, { useEffect, useRef, useState } from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Add, Close, Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Compressor from "compressorjs";
import PropTypes from "prop-types";
import FmcFormControl from "./FmcFormControl";

const FORM_VALIDATION_TEXTS = {
  required: "This field is required",
};

const useStyles = makeStyles({
  attachment: {
    width: 48,
    height: 48,
    borderRadius: 3,
    border: "1px solid grey",
  },
  row: {
    backgroundColor: "#F6FAFF",
  },
  imageContainer: {
    position: "relative",
    display: "inline-block", // Adjust as needed
    margin: "8px",
  },
  deleteButton: {
    position: "absolute",
    top: -2,
    right: -2,
    width: 2,
    height: 2,
    backgroundColor: "grey", // Semi-transparent
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  },
});

const ImageListPreview = ({ value, onChange, required, error }) => {
  const [images, setImages] = useState(value || []);
  const filesRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(false);
  const classes = useStyles();

  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  const [uploadError, setUploadError] = useState(error || null);

  useEffect(() => onChange(images), [images]);

  useEffect(() => {
    if (images.length >= 3) {
      setLimit(true);
    }
  }, [images]);

  const btnAddClicked = () => {
    filesRef.current.click();
  };

  const compressImages = (files) => {
    const currentImages = [...images];

    files.forEach(function (img, index) {
      new Compressor(img, {
        quality: 0.2,
        success: (compressedResult) => {
          currentImages[index] = compressedResult;
          setImages([...currentImages]);
        },
        error: () => {
          this[index] = img;
        },
      });
    }, files);
  };

  const filesChanged = (event) => {
    const filesToSubmit = [...event.target.files];

    if (filesToSubmit) {
      let totalSize = 0.0;
      for (var i = 0; i < filesToSubmit.length - 1; i++) {
        var filesize = parseFloat(
          (filesToSubmit[i].size / 1024 / 1024).toFixed(4)
        ); // MB

        if (!isNaN(filesize)) {
          totalSize += filesize;
        }
      }

      // Nothing over 4 MB
      if (totalSize > 4) {
        setUploadError("File size too large, please upload smaller files.");
        return;
      }

      compressImages(filesToSubmit);

      setUploadError(null);

      setImages([...images, ...filesToSubmit]);
    }
  };

  const btnRemoveImageClicked = (index) => {
    setImages(images.filter((_, idx) => index !== idx));
  };

  const renderImages = () => {
    return images.map((item, index) => (
      <div className={classes.imageContainer} key={index}>
        <img
          className={classes.attachment}
          src={URL.createObjectURL(item)}
          alt="Note attachment"
          onClick={() => openImageModal(item)}
        />
        <IconButton
          className={classes.deleteButton}
          onClick={() => btnRemoveImageClicked(index)}
        >
          <Close style={{ width: 10, height: 10, color: "#fff" }} />
        </IconButton>
      </div>
    ));
  };

  const renderAddRow = () => {
    return (
      <React.Fragment>
        {limit && (
          <Typography
            style={{ fontSize: 12, color: "lightgray", fontFamily: "inter" }}
          >
            Limit reached *
          </Typography>
        )}
        <FmcFormControl className={classes.row}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {renderImages()}
            <IconButton onClick={btnAddClicked} disabled={limit}>
              <Add />
            </IconButton>
            {images.length === 0 && (
              <Typography
                style={{ fontSize: 14, color: "grey", fontFamily: "inter" }}
              >
                Add Pictures
              </Typography>
            )}
          </Box>
        </FmcFormControl>
        <input
          className="hidden"
          type="file"
          multiple
          onChange={filesChanged}
          ref={filesRef}
          accept=".png, .jpg, .jpeg"
        />
        {(uploadError || (required && error)) && (
          <Box pt={0.5} pl={1}>
            <Typography color="error">
              {uploadError || FORM_VALIDATION_TEXTS.required}
            </Typography>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box style={{ width: "90%", margin: "auto", marginBottom: 20 }}>
      {renderAddRow()}
      <Dialog open={isModalOpen} onClose={closeImageModal}>
        <DialogContent>
          {selectedImage && (
            <img
              className={classes.modalImage}
              src={URL.createObjectURL(selectedImage)}
              alt="Preview"
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

ImageListPreview.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
};

export default ImageListPreview;
