import { mechanicsTypes } from "../_types";

const initialState = {
  jobs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case mechanicsTypes.GET_MECHANIC_JOBS: {
      const { payload } = action;
      return {
        ...state,
        jobs: [...payload.data],
      };
    }
    default:
      return state;
  }
};
