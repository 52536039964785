import React from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const AfterPost = ({ open, onClose, text }) => {
  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>{text.header}</DialogTitle>
        <DialogContent>
          <Box m={3}>
            <p className="m-3">{text.paragraph}</p>
          </Box>

          <FmcFullWidthButton
            text="Taking You Back to Job..."
            onClick={() => {}}
            variant="outlined"
            disabled={true}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AfterPost;
