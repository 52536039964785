import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Add, Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Compressor from "compressorjs";
import PropTypes from "prop-types";
import FmcFormControl from "./FmcFormControl";

const FORM_VALIDATION_TEXTS = {
  required: "This field is required",
};

const useStyles = makeStyles({
  attachment: {
    width: 48,
    height: 48,
    borderRadius: 9,
  },
  row: {
    backgroundColor: "#F6FAFF",
  },
});

const ImageList = ({ value, onChange, required, error }) => {
  const [images, setImages] = useState(value || []);
  const filesRef = useRef(null);
  const classes = useStyles();
  const [uploadError, setUploadError] = useState(error || null);

  useEffect(() => onChange(images), [images]);

  const btnAddClicked = () => {
    filesRef.current.click();
  };

  const compressImages = (files) => {
    const currentImages = [...images];

    files.forEach(function (img, index) {
      new Compressor(img, {
        quality: 0.2,
        success: (compressedResult) => {
          currentImages[index] = compressedResult;
          setImages([...currentImages]);
        },
        error: () => {
          this[index] = img;
        },
      });
    }, files);
  };

  const filesChanged = (event) => {
    const filesToSubmit = [...event.target.files];

    if (filesToSubmit) {
      let totalSize = 0.0;
      for (var i = 0; i < filesToSubmit.length - 1; i++) {
        var filesize = parseFloat(
          (filesToSubmit[i].size / 1024 / 1024).toFixed(4)
        ); // MB

        if (!isNaN(filesize)) {
          totalSize += filesize;
        }
      }

      // Nothing over 4 MB
      if (totalSize > 4) {
        setUploadError("File size too large, please upload smaller files.");
        return;
      }

      compressImages(filesToSubmit);

      setUploadError(null);

      setImages([...images, ...filesToSubmit]);
    }
  };

  const btnRemoveImageClicked = (index) => {
    setImages(images.filter((_, idx) => index !== idx));
  };

  const renderImages = () => {
    return images.map((item, index) => (
      <FmcFormControl className={classes.row} key={index}>
        <Box display="flex" justifyContent="space-between">
          <img
            className={classes.attachment}
            src={URL.createObjectURL(item)}
            alt="Note attachment"
          />
          <IconButton onClick={() => btnRemoveImageClicked(index)}>
            <Delete />
          </IconButton>
        </Box>
      </FmcFormControl>
    ));
  };

  const renderAddRow = () => {
    return (
      <React.Fragment>
        <FmcFormControl className={classes.row}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Choose files</Typography>
            <IconButton onClick={btnAddClicked}>
              <Add />
            </IconButton>
          </Box>
        </FmcFormControl>
        <input
          className="hidden"
          type="file"
          multiple
          onChange={filesChanged}
          ref={filesRef}
          accept=".png, .jpg, .jpeg"
        />
        {(uploadError || (required && error)) && (
          <Box pt={0.5} pl={1}>
            <Typography color="error">
              {uploadError || FORM_VALIDATION_TEXTS.required}
            </Typography>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box style={{ width: '90%', margin: '0 auto' }}>
      {renderImages()}
      {renderAddRow()}
    </Box>
  );
};

ImageList.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
};

export default ImageList;
