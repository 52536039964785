import React, { useState } from "react";
import {
  Box,
  Link,
  OutlinedInput,
  Select,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { jobTypes } from "../utility";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { ScheduleNewJob } from "./ScheduleNewJob";
import moment from "moment";
import { VehicleForm } from "./VehicleForm";

function NewJobForm({ user }) {
  const [showSchedule, setShowSchedule] = useState();
  const [resetVehicle, setResetVehicle] = useState();
  const { car, name, zip_code, phone, email } = user.attributes;
  const [newJob, setNewJob] = useState({
    diagnosis_input: "",
    services: "",
    user_id: user.id,
    car_id: car.id,
    address: zip_code,
    appointment_day: moment(),
    phone,
    name,
    email,
  });

  const shouldDisableSchedule = () => {
    const typeSet = newJob.appointment_type;

    if (!typeSet) {
      return true;
    }

    return (
      (typeSet === "diagnosis" && newJob.diagnosis_input === "") ||
      (typeSet === "repair" && newJob.services === "")
    );
  };

  return (
    <>
      {resetVehicle ? (
        <VehicleForm setFoundUser={() => {}} resetForm={() => {}} user={user} />
      ) : (
        <>
          {showSchedule ? (
            <ScheduleNewJob job={newJob} setJob={setNewJob} />
          ) : (
            <>
              <Box className="my-6">
                <Typography variant="h4" className="my-3">
                  Vehicle
                </Typography>
                {user.attributes.total_unique_cars && (
                  <Select
                    fullWidth
                    value={newJob.car_id}
                    onChange={(e) =>
                      setNewJob({
                        ...newJob,
                        car_id: e.target.value,
                      })
                    }
                  >
                    {user.attributes.total_unique_cars.map((i, index) => {
                      return (
                        <MenuItem key={index} value={i.id}>
                          {i.vin
                            ? `${i.year} ${i.make} ${i.model} | ${i.vin}`
                            : `${i.year} ${i.make} ${i.model} ${i.engine_size}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                <Link
                  style={{ margin: "5px 0", cursor: "pointer" }}
                  onClick={() => setResetVehicle(true)}
                >
                  Add New Vehicle
                </Link>
              </Box>
              <Typography variant="h4" className="my-3">
                Service
              </Typography>

              <Select
                fullWidth
                value={newJob.appointment_type}
                onChange={(e) =>
                  setNewJob({
                    ...newJob,
                    appointment_type: e.target.value.enum,
                    level: e.target.value.level,
                  })
                }
              >
                {jobTypes.map((jobType, index) => {
                  return (
                    <MenuItem key={index} value={jobType}>
                      {jobType.label}
                    </MenuItem>
                  );
                })}
              </Select>

              {newJob.appointment_type && (
                <Box className="my-6">
                  <OutlinedInput
                    fullWidth
                    value={
                      newJob.appointment_type === "diagnosis"
                        ? newJob.diagnosis_input
                        : newJob.services
                    }
                    onChange={(ev) => {
                      if (newJob.appointment_type === "diagnosis") {
                        setNewJob({
                          ...newJob,
                          diagnosis_input: ev.target.value,
                        });
                      } else {
                        setNewJob({
                          ...newJob,
                          services: ev.target.value,
                        });
                      }
                    }}
                    placeholder="Customer concern"
                    type="text"
                  />

                  <FmcFullWidthButton
                    text="Schedule Service"
                    onClick={() => setShowSchedule(true)}
                    variant="contained"
                    disabled={shouldDisableSchedule()}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export { NewJobForm };
