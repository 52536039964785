import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import { LocalizationProvider } from "@material-ui/lab";
import { store, persistor } from "./_helpers";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { MediaQueryContextProvider } from "./_contexts/MediaQueryContext";
import { ThemeProvider } from "@material-ui/core";
import { createFMCTheme } from "./_theme";
import { StyledEngineProvider } from "@material-ui/core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ErrorBoundary from "./ErrorBoundary";

const theme = createFMCTheme();

export default (props) => (
  <ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MediaQueryContextProvider>
                <BrowserRouter>
                  <GoogleOAuthProvider clientId="304353214312-s3qdm9u9o570hdv2jls18joinrkplfh3.apps.googleusercontent.com">
                    <App {...props} />
                  </GoogleOAuthProvider>
                </BrowserRouter>
              </MediaQueryContextProvider>
            </LocalizationProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>
);
