import { useState, useEffect } from "react";
import { callApiV2 } from "../_apis/api_base";

export const useGeolocation = (id) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [geoTracked, setGeoTracked] = useState();

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });

    if (!geoTracked) {
      callApiV2({
        url: `/appointments/locations/${id}/track_location`,
        method: "PUT",
        data: {
          inspection_coordinates: [coords.latitude, coords.longitude],
        },
      })
        .then(() => setGeoTracked(true))
        .catch((e) => {});
    }
  };
  const onError = (error) => {
    setError(error.message);
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("geolocation is not supported on this device");
      return;
    }

    // API made it seem like this wasnt needed but I guess it is
    // initially set position
    // geo.getCurrentPosition(onChange, onError);

    // https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
    // const options = {
    //   enableHighAccuracy: true,
    //   timeout: 5000,
    //   maximumAge: 0,
    // }
    // add options as an additional parameter to watcher as required
    const watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);

  return { position, error };
};
