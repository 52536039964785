import callApi, { callApiV2 } from "../api_base";

export const apiGetEstimate = async (estimate_id) => {
  return callApi({
    url: `/mechanic/estimates/${estimate_id}`,
    method: "GET",
  });
};

export const apiCreateEstimate = async (estimate) => {
  return callApi({
    url: "/mechanic/estimates",
    method: "POST",
    data: { estimate },
  });
};

export const apiAddMechanicMsgEstimate = async (estimate_id, msg) => {
  return callApi({
    url: `/mechanic/estimates/${estimate_id}/add_mechanic_msg`,
    method: "POST",
    data: { msg },
  });
};

export const apiAdminCreateEstimate = async (estimate) => {
  return callApiV2({
    url: "/estimates/admin_create",
    method: "POST",
    data: { estimate },
  });
};

export const apiAdminUpdateEstimate = async (id, estimate) => {
  return callApiV2({
    url: `/estimates/admin_update/${id}`,
    method: "PUT",
    data: { estimate },
  });
};

export const apiUpdateEstimate = async (id, estimate) => {
  return callApi({
    url: `/mechanic/estimates/${id}`,
    method: "PATCH",
    data: { estimate },
  });
};

export const apiSendEstimateNotification = async (
  estimate_id,
  for_later = false
) => {
  return callApi({
    url: `/mechanic/estimates/${estimate_id}/text_customer_estimate`,
    method: "POST",
    data: { for_later },
  });
};

export const apiCreateEstimateService = async (data) => {
  return callApiV2({
    url: "/estimate_services",
    method: "POST",
    data,
  });
};
