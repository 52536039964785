import React, { Component } from "react";
import { callApiV2 } from "./_apis/api_base";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    callApiV2({
      url: "/errors",
      method: "POST",
      data: {
        error: `${error}. Backtrace: ${errorInfo.componentStack}`,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ margin: "20px", padding: "20px" }}>
          <h1>
            Something went wrong. We've been notified. A refresh or log out and
            log back in may solve it.
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
