import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { apiGetAppointment } from "../../../_apis";
import EstimateForm from "./components/EstimateForm";
import {
  apiAdminCreateEstimate,
  apiAdminUpdateEstimate,
} from "../../../_apis/mechanics/estimates";

export const handleBeforeUnload = (e) => {
  e.preventDefault();
  const message =
    "Are you sure you want to leave? Make sure your changes are saved first.";
  e.returnValue = message;
  return message;
};

const AdminEstimates = ({ appointment_id, admin_user_id, isManager }) => {
  const [appointment, setAppointment] = useState(null);
  const [estimate_id, setEstimateId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [estimateData, setEstimateData] = useState({});

  useEffect(() => {
    const func = async () => {
      if (appointment_id) {
        const rsp = await apiGetAppointment(appointment_id);

        setAppointment({
          ...rsp.data.attributes,
          id: appointment_id,
        });

        if (rsp.data.attributes.estimate) {
          const estimate = rsp.data.attributes.estimate;

          setEstimateData({
            discount: estimate.discount,
            recommendations: estimate.recommendations,
            note_to_management: estimate.note_to_management,
            pmg: estimate.pmg,
            pmg_price: estimate.pmg_price,
            services: Object.keys(estimate.services).map((key) => ({
              ...estimate.services[key],
              name: key,
            })),
          });

          setEstimateId(estimate.id);
        }
      }
    };
    func();
  }, [appointment_id]);

  const handleSubmit = (data) => {
    setIsSaving(true);

    if (estimate_id) {
      apiAdminUpdateEstimate(estimate_id, {
        ...data,
        appointment_id,
      })
        .then(() => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.assign(`/admin/appointments/${appointment_id}`);
        })
        .catch(() => {
          alert("Error creating an estimate, contact the office.");
        });
    } else {
      apiAdminCreateEstimate({
        ...data,
        appointment_id,
      })
        .then(() => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.assign(`/admin/appointments/${appointment_id}`);
        })
        .catch(() => {
          alert("Error creating an estimate, contact the office.");
        });
    }
  };

  if (!appointment) {
    return !appointment_id ? (
      <Box style={{ marginBottom: "100px" }}>
        <Container>
          <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
            <h2>No Customer or Job ID</h2>
            <br />
            <Typography>
              To use this feature, please provide a valid job ID or customer ID.
              You can navigate to the corresponding customer profile or job, and
              click on the 'Estimate' link to return to this page and proceed
              with modifying the estimate.
            </Typography>
          </div>
        </Container>
      </Box>
    ) : (
      <Box pt={14} pb={15} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ marginBottom: "100px" }}>
      <Container>
        <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
          <a href={`/admin/appointments/${appointment_id}`}>
            <h2 style={{ margin: "10px 0" }}>Job #{appointment_id}</h2>
          </a>
          <h2 style={{ margin: "10px 0" }}>{appointment.customer_name}</h2>
          <h2 style={{ margin: "10px 0" }}>{appointment.customer_phone}</h2>
          <h2 style={{ margin: "10px 0" }}>{appointment.problem_name}</h2>
          <h2 style={{ margin: "10px 0" }}>{appointment.car.one_string}</h2>
        </div>
      </Container>

      {appointment.status === "completed" ? (
        <Box style={{ marginBottom: "100px" }}>
          <Container>
            <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
              <h2>Job Already Completed</h2>
              <br />
              <Typography>
                This job has been completed, estimate no longer editable.
              </Typography>
            </div>
          </Container>
        </Box>
      ) : (
        <EstimateForm
          appointment={appointment}
          isSubmitting={isSaving}
          onSubmit={handleSubmit}
          initialData={estimateData}
          setAppointment={setAppointment}
          isManager={isManager}
          admin_user_id={admin_user_id}
        />
      )}
    </Box>
  );
};

export default AdminEstimates;
