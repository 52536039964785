import React, { useState } from "react";
import {
  Container,
  Select,
  MenuItem,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import PartsLookup from "../Estimates/components/PartsLookup";
import { useSelector } from "react-redux";
import { FileCopy } from "@material-ui/icons";

const ServiceManual = () => {
  const jobs = useSelector((state) => state.mechanic_appointments.jobs);
  const mechanic = useSelector((state) => state.auth.user_data);

  const formattedJobs = jobs.map((job) => {
    return {
      id: job.id,
      car: job.attributes.car.one_string,
    };
  });

  const [jobId, setJobId] = useState(formattedJobs[0]?.id);

  return (
    <Box className="px-4 pt-8 mb-10 pb-24">
      <Typography variant="h4" className="text-center">
        Service Manual
      </Typography>

      <Box className="my-6">
        <Typography className="my-3" variant="h5">
          O'Reilly
        </Typography>

        <Typography className="my-3">
          O'Reilly account #: {mechanic.parts_account_no}
        </Typography>
        <Typography className="my-3">
          O'Reilly PO: {mechanic.parts_po}
        </Typography>

        <Divider />

        <Typography className="my-3" variant="h5">
          Mitchell
        </Typography>
        <Typography className="my-3">
          Email: {mechanic.mitchell_email}
        </Typography>
        <Typography className="my-3">
          Copy password{" "}
          <FileCopy
            className="m-3"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigator.clipboard
                .writeText(mechanic.mitchell_password)
                .then(() => alert("Password copied!"))
            }
          />
        </Typography>
        <Typography className="my-3">
          <a
            className="text-fmc-blue underline hover:cursor"
            href="https://prodemand.com/"
            target="_blank"
          >
            Login to Mitchell
          </a>
        </Typography>

        <Divider />

        <Typography className="my-3" variant="h5">
          First Call Online
        </Typography>
        <Typography className="my-3">
          Username: {mechanic.first_call_username}
        </Typography>
        <Typography className="my-3">
          Copy password{" "}
          <FileCopy
            className="m-3"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigator.clipboard
                .writeText(mechanic.first_call_password)
                .then(() => alert("Password copied!"))
            }
          />
        </Typography>
        <Typography className="my-3">
          <a
            className="text-fmc-blue underline hover:cursor"
            href="https://www.firstcallonline.com/FirstCallOnline/"
            target="_blank"
          >
            Login to First Call
          </a>
        </Typography>
      </Box>

      <Box className="my-6">
        <Typography variant="h5">Look Up Parts/Labor</Typography>
        <Typography>
          To look up a service for one of your vehicles here, select the vehicle
          and the service below
        </Typography>
      </Box>

      <Select
        fullWidth
        variant="outlined"
        onChange={(e) => setJobId(e.target.value)}
        className="my-6"
      >
        <p className="sm:text-xl text-lg my-2 text-left text-fmc-blue-darker-2">
          Select Vehicle from your Jobs
        </p>
        {formattedJobs.map((item, i) => (
          <MenuItem key={i} value={item.id}>
            Job #{item.id} {item.car}
          </MenuItem>
        ))}
      </Select>
      <PartsLookup appointment_id={jobId} />
    </Box>
  );
};

export default ServiceManual;
