import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";

const DateSelector = (props) => {
  const { date, onPress, tabVal } = props;
  const minDay = moment().startOf("week").subtract(1, "week").toDate();
  const maxDay = moment().endOf("week").add(3, "weeks").toDate();

  const text = tabVal === 0 ? "" : `${moment(date).format("MMM Do")}`;

  return (
    <div className="flex flex-row px-10 pt-2 my-6">
      {tabVal === 1 && (
        <button
          className="flex-1"
          disabled={moment(date).isSame(minDay, "day")}
          onClick={() => onPress(moment(date).subtract(1, "day").toDate())}
        >
          <ArrowBackIosIcon color="secondary" />
        </button>
      )}
      <p
        style={{ flex: 3, textAlign: "center" }}
        className="text-xl font-inter"
      >
        {text}
      </p>
      {tabVal === 1 && (
        <button
          className="flex-1"
          disabled={moment(date).isSame(maxDay, "day")}
          onClick={() => onPress(moment(date).add(1, "day").toDate())}
        >
          <ArrowForwardIosIcon color="secondary" />
        </button>
      )}
    </div>
  );
};

export { DateSelector };
