import React, { useState } from "react";
import { Typography, Box, TextField } from "@material-ui/core";
import { step6FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const Step6 = ({
  styles,
  onUpdate,
  inspection,
  submitting,
  handleNext,
  goBack,
}) => {
  const [step6Fields, setStep6Fields] = useState({
    front_pads: inspection.front_pads || "",
    front_pads_text_describe: inspection.front_pads_text_describe || "",
    front_pads_service_due: inspection.front_pads_service_due || "",
    front_rotors: inspection.front_rotors || "",
    front_rotors_text_describe: inspection.front_rotors_text_describe || "",
    front_rotors_service_due: inspection.front_rotors_service_due || "",
    rear_pads: inspection.rear_pads || "",
    rear_pads_text_describe: inspection.rear_pads_text_describe || "",
    rear_pads_service_due: inspection.rear_pads_service_due || "",
    rear_rotors: inspection.rear_rotors || "",
    reat_rotors_text_describe: inspection.rear_rotors_text_describe || "",
    rear_rotors_service_due: inspection.rear_rotors_service_due || "",
    general_brakes_text_describe: inspection.general_brakes_text_describe || "",
  });

  const done =
    step6Fields.front_pads !== "" &&
    step6Fields.front_rotors !== "" &&
    step6Fields.rear_pads !== "" &&
    step6Fields.rear_rotors !== "";

  const updateField = (field, value) => {
    setStep6Fields({
      ...step6Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  return (
    <Box>
      <Typography className={styles.heading}>Brakes</Typography>

      {Object.keys(step6FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step6FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step6Fields}
          />
        );
      })}

      <Box className="text-center my-6">
        <TextField
          placeholder="Enter additional comments for brakes"
          value={step6Fields.general_brakes_text_describe}
          onChange={(event) => {
            updateField("general_brakes_text_describe", event.target.value);
          }}
          variant="outlined"
          className={styles.width90}
          type="text"
        />
      </Box>

      <FmcFullWidthButton
        text="Save & Next"
        onClick={handleNext}
        variant="contained"
        disabled={submitting || !done}
      />

      <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
    </Box>
  );
};

export { Step6 };
