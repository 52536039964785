import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import callApi from "../../../../_apis/api_base";
import clsx from "clsx";
import { Close, ExpandMore } from "@material-ui/icons";
import { DatePicker } from "@material-ui/lab";
import moment from "moment";
import { SELECT_TIME_OPTIONS } from "../../utility";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

export default function UpsellEstimate({ estimate, member, appointmentId }) {
  const [services, setServices] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [booked, setBooked] = useState(false);
  const [error, setError] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const func = async () => {
      setServices(estimate?.services);
    };

    if (!services && estimate) {
      func();
    }
  }, [services, estimate]);

  const getTotal = () => {
    if (member) {
      return parseFloat(estimate.total_member_price).toFixed(2);
    }

    return parseFloat(estimate.non_member_price).toFixed(2);
  };

  return (
    <Container className="" fullWidth>
      {estimate && services && (
        <>
          <Box>
            <Box className="my-6">
              <Typography className="my-3">
                We've already created a recommended estimate for the customer.
              </Typography>
            </Box>
            {Object.keys(services).map((service) => {
              return (
                <Accordion
                  className="my-6"
                  style={{
                    border: "2px solid var(--Emerald-Green, #005959)",
                    borderRadius: 8,
                    marginTop: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={
                      isMobile
                        ? "flex my-1 text-sm font-heavy "
                        : "flex my-1 text-lg font-heavy"
                    }
                  >
                    <Typography
                      className={clsx(
                        "text-sm",
                        services[service].status === "rejected" &&
                          "line-through"
                      )}
                    >
                      {services[service].status === "rejected" && (
                        <Close color="error" />
                      )}{" "}
                      {service}
                      {services[service].is_warranty && (
                        <>
                          <br />
                          <small className="italic">{` (Potential Warranty)`}</small>
                        </>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {services[service].parts.length > 0 && (
                      <Typography
                        className="text-sm"
                        style={{
                          color: "#A5A7A7",
                          fontFamily: "Inter",
                          marginBottom: 5,
                        }}
                      >
                        PARTS
                      </Typography>
                    )}
                    {services[service].parts.length > 0 ? (
                      services[service].parts.map((part) => {
                        return (
                          <div className="my-2">
                            <div className="flex">
                              <p className="flex-1 text-sm text-left">
                                {part.name}
                                {part.part_number && ` (${part.part_number})`}
                              </p>
                              <p className="flex-2 text-sm">
                                ${part.unit_price}
                                {part.quantity && ` (x ${part.quantity})`}
                              </p>
                            </div>
                            <hr
                              style={{
                                background: " #D4D5D5",

                                height: 1,
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}

                    <Typography
                      className="text-sm"
                      style={{
                        color: "#A5A7A7",
                        fontFamily: "Inter",
                        marginBottom: 5,
                      }}
                    >
                      LABOR
                    </Typography>
                    <div className="my-2">
                      <div className="flex">
                        <p className="flex-1 text-sm text-left">Labor hours:</p>
                        <p className="flex-2  text-sm">
                          {services[service].labor_hours}
                        </p>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="flex">
                        <p className="flex-1 text-sm text-left">Labor price:</p>
                        <p className="flex-2 text-sm ">
                          ${services[service].labor_price}
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </>
      )}

      {estimate && (
        <Container>
          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
              textAlign: "left",
            }}
          />

          <div
            className={
              isMobile
                ? "flex my-1 text-sm font-heavy "
                : "flex my-1 text-lg font-heavy"
            }
          >
            <p className="flex-1 text-left">Total:</p>
            <p className="flex-2">${getTotal()}</p>
          </div>
        </Container>
      )}

      <div>
        <Box className="my-6">
          <Typography className="my-3">
            Select a time for the estimate to be completed.
          </Typography>
          <Typography className="my06" style={{ marginTop: 30 }}>
            Select a date
            <span className="text-fmc-red italic"> * </span>
          </Typography>
          <DatePicker
            minDate={moment()}
            value={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
            }}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />

          <FormControl fullWidth variant="outlined" style={{ marginTop: 30 }}>
            <Typography className="my-6" style={{ marginTop: 10 }}>
              Select a time
              <span className="text-fmc-red italic"> * </span>
            </Typography>
            <Select
              labelId="time-label"
              value={selectedTime}
              onChange={(e) => {
                setSelectedTime(e.target.value);
              }}
              label="Select Time"
            >
              {SELECT_TIME_OPTIONS.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>

      <Box className="my-6">
        <FmcFullWidthButton
          disabled={!selectedDate || !selectedTime}
          onClick={() => {
            callApi({
              url: `/mechanic/estimates/${estimate.id}/schedule`,
              method: "POST",
              data: {
                estimate: {
                  for_later_date: selectedDate,
                  for_later_time: selectedTime,
                },
              },
            }).then((res) => {
              if (res.errors) {
                setError(true);
              } else {
                setBooked(true);
              }
            });
          }}
          variant="contained"
          text="Schedule"
          color="primary"
        />

        {booked && (
          <Typography className="my-3">Your upsell has been booked.</Typography>
        )}

        {error && (
          <Typography className="my-3">
            There was an error booking your upsell. Try again or message us in
            Slack.
          </Typography>
        )}

        <FmcFullWidthButton
          onClick={() =>
            (window.location.href = `/mechanic/job/${appointmentId}`)
          }
          variant="outlined"
          text="Back to Job"
          color="primary"
        />
      </Box>
    </Container>
  );
}
