import React, { useState } from "react";
import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Build } from "@material-ui/icons";
import { SameDayModal } from "./SameDayModal";

const useStyles = makeStyles(() => {
  return {
    root: {
      width: "100%",
      border: "1px solid #005959",
      padding: "10px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
    },
    badge: {
      borderRadius: "50%",
      width: "15px",
      height: "15px",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px",
      lineHeight: "18px",
      "& h1": {
        fontWeight: "bold",
        fontSize: "14px",
      },
      "& p": {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
    btnToggle: {
      marginLeft: "auto",
    },
    modal: {
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      outline: "none",
      backgroundColor: "white",
      border: "1px solid #E8E8E8",
      padding: "2rem",
      boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.02)",
      borderRadius: 10,
    },
  };
});

function AvailableToday({ jobs }) {
  const classes = useStyles();
  const [open, setOpen] = useState();

  return (
    <div className={classes.root}>
      <div className={classes.badge} style={{ background: "#24CF93" }}></div>
      <div className={classes.textContainer}>
        <h1>Jobs Available Today!</h1>
        <p>Click the wrench to pick up some extra work!</p>
      </div>
      <Fab
        className={classes.btnToggle}
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
        variant="extended"
      >
        <Build />
      </Fab>

      <SameDayModal
        className={classes.modal}
        boxClassNames={classes.paper}
        open={open}
        onClose={() => setOpen()}
        jobs={jobs}
      />
    </div>
  );
}

export default AvailableToday;
