import callApi, { callApiV2 } from "../api_base";

export const apiUpdateMechanic = async (params) => {
  return callApi({
    url: "/mechanic/update",
    method: "PUT",
    data: params,
  });
};

export const apiGetRevShare = () => {
  return callApiV2({
    url: "/mechanics/rev_share_metrics",
    method: "GET",
  });
};

export const apiGetLeaderboard = () => {
  return callApiV2({
    url: "/mechanics/leaderboard",
    method: "GET",
  });
};

export const apiGetPointsLeaderboard = () => {
  return callApiV2({
    url: "/mechanics/points_leaderboard",
    method: "GET",
  });
};

export const apiGetRewards = () => {
  return callApiV2({
    url: "/mechanics/rewards_count",
    method: "GET",
  });
};

export const apiCreateOilChangeAppointment = (params) => {
  return callApiV2({
    url: `/mechanics/create_oil_change_appointment`,
    method: "POST",
    data: params,
  });
};

export const apiGetPayrolls = () => {
  return callApiV2({
    url: "/mechanic/payroll_summaries/mechanic",
    method: "GET",
  });
};
