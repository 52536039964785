import React from "react";

const Policies = () => {
  return (
    <>
      <div style={{ paddingBottom: 130 }} className="pt-24">
        <div className="mt-3 px-10">
          <div className="font-tiempos mb-5 text-4xl">Policies</div>
          <div className="font-tiempos mt-5 text-2xl">
            <h3>Before the Job</h3>
          </div>
          <div className="font-inter text-base">
            <ul className="list-disc">
              <li className="p-2">
                Always take action within an hour of receiving text
                notification.
              </li>
              <li className="p-2">
                Please review the details of the assignment BEFORE you confirm
                the job.
              </li>
              <li className="p-2">
                Call the customer once you have confirmed the job, to confirm
                details of appointment.
              </li>
              <li className="p-2">
                Take this opportunity to ask questions re: the repair or
                diagnostic that is requested, so you can bring the proper tools
                and parts.
              </li>
              <li className="p-2">
                Once you confirm a job, it's your responsibility to communicate
                with the customer.
              </li>
              <li className="p-2">
                While we strongly discourage reschedules, if you have an issue
                and need toreschedule the customer, ALWAYS call AND text the
                customer if you have to reschedule.
              </li>
              <li className="p-2">
                When you are en-route to the job, press the button "On my way".
                This sends a textmessage to the customer to alert them you are
                on your way to them.
              </li>
              <li className="p-2">
                Always dress professionally, wear a mask and gloves while on
                site. Wear Goodhood branding attire, so the customer can easily
                identify you.
              </li>
            </ul>
          </div>
          <div className="font-tiempos mt-5 text-2xl">
            <h3>Before and During the Job</h3>
          </div>
          <div className="font-inter text-base">
            <ul className="list-disc">
              <li className="p-2">
                Upon arrival, confirm the details of the assignment with the
                customer.
              </li>
              <li className="p-2">
                While maintaining social distancing, do a walk-around with the
                customer, making sure to point out areas of concern (ie body
                damage, low tires, safety items). This will provide you the
                opportunity to possibly up-sell at the end of the visit, and
                will also eliminate the “ever since” issues that sometimes
                arrive after a service visit.
              </li>
              <li className="p-2">
                Prior to starting a repair, ALWAYS perform a quick diagnostic
                scan, screenshot it and upload to the app. We want to have this
                on file, on all jobs, for liability reasons.
              </li>
              <li className="p-2">
                If you are onsite for a diagnostic visit - we charge the full
                amount for the diagnostic visit, upon completion of the diag.
                Once you have charged the diagnostic charge, you will then
                provide an on-site repair estimate for the customer.
              </li>
              <li className="p-2">
                Send the repair estimate to the customer and confirm they have
                received it via either email or text message.
              </li>
              <li className="p-2">
                The customer ​MUST ​approve any additional charges, via our
                website, before you can start on any work. We do not take verbal
                acceptance of jobs. To get approval, please use the estimate
                page in your app and click the Text Customer Estimate button.
              </li>
              <li className="p-2">
                While you are working on a customer’s car, please take
                precautions to keep the jobsite clean of debris and fluids.
              </li>
              <li className="p-2">
                If you do not have all the parts to complete the job while
                on-site, clearly communicate with the customer when you plan on
                returning to finish the job. These customers will take priority
                over any new assignments you may take. Always make sure you stay
                in touch with the customer re: parts delays, schedule delays,
                etc. Make sure they understand when you plan on returning to
                finish the job and adhere to that schedule.
              </li>
            </ul>
          </div>
          <div className="font-tiempos mt-5 text-2xl">
            <h3>After the Job</h3>
          </div>
          <div className="font-inter text-base">
            <ul className="list-disc">
              <li className="p-2">
                Take a test drive, if needed, after the service is complete
                (must for all suspension, steering, brakes and drivability
                issues).
              </li>
              <li className="p-2">
                If you have other recommendations for repairs or maintenance,
                make sure you point them out to the customer and give a brief
                explanation of why you are recommending the service(s). This
                will also help you build a clientele of customers for future
                work.
              </li>
              <li className="p-2">
                Prior to the leaving job site, always make sure you charge the
                customer. If the cardis declined, ask for another card and use
                the app to change the card information.
              </li>
            </ul>
          </div>
          <div className="font-tiempos mt-5 text-2xl">
            <h3>DONT's</h3>
          </div>
          <div className="font-inter text-base">
            <ul className="list-disc">
              <li className="p-2">
                Don't confirm the job unless you're sure you can do the job!
              </li>
              <li className="p-2">
                Don't guarantee a repair for a diagnosis job, use phrases like
                "I believe it needs anew starter"
              </li>
              <li className="p-2">
                Don't leave the site unless the payment goes through &amp; don't
                accept cash
              </li>
              <li className="p-2">Don't smoke or eat at the job site.</li>
              <li className="p-2">
                Don’t scan or take pictures of the customers ID or credit cards.
              </li>
              <li className="p-2">
                Don't perform a diagnosis or any other service unless the
                customer has booked that service with us.
              </li>
            </ul>
          </div>
          <div className="font-tiempos mt-5 text-2xl">
            <h3>FAQs</h3>
          </div>
          <div>
            <div className="p-1">
              <h5 className="font-tiempos text-lg md:text-2xl">
                I performed a diagnostic service and want to create an estimate
                for parts and labor, how should I go about pricing for the
                services?
              </h5>
              <p className="font-inter text-base">
                For parts -- you can look up O'Reilly's website or call O'Reilly
                and check for parts pricing as a customer (not commercial
                prices). You should input the exact price you get in the app,
                the app will automatically add a margin. For labor -- when you
                create an estimate, the labor price will automatically populate
                once you enter the labor hrs
              </p>
            </div>
            <div className="p-1">
              <h5 className="font-tiempos text-lg md:text-2xl">
                I need more details about the job that I'm given, what should I
                do?
              </h5>
              <p className="font-inter text-base">
                Call the customer, leave a voicemail saying you're from Goodhood
                and you need more info. We're here to guide you but you should
                always reach out to the customer first
              </p>
            </div>
            <div className="p-1">
              <h5 className="font-tiempos text-lg md:text-2xl">
                Customer is trying to pay cash or customer's card is getting
                declined. What should I do?
              </h5>
              <p className="font-inter text-base">
                Let the customer know that we don't accept any cash payments and
                ask for card details. You can change the credit/debit card info
                in one of the checklist items on the job and then try charging
                the customer.
              </p>
            </div>
            <div className="p-1">
              <h5 className="font-tiempos text-lg md:text-2xl">
                I got an estimate approved by the customer but I need to add
                more parts. Can I do that?
              </h5>
              <p className="font-inter text-base">
                Yes, at any given point - you can always modify the estimate so
                feel free to go to your job's checklist and add the parts by
                clicking on "Modify the estimate". Please don't buy any more
                parts that what you have been approved for. You should include
                all small parts like fluids, belts, gaskets when you're creating
                the estimate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Policies };
