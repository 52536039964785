import React, { useState } from "react";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import callApi, { callApiV2 } from "../../../../_apis/api_base";
import AfterPost from "./AfterPost";
import clsx from "clsx";
import CreditCardModal from "../../components/CreditCardModal";

const TaskListItem = ({ index, task, jobId }) => {
  const [text, setText] = useState(task.task);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [editCardModalOpen, setEditCardModalOpen] = useState(false);
  const [errors, setErrors] = useState("");

  const handleSuccess = () => {
    setEditCardModalOpen(false);
  };

  const handleAuth = (task) => {
    setDisabled(true);

    if (task.api_version === "v2") {
      callApiV2({
        url: task.url_path,
        method: "POST",
      })
        .then((res) => {
          setText(task.task);
          setOpen(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((e) => {
          setDisabled(false);
          setText(task.task);
        });
    } else {
      callApi({
        url: task.url_path,
        method: "POST",
      }).then((res) => {
        if (res?.errors) {
          setDisabled(false);
          setErrors(res.errors);
          setEditCardModalOpen(true);
        } else {
          setOpen(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        setText(task.task);
      });
    }
  };

  const renderTask = (task) => {
    if (task.active) {
      if (task.link_method === "get") {
        return (
          <a href={task.url_path} className="text-blue-600">
            {task.task}
          </a>
        );
      } else {
        return (
          <div>
            <AfterPost
              open={open}
              onClose={setOpen}
              text={task.after_post_text}
            />

            <p
              className={clsx(
                disabled ? "text-fmc-gray-dark" : "text-blue-600 cursor-pointer"
              )}
              onClick={() => (disabled ? null : handleAuth(task))}
            >
              {disabled ? "Loading..." : text}
            </p>
            <CreditCardModal
              open={editCardModalOpen}
              onClose={() => setEditCardModalOpen(false)}
              jobId={jobId}
              handleSuccess={handleSuccess}
              errors={errors}
            />
          </div>
        );
      }
    } else {
      if (task.done) {
        return <p>{task.done_text}</p>;
      } else {
        return <p>{task.task}</p>;
      }
    }
  };

  return (
    <>
      <div className="flex items-center">
        <div style={{ flex: 6 }} className="py-2">
          <div className="flex">
            <p>{index}.</p>
            <div style={{ width: 4 }}></div>
            {renderTask(task)}
          </div>
          {task.helper_text && !task.done && (
            <p className="m-2 italic">
              * {task.helper_text.text}{" "}
              <a className="text-blue-600" href={task.helper_text.link_url}>
                {task.helper_text.link_text}
              </a>
            </p>
          )}
        </div>
        <div style={{ flex: 1 }}>
          {task.done || task.show_check ? (
            <CheckBoxRoundedIcon color="success" />
          ) : (
            <CheckBoxOutlineBlankRoundedIcon />
          )}
        </div>
      </div>
    </>
  );
};

export default TaskListItem;
