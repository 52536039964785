import React, { useState } from "react";
import config from "config";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Card, CardContent, Box } from "@material-ui/core";
import useStyles from "../../components/styles";
import { useDispatch } from "react-redux";
import callApi from "../../../../_apis/api_base";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const CheckoutForm = ({ appointment_id, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (requestInProgress) {
      return false;
    }

    setRequestInProgress(true);
    setErrors(null);
    setSuccess(null);

    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (token) {
      dispatch(
        callApi({
          url: "/payments/update_cc",
          method: "POST",
          data: { appointment_id, token: token.id },
        })
          .then((res) => {
            setRequestInProgress(false);
            if (res.errors) {
              setErrors(res.errors.join(". "));
            } else {
              setSuccess("Card updated");
              onSuccess();
            }
          })
          .catch((e) => {
            setRequestInProgress(false);
            setErrors("Invalid credit card");
          })
      );
    } else {
      setErrors(error.message);
      setRequestInProgress(false);
    }

    return true;
  };

  return (
    <Box className="w-full text-center">
      <CardElement className="w-full border rounded-md px-2 py-5 border-fmc-blue-dark" />
      <p className="w-full mx-auto text-sm text-center text-fmc-red my-1 sm:my-2">
        {errors}
      </p>
      <p className="w-full mx-auto text-sm text-center text-fmc-dark-green my-1 sm:my-2">
        {success}
      </p>

      <FmcFullWidthButton
        text="Update"
        onClick={() => handleSubmit()}
        variant="contained"
        disabled={requestInProgress}
      />
    </Box>
  );
};

const UpdateCreditCard = ({ appointment_id, onSuccess }) => {
  const classes = useStyles();
  const stripePromise = loadStripe(config.stripe.pk);

  return (
    <>
      <Card className={classes.ccCardBox}>
        <CardContent>
          <Box mb={3}>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                appointment_id={parseInt(appointment_id)}
                onSuccess={onSuccess}
              />
            </Elements>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default UpdateCreditCard;
