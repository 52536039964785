import React, { useCallback, useEffect, useState, useContext } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Autocomplete,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  apiPartsLookup,
  apiSearchJobCodes,
} from "../../../../_apis/mechanics/epicor_searches";
import { MASTER_SERVICE_LIST } from "../../../../_constants/constants";

const PartsLookup = ({ appointment_id }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [jobCodes, setJobCodes] = useState([]);
  const [loadingJobCodes, setLoadingJobCodes] = useState(false);
  const [partsLookupData, setPartsLookupData] = useState(null);
  const [loadingPartsData, setLoadingPartsData] = useState(false);
  const [currentJobCode, setCurrentJobCode] = useState(null);
  const [currentSupplier, setCurrentSupplier] = useState("");
  const [queryString, setQueryString] = useState(null);

  const loadJobCodes = useCallback(async (term) => {
    if (term.length < 2) return;
    setLoadingJobCodes(true);
    const rsp = await apiSearchJobCodes(term);
    setLoadingJobCodes(false);
    setCurrentJobCode(null);
    setCurrentSupplier("");
    setPartsLookupData(null);

    const newJobCodes = rsp.data.attributes.epicor_job_codes;

    if (newJobCodes) {
      if (newJobCodes.length === 1) {
        setCurrentJobCode(newJobCodes[0]);
        setJobCodes(newJobCodes);
      } else {
        setJobCodes(newJobCodes);
      }
    }
  }, []);

  const handleChangeSearchTerm = useCallback(
    (newSearchTerm) => {
      setSearchTerm(newSearchTerm);
      loadJobCodes(newSearchTerm);
    },
    [loadJobCodes]
  );

  const handleSelectService = useCallback((jobCode) => {
    setCurrentJobCode(jobCode);
  }, []);

  useEffect(() => {
    const loadPartsLabor = async () => {
      if (!currentJobCode) return;

      setLoadingPartsData(true);

      const rsp = await apiPartsLookup(
        appointment_id,
        currentJobCode,
        currentSupplier,
        queryString
      );

      setLoadingPartsData(false);

      if (!(rsp instanceof Array)) {
        setPartsLookupData(null);
        return;
      }

      const data = rsp[0];

      if (data) {
        setPartsLookupData(data);
      }
    };
    loadPartsLabor();
  }, [appointment_id, jobCodes, currentJobCode, queryString, currentSupplier]);

  const renderParts = () => {
    if (partsLookupData === null) {
      return <div />;
    }
    return (
      <>
        <Box mt={3}>
          <Typography variant="h6" align="center" paragraph>
            Parts
          </Typography>
          {(!partsLookupData.parts || partsLookupData.parts.length === 0) && (
            <Typography variant="body2" align="center" paragraph>
              No parts information found.
            </Typography>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Number</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(partsLookupData.parts || []).map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.number}</TableCell>
                    <TableCell>{item.fmc_cost}</TableCell>
                    <TableCell>{item.notes}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box mb={2} mt={3}>
          <Typography variant="h6" align="center" paragraph>
            Labor (from Mitchell)
          </Typography>
          {(!partsLookupData.labor || partsLookupData.labor.length === 0) && (
            <Typography variant="body2" align="center" paragraph>
              No labor information found.
            </Typography>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Labor Operation</TableCell>
                  <TableCell>Estimated Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(partsLookupData.labor || []).map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{item.laborop}</TableCell>
                    <TableCell>{item.estimated}</TableCell>
                    <TableCell>{item.factory}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box mb={2}>
        <Autocomplete
          options={MASTER_SERVICE_LIST}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a service"
              variant="outlined"
            />
          )}
          onChange={(_, newValue) => handleChangeSearchTerm(newValue)}
          value={searchTerm || ""}
        />
      </Box>
      {loadingJobCodes && (
        <Box mb={2} display="flex" justifyContent="center">
          <CircularProgress size={20} />
        </Box>
      )}
      {jobCodes.length > 1 && (
        <>
          <Box mb={2}>
            <Typography>Click on a specific service to search parts</Typography>
          </Box>
          <Grid container>
            {jobCodes.map((item) => (
              <Grid key={item[1]} item xs={6} md={4} lg={3} xl={2}>
                <Link
                  className="m-3"
                  component="button"
                  onClick={() => handleSelectService(item)}
                >
                  {item[1]}
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {loadingPartsData && (
        <>
          <Box mt={3} mb={3} display="flex" justifyContent="center">
            <CircularProgress size={20} />
          </Box>
          <Box mt={3} mb={3} display="flex" justifyContent="center">
            <Typography>
              Looking up parts and labor. This may take a moment...
            </Typography>
          </Box>
        </>
      )}
      {renderParts()}
    </>
  );
};

PartsLookup.propTypes = {
  appointment_id: PropTypes.string.isRequired,
};

export default PartsLookup;
