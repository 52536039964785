import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  IconButton,
  CircularProgress,
  Container,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import clsx from "clsx";
import { ExpandMore } from "@material-ui/icons";
import { apiCreateEstimateService } from "../../../_apis/mechanics/estimates";
import { apiGetAppointment } from "../../../_apis";

const EstimateReview = ({ onNext, appointment, reload, setReload, onBack }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getFlatServices = (fleetPricing, admin, euro) => {
    const list = [
      {
        name: "Pre Purchase Inspection",
        labor_price: 120,
        labor_hours: 1.0,
        is_canned_service: true,
        tag: "recommended",
      },
      {
        name: "Fuel Induction Service",
        labor_price: 190,
        labor_hours: 1.5,
        is_canned_service: true,
        tag: "recommended",
      },
      {
        is_canned_service: true,
        name: "Engine Air Filter",
        labor_price: euro ? 65 : 45,
        labor_hours: 0.3,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Engine Air Filter",
            price: euro ? 49.99 : 29.99,
            quantity: 1,
          },
        ],
      },
      {
        is_canned_service: true,
        name: "Drain and Fill Brake System",
        labor_hours: 0.7,
        labor_price: 110,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Brake Fluid",
            price: 20,
            quantity: 1,
          },
        ],
      },
      {
        is_canned_service: true,
        name: "Drain and Fill Cooling System",
        labor_hours: 0.7,
        labor_price: 110,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Coolant",
            price: 20,
            quantity: 1,
          },
        ],
      },
      {
        is_canned_service: true,
        name: "Drain and Fill Power Steering",
        labor_hours: 0.7,
        labor_price: 110,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Fluid - Power Steering",
            price: 20,
            quantity: 1,
          },
        ],
      },
      {
        is_canned_service: true,
        name: "Cabin Air Filter",
        labor_hours: 0.3,
        labor_price: euro ? 65 : 45,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Cabin Air Filter",
            price: euro ? 59.99 : 39.99,
            quantity: 1,
          },
        ],
      },
      {
        is_canned_service: true,
        name: "Wiper Blades",
        labor_hours: 0.3,
        labor_price: 60.0,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Wiper Blades",
            price: 35.0,
            quantity: 1,
          },
        ],
      },
    ];

    if (fleetPricing) {
      if (fleetPricing.fleet_pads_rotors_labor_price) {
        list.unshift({
          is_canned_service: true,
          name: "Rear Pads And Rotors",
          labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
          labor_hours: 1.5,
          tag: "recommended",
          parts_attributes: [
            {
              name: "Passenger Car Brake Kit - Rear",
              price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
              quantity: 1,
            },
          ],
        });
        list.unshift({
          name: "Front Pads And Rotors",
          labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
          labor_hours: 1.5,
          is_canned_service: true,
          tag: "recommended",
          parts_attributes: [
            {
              name: "Passenger Car Brake Kit - Front",
              price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
              quantity: 1,
            },
          ],
        });
      }

      return list;
    } else if (!euro) {
      list.unshift({
        is_canned_service: true,
        name: "Front Pads And Rotors",
        labor_price: 155,
        labor_hours: 1.5,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Passenger Car Brake Kit - Front",
            price: 240,
            quantity: 1,
          },
        ],
      });
      list.unshift({
        is_canned_service: true,
        name: "Rear Pads And Rotors",
        labor_price: 155,
        labor_hours: 1.5,
        tag: "recommended",
        parts_attributes: [
          {
            name: "Passenger Car Brake Kit - Rear",
            price: 240,
            quantity: 1,
          },
        ],
      });
    }

    return list;
  };

  const MEMBER_OIL = {
    free_member_service: true,
    name: "Membership Oil Change",
    is_canned_service: true,
    labor_price: 0.0,
    labor_hours: 0.3,
    tag: "recommended",
  };

  const getRegularOil = (fleetPricing, car) => {
    if (fleetPricing) {
      const priceCol = car.european_make
        ? fleetPricing.fleet_euro_oil_change_price
        : fleetPricing.fleet_oil_change_price;
      if (!priceCol) {
        return null;
      } else {
        return {
          is_canned_service: true,
          name: "Oil Change",
          labor_price: priceCol,
          labor_hours: 0.3,
          tag: "recommended",
          parts_attributes: [],
        };
      }
    }

    return {
      is_canned_service: true,
      name: "Oil Change",
      labor_price: 90,
      labor_hours: 0.3,
      tag: "recommended",
      parts_attributes: [],
    };
  };

  const getCannedServices = () => {
    const serviceList = getFlatServices(
      appointment.fleet_pricing,
      "false",
      appointment.car?.european_make
    );
    const oilChange = getRegularOil(appointment.fleet_pricing, appointment.car);

    if (appointment.member_job && !serviceList.includes(MEMBER_OIL)) {
      serviceList.push(MEMBER_OIL);
    } else if (
      oilChange &&
      !serviceList.includes(oilChange) &&
      !appointment.car.european_make
    ) {
      serviceList.unshift(oilChange);
    }

    return serviceList;
  };

  const getBorder = (status) => {
    switch (status) {
      case "recommended":
        return "1px solid #FF9C17";
      case "urgent":
        return "2px solid #FF0000";
      default:
        return "2px solid #005959";
    }
  };

  if (!appointment) {
    return <></>;
  }

  const serviceList = getCannedServices();

  const [appt, setAppt] = useState(appointment);
  const [estimate, setEstimate] = useState(appointment.estimate);
  const [services, setServices] = useState(appointment.estimate?.services);
  const [loading, setLoading] = useState(false);

  const func = async () => {
    setLoading(true);
    const { data: rspAppointment } = await apiGetAppointment(appointment.id);
    setAppt(rspAppointment.attributes);

    setEstimate(rspAppointment.attributes.estimate);

    setServices(rspAppointment.attributes.estimate?.services);

    setReload(false);
    setLoading(false);
  };

  useEffect(() => {
    if (reload) {
      func();
    }
  }, [appt, reload]);

  const handleAddService = (service) => {
    apiCreateEstimateService({
      estimate_service: {
        estimate_id: estimate.id,
        name: service.name,
        labor_price: service.labor_price,
        labor_hours: service.labor_hours,
        tag: "recommended",
        is_canned_service: true,
      },
    }).then((res) => {
      func();
    });
  };

  if (!estimate) {
    return <></>;
  }

  return (
    <Box className="m-10">
      {loading ? (
        <Box pt={5} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {estimate && services && (
            <Box>
              {Object.keys(services).map((service) => {
                return (
                  <Accordion
                    className="my-6"
                    style={{
                      border: getBorder(services[service].status),
                      borderRadius: 8,
                      marginTop: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className={
                        isMobile
                          ? "flex my-1 text-sm font-heavy "
                          : "flex my-1 text-lg font-heavy"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography
                          className={clsx(
                            "text-sm",
                            services[service].status === "rejected" &&
                              "line-through"
                          )}
                        >
                          {services[service].status === "rejected" && (
                            <Close color="error" />
                          )}{" "}
                          {service}
                          <>
                            <br />
                            <span
                              style={{
                                color:
                                  services[service].tag === "urgent"
                                    ? "red"
                                    : "#FF9C17",
                              }}
                            >
                              ({services[service].tag})
                            </span>
                          </>
                          {services[service].is_warranty && (
                            <>
                              <br />
                              <small className="italic">{` (Potential Warranty)`}</small>
                            </>
                          )}
                          {services[service].status === "pending" && (
                            <>
                              <br />
                              <small className="italic">{` (Pending Approval)`}</small>
                            </>
                          )}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{ marginTop: "5px", fontWeight: "bold" }}
                          >
                            $
                            {parseFloat(services[service].labor_price).toFixed(
                              2
                            )}
                          </span>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {services[service].parts.length > 0 && (
                        <Typography
                          className="text-sm"
                          style={{
                            color: "#A5A7A7",
                            fontFamily: "Inter",
                          }}
                        >
                          PARTS
                        </Typography>
                      )}
                      {services[service].parts.length > 0 ? (
                        services[service].parts.map((part) => {
                          return (
                            <div className="my-2">
                              <div className="flex">
                                <p className="flex-1 text-sm">
                                  {part.name}
                                  {part.part_number && ` (${part.part_number})`}
                                </p>
                                <p className="flex-2 text-sm">
                                  ${part.unit_price}
                                  {part.quantity && ` (x ${part.quantity})`}
                                </p>
                              </div>
                              <hr
                                style={{
                                  background: " #D4D5D5",

                                  height: 1,
                                }}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      <Typography
                        className="text-sm"
                        style={{
                          color: "#A5A7A7",
                          fontFamily: "Inter",
                        }}
                      >
                        LABOR
                      </Typography>
                      <div className="my-2">
                        <div className="flex">
                          <p className="flex-1 text-sm">Labor hours:</p>
                          <p className="flex-2  text-sm">
                            {services[service].labor_hours}
                          </p>
                        </div>
                      </div>

                      <div className="my-2">
                        <div className="flex">
                          <p className="flex-1 text-sm">Labor price:</p>
                          <p className="flex-2 text-sm ">
                            ${services[service].labor_price}
                          </p>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </>
      )}
      <Typography variant="h6" style={{ marginTop: "20px" }}>
        Add-ons
      </Typography>
      <Box className="service-list">
        {serviceList.map((service, index) => {
          const isAdded =
            !!services[service.name] || !!services[service.name.toLowerCase()];
          if (isAdded) {
            return;
          } else {
            return (
              <Box
                key={index}
                className="service-item"
                onClick={() => !isAdded && handleAddService(service)}
                style={{
                  padding: "10px",
                  margin: "10px 0",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  cursor: isAdded ? "not-allowed" : "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: isAdded ? "#f0f0f0" : "white",
                }}
              >
                <Typography className={isMobile ? "text-sm" : ""}>
                  {service.name}{" "}
                  {isAdded && (
                    <>
                      <span style={{ color: "#FF0000", fontSize: "10px" }}>
                        (Added)
                      </span>
                    </>
                  )}
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className={isMobile ? "text-sm" : ""}
                    style={{ marginRight: "5px" }}
                  >
                    ${service.labor_price}
                  </Typography>
                  {!isAdded && (
                    <IconButton
                      aria-label="add"
                      size="small"
                      style={{ padding: "5px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddService(service);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </div>
              </Box>
            );
          }
        })}
      </Box>

      {estimate && (
        <Container>
          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
            }}
          />

          <div
            className={
              isMobile
                ? "flex my-1 text-sm font-heavy "
                : "flex my-1 text-lg font-heavy"
            }
          >
            <p className="flex-1  " style={{ minWidth: "75%" }}>
              {appointment.member_job ? "Member Price" : "Non-Member Price"}:
            </p>
            <p className="flex-2 ">
              $
              {appointment.member_job
                ? parseFloat(estimate.total_member_price).toFixed(2)
                : parseFloat(estimate.non_member_price).toFixed(2)}
            </p>
          </div>

          <div className="flex my-1 text-sm ">
            <p className="flex-1" style={{ minWidth: "75%" }}>
              Services:
            </p>
            <p className="flex-2 ">
              ${parseFloat(estimate.subtotal).toFixed(2)}
            </p>
          </div>

          {estimate.convenience_fee > 0 ? (
            <div className="flex my-1">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Convenience Fee:
              </p>
              <p className="flex-2 text-sm"> ${estimate.convenience_fee}</p>
            </div>
          ) : (
            <></>
          )}

          {estimate.tax > 0 ? (
            <div className="flex my-1 text-sm">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Taxes:
              </p>
              <p className="flex-2 text-sm">${estimate.tax}</p>
            </div>
          ) : (
            <div className="flex my-1 text-sm">
              <p className="flex-1 text-sm" style={{ minWidth: "75%" }}>
                Labor Fee:
              </p>
              <p className="flex-2 text-sm">${90.0}</p>
            </div>
          )}

          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
            }}
          />

          {!appointment.member_job &&
            !appointment.appointment_type !== "warranty_job" && (
              <>
                <div className="flex my-1 text-sm ">
                  <p className="flex-1" style={{ minWidth: "75%" }}>
                    Member Price:
                  </p>
                  <p className="flex-2 ">
                    {!isMobile && <s>${estimate.non_member_price}</s>}
                    {"  "}${parseFloat(estimate.total_member_price).toFixed(2)}
                  </p>
                </div>

                <div className="flex my-1  text-sm ">
                  <p className="flex-1" style={{ minWidth: "75%" }}>
                    Members Save
                  </p>
                  <p className="flex-2">
                    {" "}
                    ${parseFloat(estimate.member_savings).toFixed(2)}{" "}
                  </p>
                </div>
              </>
            )}
        </Container>
      )}

      <FmcFullWidthButton
        text="Next"
        onClick={() => {
          onNext();
          setReload(true);
        }}
        variant="contained"
      />
      <FmcFullWidthButton text="Back" onClick={onBack} variant="outlined" />
    </Box>
  );
};

export default EstimateReview;
