import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Box,
  Typography,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  TextField,
  Button,
} from "@material-ui/core";
import config from "config";
import { apiAddMechanicMsgEstimate } from "../../../../_apis/mechanics/estimates";

export default function TextEstimateModal({
  className,
  open,
  appointment_id,
  estimate_id,
  onClose,
  handleTextEstimate,
  afterText,
  afterTextAction,
}) {
  const [isSending, setIsSending] = useState(false);
  const [walkthrough, setWalkthrough] = useState("");
  const [reason, setReason] = useState("");
  const [isReasonSubmitted, setIsReasonSubmitted] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  const handleSubmitReason = (reason) => {
    const str = walkthrough === "yes" ? "walked through" : reason;
    console.log("here");
    apiAddMechanicMsgEstimate(estimate_id, str).then((res) => {
      console.log("submitted");
      setIsReasonSubmitted(true);
    });
  };

  return (
    <Dialog
      styles={{ padding: "10px" }}
      className={className}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle style={{ fontWeight: 700 }}>
        Job #{appointment_id} Text Estimate
      </DialogTitle>

      {isReasonSubmitted ? (
        <Box style={{ margin: "10px auto", textAlign: "center" }}>
          <Typography
            color="primary"
            style={{ margin: "8px auto", width: "80%" }}
          >
            Thank you for your response
          </Typography>
          <Button
            style={{ margin: "8px auto", width: "95%" }}
            onClick={() => {
              window.location.href = `/mechanic/job/${appointment_id}`;
            }}
            variant="contained"
          >
            Back to Job
          </Button>
        </Box>
      ) : (
        <>
          <Typography
            className="italic"
            style={{
              textAlign: "center",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            * Please bring the customer out and review the services
          </Typography>

          {!isSending && (
            <Button
              style={{ margin: "8px auto", width: "95%" }}
              onClick={() => {
                setIsSending(true);
                handleTextEstimate();
              }}
              variant="contained"
              disabled={isSending}
            >
              Text Customer Estimate
            </Button>
          )}

          {isSending && !isReasonSubmitted && (
            <>
              <FormControl
                component="fieldset"
                style={{ margin: "8px auto", width: "90%" }}
              >
                <FormLabel component="legend">
                  Were you able to walk through the Estimate with the customer
                  in person?
                </FormLabel>
                <RadioGroup
                  aria-label="walkthrough"
                  name="walkthrough"
                  value={walkthrough}
                  onChange={(e) => setWalkthrough(e.target.value)}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>

              {walkthrough && (
                <>
                  {walkthrough === "no" && (
                    <div style={{ margin: "inherit", alignItems: "center" }}>
                      <TextField
                        label="Please input why"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                  )}
                  <div style={{ margin: "auto" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ margin: "8px auto", width: "100%" }}
                      onClick={() => handleSubmitReason(reason)}
                      disabled={walkthrough === "no" && !reason}
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </>
          )}

          <Button
            variant="text"
            color="primary"
            style={{ margin: "8px auto", width: "95%" }}
            onClick={() =>
              window.open(
                `${config.newAppPath}/appointment/estimate/${appointment_id}`,
                "_blank"
              )
            }
          >
            See what the customer sees
          </Button>

          <DialogContentText
            className="italic"
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            Above link will open another tab on your browser.
          </DialogContentText>

          {!isContinue && !isSending && (
            <Button
              style={{ margin: "8px auto", width: "95%" }}
              onClick={afterTextAction}
              variant="outlined"
            >
              {afterText}
            </Button>
          )}
        </>
      )}
    </Dialog>
  );
}
