import { createTheme } from "@material-ui/core";
import colors from "../../src/constants/colors";

const FMCTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      dark: colors.darker,
    },
    secondary: {
      main: colors.secondary,
    },
  },
});

export { FMCTheme };
