import React, { useEffect, useRef } from "react";
import { BrowserBarcodeReader } from "@zxing/library";

function BarcodeScanner({ setScannedVin }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const codeReader = new BrowserBarcodeReader();

    const handleScan = (result) => {
      if (result) {
        const vinRegex = /[A-HJ-NPR-Z0-9]{17}/;
        if (vinRegex.test(result.getText())) {
          const vin = result.getText().replace(/^I/, "");
          setScannedVin(vin);
        }
      }
    };

    const handleError = (error) => {
      alert("Failed to scan VIN. Please try again or enter it manually.");
    };

    codeReader.decodeFromVideoDevice(undefined, video, handleScan, handleError);

    return () => {
      codeReader.reset();
    };
  }, [setScannedVin]);

  return (
    <div>
      <video
        ref={videoRef}
        style={{
          width: "100%",
          maxWidth: "500px",
          overflow: "hidden",
          margin: "auto",
        }}
      />
    </div>
  );
}

export default BarcodeScanner;
