import { authTypes, mechanicsTypes } from "../_types";
import {
  apiMechanicUpdatePassword,
  apiGetMechanicJobs,
  apiGetMechanic,
  apiMechanicLogout,
} from "../_apis";

export const getMechanicJobs = (dispatch, getState) => {
  apiGetMechanicJobs().then((jobs) => {
    dispatch({
      type: mechanicsTypes.GET_MECHANIC_JOBS,
      payload: jobs,
    });
  });
};

export const mechanicUpdatePassword = (password, successCallback) => ({
  isApiCall: true,
  type: mechanicsTypes.UPDATE_MECHANIC_PASSWORD,
  apiCall: () => apiMechanicUpdatePassword(password),
});

export const getMechanicData = (failureCb) => ({
  isApiCall: true,
  type: authTypes.GET_MECHANIC_DATA,
  apiCall: () => apiGetMechanic(),
  failureCallback: failureCb,
});

export const doMechanicLogout = () => ({
  isApiCall: true,
  type: authTypes.MECHANIC_LOGOUT,
  apiCall: () => apiMechanicLogout(),
  successCallback: () => (window.location.href = "/mechanic"),
});
