import React from "react";
import {
  Select,
  TextField,
  Box,
  MenuItem,
  Autocomplete,
  Typography,
} from "@material-ui/core";
import ImageList from "../../components/ImageList";
import { DUE_IN_TYPES } from "../utility";

const RedInspectionDetails = ({
  fieldName,
  onUpdate,
  styles,
  fieldState,
  fieldInfo,
}) => {
  const DUE_IN_OPTIONS_MILES = [
    "ASAP",
    "500 miles",
    "1000 miles",
    "1500 miles",
    "2000 miles",
    "2500 miles",
    "3000 miles",
    "4000 miles",
    "4500 miles",
    "5000 miles+",
  ];

  const DUE_IN_OPTIONS_TIME = [
    "ASAP",
    "14 days",
    "30 days",
    "60 days",
    "90 days",
  ];

  const mileageDiv = (
    <Box className="text-left">
      <p className="mt-3 text-sm">
        How many miles until service is due?{" "}
        <span className="text-fmc-red">*</span>
      </p>
      <Select
        value={fieldState[`${fieldName}_service_due`] || ""}
        fullWidth
        variant="outlined"
        onChange={(event) =>
          onUpdate(`${fieldName}_service_due`, event.target.value)
        }
      >
        {DUE_IN_OPTIONS_MILES.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const timeDiv = (
    <Box className="text-left">
      <p className="mt-3 text-sm">
        How many days until service is due?{" "}
        <span className="text-fmc-red">*</span>
      </p>
      <Select
        value={fieldState[`${fieldName}_service_due`] || ""}
        fullWidth
        variant="outlined"
        onChange={(event) =>
          onUpdate(`${fieldName}_service_due`, event.target.value)
        }
      >
        {DUE_IN_OPTIONS_TIME.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const renderMileageOrTime = () => {
    if (fieldInfo.dueInType === DUE_IN_TYPES.MILEAGE) {
      return mileageDiv;
    } else if (fieldInfo.dueInType === DUE_IN_TYPES.TIME) {
      return timeDiv;
    }
  };

  return (
    <Box style={{ width: "90%", margin: "10px auto" }}>
      {fieldInfo.tags && (
        <Box mb={2}>
          <Autocomplete
            multiple
            options={fieldInfo.tags.tags}
            renderInput={(params) => (
              <TextField
                {...params}
                label={fieldInfo.tags.label}
                variant="outlined"
              />
            )}
            onChange={(_, newValue) => onUpdate(`${fieldName}_tags`, newValue)}
            value={fieldState[`${fieldName}_tags`] || []}
          />
        </Box>
      )}

      {fieldInfo.textBox !== false && (
        <TextField
          onChange={(event) =>
            onUpdate(`${fieldName}_text_describe`, event.target.value)
          }
          label="Describe any issues in detail"
          variant="outlined"
          fullWidth
          multiline
          value={fieldState[`${fieldName}_text_describe`] || ""}
        />
      )}

      {fieldInfo.photos !== false && (
        <Box className="text-left">
          {fieldState[`${fieldName}_photos`] && fieldState[`${fieldName}_photos`][0]?.url ? (
            <></>
          ) : (
            <>
              <p className={styles.textField}>
                Upload pictures
                <span className="text-fmc-red italic"> * </span>
              </p>
              <ImageList
                value={fieldState[`${fieldName}_photos`] || []}
                onChange={(d) => onUpdate(`${fieldName}_photos`, d)}
                required={false}
                error={false}
              />
            </>
          )}
        </Box>
      )}

      {fieldInfo.dueInType &&
        fieldInfo.dueInType !== DUE_IN_TYPES.NONE &&
        renderMileageOrTime()}

      {fieldInfo.add_to_quote && (
        <Box style={{ margin: "10px", textAlign: "left" }}>
          <Typography color="primary" style={{ margin: "10px 0" }}>
            {fieldInfo.serviceNameToAdd} will automatically be added to an
            estimate for later.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export { RedInspectionDetails };
