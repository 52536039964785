import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../App/routes";
import { apiGetJob, apiUpdateMechanicWarrantyNote } from "../../../_apis";
import AfterNoteModal from "../components/AfterNoteModal";
import JobDetails from "../components/JobDetails";
import FmcFullWidthButton from "../components/FmcFullWidthButton";

const WarrantyClaimNote = () => {
  const [error, setError] = useState(false);
  const [jobInfo, setJobInfo] = useState();
  const query = new URLSearchParams(useLocation().search);
  const appointment_id = query.get("appointment_id");
  const history = useHistory();
  const [isSubmittingNote, setIsSubmittingNote] = useState(false);
  const [open, setOpen] = useState(false);

  if (!appointment_id) {
    history.push(routes.INDEX());
  }

  const [claimInfo, setClaimInfo] = useState({
    original_po: null,
    service_name_warranty: [],
    labor_time_for_claim: null,
    employee_name_for_claim: null,
    store_number_for_claim: null,
  });

  useEffect(() => {
    apiGetJob(appointment_id).then((response) => {
      if (response && response.data) {
        setJobInfo(response.data);
        setClaimInfo({
          ...claimInfo,
          original_po: response.data.attributes.original_job_for_warranty,
        });
      }
    });
  }, []);

  const submitNote = () => {
    setIsSubmittingNote(true);

    const note = {
      appointment_id,
      original_po: claimInfo.original_po,
      service_name_warranty: claimInfo.service_name_warranty.join(", "),
      employee_name_for_claim: claimInfo.employee_name_for_claim,
      store_number_for_claim: claimInfo.store_number_for_claim,
    };

    note.labor_time_for_claim = claimInfo.service_name_warranty
      .map((i) => jobInfo.attributes.estimate.services[i].labor_hours)
      .reduce((a, b) => a + b, 0);

    apiUpdateMechanicWarrantyNote(note)
      .then((res) => {
        setOpen(true);
      })
      .catch((e) => {
        setError(true);
        setIsSubmittingNote(false);
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setClaimInfo({
      ...claimInfo,
      service_name_warranty:
        typeof value === "string" ? value.split(",") : value,
    });
  };

  const hasInvalidField = () => {
    return (
      !claimInfo.original_po ||
      !claimInfo.employee_name_for_claim ||
      claimInfo.service_name_warranty.length == 0 ||
      !claimInfo.store_number_for_claim
    );
  };

  return (
    <Box className="px-4 pt-24 mb-8">
      {jobInfo && (
        <>
          <div>
            <JobDetails
              jobInfo={jobInfo}
              appointmentTime={jobInfo.attributes.appointment_time}
              job_id={appointment_id}
              expand={false}
            />
          </div>

          <Container className="mb-32">
            <Box className="my-3">
              <h2 className="my-3 font-tiempos text-2xl text-center">
                Warranty Claim
              </h2>
            </Box>

            <OutlinedInput
              className="my-3"
              value={claimInfo.original_po}
              type="text"
              fullWidth
              placeholder="Original PO"
              onChange={(e) =>
                setClaimInfo({
                  ...claimInfo,
                  original_po: e.target.value,
                })
              }
            />

            <OutlinedInput
              className="my-3"
              value={claimInfo.employee_name_for_claim}
              type="text"
              fullWidth
              placeholder="Employee name at O'Reilly that helped you"
              onChange={(e) =>
                setClaimInfo({
                  ...claimInfo,
                  employee_name_for_claim: e.target.value,
                })
              }
            />

            <OutlinedInput
              className="my-3"
              value={claimInfo.store_number_for_claim}
              type="text"
              fullWidth
              placeholder="Store number where claim was submitted"
              onChange={(e) =>
                setClaimInfo({
                  ...claimInfo,
                  store_number_for_claim: e.target.value,
                })
              }
            />

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="multiple-checkbox-label">
                What services are you claiming?
              </InputLabel>
              <Select
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple
                fullWidth
                value={claimInfo.service_name_warranty}
                onChange={handleChange}
                input={<OutlinedInput label="Select services" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {Object.keys(jobInfo.attributes.estimate.services).map(
                  (name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={
                          claimInfo.service_name_warranty.indexOf(name) > -1
                        }
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  )
                )}
              </Select>

              {claimInfo.service_name_warranty.length > 0 ? (
                <Box className="my-3">
                  <Typography variant="h4">Labor Warrantied</Typography>
                  {claimInfo.service_name_warranty.map((i) => {
                    return (
                      <Box key={i} className="my-3">
                        <Typography>{i}</Typography>
                        <p>
                          Labor:{" "}
                          {jobInfo.attributes.estimate.services[i].labor_hours}{" "}
                          hr(s)
                        </p>
                        <p>
                          Labor rate: $
                          {parseFloat(
                            jobInfo.attributes.estimate.services[i]
                              .original_price_for_warranty /
                              jobInfo.attributes.estimate.services[i]
                                .labor_hours
                          ).toFixed(2)}
                          /hr
                        </p>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <></>
              )}
            </FormControl>
            <FmcFullWidthButton
              text="Submit"
              onClick={submitNote}
              variant="contained"
              disabled={isSubmittingNote || hasInvalidField()}
            />
          </Container>
        </>
      )}

      <AfterNoteModal
        open={open}
        onClose={setOpen}
        job_id={appointment_id}
        noteType="Warranty Claim"
        afterPath={`/mechanic/job/${appointment_id}`}
        needsEstimate={false}
      />
    </Box>
  );
};

export { WarrantyClaimNote };

export default WarrantyClaimNote;
