import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { palette } from "./palette";

export const createFMCTheme = () => {
  const baseTheme = responsiveFontSizes(
    createTheme({
      palette,
      props: {
        MuiTextField: {
          variant: "outlined",
        },
      },
      typography: {
        fontFamily: "Tiempos",
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
      overrides: {
        MuiButton: {
          containedPrimary: {
            "&$disabled": {
              backgroundColor: palette.primary.main,
              color: "white",
            },
            "&:hover": {
              backgroundColor: palette.secondary.main,
            },
          },
          containedSecondary: {
            color: "white",
          },
        },
        MuiDialog: {
          paper: {
            borderRadius: "22px",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "#00595999", // Opacity 90%
          },
        },
      },
    })
  );

  return {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      alternate: palette.alternate,
      cardShadow: palette.cardShadow,
      background: palette.background,
    },
    layout: {
      contentWidth: 1140,
    },
  };
};
