import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import IconDelete from "@material-ui/icons/Delete";
import useStyles from "../../components/styles";
import { getPartPrice, getPartPriceFleet } from "../../../../_helpers/utils";
import ConfirmationDialog from "./ConfirmationDialog";

const Part = ({ serviceId, partId, EstimateFormContext }) => {
  const classes = useStyles();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [skipMargins, setSkipMargins] = useState(false);
  const {
    data,
    onUpdatePartField,
    onRemovePart,
    isFleet,
    appointment,
    repeatCustomer,
    isManager,
    isDiagToRepair,
  } = useContext(EstimateFormContext);
  const service = useMemo(() => data.services[serviceId], [data, serviceId]);
  const part = useMemo(
    () => data.services[serviceId].parts[partId],
    [data, serviceId, partId]
  );

  const partPrice = (price) => {
    if (isManager && skipMargins) {
      return price;
    }

    if (isFleet) {
      return getPartPriceFleet(price, appointment);
    }

    return getPartPrice(price, repeatCustomer, isDiagToRepair);
  };

  const handleBlurPrice = (event) => {
    const quantity = part.quantity || 1;

    let priceToUpdate;

    if (quantity && quantity > 1) {
      priceToUpdate =
        partPrice(Number(event.target.value) * quantity) / quantity;
    } else {
      priceToUpdate = partPrice(Number(event.target.value));
    }

    onUpdatePartField(serviceId, partId, "price", priceToUpdate);
  };

  const handleOpenConfirm = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleConfirmationCancel = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmationOk = useCallback(() => {
    setConfirmationModalOpen(false);
    onRemovePart(serviceId, partId);
  }, [onRemovePart, serviceId, partId]);

  if (part.id && Object.keys(part).length === 1) return null;

  return (
    <Grid container spacing={1}>
      {isManager && (
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => {
                    setSkipMargins(!skipMargins);
                  }}
                  checked={skipMargins}
                />
              }
              label="Do not apply margins"
            />
          </FormGroup>
        </Grid>
      )}
      <Grid item xs={12}>
        <OutlinedInput
          disabled={service.free_member_service}
          fullWidth
          placeholder="Part Name"
          value={part.name || ""}
          onChange={(ev) =>
            onUpdatePartField(serviceId, partId, "name", ev.target.value)
          }
        />
      </Grid>
      {!service.free_member_service && (
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            placeholder="Part Number"
            value={part.part_number || ""}
            onChange={(ev) =>
              onUpdatePartField(
                serviceId,
                partId,
                "part_number",
                ev.target.value
              )
            }
          />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <OutlinedInput
          disabled={service.free_member_service}
          fullWidth
          placeholder="Quantity"
          type="number"
          value={part.quantity || ""}
          onChange={(ev) =>
            onUpdatePartField(
              serviceId,
              partId,
              "quantity",
              Number(ev.target.value)
            )
          }
          inputProps={{
            min: 0,
          }}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <OutlinedInput
          disabled={
            service.free_member_service || part.epicor_part || part.flat_part
          }
          fullWidth
          placeholder="Price/Unit"
          type="number"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          value={service.free_member_service ? part.price : part.price || ""}
          onChange={(ev) => {
            onUpdatePartField(
              serviceId,
              partId,
              "price",
              Number(ev.target.value)
            );
            onUpdatePartField(serviceId, partId, "custom_part", true);
          }}
          inputProps={{
            onBlur: handleBlurPrice,
            min: 0,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            className={classes.deleteButton}
            onClick={handleOpenConfirm}
          >
            <IconDelete />
          </IconButton>
        </Box>
      </Grid>
      <ConfirmationDialog
        open={confirmationModalOpen}
        onCancel={handleConfirmationCancel}
        onOk={handleConfirmationOk}
      />
    </Grid>
  );
};

export default Part;
