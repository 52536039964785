import React, { useState } from "react";
import {
  Box,
  OutlinedInput,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import callApi from "../../../../_apis/api_base";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const DiagNoteInPayment = ({
  diagNotes,
  setDiagNotes,
  estimate,
  nextStep,
  appointment_id,
}) => {
  const [isCodes, setIsCodes] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const NEXT_STEPS = [
    "Will create an estimate",
    "Needs a shop",
    "Small fix, fixed onsite",
    "Couldn't diagnose",
  ];

  const handleSubmit = () => {
    setIsSubmitting(true);

    callApi({
      url: `/mechanic/diag_notes`,
      method: "POST",
      data: {
        diag_note: {
          appointment_id: diagNotes.appointment_id,
          diag_result: diagNotes.diagResult,
          symptoms: diagNotes.symptoms,
          tests_performed: diagNotes.testsPerformed,
          all_codes: diagNotes.allCodes,
          next_steps: diagNotes.nextSteps,
          concerns: diagNotes.concerns,
          other_comments: diagNotes.otherComments,
          diag_confidence: diagNotes.diagConfidence,
          diag_confidence_reason: diagNotes.diagConfidenceReason,
          fixed_on_site_explain: diagNotes.fixOnSiteExplain,
          couldnt_diagnose_explain: diagNotes.couldntDiagnoseExplain,
        },
      },
    }).then((res) => {
      setIsSubmitting(false);
      nextStep();
    });
  };

  return (
    <Box className="my-6">
      <Box className="my-6">
        <Typography>Enter symptoms</Typography>
        <FormControl
          fullWidth
          error={
            diagNotes.symptoms.length > 0 && diagNotes.symptoms.length < 50
          }
        >
          <OutlinedInput
            multiline
            minRows={3}
            value={diagNotes.symptoms}
            type="text"
            fullWidth
            error={
              diagNotes.symptoms.length > 0 && diagNotes.symptoms.length < 50
            }
            placeholder="Type here"
            onChange={(e) => {
              setDiagNotes({ ...diagNotes, symptoms: e.target.value });
            }}
          />
          {diagNotes.symptoms.length > 0 && diagNotes.symptoms.length < 50 && (
            <FormHelperText>Please enter at least 50 characters</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box className="my-6">
        <Typography>Conclusion or fix</Typography>
        <FormControl
          fullWidth
          error={
            diagNotes.diagResult.length > 0 && diagNotes.diagResult.length < 50
          }
        >
          <OutlinedInput
            multiline
            minRows={3}
            value={diagNotes.diagResult}
            type="text"
            fullWidth
            error={
              diagNotes.diagResult.length > 0 &&
              diagNotes.diagResult.length < 50
            }
            placeholder="Type here"
            onChange={(e) => {
              setDiagNotes({
                ...diagNotes,
                diagResult: e.target.value,
              });
            }}
          />
          {diagNotes.diagResult.length > 0 &&
            diagNotes.diagResult.length < 50 && (
              <FormHelperText>
                Please enter at least 50 characters
              </FormHelperText>
            )}
        </FormControl>
      </Box>

      <Box className="my-6">
        <Typography>Tests performed to get to the cause</Typography>
        <FormControl
          fullWidth
          error={
            diagNotes.testsPerformed.length > 0 &&
            diagNotes.testsPerformed.length < 50
          }
        >
          <OutlinedInput
            multiline
            minRows={3}
            value={diagNotes.testsPerformed}
            type="text"
            fullWidth
            error={
              diagNotes.testsPerformed.length > 0 &&
              diagNotes.testsPerformed.length < 50
            }
            placeholder="Type here"
            onChange={(e) => {
              setDiagNotes({
                ...diagNotes,
                testsPerformed: e.target.value,
              });
            }}
          />
          {diagNotes.testsPerformed.length > 1 &&
            diagNotes.testsPerformed.length < 50 && (
              <FormHelperText>
                Please enter at least 50 characters
              </FormHelperText>
            )}
        </FormControl>
      </Box>

      <Box className="text-center my-6">
        <p className="my-3">Was there any DTC?</p>
        {["Yes", "No"].map((res) => {
          return (
            <FormControlLabel
              className="m-5"
              checked={isCodes === res}
              onChange={(event) => {
                setIsCodes(event.target.value);
              }}
              value={res}
              control={<Radio color="primary" />}
              label={res}
              labelPlacement="top"
            />
          );
        })}
      </Box>

      {isCodes === "Yes" && (
        <OutlinedInput
          className="my-3"
          multiline
          minRows={3}
          value={diagNotes.allCodes}
          type="text"
          fullWidth
          placeholder="List codes"
          onChange={(e) =>
            setDiagNotes({ ...diagNotes, allCodes: e.target.value })
          }
        />
      )}

      <Box className="my-6">
        <FormControl fullWidth>
          <InputLabel id="result-select-label">Diagnosis result</InputLabel>
          <Select
            labelId="result-select-label"
            variant="outlined"
            value={diagNotes.nextSteps}
            onChange={(e) =>
              setDiagNotes({
                ...diagNotes,
                nextSteps: e.target.value,
              })
            }
          >
            {NEXT_STEPS.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {diagNotes.nextSteps === "Will create an estimate" &&
        diagNotes.nextSteps && (
          <>
            <Box className="my-6">
              <FormControl fullWidth>
                <InputLabel id="result-select-label">
                  How sure are you about your diagnosis?
                </InputLabel>
                <Select
                  labelId="result-select-label"
                  variant="outlined"
                  value={diagNotes.diagConfidence}
                  onChange={(e) =>
                    setDiagNotes({
                      ...diagNotes,
                      diagConfidence: e.target.value,
                    })
                  }
                >
                  {[
                    "I know exactly what's wrong and can fix it",
                    "Trial and error",
                    "Totally guessing",
                  ].map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box className="my-6">
              <Typography>Explain what your plan is to fix it</Typography>
              <FormControl
                fullWidth
                error={
                  diagNotes.diagConfidenceReason.length > 0 &&
                  diagNotes.diagConfidenceReason.length < 50
                }
              >
                <OutlinedInput
                  multiline
                  minRows={3}
                  value={diagNotes.diagConfidenceReason}
                  type="text"
                  fullWidth
                  error={
                    diagNotes.diagConfidenceReason.length > 0 &&
                    diagNotes.diagConfidenceReason.length < 50
                  }
                  placeholder="Type here"
                  onChange={(e) => {
                    setDiagNotes({
                      ...diagNotes,
                      diagConfidenceReason: e.target.value,
                    });
                  }}
                />
                {diagNotes.diagConfidenceReason.length > 0 &&
                  diagNotes.diagConfidenceReason.length < 50 && (
                    <FormHelperText>
                      Please enter at least 50 characters
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          </>
        )}

      {diagNotes.nextSteps === "Small fix, fixed onsite" && (
        <Box className="my-6">
          <Typography>
            What was the fix? Is the issue solved and verified?
          </Typography>
          <FormControl
            fullWidth
            error={
              diagNotes.fixOnSiteExplain.length > 0 &&
              diagNotes.fixOnSiteExplain.length < 50
            }
          >
            <OutlinedInput
              multiline
              minRows={3}
              value={diagNotes.fixOnSiteExplain}
              type="text"
              fullWidth
              error={
                diagNotes.fixOnSiteExplain.length > 0 &&
                diagNotes.fixOnSiteExplain.length < 50
              }
              placeholder="Type here"
              onChange={(e) => {
                setDiagNotes({
                  ...diagNotes,
                  fixOnSiteExplain: e.target.value,
                });
              }}
            />
            {diagNotes.fixOnSiteExplain.length > 0 &&
              diagNotes.fixOnSiteExplain.length < 50 && (
                <FormHelperText>
                  Please enter at least 50 characters
                </FormHelperText>
              )}
          </FormControl>
        </Box>
      )}

      {diagNotes.nextSteps === "Couldn't diagnose" && (
        <Box className="my-6">
          <Typography>Explain more</Typography>
          <FormControl
            fullWidth
            error={
              diagNotes.couldntDiagnoseExplain.length > 0 &&
              diagNotes.couldntDiagnoseExplain.length < 50
            }
          >
            <OutlinedInput
              multiline
              minRows={3}
              value={diagNotes.couldntDiagnoseExplain}
              type="text"
              fullWidth
              error={
                diagNotes.couldntDiagnoseExplain.length > 1 &&
                diagNotes.couldntDiagnoseExplain.length < 50
              }
              placeholder="Type here"
              onChange={(e) => {
                setDiagNotes({
                  ...diagNotes,
                  couldntDiagnoseExplain: e.target.value,
                });
              }}
            />
            {diagNotes.couldntDiagnoseExplain.length > 1 &&
              diagNotes.couldntDiagnoseExplain.length < 50 && (
                <FormHelperText>
                  Please enter at least 50 characters
                </FormHelperText>
              )}
          </FormControl>
        </Box>
      )}

      <FmcFullWidthButton
        text="Next"
        onClick={handleSubmit}
        variant="contained"
        disabled={
          isSubmitting ||
          !diagNotes.nextSteps ||
          !diagNotes.symptoms ||
          !diagNotes.diagResult ||
          !diagNotes.testsPerformed
        }
      />
    </Box>
  );
};

export default DiagNoteInPayment;
