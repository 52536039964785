import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  isLoginInputValid,
  isPhoneInputValid,
  isCodeInputValid,
} from "./utility";
import { useDispatch } from "react-redux";
import routes from "../../../App/routes";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import { Box, Container, OutlinedInput, Typography } from "@material-ui/core";
import { GoogleLogin } from "@react-oauth/google";
import {
  apiMechanicLogin,
  apiMechanicLoginWith2FA,
  apiMechanicLoginWithGoogle,
  apiMechanicSubmitPhoneNumber,
} from "../../../_apis";
import { authTypes } from "../../../_types";

function MechanicLogin(props) {
  const [highlightedField, setHighlightedField] = useState("");
  const [phoneLogin, setPhoneLogin] = useState(false);
  const [emailLogin, setEmailLogin] = useState(true);
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [showEnterPhone, setShowEnterPhone] = useState(true);
  const [googleError, setGoogleError] = useState();
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
    phone: "",
    code: "",
    auto_login: false,
    errors: {},
  });
  const dispatch = useDispatch();
  const [isLoggingIn, setIsloggingIn] = useState();
  const query = new URLSearchParams(useLocation().search);

  const redirectAfterLogin = (res) => {
    dispatch({
      type: authTypes.MECHANIC_LOGIN,
      payload: res,
    }).then(() => (window.location = routes.MECHANIC.DASHBOARD()));
  };

  const onSubmit = () => {
    const { email, password } = loginDetails;
    const { valid, errors } = isLoginInputValid(loginDetails);

    if (valid) {
      apiMechanicLogin(email, password)
        .then((res) => {
          redirectAfterLogin(res);
        })
        .catch(() => {
          setLoginDetails({
            ...loginDetails,
            errors: {
              generic:
                "The email and password you entered did not match our records, please double-check and try again.",
            },
          });
        })
        .finally(() => {
          setIsloggingIn(false);
        });
    } else if (Object.keys(errors).length > 0) {
      setLoginDetails({ ...loginDetails, errors });
    }
  };

  const handlePhoneSubmit = (e) => {
    const { phone } = loginDetails;
    const { valid, errors } = isPhoneInputValid(loginDetails);

    if (valid) {
      apiMechanicSubmitPhoneNumber(phone)
        .then(() => {
          setShowEnterCode(true);
          setShowEnterPhone(false);
          setIsloggingIn(false);
        })
        .catch(() => {
          setIsloggingIn(false);
          setLoginDetails({
            ...loginDetails,
            errors: { phone: "Invalid phone number" },
          });
        });
    } else if (Object.keys(errors).length > 0) {
      setLoginDetails({ ...loginDetails, errors: { phone: errors["phone"] } });
    }
  };

  const handleCodeSubmit = (e) => {
    const { code } = loginDetails;
    const { valid, errors } = isCodeInputValid(loginDetails);

    if (valid) {
      apiMechanicLoginWith2FA(code)
        .then((res) => {
          redirectAfterLogin(res);
        })
        .catch(() => {
          setIsloggingIn(false);
          setLoginDetails({
            ...loginDetails,
            errors: { code: "Invalid code" },
          });
        });
    } else if (Object.keys(errors).length > 0) {
      setLoginDetails({ ...loginDetails, errors: { code: errors["code"] } });
    }
  };

  const setLoginType = (typeOfLogin) => {
    if (typeOfLogin === "email") {
      setEmailLogin(true);
      setPhoneLogin(false);
    } else {
      setEmailLogin(false);
      setPhoneLogin(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  return (
    <Container style={{ paddingLeft: "8px", paddingRight: "8px" }}>
      {phoneLogin && (
        <Box>
          <Box className="my-6 flex flex-col relative">
            {showEnterPhone && (
              <OutlinedInput
                onBlur={() => setHighlightedField("")}
                onFocus={() => setHighlightedField("phone")}
                className=""
                type="tel"
                value={loginDetails["phone"]}
                name="phone"
                placeholder="Phone"
                onChange={handleInputChange}
              />
            )}
            {showEnterPhone && loginDetails.errors["phone"] !== undefined && (
              <Typography className="my-1 text-sm text-fmc-red">
                {loginDetails.errors["phone"]}
              </Typography>
            )}

            {showEnterCode && (
              <>
                <OutlinedInput
                  onBlur={() => setHighlightedField("")}
                  onFocus={() => setHighlightedField("code")}
                  className=""
                  type="number"
                  value={loginDetails["code"]}
                  name="code"
                  placeholder="Enter code"
                  onChange={handleInputChange}
                />
                <Typography className="my-1 text-gray-600 text-sm">
                  Enter the code we sent to {loginDetails.phone}
                </Typography>
              </>
            )}

            {showEnterCode && loginDetails.errors["code"] !== undefined && (
              <Typography className="my-1 text-sm text-fmc-red">
                {loginDetails.errors["code"]}
              </Typography>
            )}
          </Box>

          <Box className="my-6 text-center">
            <Typography
              onClick={() => setLoginType("email")}
              color="primary"
              className="text-sm sm:text-lg cursor-pointer"
            >
              Sign in with email
            </Typography>
          </Box>

          {showEnterPhone && (
            <FmcFullWidthButton
              text="Submit Phone"
              onClick={handlePhoneSubmit}
              variant="contained"
              disabled={isLoggingIn}
            />
          )}

          {showEnterCode && (
            <FmcFullWidthButton
              text="Submit Code"
              onClick={handleCodeSubmit}
              variant="contained"
              disabled={isLoggingIn}
            />
          )}
        </Box>
      )}
      {emailLogin && (
        <Box>
          {props.inputFieldsArray.map((i, index) => (
            <Box key={index} className="my-6 flex flex-col relative">
              <OutlinedInput
                onBlur={() => setHighlightedField("")}
                onFocus={() => setHighlightedField(i.key)}
                className=""
                type={i.type}
                value={loginDetails[i.key] || ""}
                name={i.key}
                placeholder={i.placeholder}
                onChange={handleInputChange}
              />

              {loginDetails.errors[i.key] !== undefined && (
                <Typography className="my-1 text-sm text-fmc-red">
                  {loginDetails.errors[i.key]}
                </Typography>
              )}
            </Box>
          ))}

          {loginDetails.errors["generic"] !== undefined && (
            <Typography className="my-1 text-sm text-fmc-red">
              {loginDetails.errors["generic"]}
            </Typography>
          )}

          <Box className="my-6 text-center">
            <Typography
              onClick={() => setLoginType("phone")}
              color="primary"
              className="text-sm sm:text-lg cursor-pointer"
            >
              Sign in with phone number
            </Typography>
          </Box>

          <FmcFullWidthButton
            text="Log In"
            onClick={onSubmit}
            variant="contained"
            disabled={isLoggingIn}
          />
        </Box>
      )}

      <FmcFullWidthButton
        text="First Time Here? Sign Up"
        onClick={() => (window.location.href = "/mechanic/sign_up")}
        variant="outlined"
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "middle",
        }}
      >
        <GoogleLogin
          onSuccess={(res) => {
            apiMechanicLoginWithGoogle(res.credential)
              .then((res) => {
                redirectAfterLogin(res);
              })
              .catch(() => {
                setGoogleError(true);
              });
          }}
          onFailure={() => setGoogleError(true)}
          useOneTap
          auto_select
        />

        {googleError && <Typography color="error">Error logging in</Typography>}
      </Box>
    </Container>
  );
}

export { MechanicLogin };
