import React from "react";
import {
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Box,
} from "@material-ui/core";
import { RedInspectionDetails } from "./RedInspectionDetails";

const InspectionQuestion = ({
  field,
  onUpdate,
  styles,
  fieldState,
  labelMap,
}) => {
  const fieldInfo = labelMap[field];

  return (
    <>
      <div className={styles.questionGroup}>
        <Typography className="text-left">{fieldInfo.label}</Typography>
        <div className={styles.radioGroup}>
          {fieldInfo.options.map((option) => {
            return (
              <FormControlLabel
                className={styles.radioButton}
                checked={fieldState[field] === option.label}
                onChange={(event) => onUpdate(field, event.target.value)}
                value={option.label}
                control={<Radio color={option.color} />}
                label={
                  <Typography className="text-sm">{option.label}</Typography>
                }
                labelPlacement="top"
              />
            );
          })}
        </div>
      </div>

      {fieldInfo.moreInfoAnswer &&
        fieldInfo.moreInfoAnswer.includes(fieldState[field]) && (
          <Box style={{ margin: "15px 0" }}>
            <RedInspectionDetails
              fieldName={field}
              onUpdate={onUpdate}
              styles={styles}
              fieldState={fieldState}
              fieldInfo={fieldInfo}
            />
          </Box>
        )}

      <Divider />
    </>
  );
};

export { InspectionQuestion };
