import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/lab";
import ServiceList from "./components/ServiceList";
import update from "immutability-helper";
import useStyles from "../components/styles";
import moment from "moment";
import { SELECT_TIME_OPTIONS } from "../utility";
import FmcFullWidthButton from "../components/FmcFullWidthButton";

const EstimateFormContext = React.createContext({
  appointment: null,
  data: null,
  onUpdateServiceField: null,
  onAddService: null,
  onRemoveService: null,
  onUpdatePartField: null,
  onAddPart: null,
  onRemovePart: null,
  onUpdateField: null,
  openConfirm: null,
  isAdmin: null,
  repeatCustomer: null,
  onSave: null,
  isFleet: null,
  isManager: null,
  isDiagToRepair: null,
});

const EstimateForm = ({
  initialData,
  appointment,
  serviceForLater,
  isSubmitting,
  onSubmit,
  newExtimate,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [dateForLater, setDateForLater] = useState(moment());
  const [scheduleLater, setCanScheduleLater] = useState();

  useEffect(() => setFormData(initialData), [initialData]);

  useEffect(() => {
    if (serviceForLater) handleUpdateFormField("for_later_date", moment());
  }, [serviceForLater]);

  const isValid = useMemo(() => {
    const formErrors = [];

    (formData.services || []).forEach((service) => {
      if (Object.keys(service).length === 1 && service.id) {
        return;
      }

      const name = service.name || "a service";
      if (!service.name) {
        formErrors.push(`Missing name for ${name}`);
      } else {
        const index = formErrors.indexOf(`Missing name for ${name}`);
        if (index > -1) {
          formErrors.splice(index, 1);
        }
      }

      if (!service.tag) {
        formErrors.push(`Missing tag for ${name}`);
      } else {
        const index = formErrors.indexOf(`Missing tag for ${name}`);
        if (index > -1) {
          formErrors.splice(index, 1);
        }
      }

      if (!service.labor_hours && !service.free_member_service) {
        formErrors.push(`Missing labor hours for ${name}`);
      } else {
        const index = formErrors.indexOf(`Missing labor hours for ${name}`);
        if (index > -1) {
          formErrors.splice(index, 1);
        }
      }

      (service.parts || []).forEach((part) => {
        if (Object.keys(part).length === 1 && part.id) {
          return;
        }

        if (!part.name) {
          formErrors.push("Missing name for a part");
        } else {
          const index = formErrors.indexOf("Missing name for a part");
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        const partName = part.name || "a part";
        if (!part.quantity) {
          formErrors.push(`Missing quantity for ${partName}`);
        } else {
          const index = formErrors.indexOf(`Missing quantity for ${partName}`);
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        if (
          (!part.price || part.price == 0.0) &&
          !service.free_member_service &&
          !service.is_canned_service
        ) {
          formErrors.push(`Missing price for ${partName}`);
        } else {
          const index = formErrors.indexOf(`Missing price for ${partName}`);
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }

        if (
          (!part.part_number || part.part_number == "") &&
          !service.free_member_service &&
          !service.is_canned_service
        ) {
          formErrors.push(`Missing part number for ${partName}`);
        } else {
          const index = formErrors.indexOf(
            `Missing part number for ${partName}`
          );
          if (index > -1) {
            formErrors.splice(index, 1);
          }
        }
      });
    });

    setErrors(formErrors);
    return formData.services && formErrors.length == 0;
  }, [formData]);

  const handleUpdateFormField = useCallback((field, value) => {
    setFormData((data) =>
      update(data, {
        [field]: { $set: value },
      })
    );
  }, []);

  const handleUpdateServiceField = useCallback((serviceId, field, value) => {
    setFormData((data) =>
      update(data, {
        services: {
          [serviceId]: {
            [field]: { $set: value },
          },
        },
      })
    );
  }, []);

  const handleAddService = useCallback(() => {
    setFormData((data) => {
      if (data.services) {
        return update(data, {
          services: { $push: [{}] },
        });
      }

      return update(data, {
        services: { $set: [{}] },
      });
    });
  }, []);

  const handleRemoveService = useCallback(
    (serviceId) => {
      if (formData.services[serviceId].id) {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: (service) => ({ id: service.id }),
            },
          })
        );
      } else {
        setFormData((data) =>
          update(data, {
            services: { $splice: [[serviceId, 1]] },
          })
        );
      }
    },
    [formData]
  );

  const handleUpdatePartField = useCallback(
    (serviceId, partId, field, value) => {
      setFormData((data) =>
        update(data, {
          services: {
            [serviceId]: {
              parts: {
                [partId]: {
                  [field]: { $set: value },
                },
              },
            },
          },
        })
      );
    },
    []
  );

  const handleAddPart = useCallback((serviceId) => {
    setFormData((data) => {
      if (data.services[serviceId].parts) {
        return update(data, {
          services: {
            [serviceId]: {
              parts: { $push: [{}] },
            },
          },
        });
      }

      return update(data, {
        services: {
          [serviceId]: {
            parts: { $set: [{}] },
          },
        },
      });
    });
  }, []);

  const handleRemovePart = useCallback(
    (serviceId, partId) => {
      if (formData.services[serviceId].parts[partId].id) {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: {
                parts: {
                  [partId]: (part) => ({ id: part.id }),
                },
              },
            },
          })
        );
      } else {
        setFormData((data) =>
          update(data, {
            services: {
              [serviceId]: {
                parts: { $splice: [[partId, 1]] },
              },
            },
          })
        );
      }
    },
    [formData]
  );

  const getLabor = (service, rate) => {
    if (service.labor_hours === undefined || !rate) {
      return "";
    } else if (service.free_member_service) {
      return 0;
    } else if (
      (service.labor_price &&
        service.name &&
        service.name.toLowerCase().includes("oil change")) ||
      service.is_canned_service
    ) {
      return service.labor_price;
    }

    return (service.labor_hours * rate).toFixed(2);
  };

  const handleSave = useCallback(() => {
    const laborRate = appointment.hourly_rate;

    const submitData = {
      ...formData,
      services: undefined,
      estimate_services_attributes: formData.services.map((service) => {
        return {
          ...service,
          labor_price: getLabor(service, laborRate),
          parts: undefined,
          parts_attributes: service.parts,
        };
      }),
    };
    onSubmit(submitData);
  }, [onSubmit, formData]);

  return (
    <EstimateFormContext.Provider
      value={{
        appointment: appointment,
        data: formData,
        onUpdateField: handleUpdateFormField,
        onUpdateServiceField: handleUpdateServiceField,
        onAddService: handleAddService,
        onRemoveService: handleRemoveService,
        onUpdatePartField: handleUpdatePartField,
        onAddPart: handleAddPart,
        onRemovePart: handleRemovePart,
        isAdmin: null,
        repeatCustomer:
          appointment.new_customer_or_repeat_customer === "repeat_customer",
        onSave: handleSave,
        isFleet: appointment.fleet_job,
        isManager: null,
        isDiagToRepair:
          !appointment.keep_office_margins &&
          appointment.appointment_type !== "repair",
      }}
    >
      <Container>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ServiceList EstimateFormContext={EstimateFormContext} />
            </Grid>
            <Grid item xs={12}>
              {serviceForLater && (
                <Card className={classes.cardBox}>
                  <CardContent className="">
                    <Box className="text-center my-6">
                      <Typography className="text-lg">
                        Can you get the job scheduled?
                      </Typography>
                    </Box>

                    <Box className="text-center my-6">
                      {["Yes", "No"].map((res) => {
                        return (
                          <FormControlLabel
                            className="m-5"
                            checked={scheduleLater === res}
                            onChange={(event) => {
                              setCanScheduleLater(event.target.value);
                            }}
                            value={res}
                            control={<Radio color="primary" />}
                            label={res}
                            labelPlacement="top"
                          />
                        );
                      })}
                    </Box>

                    {scheduleLater && (
                      <>
                        <Typography>
                          {scheduleLater === "Yes"
                            ? "Schedule this service"
                            : "Set a date for us to follow up"}
                        </Typography>
                        <div className="my-6">
                          <DatePicker
                            label="Select Date"
                            value={dateForLater}
                            onChange={(newValue) => {
                              setDateForLater(newValue);
                              handleUpdateFormField(
                                scheduleLater === "Yes"
                                  ? "for_later_date"
                                  : "for_later_follow_up_date",
                                newValue
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ minWidth: "100%" }}
                                {...params}
                              />
                            )}
                          />
                        </div>

                        {scheduleLater === "Yes" && (
                          <div className="my-6">
                            <p className="text-sm text-left">Select Time</p>
                            <Select
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleUpdateFormField(
                                  "for_later_time",
                                  e.target.value
                                )
                              }
                            >
                              {SELECT_TIME_OPTIONS.map((item, i) => (
                                <MenuItem key={i} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              )}

              <Card className={classes.cardBox}>
                <CardContent>
                  {serviceForLater && (
                    <Box className="text-center my-6">
                      <Box className="text-center my-6">
                        <Typography className="text-lg">
                          Did the customer verbally approve the services?
                        </Typography>
                      </Box>

                      <Box className="text-center my-6">
                        {["Yes", "No"].map((res) => {
                          return (
                            <FormControlLabel
                              className="m-5"
                              checked={formData.customer_approval === res}
                              onChange={(event) => {
                                handleUpdateFormField(
                                  "customer_approval",
                                  event.target.value
                                );
                              }}
                              value={res}
                              control={<Radio color="primary" />}
                              label={res}
                              labelPlacement="top"
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Box>
                  {errors.length > 0 ? (
                    <Box className="my-3 text-lg">
                      <Typography>Correct the following errors</Typography>
                      <ul>
                        {[...new Set(errors)].map((i) => (
                          <li className="list-disc text-fmc-red italic m-2">
                            {i}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>

                <FmcFullWidthButton
                  text="Review Estimate"
                  onClick={handleSave}
                  variant="contained"
                  disabled={isSubmitting || !isValid || errors.length > 0}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </EstimateFormContext.Provider>
  );
};

export default EstimateForm;
