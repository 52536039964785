import React, { useState } from "react";
import { Typography, Card, useMediaQuery } from "@material-ui/core";
import {  InfoOutlined } from "@material-ui/icons";
import HowItWorks from "./HowItWorks";

const Reminder = ({ title }) => {
  const isSm = useMediaQuery("(max-width: 600px)");

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "#E3F2F9",
          maxWidth: "500px",
          margin: isSm ? 20 : "auto",
          marginTop: 20,
          marginBottom: 20,
          padding: 20,
          borderRadius: 12,
          borderColor: "#1A5B74",
        }}
        elevation={0}
      >
        <Typography
          style={{ color: "#1A5B74", fontSize: 18, marginBottom: 15 }}
        >
          {title}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            gap: 5,
          }}
          onClick={toggleModal}
        >
          <Typography style={{ color: "#1A5B74", fontSize: 12 }}>
            How it Works
          </Typography>
          <InfoOutlined style={{ color: "#1A5B74", height: 20 }} />
        </div>
      </Card>
      <HowItWorks open={showModal} onClose={toggleModal} />
    </>
  );
};

export { Reminder };
