import arrow_right from './arrow-right.svg';
import brakes from './brakes.svg';
import certified from './certified.svg';
import diagnosis from './diagnosis.svg';
import electrical from './electrical.svg';
import engine from './engine.svg';
import european_cars from './european-cars.svg';
import hand_car from './hand-car.svg';
import hand_mobile from './hand-mobile.svg';
import hand_money from './hand-money.svg';
import love from './love.svg';
import phone from './phone.svg';
import security from './security.svg';
import suspension from './suspension.svg';
import user from './user.svg';

export default {
  arrow_right,
  brakes,
  certified,
  diagnosis,
  electrical,
  engine,
  european_cars,
  hand_car,
  hand_mobile,
  hand_money,
  love,
  phone,
  security,
  suspension,
  user,
};
