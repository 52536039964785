import React from "react";
import { Typography, Box, Divider } from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import UpsellEstimate from "../components/UpsellEstimate";

const Final = ({ styles, id, inspection, goBack }) => {
  const itemsToReview = inspection.colored
    ? inspection.colored
        .filter((i) => i.color == "red" || i.color == "yellow")
        .sort((a, b) => a.color - b.color)
    : [];

  return (
    <Box>
      <Typography className={styles.heading}>Inspection Complete</Typography>

      {inspection.upsell_estimate ? (
        <Box className="my-6">
          <UpsellEstimate
            estimate={inspection.upsell_estimate}
            member={inspection.member}
            appointmentId={id}
          />
        </Box>
      ) : (
        <>
          {itemsToReview.length > 0 ? (
            <>
              <Typography className="my-3">
                The following items should be added to your quote for today or
                upsell for later.
              </Typography>

              <ul>
                {itemsToReview.map((item, index) => (
                  <li className="my-3">- {item.name}</li>
                ))}
              </ul>
              <Divider />
            </>
          ) : (
            <Typography className="my-3">
              Vehicle is in good condition
            </Typography>
          )}
          <FmcFullWidthButton
            text="Back to Job"
            onClick={() => (window.location.href = `/mechanic/job/${id}`)}
            variant="contained"
          />

          <FmcFullWidthButton
            text="Go Back"
            onClick={goBack}
            variant="outlined"
          />
        </>
      )}
    </Box>
  );
};

export { Final };
