export function isEmail(email) {
  const regularExpression = /\S+@\S+\.\S+/;
  return regularExpression.test(email);
}

export const authTypes = {
  0: "SIGN_IN",
  1: "SIGN_UP",
};

export const authTypeHeadName = {
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
};

export const signInFields = [
  { key: "email", placeholder: "Email", type: "text" },
  { key: "password", placeholder: "Password", type: "password" },
];

export const signUpFields = [
  { key: "name", placeholder: "First Name", type: "text" },
  { key: "last_name", placeholder: "Last Name", type: "text" },
  { key: "email", placeholder: "Email", type: "text" },
  { key: "phone", placeholder: "Phone", type: "tel" },
  { key: "password", placeholder: "Password", type: "password" },
  {
    key: "password_confirmation",
    placeholder: "Password Confirmation",
    type: "password",
  },
  {
    key: "shirt_size",
    placeholder: "T-Shirt Size (for uniform)",
    type: "text",
  },
];

export function isPhoneInputValid(inputs) {
  const { phone } = inputs;
  let errors = {};
  let valid = true;

  if (phone.length === 0 || phone === "") {
    valid = false;
    errors["phone"] = "Phone must be filled out";
  }

  return { valid, errors };
}

export function isCodeInputValid(inputs) {
  const { code } = inputs;
  let errors = {};
  let valid = true;

  if (code.length === 0 || code === "") {
    valid = false;
    errors["code"] = "Code must be filled out";
  }

  return { valid, errors };
}

export function isLoginInputValid(inputs) {
  const { email, password } = inputs;
  let errors = {};
  let valid = true;

  if (email.length === 0 || email === "") {
    valid = false;
    errors["email"] = "Email must be filled out";
  }

  if (email.length > 0 && !isEmail(email)) {
    valid = false;
    errors["email"] = "Invalid email";
  }

  if (password.length === 0 || password === "") {
    valid = false;
    errors["password"] = "Password must be filled out";
  }

  return { valid, errors };
}

export function isSignUpInputValid(inputs) {
  const { email, name, phone, password, password_confirmation } = inputs;
  let valid = true;
  let errors = {};

  if (email.length === 0 || email === "") {
    valid = false;
    errors["email"] = "Email is missing";
  }

  if (email.length > 0 && !isEmail(email)) {
    valid = false;
    errors["email"] = "Please enter a valid email";
  }

  if (name.length === 0 || name === "") {
    valid = false;
    errors["name"] = "Name is missing";
  }

  if (phone.length === 0 || phone === "") {
    valid = false;
    errors["phone"] = "Phone number is missing";
  }

  if (phone.length < 10) {
    valid = false;
    errors["phone"] = "Please enter a valid phone number";
  }

  if (password.length === 0 || password === "") {
    valid = false;
    errors["password"] = "Password is missing";
  }

  if (password.length < 6) {
    valid = false;
    errors["password"] = "Password must be at least 6 characters";
  }

  if (password_confirmation.length === 0 || password_confirmation === "") {
    valid = false;
    errors["password_confirmation"] = "Password confirmation is missing";
  }

  if (password_confirmation.length < 6) {
    valid = false;
    errors["password_confirmation"] =
      "Password confirmation must be at least 6 characters";
  }

  if (
    password.length > 0 &&
    password_confirmation.length > 0 &&
    password !== password_confirmation
  ) {
    valid = false;
    errors["password_confirmation"] = "Passwords must match";
  }

  return { valid, errors };
}
