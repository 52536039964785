import { callApiV2 } from "../api_base";

export const apiMechanicCreateJob = (data) => {
  return callApiV2({
    url: "/mechanic/appointments",
    method: "POST",
    data,
  });
};

export const apiMechanicGetAllJobs = () => {
  return callApiV2({
    url: "/mechanic/appointments/all",
    method: "GET",
  });
};

export const getAllMessages = async () => {
  return callApiV2({
    url: `mechanics/all_messages`,
    method: "GET",
  });
};

export const apiMechanicGetSameDayJobs = () => {
  return callApiV2({
    url: "/mechanic/appointments/same_day_today",
    method: "GET",
  });
};

export const apiMechanicGetCustomerJobs = (id) => {
  return callApiV2({
    url: `/mechanic/appointments/customer-jobs/${id}`,
    method: "GET",
  });
};

export const apiMechanicRequestSameDayTime = (id, time) => {
  return callApiV2({
    url: `/mechanic/appointments/request_same_day_time/${id}`,
    method: "POST",
    data: {
      time,
    },
  });
};

export const apiMechanicPickUpSameDayJob = (id) => {
  return callApiV2({
    url: `/mechanic/appointments/pickup_same_day_job/${id}`,
    method: "POST",
  });
};

export const apiMechanicUpdateAppointment = async (id, data) => {
  return callApiV2({
    url: `/mechanic/appointments/${id}`,
    method: "PUT",
    data,
  });
};
