import React, { useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import AdminCalendar from "../AdminCalendar";
import AdminEstimates from "../AdminEstimates";
import { useLocation } from "react-router-dom";
import AdminNewCustomer from "../AdminNewCustomer";
import { TabPanel, a11yProps } from "../../../_components/Tabs";
import AdminMembership from "../AdminMembership";
import AdminAfterServiceCall from "../AdminAfterServiceCall";

const AdminTabs = ({ current_admin_user_id, isManager }) => {
  const query = new URLSearchParams(useLocation().search);
  const appointment_id = query.get("appointment_id");
  const user_id = query.get("user_id");
  const [jobId, setJobId] = useState(appointment_id);

  const tab_num = query.get("tab");

  const [value, setValue] = useState(tab_num ? parseInt(tab_num) : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Calendar" {...a11yProps(0)} />
          <Tab label="Estimate" {...a11yProps(1)} />
          <Tab label="New Customer" {...a11yProps(2)} />
          <Tab label="Membership" {...a11yProps(3)} />
          <Tab label="After Service Call" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <AdminCalendar
          appointment_id={jobId}
          setJobId={setJobId}
          admin_user_id={current_admin_user_id}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AdminEstimates
          appointment_id={jobId}
          admin_user_id={current_admin_user_id}
          isManager={isManager}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <AdminNewCustomer
          setJobId={setJobId}
          setTab={handleChange}
          admin_user_id={current_admin_user_id}
        />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AdminMembership
          appointment_id={jobId}
          user_id={user_id}
          admin_user_id={current_admin_user_id}
        />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <AdminAfterServiceCall
          appointment_id={jobId}
          admin_user_id={current_admin_user_id}
        />
      </TabPanel>
    </Box>
  );
};

export default AdminTabs;
