import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { SearchJobCard } from "./components/SearchJobCard";
import { apiMechanicGetAllJobs } from "../../../_apis/mechanics/appointments";

const SearchScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [jobIds, setJobIds] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState();
  const [vehicleData, setVehicleData] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [customerName, setCustomerNames] = useState([]);
  const [selectedName, setSelectedName] = useState();
  const [searchedJobs, setSearchedJobs] = useState();

  const handleJobIdFilter = (val) => {
    setSelectedJobId(val);
    setFilteredJobs(allJobs.filter((job) => job.id === val));
  };

  const handleVehicleFilter = (val) => {
    setSelectedVehicle(val);

    setFilteredJobs(
      allJobs.filter((job) => {
        const car = job.attributes.car;

        return car && `${car.year} ${car.make} ${car.model}` === val;
      })
    );
  };

  const handleCustomerFilter = (val) => {
    setSelectedName(val);

    setFilteredJobs(
      allJobs.filter((job) => job.attributes.customer_name === val)
    );
  };

  useEffect(() => {
    if (allJobs.length === 0 && !searchedJobs) {
      setIsLoading(true);

      apiMechanicGetAllJobs()
        .then((jobs) => {
          setAllJobs(jobs.data);

          const ids = [];
          const vehicles = [];
          const customerNames = [];

          jobs.data.forEach((job) => {
            ids.push(job.id);

            const car = job.attributes.car;
            if (car) {
              vehicles.push(`${car.year} ${car.make} ${car.model}`);
            }

            customerNames.push(job.attributes.customer_name);

            setJobIds(ids);
            setVehicleData(new Set(vehicles));
            setCustomerNames(new Set(customerNames));
          });
        })
        .finally(() => {
          setIsLoading(false);
          setSearchedJobs(true);
        });
    }
  }, []);

  return (
    <Box className="px-4 pt-24 mb-32">
      <Typography variant="h4">Search Jobs</Typography>

      {isLoading ? (
        <div className="w-full h-full justify-center items-center flex p-40">
          <CircularProgress color="primary" className="flex-1" size="sm" />
        </div>
      ) : (
        <>
          {jobIds.length > 0 ? (
            <Autocomplete
              className="my-6"
              options={jobIds}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by job #"
                  variant="outlined"
                />
              )}
              onChange={(_, newValue) => handleJobIdFilter(newValue)}
              value={selectedJobId || ""}
            />
          ) : (
            <>No jobs to search yet</>
          )}

          {[...vehicleData].length > 0 ? (
            <Autocomplete
              className="my-6"
              options={[...vehicleData]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by vehicle"
                  variant="outlined"
                />
              )}
              onChange={(_, newValue) => handleVehicleFilter(newValue)}
              value={selectedVehicle || ""}
            />
          ) : (
            <></>
          )}

          {[...customerName].length > 0 ? (
            <Autocomplete
              className="my-6"
              options={[...customerName]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by customer name"
                  variant="outlined"
                />
              )}
              onChange={(_, newValue) => handleCustomerFilter(newValue)}
              value={selectedName || ""}
            />
          ) : (
            <></>
          )}

          {filteredJobs.length > 0
            ? filteredJobs.map((job) => {
                return <SearchJobCard job={job} />;
              })
            : allJobs.map((job) => {
                return <SearchJobCard job={job} />;
              })}
        </>
      )}
    </Box>
  );
};

export { SearchScreen };
