import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  OutlinedInput,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import InfoPanel from "./components/InfoPanel";
import { Star } from "@material-ui/icons";
import AdminMembership from "../AdminMembership";
import { callApiV2 } from "../../../_apis/api_base";
import { handleBeforeUnload } from "../AdminEstimates";
import { apiGetAdminAsc } from "../../../_apis";

const STEPS = {
  EXPERIENCE: 1,
  TECH_ARRIVAL: 2,
  UPSELL: 3,
  STICKER: 4,
  MEMBERSHIP: 5,
  SUCCESS: 6,
};

const AdminAfterServiceCall = ({ appointment_id, admin_user_id }) => {
  const [step, setStep] = useState(STEPS.EXPERIENCE);
  const [rating, setRating] = useState(0);
  const [ratingDetails, setRatingDetails] = useState();
  const [techArrival, setTechArrival] = useState();
  const [arrivalDetails, setArrivalDetails] = useState();
  const [techUniform, setTechUniform] = useState();
  const [rewardsMobileApp, setRewardsMobileApp] = useState();
  const [diagnosisConfidence, setDiagnosisConfidence] = useState();
  const [error, setError] = useState();
  const [requesting, setRequesting] = useState();
  const [points, setPoints] = useState(null);
  const [stickerImg, setStickerImg] = useState();
  const [upsellDetails, setUpsellDetails] = useState();
  const [sticker, setSticker] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [stickerNotFilled, setStickerNotFilled] = useState(false);
  const [deduct, setDeduct] = useState(false);

  useEffect(() => {
    const func = async () => {
      if (appointment_id) {
        const rsp = await apiGetAdminAsc(appointment_id);

        setPoints(rsp.points_earned);
        setStickerImg(rsp.sticker);
        setAppointmentType(rsp.appointment_type);
      }
    };
    func();
  }, [appointment_id]);

  const handleSubmit = () => {
    setRequesting(true);

    window.removeEventListener("beforeunload", handleBeforeUnload);

    const questions = [
      { question: "Rating", answer: `${rating}/10` },
      { question: "Anything we could do better?", answer: ratingDetails },
      {
        question: "Did the technician arrive on time?",
        answer: techArrival,
      },
      { question: "How late/early?", answer: arrivalDetails },
      {
        question: "Did the technician wear a Goodhood uniform?",
        answer: techUniform,
      },
      {
        question: `Did we inform the customer about the rewards program (they earned ${points} points on this service) and have them download the mobile app?`,
        answer: rewardsMobileApp,
      },
      { question: "What were we able to upsell?", answer: upsellDetails },
      {
        question: "Did the tech use the oil change sticker?",
        answer: sticker,
      },
      { question: "Sticker not filled out", answer: stickerNotFilled },
    ];

    if (diagnosisConfidence) {
      questions.push({
        question: "How confident was the tech in his diagnosis?",
        answer: diagnosisConfidence,
      });
    }

    callApiV2({
      url: "/after_service_calls",
      method: "POST",
      data: {
        after_service_call: {
          appointment_id,
          admin_user_id,
          deduct_for_oil_change_sticker: deduct,
          questions,
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          setError(true);

          setTimeout(() => {
            setError();
          }, 4000);
        } else {
          setStep(STEPS.SUCCESS);
        }
      })
      .finally(() => setRequesting());
  };

  if (!appointment_id) {
    return (
      <Box style={{ marginBottom: "100px" }}>
        <Container>
          <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
            <h2>No Customer or Job ID</h2>
            <br />
            <Typography>
              To use this feature, please provide a valid job ID. You can
              navigate to the corresponding job, and click on the 'After Service
              Call' link to return to this page and proceed with recording your
              after service call.
            </Typography>
          </div>
        </Container>
      </Box>
    );
  }

  return (
    <Container style={{ marginBottom: "100px", marginTop: "40px" }}>
      <Card style={{ width: "70vw", margin: "10px auto" }}>
        <InfoPanel step={step} setStep={setStep} />

        <Divider />

        <CardHeader title={"After Service Call"} />

        <CardContent>
          {step === STEPS.EXPERIENCE && (
            <Box>
              <Typography>How was your experience?</Typography>

              <Box className="my-3">
                {Array.from({ length: 10 }, (_, index) => (
                  <Star
                    key={index}
                    style={{ cursor: "pointer" }}
                    color={index < rating ? "primary" : "disabled"}
                    onClick={() => setRating(index + 1)}
                  />
                ))}
              </Box>

              <Typography>Anything we could do better?</Typography>

              <Box className="my-3">
                <OutlinedInput
                  style={{ width: "80%" }}
                  multiline
                  rows={4}
                  value={ratingDetails}
                  onChange={(e) => setRatingDetails(e.target.value)}
                  placeholder="Add details"
                />
              </Box>

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={rating === 0 || !ratingDetails}
                onClick={() => setStep(STEPS.TECH_ARRIVAL)}
              >
                Next
              </Button>
            </Box>
          )}

          {step === STEPS.TECH_ARRIVAL && (
            <Box>
              <FormGroup>
                <Typography>Did the technician arrive on time?</Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setTechArrival("Yes")}
                      checked={techArrival === "Yes"}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setTechArrival("No")}
                      checked={techArrival === "No"}
                    />
                  }
                  label="No"
                />
              </FormGroup>

              <Box className="my-3">
                <OutlinedInput
                  style={{ width: "80%" }}
                  multiline
                  rows={4}
                  value={arrivalDetails}
                  onChange={(e) => setArrivalDetails(e.target.value)}
                  placeholder="How late/early?"
                />
              </Box>

              {appointmentType === "diagnosis" && (
                <FormGroup>
                  <Typography>
                    How confident was the tech in his diagnosis? (5 is extremely
                    confident)
                  </Typography>

                  <Box className="my-3">
                    {Array.from({ length: 5 }, (_, index) => (
                      <Star
                        key={index}
                        style={{ cursor: "pointer" }}
                        color={
                          index < diagnosisConfidence ? "primary" : "disabled"
                        }
                        onClick={() => setDiagnosisConfidence(index + 1)}
                      />
                    ))}
                  </Box>
                </FormGroup>
              )}

              <FormGroup>
                <Typography>
                  Did the technician wear a Goodhood uniform?
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setTechUniform("Yes")}
                      checked={techUniform === "Yes"}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setTechUniform("No")}
                      checked={techUniform === "No"}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() =>
                        setTechUniform("Customer couldn't tell or notice")
                      }
                      checked={
                        techUniform === "Customer couldn't tell or notice"
                      }
                    />
                  }
                  label="Customer couldn't tell or notice"
                />
              </FormGroup>

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={!techArrival}
                onClick={() => setStep(STEPS.UPSELL)}
              >
                Next
              </Button>
            </Box>
          )}

          {step === STEPS.UPSELL && (
            <Box>
              <Typography>Upsell</Typography>

              <Box className="my-3">
                <OutlinedInput
                  style={{ width: "80%" }}
                  multiline
                  rows={4}
                  value={upsellDetails}
                  onChange={(e) => setUpsellDetails(e.target.value)}
                  placeholder="What were you able to upsell?"
                />
              </Box>

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={!upsellDetails}
                onClick={() => setStep(STEPS.STICKER)}
              >
                Next
              </Button>
            </Box>
          )}

          {step === STEPS.STICKER && (
            <Box>
              <Typography>Sticker</Typography>

              <Box className="my-3">
                <img
                  src={stickerImg}
                  style={{ width: "200px", height: "200px" }}
                  alt="sticker"
                />
              </Box>

              <FormGroup>
                <Typography>
                  Did the tech use the oil change sticker?
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setSticker("Yes")}
                      checked={sticker === "Yes"}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setSticker("No")}
                      checked={sticker === "No"}
                    />
                  }
                  label="No"
                />

                {sticker === "Yes" && (
                  <Box className="my-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            setStickerNotFilled(!stickerNotFilled)
                          }
                          checked={stickerNotFilled}
                        />
                      }
                      label="Sticker not filled out"
                    />
                  </Box>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setDeduct(!deduct)}
                      checked={deduct}
                    />
                  }
                  label="Deduct 50 points"
                />
              </FormGroup>
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={!sticker}
                onClick={() => setStep(STEPS.MEMBERSHIP)}
              >
                Next
              </Button>
            </Box>
          )}

          {step === STEPS.MEMBERSHIP && (
            <Box>
              <FormGroup>
                <Typography>
                  Did we inform the customer about the rewards program
                  {points
                    ? ` (they earned ${points} points on this service)`
                    : ""}{" "}
                  and have them download the mobile app?
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setRewardsMobileApp("Yes")}
                      checked={rewardsMobileApp === "Yes"}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setRewardsMobileApp("No")}
                      checked={rewardsMobileApp === "No"}
                    />
                  }
                  label="No"
                />
              </FormGroup>

              <AdminMembership
                appointment_id={appointment_id}
                admin_user_id={admin_user_id}
                after_service_call={true}
              />

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={!techArrival || requesting}
                onClick={handleSubmit}
              >
                Done
              </Button>

              {error && (
                <Typography className="my-3" style={{ color: "#FF0000" }}>
                  Error saving after service call.
                </Typography>
              )}
            </Box>
          )}

          {step === STEPS.SUCCESS && (
            <Box>
              <Typography>After service call done</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default AdminAfterServiceCall;
