import React, { useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import { Scheduling } from "../Scheduling";
import ServiceManual from "../ServiceManual";
import AccountPage from "./components/AccountPage";
import { TabPanel, a11yProps } from "../../../_components/Tabs";
import FaqPage from "./components/FaqPage";
import Points from "./components/Points";

const Account = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="px-4 pt-24 mb-8">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Availability" {...a11yProps(0)} />
          <Tab label="Service Manual" {...a11yProps(1)} />
          <Tab label="Points" {...a11yProps(2)} />
          <Tab label="Account" {...a11yProps(3)} />
          <Tab label="How You Get Paid" {...a11yProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Scheduling />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ServiceManual />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Points />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AccountPage />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <FaqPage />
      </TabPanel>
    </Box>
  );
};

export { Account };
