import React, { useEffect, useState } from "react";

import {
  Typography,
  Box,
  Container,
  Autocomplete,
  TextField,
} from "@material-ui/core";
import callApi, { callApiV2 } from "../../../../_apis/api_base";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { stateList } from "../utility";
import { useGeolocation } from "../../../../_helpers";
import BarcodeScanner from "../components/BarcodeScanner";
import { isValidVIN } from "../../../../_helpers/utils";

const Step0 = ({ styles, handleNext, vehicle, id }) => {
  const [licensePlate, setLicensePlate] = useState();
  const [licensePlateState, setLicensePlateState] = useState();
  const [scanning, setScanning] = useState();
  const [manualVin, setManualVin] = useState();
  const [scannedVin, setScannedVin] = useState("");

  useEffect(() => {
    setManualVin(scannedVin);
  }, [scannedVin]);

  if (id) {
    useGeolocation(id);
  }

  const startTimer = () => {
    callApi({
      url: `/inspection_reports/${id}/start`,
      method: "POST",
      data: {
        inspection_report: {
          report_type: "default",
        },
      },
    })
      .then(() => handleNext())
      .catch();
  };

  const manualUpdate = () => {
    callApiV2({
      url: `/appointments/${id}/update_vehicle`,
      method: "POST",
      data: {
        car: {
          vin: manualVin,
          license_plate: licensePlate,
          license_plate_state: licensePlateState,
        },
      },
    }).then((res) => {
      startTimer();
    });
  };

  return (
    <Container className="my-6">
      <Box className="my-6">
        <Typography variant="h3">Confirm Vehicle</Typography>
        <Typography style={{ margin: "5px 0" }}>{vehicle}</Typography>
      </Box>

      <Box className="my-6">
        <TextField
          fullWidth
          label="License Plate"
          value={licensePlate || ""}
          onChange={(event) => setLicensePlate(event.target.value)}
          variant="outlined"
          type="text"
        />
      </Box>

      <Box className="my-6">
        <Autocomplete
          options={stateList}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Registration State"
              variant="outlined"
            />
          )}
          onChange={(_, newValue) => setLicensePlateState(newValue)}
          value={licensePlateState || ""}
        />
      </Box>

      <Box>
        <Box className="my-6">
          <TextField
            fullWidth
            label="Scan or Enter VIN"
            defaultValue={manualVin}
            value={manualVin}
            onChange={(event) => setManualVin(event.target.value)}
            variant="standard"
            type="text"
            error={manualVin && !isValidVIN(manualVin)}
            helperText={
              manualVin && !isValidVIN(manualVin)
                ? "Not a valid VIN number"
                : ""
            }
          />
        </Box>

        <FmcFullWidthButton
          text={scanning ? "Close Scanner" : "Open Scanner"}
          variant="outlined"
          onClick={() => setScanning(!scanning)}
        />

        {scanning && (
          <div>
            <BarcodeScanner setScannedVin={setScannedVin} />
          </div>
        )}

        <FmcFullWidthButton
          text="Start Inspection"
          onClick={manualUpdate}
          variant="contained"
          disabled={!isValidVIN(manualVin)}
        />
      </Box>
    </Container>
  );
};

export { Step0 };
