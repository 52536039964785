import React from "react";
import { Link } from "@material-ui/core";

const MapLink = ({ address }) => {
  const geoUrl = `geo:0,0?q=${encodeURIComponent(address)}`;

  return <Link href={geoUrl}>{address}</Link>;
};

export default MapLink;
