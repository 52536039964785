import config from "config";

export const MEMBER_PLANS = [
  {
    name: "$180 / 6 months",
    price: 180,
    billing: "6 months",
    billing_interval: "month",
    billing_count: 6,
  },
  {
    name: "$290 / year",
    price: 290,
    billing: "year",
    billing_interval: "month",
    billing_count: 12,
  },
];

export const BILLINGS = [
  {
    label: "6 months",
    interval: "month",
    count: 6,
  },
  {
    label: "1 year",
    interval: "month",
    count: 12,
  },
];
