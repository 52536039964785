import apiTypes from "./api";
import authTypes from "./auth";
import mechanicsTypes from "./mechanics";
import appointmentsTypes from "./appointments";
import diagNotesTypes from "./diag_notes";

export {
  apiTypes,
  authTypes,
  mechanicsTypes,
  appointmentsTypes,
  diagNotesTypes,
};
