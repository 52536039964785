import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import VehicleForm from "../../AdminNewCustomer/components/VehicleForm";

const VehicleModal = ({ open, onClose, userId, nextSteps }) => {
  const [car, setCar] = useState({
    year: "",
    make: "",
    model: "",
    engine_size: "",
    user_id: userId,
  });

  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>Add New Vehicle</DialogTitle>
        <DialogContent>
          <VehicleForm
            car={car}
            setCar={setCar}
            nextStep={() => {
              nextSteps(car);
              onClose(false);
            }}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default VehicleModal;
