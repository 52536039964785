import React from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const ViewInspectionModal = ({ open, onClose, url }) => {
  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogContent>
          <iframe height={450} src={url} />

          <FmcFullWidthButton
            text="Open in Separate Window"
            onClick={() => window.open(url)}
            variant="contained"
          />

          <FmcFullWidthButton
            text="Close"
            onClick={onClose}
            variant="outlined"
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ViewInspectionModal;
