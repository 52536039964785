import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from '../_constants/config';

const MediaQueryContext = React.createContext({
  isMobile: null,
  isTabletOrMobile: null,
  isDesktopOrLaptop: null
});

const MediaQueryContextProvider = ({ children }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.SM - 1}px)`
  });
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS.LG - 1}px`
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.LG}px)`
  });

  return (
    <MediaQueryContext.Provider value={{
      isMobile,
      isTabletOrMobile,
      isDesktopOrLaptop,
    }}>
      {children}
    </MediaQueryContext.Provider>
  )
};

export { MediaQueryContext, MediaQueryContextProvider };
