import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { MechanicHeader } from "../_components/MechanicHeader";

const MechanicLayout = ({ children }) => {
  const authToken = useSelector((state) => {
    return state.auth.auth_token;
  });

  if (!authToken) {
    return <Redirect to="/mechanic/sign_in" />;
  }

  return (
    <div className="mechanic-layout">
      <MechanicHeader />
      {children}
    </div>
  );
};

export default MechanicLayout;
