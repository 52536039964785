export const USER_TYPES = {
  MECHANIC: "mechanic",
  CUSTOMER: "customer",
};

export const MASTER_SERVICE_LIST = [
  "4-Way Flasher",
  "AC / Heater Blower Motor",
  "AC Accumulator Assembly",
  "AC Automatic Temperature Control Module",
  "AC Automatic Temperature Sensor",
  "AC Compressor Assembly",
  "AC Compressor Clutch Cycle Switch",
  "AC Compressor Clutch and Coil Only",
  "AC Condenser Assembly",
  "AC Expansion Valve",
  "AC High Pressure Cut-Out Switch",
  "AC Hose / AC Line",
  "AC Low Pressure Cut-Out Switch",
  "AC Orifice Tube",
  "AC Receiver Drier",
  "AC System Evacuate & Recharge",
  "AC System Recharge",
  "AC/Heater Blower Relay",
  "AC/Heater Blower Switch",
  "Adjust Fan Belts",
  "Air Filter",
  "Air Injection Pump - Emissions",
  "Air Switch Valve - Emissions",
  "All Vehicle Drive Belts",
  "Alternator",
  "Anti-lock Brake System (ABS) Control Unit",
  "Axle Bearing",
  "Axle Seal",
  "Axle Shaft - Front Axle",
  "Axle Shaft - Rear Axle",
  "Back-Up Light",
  "Back-Up Light Switch",
  "Ball Joint - Front Suspension",
  "Ball Joint - Rear Suspension",
  "Ballast Resistor - Ignition",
  "Barometer Sensor - Fuel Injection",
  "Battery",
  "Battery Hold Down",
  "Body Control Module",
  "Both Radiator Hoses",
  "Brake Compensator / Proportioning Valve",
  "Brake Fluid Level Sensor",
  "Brake Light",
  "Brake Light Switch",
  "Brake Master Cylinder",
  "Brake Pressure Differential Valve/Switch",
  "Brake Warning Light Switch",
  "Breather Filter",
  "Bypass Hose",
  "CV Axle Shaft / Driveshaft",
  "CV Joint",
  "CV Joint Boot",
  "Cabin Air Filter",
  "Camshaft Position Sensor (CMP)",
  "Canister Purge Valve",
  "Catalytic Converter",
  "Center Link",
  "Check Valve - Air Injection System",
  "Clean, Inspect & Adjust Brakes",
  "Control Arm - Front Suspension",
  "Control Arm - Rear Suspension",
  "Control Arm Bushings - Front Suspension",
  "Control Arm Bushings - Rear Suspension",
  "Coolant Reservoir / Recovery Tank",
  "Coolant Temperature Sensor",
  "Cooling Fan Assembly",
  "Courtesy / Interior Light",
  "Crankshaft Position Sensor",
  "Crossover Pipe",
  "Cruise Control Actuator",
  "Cruise Control Module",
  "Cruise Control Switch",
  "Damper / Harmonic Balancer",
  "Distributor - Ignition",
  "Distributor Cap",
  "Distributor Rotor",
  "Distributor Vacuum Control",
  "Diverter Valve",
  "Door Ajar Switch",
  "Door Lock Motor",
  "Door Lock Switch",
  "Door Lock Timer",
  "Driveshaft",
  "Driveshaft Flange",
  "Driveshaft Slip Yoke",
  "Driveshaft Support Bearing",
  "Early Fuel Evaporation Solenoid",
  "Early Fuel Evaporation Valve",
  "Electric Fan Motor - Cooling System",
  "Electric Fan Relay - Cooling System",
  "Electronic Control Module - Fuel Inject.",
  "Engine Mounts",
  "Engine Sensors",
  "Engine Torque Strut",
  "Engine Tune Up",
  "Evaporation Canister Element",
  "Exhaust Clamp",
  "Exhaust Gas Recirculation (EGR) Solenoid",
  "Exhaust Gas Recirculation (EGR) Valve",
  "Exhaust Hanger",
  "Exhaust Manifold",
  "Exhaust Manifold Gasket",
  "Exhaust Pipe",
  "Extension Pipe",
  "Fan Belt - AC System",
  "Fan Belt - Cooling System",
  "Fan Belt - Electrical System",
  "Fan Belt - Emission System",
  "Fan Belt - Power Steering System",
  "Fan Blade - Cooling System",
  "Fan Clutch",
  "Freeze / Frost Plugs",
  "Front Brake Rotors / Drums",
  "Front Disc Brake Calipers",
  "Front Disc Brake Pads",
  "Front Struts or Shocks",
  "Fuel Accumulator",
  "Fuel Distributor or Rail",
  "Fuel Door Cable",
  "Fuel Filter",
  "Fuel Gauge",
  "Fuel Hose",
  "Fuel Injection Pump Relay",
  "Fuel Injector",
  "Fuel Injector O-Ring",
  "Fuel Pump",
  "Fuel Pump Pressure Regulator",
  "Fuel Rail O-Ring",
  "Fuel Shut Off Relay / Solenoid",
  "Fuse",
  "Fusible Link Cable",
  "Headlight",
  "Headlight Aim",
  "Headlight Dimmer Switch",
  "Headlight Switch",
  "Heat Riser / Exchanger Valve",
  "Heater Control  / Water Valve",
  "Heater Hose(s)",
  "Heater Mode Door Motor",
  "High Brake Light",
  "Hood Release Cable",
  "Horn",
  "Horn Relay",
  "Idle Speed Control Motor/Regulator - FI",
  "Idler Arm",
  "Ignition  Module",
  "Ignition Amplifier",
  "Ignition Cables / Plug Wires",
  "Ignition Capacitor",
  "Ignition Coil",
  "Ignition Switch",
  "Instrument Panel Lights",
  "Intake Manifold Gasket",
  "Knock / Detonation Sensor",
  "License Plate Light",
  "Lift Support - Hood",
  "Lift Support - Trunk",
  "Lower Radiator Hose",
  "Manifold Air Pressure (MAP) Sensor - Emissions",
  "Marker Light",
  "Mass Air Flow (MAF) Sensor",
  "Mixture Control Solenoid",
  "Negative Battery Cable",
  "Neutral Safety Switch",
  "Oil Pan Gasket",
  "Oil Pressure Gauge",
  "Oil Pressure Light",
  "Oil Pressure Sender Unit",
  "Oil Pump",
  "Oil Pump Seal",
  "Oxygen Sensor - Emissions",
  "PCV Valve",
  "Pack Wheel Bearings",
  "Park Brake Cable",
  "Park Brake Switch",
  "Park Light",
  "Park Light Lens",
  "Pick-Up Coil",
  "Pitman Arm",
  "Plenum Gasket",
  "Positive Battery Cable",
  "Power Antenna",
  "Power Brake Booster",
  "Power Steering Hi-Pressure Hose",
  "Power Steering Pump",
  "Power Steering Return Hose",
  "Radiator",
  "Radiator Cap",
  "Rear Brake Linings",
  "Rear Brake Rotors / Drums",
  "Rear Defroster Relay",
  "Rear Defroster Switch",
  "Rear Disc Brake Calipers",
  "Rear Disc Brake Pads",
  "Rear Struts or Shocks",
  "Rear Wheel Cylinders",
  "Rear Window Washer Switch",
  "Rear Wiper Motor",
  "Release Cable - Fuel Door",
  "Release Cable - Hood",
  "Release Cable - Trunk",
  "Reluctor",
  "Seat Belt Control Module / Switch",
  "Seat Belt Warning Switch",
  "Seat Motor - Power",
  "Seat Switch - Power",
  "Serpentine Belt Tensioner",
  "Side Mirror Motor",
  "Spark Plugs",
  "Speedometer Cable",
  "Speedometer Head",
  "Speedometer Seal",
  "Stabilizer Bar - Front Suspension",
  "Stabilizer Bar - Rear Suspension",
  "Starter Motor",
  "Starter Solenoid",
  "Steering Coupling Disc",
  "Strut Mount Bearing",
  "Sway / Stabilizer Bar End Link / Bushing - Front",
  "Tachometer Head",
  "Tail Light",
  "Tail Light Lens",
  "Temperature Gauge / Light",
  "Temperature Sender Unit",
  "Thermostat",
  "Thermostat Housing",
  "Throttle Position Sensor - Emissions",
  "Throttle Positioner - Carburetor",
  "Tie-Rod Ends",
  "Tilt Steering Column U-Joint",
  "Transmission Mount",
  "Turn Signal Flasher",
  "Turn Signal Switch",
  "Universal Joint - Center",
  "Universal Joint - Front",
  "Universal Joint - Rear",
  "Universal Joints - All",
  "Upper Radiator Hose",
  "Valve Cover Gaskets",
  "Vehicle Specifications, Capacities & Torque",
  "Vehicle Speed Sensor (VSS)",
  "Voltage Regulator",
  "Water Pump",
  "Wheel Anti-lock Brake System (ABS) Sensor",
  "Wheel Bearings",
  "Wide Open Throttle Switch/Valve",
  "Window Lift Motor - Power",
  "Window Regulator",
  "Window Switch - Power",
  "Windshield Washer Hose / Nozzles",
  "Windshield Washer Level Sensor",
  "Windshield Washer Pump",
  "Windshield Washer Reservoir",
  "Windshield Washer Switch",
  "Windshield Wiper Motor",
  "Windshield Wiper Switch",
  "Wiper Blades",
];

export const COOLANT_SERVICE = [
  "Both Radiator Hoses",
  "Coolant Reservoir / Recovery Tank",
  "Coolant Temperature Sensor",
  "Lower Radiator Hose",
  "Radiator",
  "Thermostat",
  "Thermostat Housing",
  "Upper Radiator Hose",
  "Water Pump",
];
