import React from "react";
import { Typography, Box, Container, Grid } from "@material-ui/core";
import {
  Star,
  Build,
  CardMembership,
  Check,
  Label,
  AttachMoney,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    backgroundColor: "#fff",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    marginBottom: "2px",
  },
}));

function InfoPanel({ step, setStep }) {
  const classes = useStyles();

  const data = [
    { label: "Experience", icon: "Star", step: 1 },
    { label: "Technican", icon: "Build", step: 2 },
    { label: "Upsell", icon: "Sell", step: 3 },
    { label: "Sticker", icon: "Label", step: 4 },
    { label: "Membership", icon: "CardMembership", step: 5 },
    { label: "Done", icon: "Check", step: 6 },
  ];

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Grid container>
          {data.map((item) => (
            <Grid
              item
              xs={4}
              sm={2}
              key={item.step}
              className={classes.item}
              style={{ color: step >= item.step ? "primary" : "#ddd" }}
            >
              {item.icon === "Star" && (
                <Star
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "Build" && (
                <Build
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "Sell" && (
                <AttachMoney
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "Label" && (
                <Label
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "CardMembership" && (
                <CardMembership
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "Check" && (
                <Check
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              <Typography
                style={{
                  cursor: "pointer",
                  textDecoration: step > item.step ? "underline" : "",
                }}
                onClick={() => {
                  if (step > item.step) {
                    setStep(item.step);
                  }
                }}
                variant="subtitle1"
              >
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default InfoPanel;
