import React from "react";
import { Button, Container } from "@material-ui/core";
import PropTypes from "prop-types";

const FmcFullWidthButton = ({ text, onClick, variant, disabled, color }) => {
  return (
    <Container>
      <div className="flex my-6">
        <Button
          className="flex-1"
          variant={variant}
          onClick={() => onClick()}
          disabled={disabled || false}
          color={color || "primary"}
        >
          {text}
        </Button>
      </div>
    </Container>
  );
};

FmcFullWidthButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FmcFullWidthButton;
