import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router";
import { apiGetAppointment } from "../../../_apis";
import UpdateCreditCard from "./components/UpdateCreditCard";
import FmcFullWidthButton from "../components/FmcFullWidthButton";

function CreditCard() {
  const { appointment_id, message } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const func = async () => {
      if (appointment_id) {
        const rsp = await apiGetAppointment(appointment_id);
        setAppointment({
          ...rsp.data.attributes,
          id: appointment_id,
        });
      }
    };
    func();
  }, [appointment_id]);

  if (!appointment) {
    return (
      <Box pt={3} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Box pt={15} pb={15}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Update Credit Card</Typography>
            {message && <p className="text-fmc-red">{message}</p>}
            <UpdateCreditCard
              appointment_id={appointment_id}
              onSuccess={() => setSuccess(true)}
            />
            <FmcFullWidthButton
              onClick={handleBack}
              text=" Go Back to Job"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export { CreditCard };
