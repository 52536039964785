import React, { useState, useEffect } from "react";
import {
  IconButton,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  Avatar,
  Chip,
  Button,
} from "@material-ui/core";
import { ArrowBack, CameraAlt, Send } from "@material-ui/icons";
import {
  apiGetAppointment,
  getAppointmentMessages,
  sendAppointmentMessages,
} from "../../../_apis";
import { useLocation } from "react-router-dom";
import ImageListPreview from "../components/ImageListPreview";
import { Link } from "react-router-dom";

const MessageByID = () => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [userId, setUserId] = useState();
  const [mechanicId, setMechanicId] = useState();
  const [customerName, setCustomerName] = useState();
  const [rawData, setRawData] = useState();
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [openPictureBar, setOpenPictureBar] = useState(false);
  const [ref, setRef] = useState(false);

  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const appId = pathSegments[3];

  useEffect(() => {
    const fetchAndProcessMessages = async () => {
      try {
        const rawMessages = await getAppointmentMessages(appId, "mechanic");
        setRawData(rawMessages);

        if (appId) {
          const appointment = await apiGetAppointment(appId);
          if (appointment) {
            setUserId(appointment.data.attributes.user_id);
            setMechanicId(appointment.data.attributes.mechanic.id);
            setCustomerName(appointment.data.attributes.customer_name);
          }
        }
      } catch (error) {
        console.error("Error fetching and processing messages:", error);
      }
    };

    if (appId) {
      fetchAndProcessMessages();
    }
  }, [appId]);

  const handleSend = () => {
    async function fetchAndProcessMessages() {
      const data = await getAppointmentMessages(appId, "mechanic");
      setRawData(data);
    }

    if (currentMessage.trim() || selectedPhotos.length !== 0) {
      const newMessage = {
        mechanic_id: mechanicId,
        user_id: userId,
        appointment_id: appId,
        body: currentMessage.trim(),
        direction: "to_user",
        photos: selectedPhotos,
      };
      sendAppointmentMessages(newMessage).then((res) => {
        fetchAndProcessMessages();
        setRef(false);
      });
      setCurrentMessage("");
      setSelectedPhotos([]);
      setOpenPictureBar(false);
    }
  };

  const isLocationLink = (message) => {
    return message?.startsWith("http://") || message?.startsWith("https://");
  };

  const groupMessagesByDate = (data) => {
    const groupedMessages = [];
    for (const [date, messages] of Object.entries(data)) {
      groupedMessages.push({ date, messages });
    }
    groupedMessages.reverse();
    return groupedMessages;
  };

  const Message = ({ message }) => {
    let messageStyle = {};
    let textStyle = {};

    if (message.message_type === "automated") {
      messageStyle = {
        backgroundColor: "#F3F3F3",
        padding: "16px",
        borderRadius: "20px",
        margin: "15px",
        maxWidth: "80%",
        alignSelf: "center",
        borderBottom: "1px solid #FFEB3B",
        border: "1px solid var(--Black-50, #646867)",
      };
      textStyle = { color: "black", fontSize: 14, fontFamily: "Inter" };
    } else if (
      (message.message_type === "manual" || message.message_type === null) &&
      message.direction === "to_user"
    ) {
      messageStyle = {
        backgroundColor: "#005959",
        padding: "10px",
        borderRadius: "16px",
        margin: "5px",
        maxWidth: "80%",
        alignSelf: "flex-end",
        borderBottom: "1px solid transparent",
        borderBottomRightRadius: "0px",
      };
      textStyle = { color: "white", fontSize: 14, fontFamily: "Inter" };
    } else if (
      message.direction === "to_mechanic" &&
      (message.message_type === "manual" || message.message_type === null)
    ) {
      messageStyle = {
        backgroundColor: "#F3F3F3",
        padding: "10px",
        borderRadius: "16px",
        margin: "5px",
        maxWidth: "80%",
        alignSelf: "flex-start",
        borderBottom: "1px solid transparent",
        borderBottomLeftRadius: "0px",
      };
      textStyle = { color: "black", fontSize: 14, fontFamily: "Inter" };
    }

    return (
      <Box style={messageStyle}>
        {message.photos.length > 0 ? (
          message.photos.map((photo, index) => (
            <React.Fragment key={index}>
              <img src={photo.url} style={messageStyle} />

              {message.body !== "" && (
                <Typography variant="body1" style={textStyle}>
                  {message.body}
                </Typography>
              )}
            </React.Fragment>
          ))
        ) : message.body && isLocationLink(message.body) ? (
          <Link
            variant="body1"
            style={{
              textDecoration: "underline",
              fontSize: 14,
              color: "#000",
            }}
            onClick={() => window.open(message.body, "_blank")}
          >
            My Location
          </Link>
        ) : (
          <>
            <Typography variant="body1" style={textStyle}>
              {message.body}
            </Typography>
          </>
        )}
      </Box>
    );
  };

  function formatDate(dateStr) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const parts = dateStr.split("-");
    const monthIndex = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    let suffix = "th";
    if (day === 1 || day === 21 || day === 31) suffix = "st";
    else if (day === 2 || day === 22) suffix = "nd";
    else if (day === 3 || day === 23) suffix = "rd";

    const month = months[monthIndex];

    return `${day}${suffix} ${month}`;
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          backgroundColor: "#fff",
          width: "100%",
          maxWidth: "700px",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "20px",
          zIndex: 1000,
          background: "#f3f3f3",
        }}
      >
        <div style={{ marginLeft: "10px", marginTop: "10px" }}>
          <IconButton
            onClick={() => window.history.back()}
            style={{ marginRight: 20 }}
          >
            <ArrowBack />
          </IconButton>
          <Chip
            avatar={
              <Avatar style={{ fontFamily: "Inter" }}>
                {customerName?.charAt(0)}
              </Avatar>
            }
            label={customerName}
            inputProps={{
              style: {
                fontFamily: "Inter",
              },
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{
              position: "absolute",
              right: "40px",
              top: "10px",
              marginRight: 20,
              textTransform: "none",
            }}
            onClick={() => {
              window.location.href = `/mechanic/job/${appId}`;
            }}
          >
            {" "}
            Job #{appId}
          </Button>
        </div>
      </div>

      <div
        style={{
          paddingTop: "70px",
          paddingBottom: "70px",
          height: "calc(100vh - 140px)",
          overflowY: "scroll",
          paddingLeft: "4vw",
          paddingRight: "4vw",
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
          maxWidth: "700px",
          margin: "auto",
        }}
      >
        {rawData &&
          groupMessagesByDate(rawData).map(({ date, messages }) => (
            <Box key={date} style={{ flex: 1 }}>
              <div
                style={{
                  textAlign: "center",
                  margin: "50px auto 10px auto",
                  fontSize: 14,
                  backgroundColor: "#646867",
                  color: "#fff",
                  borderRadius: "20px",
                  fontFamily: "Inter",
                  width: 100,
                }}
              >
                {formatDate(date)}
              </div>

              <Box style={{ flexDirection: "column", display: "flex" }}>
                {messages.map((message, index) => (
                  <>
                    <Message key={index} message={message} />
                  </>
                ))}
              </Box>
            </Box>
          ))}
        <Box style={{ height: 100 }}></Box>
        <div style={{ overflow: "hidden", height: "0px" }} id="scrollAnchor" />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          background: "#FFF",
          width: "100%",
          maxWidth: "500px", // Set max width
          left: "50%", // Centering
          transform: "translateX(-50%)", // Centering
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          gap: 10, // Ensure it's above other elements
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
            <OutlinedInput
              value={currentMessage}
              placeholder="Type your message here"
              onChange={(e) => setCurrentMessage(e.target.value)}
              aria-describedby="base-name-helper-text"
              inputProps={{
                "aria-label": "weight",
                style: {
                  fontFamily: "Inter",
                  fontSize: 16,
                },
              }}
            />
          </FormControl>
          <Box
            mt={1}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              style={{ background: "#005959", width: 30, height: 30 }}
              onClick={handleSend}
            >
              <Send style={{ width: 20, height: 20, color: "#fff" }} />
            </IconButton>
            <IconButton
              style={{ background: "grey", width: 30, height: 30 }}
              onClick={() => setOpenPictureBar(!openPictureBar)}
            >
              <CameraAlt style={{ width: 20, height: 20, color: "#fff" }} />
            </IconButton>
          </Box>
        </div>
        {openPictureBar && (
          <ImageListPreview
            value={selectedPhotos || []}
            onChange={(d) => setSelectedPhotos(d)}
            required
          />
        )}
      </div>
    </>
  );
};

export default MessageByID;
