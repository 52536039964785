import React, { useState } from "react";
import { Box, Container, OutlinedInput, Typography } from "@material-ui/core";
import { isSignUpInputValid } from "./utility";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import { useDispatch } from "react-redux";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { apiMechanicSignUp } from "../../../_apis";
import { authTypes } from "../../../_types";

function MechanicRegistration(props) {
  const initialState = props.inputFieldsArray.reduce(
    (prev, i) => ({ ...prev, [i.key]: "" }),
    {}
  );
  const [signUpDetails, setSignUpDetails] = useState({
    ...initialState,
    errors: {},
  });
  const [homeAddress, setHomeAddress] = useState();
  const [highlightedField, setHighlightedField] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = () => {
    setIsRegistering(true);
    setSignUpDetails({ ...signUpDetails, errors: {} });

    const {
      email,
      name,
      last_name,
      phone,
      password,
      password_confirmation,
      shirt_size,
    } = signUpDetails;
    const { valid, errors } = isSignUpInputValid(signUpDetails);

    if (valid) {
      apiMechanicSignUp({
        email,
        phone,
        name,
        last_name,
        password,
        password_confirmation,
        shirt_size,
        home_address: homeAddress?.label,
      })
        .then((res) => {
          dispatch({
            type: authTypes.MECHANIC_REGISTRATION,
            payload: res,
          }).then(() => {
            window.location.href = "/mechanic/jobs";
          });
        })
        .catch(() => {
          setSignUpDetails({
            ...signUpDetails,
            errors: { generic: error },
          });
        })
        .finally(() => {
          setIsRegistering(false);
        });
    } else if (Object.keys(errors).length > 0) {
      setSignUpDetails({ ...signUpDetails, errors });
      setIsRegistering(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSignUpDetails({
      ...signUpDetails,
      [name]: value,
    });
  };

  return (
    <Container style={{ paddingLeft: "8px", paddingRight: "8px" }}>
      <Box>
        {props.inputFieldsArray.map((i, index) => (
          <Box key={index} className="my-6 flex flex-col relative">
            <OutlinedInput
              onBlur={() => setHighlightedField("")}
              onFocus={() => setHighlightedField(i.key)}
              className=""
              type={i.type}
              value={signUpDetails[i.key] || ""}
              name={i.key}
              placeholder={i.placeholder}
              onChange={handleInputChange}
            />
            {signUpDetails.errors[i.key] !== undefined && (
              <Typography className="my-1 text-sm text-fmc-red">
                {signUpDetails.errors[i.key]}
              </Typography>
            )}
          </Box>
        ))}

        <GooglePlacesAutocomplete
          selectProps={{
            value: homeAddress,
            onChange: setHomeAddress,
            placeholder: "Home address",
          }}
          apiKey="AIzaSyAz4WaDB8lmLBWKuPF0RGJszY2Hf7tcAhk"
          debounce={300}
        />

        {signUpDetails.errors["generic"] !== undefined && (
          <Typography className="my-1 text-sm text-fmc-red">
            {signUpDetails.errors["generic"].join(". ")}
          </Typography>
        )}

        <FmcFullWidthButton
          text="Sign Up"
          onClick={onSubmit}
          variant="contained"
          disabled={isRegistering}
        />
      </Box>

      <FmcFullWidthButton
        text="Have an account? Sign In"
        onClick={() => (window.location.href = "/mechanic/sign_in")}
        variant="outlined"
      />
    </Container>
  );
}

export { MechanicRegistration };
