export const buildFormData = (data, wrapper = null) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    const value = data[key];
    let form_key = key;

    if (wrapper) {
      form_key = `${wrapper}[${form_key}]`;
    }

    if (Array.isArray(value)) {
      form_key += '[]';
      value.forEach((item) => formData.append(form_key, item));
    } else {
      formData.append(form_key, value);
    }
  });

  return formData;
};
