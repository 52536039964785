import React, { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MapLink from "../../../_components/MapLink";
import { SELECT_TIME_OPTIONS } from "../utility";
import {
  apiMechanicPickUpSameDayJob,
  apiMechanicRequestSameDayTime,
} from "../../../_apis/mechanics/appointments";
import moment from "moment";

const SameDayModal = ({ classNames, boxClassNames, open, onClose, jobs }) => {
  const [showTime, setShowTime] = useState();
  const [requestedTime, setRequestedTime] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const JobDisplay = ({ job }) => {
    const car = job.attributes.car;
    let labor = job.attributes.appointment_type !== "repair" ? 1 : 0;

    let services;

    const estimate = job.attributes.estimate;

    if (estimate) {
      services = Object.keys(estimate.services);
    }

    if (estimate) {
      labor += estimate.total_labor;
    }

    return (
      <Box>
        {job.attributes.appointment_time && (
          <Box className="my-3">
            <Typography style={{ fontWeight: "bold" }}>Time</Typography>
            <Typography>{job.attributes.appointment_time}</Typography>
          </Box>
        )}

        {labor > 0 ? (
          <Box className="my-3">
            <Typography style={{ fontWeight: "bold" }}>Labor</Typography>
            <Typography>{labor} hr(s)</Typography>
          </Box>
        ) : (
          <></>
        )}

        {car && (
          <Box className="my-3">
            <Typography>
              <Typography style={{ fontWeight: "bold" }}>Vehicle</Typography>
              {car.year} {car.make} {car.model}
            </Typography>
          </Box>
        )}

        <Box className="my-3">
          <Typography style={{ fontWeight: "bold" }}>Services</Typography>

          {job.attributes.appointment_type === "diagnosis" &&
            job.attributes.diagnosis_input && (
              <Typography>{job.attributes.diagnosis_input}</Typography>
            )}

          {services && services.map((s) => <Typography>{s}</Typography>)}
        </Box>

        {job.attributes.exact_address && (
          <Box className="my-3">
            <Typography style={{ fontWeight: "bold" }}>Location</Typography>
            <MapLink address={job.attributes.exact_address} />
          </Box>
        )}

        <Grid className="my-3" container spacing={2}>
          <Grid item>
            <Button
              disabled={
                !job.attributes.exact_address ||
                !job.attributes.appointment_time ||
                !job.attributes.stripe_customer_exists
              }
              onClick={() => {
                apiMechanicPickUpSameDayJob(job.id).then((res) => {
                  if (res.errors) {
                    setErrorMsg(res.errors.join(", "));
                  } else {
                    setSuccessMsg(
                      "You just picked up this job. We're redirecting you to the task list."
                    );

                    setTimeout(() => {
                      window.location.href = `/mechanic/job/${job.id}`;
                    }, 3500);
                  }
                });
              }}
              variant="contained"
              color="primary"
            >
              Pick Up Job
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => (window.location.href = "slack://")}
              variant="contained"
              color="secondary"
            >
              Message Us
            </Button>
          </Grid>
        </Grid>

        <Box className="my-3">
          <Typography
            className="my-3 italic underline text-fmc-blue cursor-pointer"
            onClick={() => setShowTime(!showTime)}
          >
            Request a time
          </Typography>

          {showTime && (
            <>
              <Select
                variant="outlined"
                size="small"
                value={requestedTime}
                onChange={(e) => setRequestedTime(e.target.value)}
                fullWidth
              >
                {SELECT_TIME_OPTIONS.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <Button
                onClick={() => {
                  apiMechanicRequestSameDayTime(job.id, requestedTime).then(
                    () => {
                      setSuccessMsg(
                        "Request received, you'll be notified if the customer accepts this time."
                      );

                      setTimeout(() => {
                        setSuccessMsg();
                      }, 4000);
                    }
                  );
                }}
                className="my-3"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!requestedTime}
              >
                Request
              </Button>
            </>
          )}

          {successMsg && (
            <Typography className="my-3" color="green">
              {successMsg}
            </Typography>
          )}
          {errorMsg && (
            <Typography className="my-3" color="red">
              {errorMsg}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      style={{ maxHeight: "100vh", overflow: "scroll" }}
      className={classNames}
      open={open}
      onClose={onClose}
    >
      <Box className="px-4 pt-24 mb-10 pb-24 max-h-full overflow-scroll">
        <div className={boxClassNames}>
          <IconButton
            style={{
              padding: "2px",
            }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <div className="flex flex-col">
            {jobs.map((i) => {
              return (
                <Card className="my-3">
                  <CardHeader title={`Job #${i.id}`} />
                  <CardContent>
                    <JobDisplay job={i} />
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export { SameDayModal };
