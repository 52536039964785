import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Tabs,
  Tab,
  OutlinedInput,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { AddCircle, Check, Close } from "@material-ui/icons";
import {
  apiPartOnlyLookup,
  apiPartsListLookup,
  apiPartsLookup,
} from "../../../../_apis/mechanics/epicor_searches";
import {
  getPartPrice,
  getPartPriceFleet,
  MASTER_EPICOR_LIST,
} from "../../../../_helpers/utils";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { TabPanel, a11yProps } from "../../../../_components/Tabs";

const columns = [
  { key: "Name", type: "text" },
  { key: "Number", type: "text" },
  { key: "Brand", type: "text" },
  { key: "Price", type: "text" },
  { key: "URL", type: "link" },
  { key: "Notes", type: "text" },
];

const PartsLookupDialog = ({
  open,
  onCancel,
  serviceId,
  EstimateFormContext,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [partsLookupData, setPartsLookupData] = useState(null);
  const [partLookupData, setPartLookupData] = useState(null);
  const [loadingPartsData, setLoadingPartsData] = useState(false);
  const [loadingPartsList, setLoadingPartsList] = useState(false);
  const [partsList, setPartsList] = useState();
  const [partToSearch, setPartToSearch] = useState();
  const [currentJobCode, setCurrentJobCode] = useState(null);
  const [currentSupplier, setCurrentSupplier] = useState("");
  const [itemsAdded, setItemsAdded] = useState([]);
  const [shouldSave, setShouldSave] = useState();
  const [partSearchTerm, setPartSearchTerm] = useState("");
  const [questions, setQuestions] = useState({
    partCondition: [],
    laborCondition: [],
  });
  const [answers, setAnswers] = useState({
    partCondition: [],
    laborCondition: [],
  });
  const [partQuestions, setPartQuestions] = useState({
    partCondition: [],
    laborCondition: [],
  });
  const [partAnswers, setPartAnswers] = useState({
    partCondition: [],
    laborCondition: [],
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data,
    onUpdateServiceField,
    onAddPart,
    onUpdatePartField,
    appointment,
    isAdmin,
    repeatCustomer,
    onRemovePart,
    onSave,
    isFleet,
    isDiagToRepair,
  } = useContext(EstimateFormContext);

  const service = useMemo(() => data.services[serviceId], [data, serviceId]);

  const isValidService = () => {
    if (!service.labor_hours) {
      return false;
    }

    if (!service.parts || service.parts.length == 0) {
      return true;
    }

    return (
      service.parts.filter((i) => !i.name || !i.price || !i.quantity).length ==
      0
    );
  };

  const appointment_id = appointment.id;

  const partPrice = (price) => {
    if (isFleet) {
      return getPartPriceFleet(price, appointment);
    }

    return getPartPrice(price, repeatCustomer, isDiagToRepair, searchTerm);
  };

  const addPartToService = (item) => {
    let newPartId = data.services[serviceId].parts?.length || 0;

    onAddPart(serviceId);

    onUpdatePartField(serviceId, newPartId, "name", item.name);
    onUpdatePartField(serviceId, newPartId, "part_number", item.number);
    onUpdateServiceField(serviceId, "name", searchTerm);

    const quantity = item.quantity || 1;

    let priceToUpdate;

    if (quantity && quantity > 1) {
      priceToUpdate = partPrice(Number(item.fmc_cost) * quantity) / quantity;
    } else {
      priceToUpdate = partPrice(Number(item.fmc_cost));
    }

    onUpdatePartField(serviceId, newPartId, "price", priceToUpdate);

    onUpdatePartField(serviceId, newPartId, "quantity", quantity);

    onUpdatePartField(serviceId, newPartId, "epicor_part", true);

    setShouldSave(true);

    if (!itemsAdded.includes(item)) {
      setItemsAdded([...itemsAdded, item]);
    }
  };

  const addLaborToService = (item) => {
    onUpdateServiceField(serviceId, "labor_hours", item.estimated);
    onUpdateServiceField(serviceId, "name", searchTerm);

    setShouldSave(true);

    if (!itemsAdded.includes(item)) {
      setItemsAdded([...itemsAdded, item]);
    }
  };

  const addCustomPart = () => {
    onAddPart(serviceId);

    onCancel();
  };

  const removePart = (item) => {
    if (service.parts) {
      const partId = service.parts.findIndex((p) => p.name == item.name);

      onRemovePart(serviceId, partId);
      setItemsAdded(itemsAdded.filter((i) => i.number !== item.number));
    } else {
      setItemsAdded([]);
    }
  };

  const handleChangeSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      setCurrentJobCode(
        MASTER_EPICOR_LIST.filter(
          (i) =>
            i[1] &&
            newSearchTerm &&
            i[1].toLowerCase() === newSearchTerm.toLowerCase()
        )[0]
      );
    }
  };

  const loadPartsList = async () => {
    if (!partSearchTerm) return;

    setLoadingPartsList(true);

    const rsp = await apiPartsListLookup(partSearchTerm, appointment_id);

    setLoadingPartsList(false);

    setPartsList(rsp);
  };

  const loadPart = async () => {
    if (!partToSearch) return;

    setLoadingPartsList(true);

    let queryString = "";
    if (partAnswers.partCondition.length > 0) {
      queryString += `&partCondition=${JSON.stringify(
        partAnswers.partCondition.map((i) => {
          return {
            QuestionCode: i.QuestionCode,
            Answers: [
              {
                Code: i.Answer.Code,
                Text: i.Answer.Text,
              },
            ],
          };
        })
      )}`;
    }
    if (partAnswers.laborCondition.length > 0) {
      queryString += `&laborCondition=${JSON.stringify(
        partAnswers.laborCondition.map((i) => {
          return {
            QuestionCode: i.QuestionCode,
            Answers: [
              {
                Code: i.Answer.Code,
                Text: i.Answer.Text,
              },
            ],
          };
        })
      )}`;
    }

    const rsp = await apiPartOnlyLookup(
      partToSearch.Code,
      partToSearch.Text,
      appointment_id,
      queryString
    );

    setLoadingPartsList(false);

    const data = rsp[0];

    if (data) {
      if (data.Questions) {
        setPartQuestions({
          ...partQuestions,
          [data.QuestionType]: data.Questions,
        });
      } else {
        setPartQuestions({
          partCondition: [],
          laborCondition: [],
        });
      }

      setPartLookupData(data);
    }
  };

  const loadPartsLabor = async () => {
    if (!currentJobCode) return;

    setLoadingPartsData(true);

    let queryString = "";
    if (answers.partCondition.length > 0) {
      queryString += `&partCondition=${JSON.stringify(
        answers.partCondition.map((i) => {
          return {
            QuestionCode: i.QuestionCode,
            Answers: [
              {
                Code: i.Answer.Code,
                Text: i.Answer.Text,
              },
            ],
          };
        })
      )}`;
    }
    if (answers.laborCondition.length > 0) {
      queryString += `&laborCondition=${JSON.stringify(
        answers.laborCondition.map((i) => {
          return {
            QuestionCode: i.QuestionCode,
            Answers: [
              {
                Code: i.Answer.Code,
                Text: i.Answer.Text,
              },
            ],
          };
        })
      )}`;
    }

    const rsp = await apiPartsLookup(
      appointment_id,
      currentJobCode,
      currentSupplier,
      queryString,
      !isAdmin
    );

    setLoadingPartsData(false);

    if (!(rsp instanceof Array)) {
      setPartsLookupData({ parts: [] });
      return;
    }

    const data = rsp[0];

    if (data) {
      if (data.Questions) {
        setQuestions({
          ...questions,
          [data.QuestionType]: data.Questions,
        });
      } else {
        setQuestions({
          partCondition: [],
          laborCondition: [],
        });
      }

      setPartsLookupData(data);
    }
  };

  useEffect(() => {
    if (
      service.name &&
      MASTER_EPICOR_LIST.map((i) => i[1].toLowerCase()).includes(
        service.name.toLowerCase()
      )
    )
      handleChangeSearchTerm(service.name);
  }, [service.name]);

  const CustomPart = () => {
    return (
      <Box className="my-5">
        <FmcFullWidthButton
          text="Add a custom part"
          onClick={() => addCustomPart()}
          variant="outlined"
        />
      </Box>
    );
  };

  const SaveButton = () => {
    return (
      <Box className="my-5">
        <Box
          className="p-5"
          style={{
            background: "rgba(109, 89, 249, 0.07)",
            border: "2px solid #005959",
            borderRadius: "8px",
          }}
        >
          <Typography className="text-xl">Items Added</Typography>

          <ul className="p-5">
            {itemsAdded.map((i, index) => {
              return (
                <li className="list-disc text-lg" key={index}>
                  {i.is_part ? i.name : `${i.estimated} hr(s) labor`}
                </li>
              );
            })}
          </ul>

          {itemsAdded.filter((i) => i.is_labor).length == 0 &&
            !service.labor_hours && (
              <Typography color="error">Missing labor</Typography>
            )}
        </Box>
        <FmcFullWidthButton
          text="Back to Estimate"
          onClick={onCancel}
          variant="outlined"
        />
        <FmcFullWidthButton
          text="Save & Return"
          disabled={!isValidService()}
          onClick={onSave}
          variant="contained"
        />
      </Box>
    );
  };

  const ConditionQuestion = ({ i, conditionType }) => {
    return (
      <Box className="my-6">
        <p>{i.QuestionText}</p>
        <FormGroup>
          {i.Options.map((item, index) => (
            <FormControlLabel
              onChange={() => {
                const newAnswer = {
                  QuestionCode: i.QuestionCode,
                  Answer: item,
                };

                let newAnswers = answers[conditionType];

                const existingAnswer = newAnswers.find(
                  (j) => j.QuestionCode == i.QuestionCode
                );

                if (existingAnswer) {
                  newAnswers = newAnswers.filter(
                    (j) => j.QuestionCode != i.QuestionCode
                  );

                  newAnswers.push(newAnswer);

                  setAnswers({
                    ...answers,
                    [conditionType]: newAnswers,
                  });
                } else {
                  newAnswers.push(newAnswer);

                  setAnswers({
                    ...answers,
                    [conditionType]: newAnswers,
                  });
                }
              }}
              key={index}
              control={
                <Checkbox
                  checked={
                    answers[conditionType].find(
                      (j) => j.QuestionCode == i.QuestionCode
                    )?.Answer?.Text === item.Text
                  }
                />
              }
              label={item.Text}
            />
          ))}
        </FormGroup>
      </Box>
    );
  };

  const ConditionQuestionPart = ({ i, conditionType }) => {
    return (
      <Box className="my-6">
        <p>{i.QuestionText}</p>
        <FormGroup>
          {i.Options.map((item, index) => (
            <FormControlLabel
              onChange={() => {
                const newAnswer = {
                  QuestionCode: i.QuestionCode,
                  Answer: item,
                };

                let newAnswers = partAnswers[conditionType];

                const existingAnswer = newAnswers.find(
                  (j) => j.QuestionCode == i.QuestionCode
                );

                if (existingAnswer) {
                  newAnswers = newAnswers.filter(
                    (j) => j.QuestionCode != i.QuestionCode
                  );

                  newAnswers.push(newAnswer);

                  setPartAnswers({
                    ...partAnswers,
                    [conditionType]: newAnswers,
                  });
                } else {
                  newAnswers.push(newAnswer);

                  setPartAnswers({
                    ...partAnswers,
                    [conditionType]: newAnswers,
                  });
                }
              }}
              key={index}
              control={
                <Checkbox
                  checked={
                    partAnswers[conditionType].find(
                      (j) => j.QuestionCode == i.QuestionCode
                    )?.Answer?.Text === item.Text
                  }
                />
              }
              label={item.Text}
            />
          ))}
        </FormGroup>
      </Box>
    );
  };

  const RenderParts = () => {
    if (partsLookupData === null) {
      return <></>;
    }

    const isSm = useMediaQuery("(max-width: 600px)");

    return (
      <>
        <Box>
          {shouldSave && <SaveButton />}
          {(!partsLookupData.parts || partsLookupData.parts.length === 0) &&
          questions.laborCondition.length === 0 &&
          questions.partCondition.length === 0 ? (
            <Typography variant="body2" align="center" paragraph>
              No parts information found.
            </Typography>
          ) : (
            <>
              <Typography variant="h6" align="center" paragraph>
                Parts
              </Typography>

              <TableContainer component={Paper} style={{ width: "100%" }}>
                <Table style={{ marginBottom: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          background: "#F3F3F3",
                          border: "1px solid #D4D5D5",
                          fontWeight: "bold",
                          width: "60px",
                          color: "#005959",
                        }}
                      >
                        Add
                      </TableCell>
                      {columns.map((col, index) => (
                        <TableCell
                          key={index}
                          style={{
                            background: "#F3F3F3",
                            border: "1px solid #D4D5D5",
                            color: "#005959",
                            fontWeight: "bold",
                          }}
                        >
                          {col.key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(partsLookupData.parts || []).map((item, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{
                            border: "1px solid #D4D5D5",
                            background: "#F3F3F3",
                          }}
                        >
                          {itemsAdded.includes(item) ? (
                            <IconButton
                              color="success"
                              onClick={() => removePart(item)}
                            >
                              <Check />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => addPartToService(item)}
                            >
                              <AddCircle />
                            </IconButton>
                          )}
                        </TableCell>
                        {columns.map((col, index) => {
                          if (col.key === "Name") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.name}
                              </TableCell>
                            );
                          }
                          if (col.key === "Number") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.number}
                              </TableCell>
                            );
                          }
                          if (col.key === "Brand") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.brand}
                              </TableCell>
                            );
                          }
                          if (col.key === "Price") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {partPrice(item.fmc_cost)}
                              </TableCell>
                            );
                          }
                          if (col.key === "URL") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.url && (
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Part
                                  </a>
                                )}
                              </TableCell>
                            );
                          }
                          if (col.key === "Notes") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "100px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {item.notes}
                                </div>
                              </TableCell>
                            );
                          }
                          return null;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
        <Box mt={3}>
          {(!partsLookupData.labor || partsLookupData.labor.length === 0) &&
          questions.laborCondition.length === 0 &&
          questions.partCondition.length === 0 ? (
            <Typography variant="body2" align="center" paragraph>
              No labor information found.
            </Typography>
          ) : (
            <>
              <Typography variant="h6" align="center" paragraph>
                Labor (from Mitchell)
              </Typography>
              <TableContainer component={Paper} style={{ width: "100%" }}>
                <Table style={{ marginBottom: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          background: "#F3F3F3",
                          border: "1px solid #D4D5D5",
                          fontWeight: "bold",
                          width: "60px",
                          color: "#005959",
                        }}
                      >
                        Add
                      </TableCell>
                      <TableCell
                        style={{
                          background: "#F3F3F3",
                          border: "1px solid #D4D5D5",
                          color: "#005959",
                          fontWeight: "bold",
                        }}
                      >
                        Labor Operation
                      </TableCell>
                      <TableCell
                        style={{
                          background: "#F3F3F3",
                          border: "1px solid #D4D5D5",
                          color: "#005959",
                          fontWeight: "bold",
                        }}
                      >
                        Estimated Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(partsLookupData.labor || []).map(
                      (item, idx) =>
                        item.laborop && (
                          <TableRow key={idx}>
                            <TableCell
                              style={{
                                border: "1px solid #D4D5D5",
                                maxWidth: isSm ? 120 : "auto",
                                background: "#F3F3F3",
                              }}
                            >
                              {itemsAdded.includes(item) ? (
                                <IconButton color="success" onClick={() => {}}>
                                  <Check />
                                </IconButton>
                              ) : (
                                <IconButton
                                  color="primary"
                                  onClick={() => addLaborToService(item)}
                                >
                                  <AddCircle />
                                </IconButton>
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #D4D5D5",
                                maxWidth: 120,
                                overflow: "scroll",
                              }}
                            >
                              {item.laborop}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #D4D5D5",
                                maxWidth: 120,
                                overflow: "scroll",
                              }}
                            >
                              {item.estimated}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={onCancel} edge="start">
          <Close />
        </IconButton>
        {appointment
          ? `${appointment.car.year} ${appointment.car.make} ${appointment.car.model} ${appointment.car.engine_size}`
          : "Search Service"}
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Parts & Labor" {...a11yProps(0)} />
            <Tab label="Search Any Part" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box mb={2}>
            <Autocomplete
              options={MASTER_EPICOR_LIST.sort((a, b) =>
                a[1].localeCompare(b[1])
              ).map((i) => i[1])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Service"
                  variant="outlined"
                />
              )}
              onChange={(_, newValue) => handleChangeSearchTerm(newValue)}
              value={searchTerm || ""}
            />
          </Box>

          <Box className="my-6 text-center">
            <Button
              variant="outlined"
              disabled={!searchTerm}
              onClick={() => loadPartsLabor()}
            >
              Search
            </Button>
          </Box>

          {loadingPartsData && (
            <Box mt={3} mb={3} display="flex" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          )}

          {questions.laborCondition.length > 0 ||
          questions.partCondition.length > 0 ? (
            <>
              <Box className="my-6">
                {questions.partCondition.length > 0 ? (
                  <Typography style={{ margin: "10px 0" }}>
                    Part Questions
                  </Typography>
                ) : (
                  <></>
                )}
                {questions.partCondition.map((i) => {
                  return (
                    <ConditionQuestion i={i} conditionType={"partCondition"} />
                  );
                })}

                {questions.laborCondition.length > 0 ? (
                  <Typography style={{ margin: "10px 0" }}>
                    Labor Questions
                  </Typography>
                ) : (
                  <></>
                )}
                {questions.laborCondition.map((i) => {
                  return (
                    <ConditionQuestion i={i} conditionType={"laborCondition"} />
                  );
                })}
              </Box>

              <Box className="my-6 text-center">
                <Button variant="contained" onClick={() => loadPartsLabor()}>
                  Continue
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}

          {searchTerm &&
            partsLookupData &&
            (partsLookupData.parts || partsLookupData.labor) && <RenderParts />}
          {(isAdmin || isFleet) && <CustomPart />}
          {shouldSave && <SaveButton />}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Box mb={2}>
            <OutlinedInput
              placeholder="Search Part"
              value={partSearchTerm}
              onChange={(e) => setPartSearchTerm(e.target.value)}
              fullWidth
            />
          </Box>

          <Box className="my-6 text-center">
            <Button
              variant="outlined"
              disabled={!partSearchTerm || partSearchTerm.length < 3}
              onClick={() => loadPartsList()}
            >
              Search
            </Button>
          </Box>

          {loadingPartsList && (
            <Box mt={3} mb={3} display="flex" justifyContent="center">
              <CircularProgress size={20} />
            </Box>
          )}

          {partsList && partsList.length > 0 && Array.isArray(partsList) && (
            <Box>
              <Typography variant="h6" align="center" paragraph>
                Select Part to Search
              </Typography>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="solenoid-select-label">Select Part</InputLabel>
                <Select
                  labelId="solenoid-select-label"
                  value={partToSearch?.Code || ""}
                  onChange={(e) => {
                    const selectedCode = e.target.value;
                    const selectedObject = partsList.find(
                      (i) => i.Code === selectedCode
                    );
                    setPartToSearch(selectedObject);
                  }}
                  label="Select Solenoid"
                >
                  {partsList.map((i) => (
                    <MenuItem key={i.Code} value={i.Code}>
                      {i.Text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box className="my-6 text-center">
                <Button
                  variant="contained"
                  disabled={!partToSearch}
                  onClick={() => loadPart()}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          )}

          {partQuestions.laborCondition.length > 0 ||
          partQuestions.partCondition.length > 0 ? (
            <>
              <Box className="my-6">
                {partQuestions.partCondition.length > 0 ? (
                  <Typography style={{ margin: "10px 0" }}>
                    Part Questions
                  </Typography>
                ) : (
                  <></>
                )}
                {partQuestions.partCondition.map((i) => {
                  return (
                    <ConditionQuestionPart
                      i={i}
                      conditionType={"partCondition"}
                    />
                  );
                })}

                {partQuestions.laborCondition.length > 0 ? (
                  <Typography style={{ margin: "10px 0" }}>
                    Labor Questions
                  </Typography>
                ) : (
                  <></>
                )}
                {partQuestions.laborCondition.map((i) => {
                  return (
                    <ConditionQuestionPart
                      i={i}
                      conditionType={"laborCondition"}
                    />
                  );
                })}
              </Box>

              <Box className="my-6 text-center">
                <Button variant="contained" onClick={() => loadPart()}>
                  Continue
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}

          {partLookupData && (
            <Box>
              <Typography variant="h6" align="center" paragraph>
                Parts
              </Typography>

              <TableContainer component={Paper} style={{ width: "100%" }}>
                <Table style={{ marginBottom: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          background: "#F3F3F3",
                          border: "1px solid #D4D5D5",
                          fontWeight: "bold",
                          width: "60px",
                          color: "#005959",
                        }}
                      >
                        Add
                      </TableCell>
                      {columns.map((col, index) => (
                        <TableCell
                          key={index}
                          style={{
                            background: "#F3F3F3",
                            border: "1px solid #D4D5D5",
                            color: "#005959",
                            fontWeight: "bold",
                          }}
                        >
                          {col.key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(partLookupData.parts || []).map((item, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{
                            border: "1px solid #D4D5D5",
                            background: "#F3F3F3",
                          }}
                        >
                          {itemsAdded.includes(item) ? (
                            <IconButton
                              color="success"
                              onClick={() => removePart(item)}
                            >
                              <Check />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => addPartToService(item)}
                            >
                              <AddCircle />
                            </IconButton>
                          )}
                        </TableCell>
                        {columns.map((col, index) => {
                          if (col.key === "Name") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.name}
                              </TableCell>
                            );
                          }
                          if (col.key === "Number") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.number}
                              </TableCell>
                            );
                          }
                          if (col.key === "Brand") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.brand}
                              </TableCell>
                            );
                          }
                          if (col.key === "Price") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {partPrice(item.fmc_cost)}
                              </TableCell>
                            );
                          }
                          if (col.key === "URL") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                {item.url && (
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Part
                                  </a>
                                )}
                              </TableCell>
                            );
                          }
                          if (col.key === "Notes") {
                            return (
                              <TableCell
                                key={index}
                                style={{
                                  border: "1px solid #D4D5D5",
                                  maxWidth: 120,
                                  overflow: "scroll",
                                }}
                              >
                                <div
                                  style={{
                                    maxHeight: "100px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {item.notes}
                                </div>
                              </TableCell>
                            );
                          }
                          return null;
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default PartsLookupDialog;
