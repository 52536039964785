import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  Select,
  MenuItem,
} from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { apiUpdateMechanicNote } from "../../../../_apis";
import { apiMechanicUpdateAppointment } from "../../../../_apis/mechanics/appointments";

const STEPS = {
  PARTS: 1,
  NEXT_STEPS: 2,
};

const AfterPaidModal = ({
  open,
  onClose,
  payment_type,
  job_id,
  system_note_id,
  partsTravel,
  fleet,
}) => {
  const [precautions_after, setPrecautions] = useState();
  const [noteSubmitted, setNoteSubmitted] = useState();
  const [travelForParts, setTravelForParts] = useState();
  const [mechanicFeedback, setMechanicFeedback] = useState();
  const [step, setStep] = useState(STEPS.PARTS);

  useEffect(() => {
    if (fleet || partsTravel || payment_type === "0") {
      setStep(STEPS.NEXT_STEPS);
    }
  }, [partsTravel]);

  const handleSubmitPrecaution = () => {
    apiUpdateMechanicNote(system_note_id, { precautions_after }).then((res) => {
      if (!res.errors) {
        setNoteSubmitted(true);
      }
    });
  };

  const handlePartsTravel = () => {
    apiMechanicUpdateAppointment(job_id, {
      parts_travel_time: travelForParts,
      mechanic_feedback: mechanicFeedback,
    }).then((res) => {
      if (!res.errors) {
        setStep(STEPS.NEXT_STEPS);
      }
    });
  };

  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>Customer Charged!</DialogTitle>
        {step === STEPS.PARTS ? (
          <DialogContent>
            <Box>
              <p className="m-3">Feedback</p>

              <p className="m-3">
                Anything else we could do better to make your life easier?
              </p>

              <OutlinedInput
                className="m-3"
                type="text"
                rows={4}
                multiline
                value={mechanicFeedback}
                name="mechanicFeedback"
                placeholder="Type your feedback here"
                style={{ width: "94%" }}
                onChange={(e) => setMechanicFeedback(e.target.value)}
              />

              <p className="m-3">
                How much travel time did you do for parts for this job?
              </p>

              <Select
                className="m-3"
                value={travelForParts}
                style={{ width: "94%" }}
                onChange={(e) => setTravelForParts(e.target.value)}
              >
                {[
                  "None",
                  "Less than 30 minutes",
                  "30 minutes to 1 hour",
                  "More than 1 hour",
                ].map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>

              <FmcFullWidthButton
                text="Next"
                onClick={() => handlePartsTravel()}
                variant="contained"
                disabled={!mechanicFeedback}
              />
            </Box>
          </DialogContent>
        ) : (
          <>
            {payment_type === "1" && system_note_id ? (
              <DialogContent>
                <Box>
                  <p className="m-3">Job #{job_id} Completed!</p>

                  <p className="m-3">
                    What's the next priority for the vehicle?
                  </p>

                  <OutlinedInput
                    className="m-3"
                    type="text"
                    value={precautions_after}
                    name="precautions"
                    placeholder="Precautions"
                    onChange={(e) => setPrecautions(e.target.value)}
                  />

                  {noteSubmitted && (
                    <>
                      <p className="m-3 text-fmc-dark-green">
                        Your response was sent. This job is complete!
                      </p>
                    </>
                  )}

                  <FmcFullWidthButton
                    text="Submit"
                    onClick={() => handleSubmitPrecaution()}
                    variant="contained"
                  />

                  <FmcFullWidthButton
                    text="Back to Job"
                    onClick={() =>
                      (window.location.href = `/mechanic/job/${job_id}`)
                    }
                    variant="outlined"
                  />
                </Box>
              </DialogContent>
            ) : (
              <DialogContent>
                <Box m={3}>
                  <p className="m-3">
                    The job is complete. Please inform the customer of next
                    steps.
                  </p>
                </Box>

                <FmcFullWidthButton
                  text="Back to Job"
                  onClick={() =>
                    (window.location.href = `/mechanic/job/${job_id}`)
                  }
                  variant="contained"
                />
              </DialogContent>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default AfterPaidModal;
