import React from "react";
import { Modal, Typography, Card } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";

const HowItWorks = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 500,
        margin: "auto",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          height: "80vh",
          width: "100%",
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            position: "sticky",
            top: 0,
            zIndex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Typography variant="h6" style={{ padding: 20 }}>
            How it Works
          </Typography>
          <CloseOutlined
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: 20 }}
          />
        </div>
        <Card
          style={{
            backgroundColor: "#E3F2F9",
            maxWidth: "500px",
            margin: 20,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            borderRadius: 12,
          }}
          elevation={0}
        >
          <Typography
            style={{ color: "#1A5B74", fontSize: 16, fontWeight: 600 }}
          >
            Earning Points
          </Typography>
          <ul style={{ listStyleType: "disc", marginLeft: 20 }}>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                Every 100 points equals $10.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                Earn 100 points for every membership sale.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                Every $10 of services you sell gets you 1 point. The service
                must be completed to earn points.
              </Typography>
            </li>
          </ul>
        </Card>

        <Card
          style={{
            backgroundColor: "#E3F2F9",
            maxWidth: "500px",
            margin: 20,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            borderRadius: 12,
          }}
          elevation={0}
        >
          <Typography
            style={{ color: "#1A5B74", fontSize: 16, fontWeight: 600 }}
          >
            Weekly Bonus
          </Typography>
          <ul style={{ listStyleType: "disc", marginLeft: 20 }}>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                Your points are automatically redeemed for cash on your
                paychecks.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                The tech with the most points each week earns a $100 bonus.
              </Typography>
            </li>
          </ul>
        </Card>

        <Card
          style={{
            backgroundColor: "#E3F2F9",
            maxWidth: "500px",
            margin: 20,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            borderRadius: 12,
          }}
          elevation={0}
        >
          <Typography
            style={{ color: "#1A5B74", fontSize: 16, fontWeight: 600 }}
          >
            Deduct Points
          </Typography>
          <ul style={{ listStyleType: "disc", marginLeft: 20 }}>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                For your ETA's, if you don't arrive when you say you will, you
                will be deducted 50 points. You will also be deducted 50 points
                if you don't send an ETA at all.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: "#646867", fontSize: 12 }}>
                If you don't place an oil change sticker on the inside of the
                windshield, you will be deducted 50 points.
              </Typography>
            </li>
          </ul>
        </Card>

        <Card
          style={{
            backgroundColor: "#E3F2F9",
            maxWidth: "500px",
            margin: 20,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
            borderRadius: 12,
          }}
          elevation={0}
        >
          <Typography
            style={{ color: "#1A5B74", fontSize: 16, fontWeight: 600 }}
          >
            Want a Raise?
          </Typography>
          <Typography style={{ color: "#646867", fontSize: 12 }}>
            Boost your hourly rate by $1 for every 3000 points earned.
          </Typography>
        </Card>
      </div>
    </Modal>
  );
};

export default HowItWorks;
