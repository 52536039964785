import { callApiV2 } from "./api_base";

export const apiGetVehicles = (car = {}) => {
  let params = [];

  if (car.year) {
    params.push(`year=${car.year}`);
  }

  if (car.make) {
    params.push(`make=${car.make}`);
  }

  if (car.model) {
    params.push(`model=${encodeURIComponent(car.model)}`);
  }

  return callApiV2({
    url: `/vehicles?${params.join("&")}`,
    method: "GET",
  });
};
