import { mechanicUserConstants } from "../_constants";

const initial = {
  data: {
    auth_token: "",
    id: null,
    name: null,
    email: null
  },
  mechanic_auth_data: {},
  loginInProgress: false,
  registerInProgress: false,
  errors: [],
};

export function mechanic_user(state = initial, action) {
  switch (action.type) {
    case mechanicUserConstants.SET_MECHANIC_AUTH_DATA:
      return {
        ...state,
        mechanic_auth_data: { ...state.mechanic_auth_data, ...action.data },
      };
    case mechanicUserConstants.RESET_ERRORS:
      return { ...state, errors: [] };
    case mechanicUserConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
      };
    case mechanicUserConstants.LOGIN_SUCCESS:
      if (Object.keys(action.data).includes("errors")) {
        return {
          ...state,
          loginInProgress: false,
          errors: [...new Set([...state.errors, ...action.data?.errors])],
        };
      }
      return {
        ...state,
        loginInProgress: false,
        data: { ...state.data, ...action.data },
      };
    case mechanicUserConstants.LOGIN_FAILED:
      return {
        ...state,
        loginInProgress: false,
        errors: [...new Set([...state.errors, ...action.data?.errors])],
      };

    case mechanicUserConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerInProgress: true,
      };
    case mechanicUserConstants.REGISTER_SUCCESS:
      if (Object.keys(action.data).includes("errors")) {
        return {
          ...state,
          registerInProgress: false,
          errors: [...new Set([...state.errors, ...action.data?.errors])],
        };
      }
      return {
        ...state,
        registerInProgress: false,
        data: { ...state.data, ...action.data },
      };

    case mechanicUserConstants.REGISTER_FAILED:
      return {
        ...state,
        registerInProgress: false,
        errors: [...new Set([...state.errors, ...action.data?.errors])],
      };

    case mechanicUserConstants.LOGOUT:
      return initial;

    case mechanicUserConstants.RESET:
      return initial;

    default:
      return state;
  }
}
