import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";

const SearchInputs = ({
  mkt,
  setMkt,
  jobId,
  setJobId,
  zip,
  setZip,
  searchZip,
  searchMkt,
  searchJobId,
}) => {
  return (
    <>
      <div style={{ margin: "10px 0" }}>
        <FormControl>
          <InputLabel id="mkt-select-label">Market</InputLabel>
          <Select
            labelId="mkt-select-label"
            id="mkt-select"
            style={{ width: "210px" }}
            variant="outlined"
            onChange={(e) => setMkt(e.target.value)}
          >
            {["DFW", "HOU", "MI", "PHX"].map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ margin: "10px 0" }}>
        <OutlinedInput
          value={jobId}
          onChange={(e) => setJobId(e.target.value)}
          placeholder="Job #"
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <OutlinedInput
          value={zip}
          error={zip && zip.length !== 5}
          onChange={(e) => setZip(e.target.value)}
          placeholder="Zip"
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (zip && zip.length === 5) {
              searchZip();
            } else if (mkt) {
              searchMkt();
            } else if (jobId) {
              searchJobId();
            }
          }}
        >
          Check Availability
        </Button>
      </div>
    </>
  );
};

export default SearchInputs;
