import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { step3FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import ImageList from "../../components/ImageList";

const Step3 = ({ styles, handleNext, onUpdate, inspection, goBack }) => {
  const [step3Fields, setStep3Fields] = useState({
    exterior_lights: inspection.exterior_lights || "",
    interior_lights: inspection.interior_lights || "",
    interior_lights_text_describe:
      inspection.interior_lights_text_describe || "",
    interior_lights_service_due: inspection.interior_lights_service_due || "",
    interior_lights_likely_approve_service:
      inspection.interior_lights_likely_approve_service || "",
    wipers: inspection.wipers || "",
    wipers_text_describe: inspection.wipers_text_describe || "",
    wipers_service_due: inspection.wipers_service_due || "",
    wipers_likely_approve_service:
      inspection.wipers_likely_approve_service || "",
    exterior_lights_tags: inspection.exterior_lights_tags || [],
    exterior_lights_text_describe:
      inspection.exterior_lights_text_describe || "",
    exterior_lights_service_due: inspection.exterior_lights_service_due || "",
    exterior_lights_likely_approve_service:
      inspection.exterior_lights_likely_approve_service || "",
    windshield: inspection.windshield || "",
    window_glass: inspection.window_glass || "",
  });

  const done =
    step3Fields.exterior_lights !== "" &&
    step3Fields.interior_lights !== "" &&
    step3Fields.wipers !== "" &&
    step3Fields.windshield !== "" &&
    step3Fields.window_glass !== "";

  const updateField = (field, value) => {
    setStep3Fields({
      ...step3Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  const exteriorPhotosExist = () => {
    return (inspection.exterior_front_photos && inspection.exterior_front_photos[0]?.url) || (inspection.exterior_back_photos && inspection.exterior_back_photos[0]?.url) || (inspection.exterior_left_photos && inspection.exterior_left_photos[0]?.url) || (inspection.exterior_right_photos && inspection.exterior_right_photos[0]?.url)
  }

  return (
    <Box>
      <Typography className={styles.heading}>Exterior</Typography>

      {Object.keys(step3FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step3FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step3Fields}
          />
        );
      })}

      {exteriorPhotosExist() ? (
        <></>
      ) : (

        <>
          <Typography
            style={{
              fontSize: 18,
              fontFamily: "Inter",
              fontWeight: 700,
              margin: "10px 0",
            }}
          >
            Exterior pictures (upload for liability reason)
          </Typography>

          {
            [
              "exterior_front",
              "exterior_back",
              "exterior_left",
              "exterior_right",
            ].map((fieldName) => {
              return (
                <Box className="text-left">
                  <p className={styles.textField}>
                    {fieldName == 'exterior_front' && 'Front of the vehicle'}
                    {fieldName == 'exterior_back' && 'Back of the vehicle'}
                    {fieldName == 'exterior_left' && 'Left side'}
                    {fieldName == 'exterior_right' && 'Right side'}
                    <span className="text-fmc-red italic"> * </span>
                  </p>
                  <ImageList
                    value={inspection[`${fieldName}_photos`] || []}
                    onChange={(d) => updateField(`${fieldName}_photos`, d)}
                    required={false}
                    error={false}
                  />
                </Box>
              );
            })
          }
        </>
      )}

      <FmcFullWidthButton
        text="Save & Next"
        onClick={handleNext}
        variant="contained"
        disabled={!done}
      />

      <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
    </Box>
  );
};

export { Step3 };
