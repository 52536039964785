import React, { useContext, useMemo } from "react";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import useStyles from "../../components/styles";
import Service from "./Service";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const ServiceList = ({ EstimateFormContext }) => {
  const classes = useStyles();
  const { data, onAddService } = useContext(EstimateFormContext);
  const services = useMemo(() => data.services || [], [data]);

  return (
    <Card className={classes.cardBox}>
      <CardHeader title="Services" />
      <CardContent>
        {services.map((_, index) => (
          <Box key={index} mb={3}>
            <Service
              serviceId={index}
              EstimateFormContext={EstimateFormContext}
            />
          </Box>
        ))}

        <FmcFullWidthButton
          text="Add Service"
          onClick={onAddService}
          variant="outlined"
        />
      </CardContent>
    </Card>
  );
};

export default ServiceList;
