import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { callApiV2 } from "../../../../_apis/api_base";
import { isValidVIN } from "../../../../_helpers/utils";
import { apiCreateCarForCustomer } from "../../../../_apis/mechanics/customers";
import VehicleForm from "../../../Admin/AdminNewCustomer/components/VehicleForm";

const CAR_TYPES = {
  VIN: 1,
  YMME: 2,
};

const AdditionalInspection = ({ open, onClose, userId, appointmentId }) => {
  const [isCar, setIsCar] = useState();
  const [vin, setVin] = useState();
  const [vinError, setVinError] = useState();
  const [foundCar, setFoundCar] = useState();
  const [requesting, setRequesting] = useState();
  const [carType, setCarType] = useState(CAR_TYPES.VIN);
  const [car, setCar] = useState({
    year: null,
    make: null,
    model: null,
    engine_size: null,
  });

  const handleAdditionalCarInspection = (carData) => {
    setRequesting(true);

    apiCreateCarForCustomer(
      {
        year: carData.year,
        make: carData.make,
        model: carData.model,
        engine_size: carData.engine_size || carData.engine,
        vin,
        user_id: userId,
      },
      "?return_car=true"
    )
      .then((res) => {
        window.location.assign(
          `/mechanic/inspection/${appointmentId}?additional_car=true&car_id=${res.data.id}`
        );
      })
      .finally(() => setRequesting());
  };

  return (
    <Dialog
      styles={{ padding: "10px" }}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <IconButton
          style={{
            padding: "2px",
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>{" "}
        Free Inspection
      </DialogTitle>

      <DialogContent>
        {!foundCar && (
          <Box className="my-6">
            <Typography className="my-3">
              Offer the customer a free inspection on an additional vehicle at
              the house. You'll get paid 0.5 labor hours for your time.
            </Typography>
          </Box>
        )}

        {carType === CAR_TYPES.VIN ? (
          <Box className="my-6">
            {isCar ? (
              <>
                <Box className="my-3">
                  {foundCar ? (
                    <>
                      <Typography className="my-3">Correct vehicle?</Typography>
                      <Typography>
                        {foundCar.year} {foundCar.make} {foundCar.model}{" "}
                        {foundCar.engine} | {foundCar.vin}
                      </Typography>

                      <FmcFullWidthButton
                        disabled={requesting}
                        text="Yes"
                        onClick={() => handleAdditionalCarInspection(foundCar)}
                        variant="contained"
                      />
                      <FmcFullWidthButton
                        text="No, start over"
                        onClick={() => setFoundCar()}
                      />
                    </>
                  ) : (
                    <>
                      <OutlinedInput
                        value={vin}
                        onChange={(e) => setVin(e.target.value)}
                        placeholder="Enter vin # of vehicle"
                        fullWidth
                      />
                      <Button
                        className="my-3"
                        disabled={!isValidVIN(vin) || requesting}
                        onClick={() => {
                          callApiV2({
                            url: `/vehicles/vin/${vin}`,
                            method: "GET",
                          })
                            .then((res) => {
                              setRequesting(true);

                              if (res.errors) {
                                setVinError(true);

                                setTimeout(() => {
                                  setVinError();
                                }, 4000);
                              } else {
                                setFoundCar(res);
                              }
                            })
                            .finally(() => setRequesting());
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Look Up Vehicle
                      </Button>

                      {vinError && (
                        <Typography color="error" className="my-3">
                          Invalid vin, please try again.
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </>
            ) : (
              <FmcFullWidthButton
                variant="contained"
                onClick={() => setIsCar(true)}
                text="Start free inspection"
              />
            )}
          </Box>
        ) : (
          <Box className="my-6">
            <VehicleForm
              car={car}
              setCar={setCar}
              nextStep={() => {
                handleAdditionalCarInspection(car);
              }}
            />
          </Box>
        )}

        {isCar && (
          <Typography
            onClick={() =>
              setCarType(
                carType === CAR_TYPES.VIN ? CAR_TYPES.YMME : CAR_TYPES.VIN
              )
            }
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Use {carType === CAR_TYPES.VIN ? "Year, Make and Model" : "VIN"}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalInspection;
