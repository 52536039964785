import React from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FmcFullWidthButton from "./FmcFullWidthButton";

const AfterNoteModal = ({
  open,
  onClose,
  job_id,
  noteType,
  afterPath,
  needsEstimate,
}) => {
  const getTitle = () => {
    if (noteType === "Diagnosis") {
      return "Diagnosis Complete";
    }

    if (noteType === "Repair") {
      return "Job Complete";
    }

    if (noteType === "Warranty") {
      return "Contact Office";
    }

    if (noteType === "Warranty Claim") {
      return "Warranty Claim";
    }
  };

  const getText = () => {
    if (noteType === "Diagnosis") {
      return "Please bring the customer out and explain the result of the diagnosis. If you need to perform any repairs, click the button below to create the estimate, otherwise this job is complete and no further action is needed.";
    }

    if (noteType === "Repair") {
      return "This job is complete. If there's additional services you quoted that the customer did not approve today, or if there's additional work you'd recommend for later, click the button below to send the customer the additional work.";
    }

    if (noteType === "Warranty") {
      return "Please contact the office for next steps.";
    }

    if (noteType === "Warranty Claim") {
      return "Thank you for submitting the labor claim.";
    }
  };

  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
          {needsEstimate ? (
            <>
              <Box m={3}>
                <p className="m-3">
                  NOTE: The customer has been notified to expect an estimate
                  from you today. This is required for completion of the job.
                  Continue to estimate form.
                </p>
              </Box>

              <FmcFullWidthButton
                text="Continue"
                onClick={() => (window.location.href = afterPath)}
                variant="contained"
              />
            </>
          ) : (
            <>
              <Box m={3}>
                <p className="m-3">{getText()}</p>
              </Box>

              <FmcFullWidthButton
                text="Continue"
                onClick={() => (window.location.href = afterPath)}
                variant="contained"
              />
              <FmcFullWidthButton
                text="Back to Job"
                onClick={() =>
                  (window.location.href = `/mechanic/job/${job_id}`)
                }
                variant="outlined"
              />
            </>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AfterNoteModal;
