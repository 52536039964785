import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    margin: "16px",
    paddingBottom: "48px",
  },
  header: {
    marginTop: "24px",
    marginBottom: "8px",
  },
  subHeader: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  paragraph: {
    marginBottom: "16px",
  },
  list: {
    marginTop: "8px",
    marginBottom: "16px",
  },
});

const FaqPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        How you get paid?
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        You get paid for Flag hours you flag + Commissions
      </Typography>

      <Typography variant="h5" className={classes.subHeader}>
        Flag hrs
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        We pay for hours listed in Mitchell for any job
      </Typography>

      <Typography variant="h4" className={classes.header}>
        When you get paid?
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Every Friday
        <br />
        Every Monday, we calculate your hours from last week Monday-Sunday
        <br />
        For example, for pay period Jan 1 (Monday) to Jan 7 (Sunday), we process
        your hours for that pay period on Monday Jan 8 for a Payout on Friday
        Jan 12.
      </Typography>

      <Typography variant="body1" className={classes.paragraph}>
        For any questions, please reach out to James in Slack.
      </Typography>
    </div>
  );
};

export default FaqPage;
