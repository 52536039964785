import React, { useState } from "react";
import { Box, OutlinedInput, Typography } from "@material-ui/core";
import { isCustomerInfoValid, newCustomerFields } from "../utility";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { VehicleForm } from "./VehicleForm";

function CustomerInfo({ setFoundUser, resetForm }) {
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    zip: "",
  });
  const [addingVehicle, setAddingVehicle] = useState();
  const [errors, setErrors] = useState();

  const handleSubmit = () => {
    const { valid, errors } = isCustomerInfoValid(user);

    if (valid) {
      setErrors();
      setAddingVehicle(true);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Box className="my-6">
      {addingVehicle ? (
        <VehicleForm
          user={user}
          setFoundUser={setFoundUser}
          reset={resetForm}
        />
      ) : (
        <>
          <Typography variant="h4">Customer</Typography>
          <Box className="my-3">
            {newCustomerFields.map((fieldInfo) => {
              return (
                <OutlinedInput
                  className="my-3"
                  fullWidth
                  value={user[fieldInfo.field]}
                  onChange={(ev) => {
                    setUser({
                      ...user,
                      [fieldInfo.field]: ev.target.value,
                    });
                  }}
                  placeholder={fieldInfo.label}
                  type={fieldInfo.type}
                />
              );
            })}
          </Box>

          {errors && (
            <Box>
              {Object.keys(errors).map((i) => (
                <Typography className="my-1 text-fmc-red">
                  {errors[i]}
                </Typography>
              ))}
            </Box>
          )}

          <FmcFullWidthButton
            text="Enter Customer Info"
            onClick={() => handleSubmit()}
            variant="contained"
            disabled={
              user.name === "" ||
              user.phone === "" ||
              user.email === "" ||
              user.zip === ""
            }
          />
        </>
      )}
    </Box>
  );
}

export { CustomerInfo };
