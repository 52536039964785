import React from "react";
import { Stars } from "@material-ui/icons";

const CustomSlider = ({ value, disabled }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <input
        type="range"
        min={0}
        max={100}
        step={1}
        value={value}
        disabled={disabled}
        style={{
          width: "100%",
          height: "20px",
          appearance: "none",
          outline: "none",
          pointerEvents: disabled ? "none" : "auto",
          borderRadius: "10px",
          MozAppearance: "none",
        }}
      />

      <div
        className="custom-thumb"
        style={{
          position: "absolute",
          top: "-5px",
          left: `calc(${(value / 100) * 100}% - 15px)`,
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: "white",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <Stars style={{ color: "#005959" }} />
      </div>

      <style>
        {`
          input[type="range"]::-webkit-slider-runnable-track {
            width: 100%;
            height: 20px;
            border-radius: 10px;
            background: linear-gradient(to right, #005959 0%, #005959 ${
              (value / 100) * 100
            }%, white ${(value / 100) * 100}%, white 100%);
          }

          input[type="range"]::-moz-range-track {
            width: 100%;
            height: 20px;
            border-radius: 10px;
            background: linear-gradient(to right, #005959 0%, #005959 ${
              (value / 100) * 100
            }%, white ${(value / 100) * 100}%, white 100%);
          }

        `}
      </style>
    </div>
  );
};

export default CustomSlider;
