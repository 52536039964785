import React from "react";
import PropTypes from 'prop-types';

const Label = ({text, subtitle}) => {
  return (
    <div className="Label">
      <label>{text}</label>
      {
        subtitle && <p>{subtitle}</p>
      }
    </div>
  )
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default Label;
