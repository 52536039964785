import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import { LocalizationProvider } from "@material-ui/lab";
import { store, persistor } from "./_helpers";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MediaQueryContextProvider } from "./_contexts/MediaQueryContext";
import { ThemeProvider } from "@material-ui/core";
import { createFMCTheme } from "./_theme";
import { StyledEngineProvider } from "@material-ui/core";
import AdminTabs from "./Pages/Admin/AdminTabs";

const theme = createFMCTheme();

export default ({ current_admin_user_id, admin_manager }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MediaQueryContextProvider>
              <BrowserRouter>
                <Switch>
                  <Route
                    path="/"
                    render={(props) => (
                      <AdminTabs
                        current_admin_user_id={current_admin_user_id}
                        isManager={admin_manager}
                        {...props}
                      />
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </MediaQueryContextProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);
