import React from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const NewJobModal = ({ open, onClose, name, id }) => {
  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>Job Created!</DialogTitle>
        <DialogContent>
          <Box m={3}>
            <p className="m-3">
              You successfully created job #{id} for {name}
            </p>
          </Box>

          <FmcFullWidthButton
            text="Go to Job"
            onClick={() => (window.location.href = `/mechanic/job/${id}`)}
            variant="contained"
            disabled={false}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default NewJobModal;
