import React, { useState } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { VEHICLE_YEAR_OPTIONS } from "../utility";
import { apiGetVehicles } from "../../../../_apis/vehicles";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import {
  apiCreateCarForCustomer,
  apiCreateCustomerWithCar,
  apiSearchCustomer,
} from "../../../../_apis/mechanics/customers";
import { NewJobForm } from "./NewJobForm";

function VehicleForm({ user, setFoundUser, reset }) {
  const [car, setCar] = useState({
    year: "",
    make: "",
    model: "",
    engine_size: "",
    user_id: user?.id,
  });
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [engines, setEngines] = useState([]);
  const [newUser, setNewUser] = useState();

  const updateYear = (year) => {
    setCar({ ...car, year });
    setEngines([]);
    setModels([]);
    setMakes([]);

    apiGetVehicles({ year }).then((res) => {
      const makes = res.data.map((i) => i.attributes.make).sort();

      setMakes([...new Set(makes)]);
    });
  };

  const updateMake = (make) => {
    setCar({ ...car, make });
    setEngines([]);
    setModels([]);

    apiGetVehicles({ year: car.year, make }).then((res) => {
      const models = res.data.map((i) => i.attributes.model).sort();

      setModels([...new Set(models)]);
    });
  };

  const updateModel = (model) => {
    setCar({ ...car, model });
    setEngines([]);

    apiGetVehicles({
      year: car.year,
      make: car.make,
      model,
    }).then((res) => {
      const engines = res.data.map((i) => i.attributes.engine_size);

      setEngines([...new Set(engines)]);
      setCar({ ...car, model, engine_size: engines[0] });
    });
  };

  const createUserAndCar = () => {
    apiCreateCustomerWithCar({ user, car }).then((res) => {
      if (!res.errors) {
        setNewUser(res.data);
      } else {
        if (res.errors.user && res.errors.user.length > 0) {
          const searchParams = {};
          if (res.errors.user[0] === "Email has already been taken") {
            searchParams.email = user.email;
          } else if (res.errors.user[0] === "Phone has already been taken") {
            searchParams.phone = user.phone;
          }

          apiSearchCustomer({ search: searchParams }).then((res) => {
            if (res.data) {
              setFoundUser(res.data);
              reset();
            }
          });
        }
      }
    });
  };

  const createCar = () => {
    apiCreateCarForCustomer({ car }).then((res) => {
      if (!res.errors) {
        setNewUser(res.data);
      }
    });
  };

  return (
    <Container>
      {newUser ? (
        <NewJobForm user={newUser} />
      ) : (
        <>
          <Typography variant="h4" className="my-3">
            Vehicle
          </Typography>

          <FormControl fullWidth className="my-3">
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              id="year-select-label"
              value={car.year}
              fullWidth
              variant="outlined"
              onChange={(event) => updateYear(event.target.value)}
            >
              {VEHICLE_YEAR_OPTIONS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {makes.length > 0 ? (
            <FormControl fullWidth className="my-3">
              <InputLabel id="make-select-label">Make</InputLabel>
              <Select
                id="make-select-label"
                value={car.make}
                fullWidth
                variant="outlined"
                onChange={(event) => updateMake(event.target.value)}
              >
                {makes.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}

          {models.length > 0 ? (
            <FormControl fullWidth className="my-3">
              <InputLabel id="model-select-label">Model</InputLabel>
              <Select
                id="model-select-label"
                value={car.model}
                fullWidth
                variant="outlined"
                onChange={(event) => updateModel(event.target.value)}
              >
                {models.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}

          {engines.length > 0 ? (
            <FormControl fullWidth className="my-3">
              <InputLabel id="engine-select-label">Engine</InputLabel>
              <Select
                id="engine-select-label"
                value={car.engine_size}
                fullWidth
                variant="outlined"
                onChange={(event) =>
                  setCar({ ...car, engine_size: event.target.value })
                }
              >
                {engines.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}

          <FmcFullWidthButton
            text="Enter Vehicle"
            onClick={() => {
              if (car.user_id) {
                createCar();
              } else {
                createUserAndCar();
              }
            }}
            variant="contained"
            disabled={
              car.year === "" ||
              car.make === "" ||
              car.model === "" ||
              car.engine_size === ""
            }
          />
        </>
      )}
    </Container>
  );
}

export { VehicleForm };
