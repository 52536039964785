import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  titleIcon: {
    width: 24,
  },
  carIcon: {
    width: 40,
  },
  summaryHeader: {
    backgroundColor: "#4caf50",
    color: "white",
    borderRadius: "3px 3px 0 0",
  },
  noBorderCard: {
    border: "none",
    boxShadow: "none",
  },
  serviceCard: {
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .16)",
  },
  serviceCardHeader: {
    backgroundColor: "rgba(0, 0, 0, .05)",
    borderBottom: "solid 1px rgba(0, 0, 0, .125)",
  },
  cardBox: {
    border: "none",
    boxShadow: "none",
  },
  ccCardBox: {
    border: "none",
    boxShadow: "none",
  },
  deleteButton: {
    "& svg": {
      color: "red",
    },
  },
  price: {
    marginLeft: "10px",
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: "13pt",
    color: "black",
  },
  discount: {
    fontSize: "13pt",
    color: "#269300",
    fontWeight: "bold",
    marginLeft: "4px",
  },

  priceTitle: {
    fontFamily: "Tiempos",
    lineHeight: "18px",
    color: "#707070",
  },

  membershipBox: {
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    padding: "15px",
    textAlign: "center",
    "& h2": {
      fontFamily: "Inter",
      fontWeight: "bold",
      color: "#FFFFFF",
    },
  },

  backgroundBox: {
    position: "absolute",
    top: "0",
    left: "0",
    background: "#57FFC4",
    borderRadius: "8px",
    transform: "rotate(-3deg)",
    height: "100%",
    width: "100%",
  },
  learnMoreBtn: {
    background: "#FFFFFF",
    borderRadius: "50px",
    padding: "10px",
    color: "#7157FF",
    margin: "30px",
  },
  rewardsBonusContainer: {
    display: "flex",
    alignItems: "center",
    background: "#F6F4FF",
  },
  promo: {
    display: "flex",
    padding: "8px",
    background: "#F6F4FF",
    borderRadius: "8px",
    margin: "20px",
    "& img": {
      marginRight: "16px",
    },
  },
  itemTitle: {
    fontFamily: "Tiempos",
    fontWeight: "bold",
    display: "inline-block",
    fontSize: "14px",
    marginLeft: "8px",
  },
  container: {
    textAlign: "left",
    marginTop: "20px",
  },
}));
