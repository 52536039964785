import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Container,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { getAllMessages } from "../../../_apis/mechanics/appointments";
import { ArrowBack, Search } from "@material-ui/icons";

const useStyles = makeStyles({
  centerAuth: {
    margin: "1vh auto",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  dialogPaper: {
    width: "100%",
    height: "100%",
    margin: 0,
    top: 40,
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
    height: "8vh",
    padding: 20,
  },
  searchInput: {
    width: "100%",
    maxWidth: 700,
    height: 50,
    fontSize: 16,
    fontFamily: "Inter",
    borderRadius: 12,
  },
  messageContainer: {
    background: "#F3F3F3",
    margin: "20px",
    padding: 10,
    borderRadius: 20,
    display: "flex",
    gap: 20,
    alignItems: "center",
    maxWidth: 500,
  },
  jobId: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 500,
  },
});

function Messages() {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAllMessages = async () => {
    getAllMessages().then((res) => {
      setContacts(res.messages);
    });
  };

  useEffect(() => {
    fetchAllMessages();
  }, []);

  function formatTimeAgo(timestamp) {
    const messageDate = new Date(timestamp);
    const now = new Date();
    const differenceInMinutes = Math.floor((now - messageDate) / 60000);

    if (differenceInMinutes === 1) {
      return `1 minute ago`;
    } else if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes ago`;
    } else {
      const differenceInHours = Math.floor(differenceInMinutes / 60);
      if (differenceInHours === 1) {
        return `1 hour ago`;
      } else if (differenceInHours < 24) {
        return `${differenceInHours} hours ago`;
      } else {
        const differenceInDays = Math.floor(differenceInHours / 24);
        if (differenceInDays === 1) {
          return `1 day ago`;
        } else {
          return `${differenceInDays} days ago`;
        }
      }
    }
  }

  const getFilteredContacts = () => {
    const query = searchQuery.toLowerCase();
    return Object.entries(contacts)
      .filter(
        ([appointmentId, info]) =>
          info.name.toLowerCase().includes(query) ||
          appointmentId.toLowerCase().includes(query)
      )
      .sort((a, b) => {
        if (b[1].has_unread && !a[1].has_unread) {
          return 1;
        }
        if (a[1].has_unread && !b[1].has_unread) {
          return -1;
        }
        return 0;
      });
  };

  return (
    <Container className={classes.centerAuth}>
      <div
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "#fff",
          zIndex: 1,
          height: "8vh",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            gap: 40,
            marginBottom: 10,
          }}
        >
          <IconButton onClick={() => (window.location.href = "/mechanic/jobs")}>
            <ArrowBack />
          </IconButton>
          <p style={{ fontWeight: 600 }}> Messages</p>
        </div>
        <div style={{ borderTop: "1px solid grey" }} />

        <div style={{ padding: 10, marginTop: 20 }}>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder="Search by name or job ID..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className={classes.searchInput}
          />
        </div>

        <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
          {getFilteredContacts().map(([appointmentId, info]) => {
            return (
              <div key={appointmentId}>
                <div
                  className={classes.messageContainer}
                  onClick={() => {
                    window.location.href = `/mechanic/chat/${appointmentId}`;
                  }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "80%", display: "flex", gap: 20 }}>
                    <Avatar src="/broken-image.jpg" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "130%",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {info && info.name.length > 0 && (
                          <h3
                            style={{
                              fontWeight: info.has_unread ? 700 : 500,
                            }}
                          >
                            {info.name}
                          </h3>
                        )}
                        {info.has_unread ? (
                          <Typography
                            style={{
                              fontSize: 14,
                              fontFamily: "Inter",
                              fontWeight: 600,
                            }}
                          >
                            Sent a new Message
                          </Typography>
                        ) : (
                          <Typography
                            style={{ fontSize: 14, fontFamily: "Inter" }}
                          >
                            {info.created_at
                              ? `Sent ${formatTimeAgo(info.created_at)}`
                              : "Send a message"}
                          </Typography>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 className={classes.jobId}>Job #{appointmentId}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export { Messages };
