import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import { apiGetVehicles } from "../../../../_apis/vehicles";
import { VEHICLE_YEAR_OPTIONS } from "../../../Mechanic/MechanicAddJob/utility";

function VehicleForm({ car, setCar, nextStep }) {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [engines, setEngines] = useState([]);

  const updateYear = (year) => {
    setCar({ ...car, year });
    setEngines([]);
    setModels([]);
    setMakes([]);

    apiGetVehicles({ year }).then((res) => {
      const makes = res.data.map((i) => i.attributes.make);

      setMakes([...new Set(makes)]);
    });
  };

  const updateMake = (make) => {
    setCar({ ...car, make });
    setEngines([]);
    setModels([]);

    apiGetVehicles({ year: car.year, make }).then((res) => {
      const models = res.data.map((i) => i.attributes.model);

      setModels([...new Set(models)]);
    });
  };

  const updateModel = (model) => {
    setCar({ ...car, model });
    setEngines([]);

    apiGetVehicles({
      year: car.year,
      make: car.make,
      model,
    }).then((res) => {
      const engines = res.data.map((i) => i.attributes.engine_size);

      setEngines([...new Set(engines)]);
      setCar({ ...car, model, engine_size: engines[0] });
    });
  };

  return (
    <Box>
      <FormControl fullWidth className="my-3">
        <InputLabel id="year-select-label">Year</InputLabel>
        <Select
          id="year-select-label"
          value={car.year}
          fullWidth
          variant="outlined"
          onChange={(event) => updateYear(event.target.value)}
        >
          {VEHICLE_YEAR_OPTIONS.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {makes.length > 0 ? (
        <FormControl fullWidth className="my-3">
          <InputLabel id="make-select-label">Make</InputLabel>
          <Select
            id="make-select-label"
            value={car.make}
            fullWidth
            variant="outlined"
            onChange={(event) => updateMake(event.target.value)}
          >
            {makes.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      {models.length > 0 ? (
        <FormControl fullWidth className="my-3">
          <InputLabel id="model-select-label">Model</InputLabel>
          <Select
            id="model-select-label"
            value={car.model}
            fullWidth
            variant="outlined"
            onChange={(event) => updateModel(event.target.value)}
          >
            {models.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      {engines.length > 0 ? (
        <FormControl fullWidth className="my-3">
          <InputLabel id="engine-select-label">Engine</InputLabel>
          <Select
            id="engine-select-label"
            value={car.engine_size}
            fullWidth
            variant="outlined"
            onChange={(event) =>
              setCar({ ...car, engine_size: event.target.value })
            }
          >
            {engines.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}

      <Button
        disabled={!car.year || !car.make || !car.model || !car.engine_size}
        onClick={nextStep}
        color="primary"
        variant="contained"
        style={{ margin: "10px" }}
      >
        Next
      </Button>
    </Box>
  );
}

export default VehicleForm;
