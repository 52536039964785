import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  Button,
  Box,
  Autocomplete,
  Typography,
  FormHelperText,
  Divider,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { BILLINGS, MEMBER_PLANS } from "../../../../_helpers/membership";
import { apiCreateCarForCustomer } from "../../../../_apis/mechanics/customers";
import { callApiV2 } from "../../../../_apis/api_base";
import { apiGetUserMembership } from "../../../../_apis/user";
import VehicleModal from "../../../Admin/AdminMembership/components/VehicleModal";

const MembershipModal = ({
  appointment_id,
  open,
  onClose,
  mechanic_id,
  user_id,
}) => {
  const [dirty, setDirty] = useState();
  const [plan, setPlan] = useState(MEMBER_PLANS[0]);
  const [existingPrice, setExistingPrice] = useState();
  const [interval, setInterval] = useState({
    count: null,
    name: null,
  });
  const [vehicles, setVehicles] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [creatingMembership, setCreatingMembership] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [billing, setBilling] = useState(BILLINGS[0]);
  const [carAdded, setCarAdded] = useState(carAdded);
  const [membership, setMembership] = useState();
  const [canceled, setCanceled] = useState();
  const [vehicleOpen, setVehicleOpen] = useState();

  useEffect(() => {
    if (!dirty && user_id) {
      apiGetUserMembership(user_id).then((res) => {
        if (res.membership) {
          setMembership(res.membership);
          setVehicles(res.membership.vehicles);
          setExistingPrice(res.membership.price);
          setInterval({
            ...interval,
            count: res.membership.interval_count,
            name: res.membership.interval,
          });
        }

        const list = res.vehicles.sort() || [];
        list.push("+ Add New");
        setAllVehicles(list);
      });
    }
  }, [dirty, user_id]);

  const planPrice = () => {
    const len = vehicles.length;

    const price = membership && existingPrice ? existingPrice : plan.price;

    let ogPrice;

    if (existingPrice) {
      ogPrice = membership.price / (1 + (membership.vehicles.length - 1) * 0.5);
    } else {
      ogPrice = price;
    }

    let result = 1 * ogPrice + (len - 1) * (ogPrice * 0.5);

    if (
      interval.name == "year" &&
      (!membership || membership.interval !== "year")
    ) {
      const yearlyTotal = price * len * (12 / membership.interval_count);

      result = yearlyTotal * 0.8;
    }

    return parseFloat(result).toFixed(2);
  };

  const createCar = (data) => {
    apiCreateCarForCustomer(data).then((res) => {
      if (!res.errors) {
        const cars = res.data.attributes.user_cars_list;

        setVehicles([...vehicles, cars.slice(-1)[0]]);

        const list = cars.sort() || [];
        list.push("+ Add New");

        setAllVehicles(list);

        setCarAdded(true);

        setTimeout(() => {
          setCarAdded();
        }, 4000);
      }
    });
  };

  const handleCancel = () => {
    setCreatingMembership(true);

    callApiV2({
      url: `/memberships/cancel/${membership.id}`,
      method: "POST",
    }).then((e) => {
      if (e.errors && e.errors.length > 0) {
        setError(e.errors.join(", "));
      } else {
        setCanceled(true);
      }

      setCreatingMembership();

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  const getStatusTag = (status) => {
    if (status == "active") {
      return (
        <span
          style={{
            padding: "10px",
            background: "#005959",
            color: "#fff",
            borderRadius: "4px",
            marginLeft: "4px",
          }}
        >
          ACTIVE
        </span>
      );
    }

    if (status == "canceled") {
      return (
        <span
          style={{
            padding: "10px",
            background: "#FF0000",
            color: "#fff",
            borderRadius: "4px",
            marginLeft: "4px",
          }}
        >
          CANCELED
        </span>
      );
    }

    return (
      <span
        style={{
          padding: "10px",
          background: "#ddd",
          color: "#000",
          borderRadius: "4px",
          marginLeft: "4px",
        }}
      >
        {status.toUpperCase()}
      </span>
    );
  };

  return (
    <Dialog
      styles={{ padding: "10px" }}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <IconButton
          style={{
            padding: "2px",
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>{" "}
        <h2>{membership ? "Edit" : "Add"} Membership</h2>
        {membership && (
          <>
            <h3 style={{ margin: "10px 0" }}>
              ${membership.price} /{" "}
              {membership.interval_count > 1
                ? `${membership.interval_count} `
                : ""}
              {membership.interval == "year" ? "yr" : "month(s)"}
            </h3>

            <h3 style={{ margin: "10px 0" }}>
              {getStatusTag(membership.current_status)}
            </h3>
          </>
        )}
      </DialogTitle>

      <DialogContent>
        {!membership ? (
          <>
            <Divider />
            <Box style={{ marginTop: "10px", width: "100%" }}>
              <FormHelperText>Price</FormHelperText>
              {vehicles.length > 0 ? (
                <Typography color="primary">
                  <span style={{ fontWeight: "bold" }}>${planPrice()}</span> /{" "}
                  {plan.billing}
                </Typography>
              ) : (
                <Typography color="error">
                  Select vehicles for price!
                </Typography>
              )}
            </Box>

            <br />

            {allVehicles && (
              <FormControl style={{ marginTop: "10px", width: "100%" }}>
                <Autocomplete
                  multiple
                  options={allVehicles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vehicles"
                      variant="outlined"
                    />
                  )}
                  onChange={(_, newValue) => {
                    if (newValue.includes("+ Add New")) {
                      setVehicleOpen(true);
                    } else {
                      setVehicles(newValue);
                    }
                  }}
                  value={vehicles || []}
                />

                <VehicleModal
                  open={vehicleOpen}
                  onClose={() => setVehicleOpen()}
                  userId={user_id}
                  nextSteps={createCar}
                />

                {carAdded && (
                  <Typography className="my-3" color="green">
                    Car successfully added
                  </Typography>
                )}
              </FormControl>
            )}

            <br />
            <FormControl style={{ marginTop: "10px", width: "100%" }}>
              <Button
                disabled={!plan || vehicles.length === 0 || creatingMembership}
                onClick={() => {
                  setCreatingMembership(true);

                  const membership_params = {
                    user_id,
                    plan_name: plan.name,
                    billing_interval: billing.interval,
                    billing_count: billing.count,
                    price_sold: planPrice(),
                    sold_by: "Mechanic",
                    upsold_by_mechanic_id: mechanic_id,
                    vehicles,
                    mechanic_id,
                    appointment_id,
                  };

                  callApiV2({
                    url: "/memberships/create_for_existing_user",
                    method: "POST",
                    data: {
                      membership: membership_params,
                    },
                  }).then((e) => {
                    if (e.errors) {
                      setError(e.errors.join(", "));
                    } else {
                      setSuccess(true);
                    }

                    setCreatingMembership();

                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  });
                }}
                variant="contained"
                color="primary"
              >
                Add Membership
              </Button>

              <Box style={{ marginTop: "10px" }}>
                {error && <Typography color="error">{error}</Typography>}
                {success && (
                  <Typography color="green">Membership created</Typography>
                )}
              </Box>
            </FormControl>
          </>
        ) : (
          <FormControl style={{ marginTop: "10px", width: "100%" }}>
            {membership ? (
              membership.current_status === "canceled" ? (
                <Box style={{ marginTop: "10px" }}>
                  <Typography>
                    Customer has a canceled membership. Click below to refresh
                    the system and create a new one.
                  </Typography>

                  <Button
                    className="my-6"
                    disabled={creatingMembership}
                    onClick={() => handleCancel(true)}
                    variant="contained"
                    color="primary"
                  >
                    Refresh System
                  </Button>

                  {canceled && (
                    <Typography color="red">
                      Membership refreshed, reloading...
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box style={{ marginTop: "10px" }}>
                  <Box style={{ marginTop: "10px", width: "100%" }}>
                    <FormHelperText>Price</FormHelperText>
                    {vehicles.length > 0 ? (
                      <Typography color="primary">
                        <span style={{ fontWeight: "bold" }}>
                          ${planPrice()}
                        </span>{" "}
                        / {interval.count == 1 ? "" : `${interval.count} `}
                        {interval.name}
                      </Typography>
                    ) : (
                      <Typography color="error">
                        Select vehicles for price!
                      </Typography>
                    )}
                  </Box>

                  {membership.interval != "year" && (
                    <>
                      <FormControl style={{ marginTop: "10px" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={interval.name == "year"}
                              onChange={() => {
                                setInterval({
                                  ...interval,
                                  name:
                                    interval.name == "year" ? "month" : "year",
                                  count:
                                    interval.name == "year"
                                      ? membership.interval_count
                                      : 1,
                                });
                              }}
                            />
                          }
                          label="Yearly (10% off)"
                        />
                      </FormControl>
                      <br />
                    </>
                  )}

                  <FormControl style={{ marginTop: "10px", width: "100%" }}>
                    <Autocomplete
                      multiple
                      options={allVehicles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add Vehicles"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, newValue) => {
                        if (newValue.includes("+ Add New")) {
                          setVehicleOpen(true);
                        } else {
                          setVehicles(newValue);
                        }
                      }}
                      value={vehicles || []}
                    />

                    <VehicleModal
                      open={vehicleOpen}
                      onClose={() => setVehicleOpen()}
                      userId={user_id}
                      nextSteps={createCar}
                    />

                    {carAdded && (
                      <Typography className="my-3" color="green">
                        Car successfully added
                      </Typography>
                    )}
                  </FormControl>
                  <br />
                  <FormControl style={{ marginTop: "10px", width: "100%" }}>
                    <Button
                      disabled={vehicles.length === 0 || creatingMembership}
                      onClick={() => {
                        setCreatingMembership(true);

                        const membership_params = {
                          price_sold: planPrice(),
                          billing_interval: interval.name,
                          billing_count: interval.count,
                          vehicles,
                          upsold_to_family_plan_by_mechanic_id: mechanic_id,
                        };

                        callApiV2({
                          url: `/memberships/${membership.id}`,
                          method: "PUT",
                          data: {
                            membership: membership_params,
                          },
                        }).then((e) => {
                          if (e.errors) {
                            setError(e.errors.join(", "));
                          } else {
                            setSuccess(true);
                          }

                          setCreatingMembership();

                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Edit Membership
                    </Button>

                    <Box style={{ marginTop: "10px" }}>
                      {error && <Typography color="error">{error}</Typography>}
                      {success && (
                        <Typography color="green">Membership edited</Typography>
                      )}
                      {canceled && (
                        <Typography color="red">Membership canceled</Typography>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              )
            ) : (
              <Typography>Customer is already a member</Typography>
            )}
          </FormControl>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MembershipModal;
