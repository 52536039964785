import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  OutlinedInput,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import InfoPanel from "./components/InfoPanel";
import { apiAdminCreateJob, apiGetAdminZipAvailability } from "../../../_apis";
import { availableDiv } from "../components/shared";
import VehicleForm from "./components/VehicleForm";
import { isValidEmail, isValidPhoneNumber } from "../../../_helpers/utils";

const STEPS = {
  ZIP: 1,
  SERVICE: 2,
  VEHICLE: 3,
  CUSTOMER: 4,
};

const SERVICE_TYPES = {
  DIAG: "diagnosis",
  REPAIR: "repair",
};

const AdminNewCustomer = ({ setJobId, setTab, admin_user_id }) => {
  const [step, setStep] = useState(STEPS.ZIP);
  const [zip, setZip] = useState();
  const [success, setSuccess] = useState();
  const [newJobId, setNewJobId] = useState();
  const [errors, setErrors] = useState({
    zip: null,
    job: null,
  });
  const [availability, setAvailability] = useState([]);
  const [serviceType, setServiceType] = useState();
  const [serviceDetails, setServiceDetails] = useState();
  const [car, setCar] = useState({
    year: "",
    make: "",
    model: "",
    engine_size: "",
  });
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [heard_about_us, setHeardAboutUs] = useState();
  const [referral_name, setReferralName] = useState();

  const searchZip = () => {
    apiGetAdminZipAvailability(zip).then((res) => {
      const availableDays = [];

      if (res.errors) {
        setErrors({
          ...errors,
          zip: res.errors.join(", "),
        });
      } else {
        res.mechanics.forEach((m) => {
          Object.keys(m.schedule).forEach((day) => {
            availableDays.push(day);
          });
        });

        setAvailability(res.mechanics);
        setStep(STEPS.SERVICE);
      }
    });
  };

  const createJob = () => {
    const jobParams = {
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      heard_about_us,
      referral_name,
      diagnosis_input: serviceDetails,
      services: [serviceDetails],
      appointment_type: serviceType,
      level: serviceType === "diagnosis" ? "A" : "B",
      address: zip,
      status: "quote_requested",
      car_attributes: {
        year: car.year,
        make: car.make,
        model: car.model,
        engine_size: car.engine_size,
      },
      owner_id: admin_user_id,
    };

    apiAdminCreateJob(jobParams).then((res) => {
      if (res.errors) {
        setErrors({
          ...errors,
          job: res.errors.join(", "),
        });
      } else {
        setJobId(res.data.id);
        setNewJobId(res.data.id);
        setSuccess(true);
      }
    });
  };

  const cardTitle = () => {
    switch (step) {
      case 1: {
        return "Zip";
      }
      case 2: {
        return "Service";
      }
      case 3: {
        return "Vehicle";
      }
      case 4: {
        return "Customer";
      }
    }
  };

  const placeHolderReferName = () => {
    switch (heard_about_us) {
      case "Biz Cards": {
        return "Technician name?";
      }
      case "Referral": {
        return "Customer name?";
      }
      case "Other": {
        return "More details";
      }
    }
  };

  return (
    <Container style={{ marginBottom: "100px", marginTop: "40px" }}>
      <Card style={{ width: "70vw", margin: "10px auto" }}>
        <InfoPanel step={step} setStep={setStep} />

        <Divider />

        <CardHeader title={cardTitle()} />

        <CardContent>
          {step === STEPS.ZIP && (
            <Box style={{ margin: "10px 0" }}>
              <OutlinedInput
                value={zip}
                error={zip && zip.length !== 5}
                onChange={(e) => {
                  setZip(e.target.value);
                }}
                placeholder="Zip Code"
              />
              <br />
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                onClick={searchZip}
                disabled={!zip || zip.length !== 5}
              >
                Next
              </Button>

              {errors.zip && (
                <Box>
                  <Typography color="error">{errors.zip}</Typography>
                </Box>
              )}
            </Box>
          )}

          {step === STEPS.SERVICE && (
            <Box style={{ margin: "10px 0" }}>
              {availableDiv(availability)}

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setServiceType(SERVICE_TYPES.REPAIR)}
                      checked={serviceType === SERVICE_TYPES.REPAIR}
                    />
                  }
                  label="Repair"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setServiceType(SERVICE_TYPES.DIAG)}
                      checked={serviceType === SERVICE_TYPES.DIAG}
                    />
                  }
                  label="Diagnosis"
                />
              </FormGroup>

              <Box style={{ margin: "10px" }}>
                <OutlinedInput
                  style={{ width: "80%" }}
                  multiline
                  rows={4}
                  value={serviceDetails}
                  onChange={(e) => setServiceDetails(e.target.value)}
                  placeholder="Service details"
                />
              </Box>

              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                disabled={!serviceType || !serviceDetails}
                onClick={() => setStep(STEPS.VEHICLE)}
              >
                Next
              </Button>
            </Box>
          )}

          {step === STEPS.VEHICLE && (
            <Box style={{ margin: "10px 0" }}>
              <VehicleForm
                car={car}
                setCar={setCar}
                nextStep={() => setStep(STEPS.CUSTOMER)}
              />
            </Box>
          )}

          {step === STEPS.CUSTOMER && (
            <Box style={{ margin: "10px 0" }}>
              <OutlinedInput
                style={{ margin: "10px" }}
                value={customer.name}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    name: e.target.value,
                  });
                }}
                placeholder="Name"
              />
              <OutlinedInput
                style={{ margin: "10px" }}
                error={customer.email && !isValidEmail(customer.email)}
                value={customer.email}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    email: e.target.value,
                  });
                }}
                placeholder="Email"
              />
              <OutlinedInput
                style={{ margin: "10px" }}
                value={customer.phone}
                error={customer.email && !isValidPhoneNumber(customer.phone)}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    phone: e.target.value,
                  });
                }}
                placeholder="Phone"
              />
              <br />
              <div style={{ margin: "10px" }}>
                <FormControl>
                  <InputLabel id="heard-about-us-select-label">
                    How did they hear about us?
                  </InputLabel>
                  <Select
                    labelId="heard-about-us-select-label"
                    id="mkt-select"
                    style={{ width: "300px" }}
                    variant="outlined"
                    onChange={(e) => setHeardAboutUs(e.target.value)}
                  >
                    {[
                      "Google",
                      "Referral",
                      "Biz Cards",
                      "Door Hanger",
                      "Mail",
                      "News",
                      "Other",
                    ].map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {["Referral", "Biz Cards", "Other"].includes(
                  heard_about_us
                ) && (
                  <div>
                    <OutlinedInput
                      style={{ marginTop: "10px", width: "300px" }}
                      value={referral_name}
                      onChange={(e) => {
                        setReferralName(e.target.value);
                      }}
                      placeholder={placeHolderReferName()}
                    />
                  </div>
                )}
              </div>
              <Button
                style={{ margin: "10px" }}
                variant="contained"
                color="primary"
                onClick={createJob}
                disabled={
                  !customer.name ||
                  !customer.email ||
                  !isValidEmail(customer.email) ||
                  !customer.phone ||
                  !heard_about_us ||
                  (["Referral", "Biz Cards", "Other"].includes(
                    heard_about_us
                  ) &&
                    !referral_name) ||
                  success
                }
              >
                Create Job
              </Button>

              {errors.job && (
                <Box>
                  <Typography color="error">{errors.job}</Typography>
                </Box>
              )}

              {success && (
                <Box style={{ margin: "10px" }}>
                  <Typography>Job #{newJobId} Created</Typography>
                  <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => setTab(e, 0)}
                  >
                    Book Job
                  </Button>
                  <Button
                    style={{ margin: "10px" }}
                    variant="contained"
                    color="primary"
                    onClick={(e) => setTab(e, 1)}
                  >
                    Make Estimate
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default AdminNewCustomer;
