import React from "react";

export const availableDiv = (set) => {
  return (
    <div
      style={{
        margin: "30px 0",
        height: "200px",
        width: "450px",
        overflow: "scroll",
        border: "1px solid #ddd",
        padding: "30px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            background: "#24CF93",
            borderRadius: "50%",
            width: "15px",
            height: "15px",
            marginRight: "5px",
          }}
        ></div>
        <h3 style={{ fontSize: "18px" }}>Available</h3>
      </div>

      <p style={{ fontStyle: "italic", margin: "5px 0" }}>
        {set.length} mechanic(s)
      </p>

      <div style={{ padding: "20px" }}>
        <ul>
          {set.map((m) => {
            return (
              <li style={{ fontSize: "14px", listStyle: "inside" }}>
                {m.name} ({m.level} tech)
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
