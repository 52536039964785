import { abstractURL } from "../_helpers/utils";

export default {
  INDEX: abstractURL("/mechanic"),

  // Mechanics
  MECHANIC: {
    ACCOUNT: abstractURL("/mechanic/account"),
    DASHBOARD: abstractURL("/mechanic/jobs"),
    ALL_MESSAGES: abstractURL("/mechanic/messages"),
    MESSAGES_BY_ID: abstractURL("/mechanic/chat/:id"),
    DEFAULT: abstractURL("/mechanic/sign_in"),
    INSPECTION: abstractURL("/mechanic/inspection/:id"),
    SIGN_IN: abstractURL("/mechanic/sign_in"),
    SIGN_UP: abstractURL("/mechanic/sign_up"),
    NEW_FREE_DAYS: abstractURL("/mechanic/free_days/new"),
    TIME_CARD: abstractURL("/mechanic/timecard"),
    FAQS: abstractURL("/mechanic/faqs"),
    PARTS_INFO: abstractURL("/mechanic/parts-info"),
    FIND_JOB: abstractURL("/mechanic/find_appointment"),
    PREVIOUS_TIME_CARD: abstractURL("/mechanic/timecard/previous/:previous"),
    DIAG_NOTE: abstractURL("/mechanic/notes/diagnosis"),
    INCOMPLETE_JOB_NOTE: abstractURL("/mechanic/incomplete_notes"),
    WARRANTY_NOTE: abstractURL("/mechanic/notes/warranty"),
    WARRANTY_CLAIM: abstractURL("/mechanic/notes/warranty-claim"),
    NEW_ESTIMATE: abstractURL("/mechanic/estimate/new/:appointment_id"),
    EDIT_ESTIMATE: abstractURL("/mechanic/estimate/edit/:estimate_id"),
    NEW_PAYMENT: abstractURL("/payments/new/:appointment_id/:payment_type"),
    UPDATE_CREDIT_CARD: abstractURL(
      "/payments/update_cc/:appointment_id/:message?"
    ),
    RESET_PASSWORD: abstractURL("/mechanic/reset_password"),
    JOB_PAGE: abstractURL("/mechanic/job/:job_id"),
    SEARCH_SCREEN: abstractURL("/mechanic/search"),
    NEW_JOB: abstractURL("/mechanic/add-job"),
  },
};
