import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import {
  apiGetEstimate,
  apiSendEstimateNotification,
  apiUpdateEstimate,
} from "../../../_apis/mechanics/estimates";
import { apiGetAppointment } from "../../../_apis";
import EstimateForm from "./EstimateForm";
import EstimateReview from "./EstimateReview";
import ViewEstimateModal from "../components/ViewEstimateModal";
import JobDetails from "../components/JobDetails";
import TextEstimateModal from "./components/TextEstimateModal";

const EstimateEdit = () => {
  const { estimate_id } = useParams();
  const [estimate, setEstimate] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [reload, setNeedReload] = useState(false);
  const [textedEstimate, setTextedEstimate] = useState();
  const [showReview, setShowReview] = useState(false);
  const [textOpen, setTextOpen] = useState(false);

  const estimateFormData = useMemo(() => {
    if (!estimate) return {};

    return {
      discount: estimate.discount,
      recommendations: estimate.recommendations,
      note_to_management: estimate.note_to_management,
      services: Object.keys(estimate.services).map((key) => ({
        ...estimate.services[key],
        name: key,
      })),
    };
  }, [estimate]);

  const handleSubmit = useCallback((data) => {
    handleFinalSubmit(data, true);
  }, []);

  const handleFinalSubmit = (data, needsReview = false) => {
    setIsSaving(true);
    apiUpdateEstimate(estimate_id, data)
      .then((res) => {
        if (!res) {
          window.location.assign(`/mechanic/job/${appointment.id}`);
        } else if (needsReview) {
          setShowReview(true);
          setEstimate({
            ...res.data.attributes,
            id: res.data.id,
          });
          setNeedReload(true);
        } else {
          setOpen(true);
          setNeedReload(true);
        }
      })
      .catch(() => alert("Error saving, check all fields and try again."))
      .finally(() => setIsSaving(false));
  };

  const handleTextCustomer = useCallback(() => {
    apiSendEstimateNotification(estimate_id)
      .then(() => {
        setTextedEstimate(true);

        setTimeout(() => {
          setTextedEstimate(false);
        }, 3000);
      })
      .catch(() => alert("Error sending."));
  }, [estimate_id]);

  const func = async () => {
    setLoading(true);

    const { data: rspEstimate } = await apiGetEstimate(estimate_id);
    setEstimate({
      ...rspEstimate.attributes,
      id: rspEstimate.id,
    });

    const { data: rspAppointment } = await apiGetAppointment(
      rspEstimate.attributes.appointment_id
    );
    setAppointment({
      ...rspAppointment.attributes,
      id: rspAppointment.id,
    });

    setLoading(false);
  };

  useEffect(() => {
    func();
  }, [estimate_id]);

  if (!estimate || loading) {
    return (
      <Box pt={40} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box pt={12} pb={15}>
      <Container>
        {appointment && (
          <JobDetails
            jobInfo={{ attributes: appointment }}
            appointmentTime={`${appointment.appointment_day} ${appointment.time_for_mechanic}`}
            job_id={appointment.id}
            expand={false}
          />
        )}

        <Box mb={3}>
          <Typography variant="h4" align="center">
            {showReview ? "Review Estimate" : "Modify estimate"}
          </Typography>
        </Box>
      </Container>
      {showReview ? (
        <EstimateReview
          onNext={() => setTextOpen(true)}
          appointment={appointment}
          reload={reload}
          setReload={setNeedReload}
          onBack={() => {
            func();
            setShowReview(false);
          }}
        />
      ) : (
        <EstimateForm
          initialData={estimateFormData}
          appointment={appointment}
          isSubmitting={isSaving}
          onSubmit={handleSubmit}
        />
      )}
      <ViewEstimateModal
        open={open}
        appointment_id={appointment.id}
        className=""
        onClose={setOpen}
        reload={reload}
        setReload={setNeedReload}
        handleTextEstimate={handleTextCustomer}
        textedEstimate={textedEstimate}
        afterText="Back To Edit Estimate"
        afterTextAction={() => setOpen(false)}
        shouldTextEstimate={true}
      />

      <TextEstimateModal
        open={textOpen}
        appointment_id={appointment.id}
        estimate_id={estimate_id}
        onClose={() => setTextedEstimate(false)}
        handleTextEstimate={handleTextCustomer}
        afterText="Back To Edit Estimate"
        afterTextAction={() => setTextOpen(false)}
      />
    </Box>
  );
};

export default EstimateEdit;
