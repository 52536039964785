import React, { useState, useEffect } from "react";
import { Typography, Card, useMediaQuery } from "@material-ui/core";
import { InfoOutlined, Stars } from "@material-ui/icons";
import HowItWorks from "./HowItWorks";

const Rewards = ({ rewards }) => {
  const isSm = useMediaQuery("(max-width: 600px)");

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "#1A5B74",
          maxWidth: "500px",
          margin: isSm ? 20 : "auto",
          marginTop: 20,
          marginBottom: 20,
          padding: 20,
          borderRadius: 12,
        }}
        elevation={0}
      >
        <Typography style={{ color: "white", fontSize: 25 }}>
          <Stars /> Rewards Points
        </Typography>
        <Typography style={{ color: "white", fontSize: 18 }}>
          This Week: {rewards.weekly}
        </Typography>
        <Typography style={{ color: "white", fontSize: 16 }}>
          {rewards.weekly && rewards.weekly > 0
            ? `Redeemable for $${rewards.weekly / 10}.00`
            : "Redeemable for -"}
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Typography style={{ color: "white", fontSize: 14 }}>
            Total: {rewards.total}
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
            onClick={toggleModal}
          >
            <Typography style={{ color: "white", fontSize: 12 }}>
              How it Works
            </Typography>
            <InfoOutlined
              style={{ backgroundColor: "#1A5B74", height: 20, color: "white" }}
            />
          </div>
        </div>
      </Card>
      <HowItWorks open={showModal} onClose={toggleModal} />
    </>
  );
};

export { Rewards };
