import React from "react";
import moment from "moment";
import colors from "../../../../../src/constants/colors";

const DayButton = ({
  val,
  index,
  isEvent,
  today,
  currentDay,
  availability,
  setAvailableMechanics,
  setCurrentDay,
  isScheduledAppt,
}) => {
  const isCurrent = moment(val).isSame(moment(currentDay), "day");
  const isToday = moment(val).isSame(today, "day");

  const Dot = ({ exists }) => {
    let bg = "";

    if (isScheduledAppt) {
      bg = colors.primary;
    } else if (exists) {
      bg = colors.secondaryDarker;
    }

    return (
      <div
        style={{
          width: 6,
          height: 6,
          flex: 1,
          margin: "auto",
          backgroundColor: bg,
          borderRadius: 10,
        }}
      ></div>
    );
  };

  return (
    <button
      className={"p-0.5 flex flex-col items-center focus:outline-none"}
      style={{
        WebkitTapHighlightColor: "transparent",
        border: "none",
        boxShadow: "none",
      }}
      onClick={() => {
        const formatted = moment(val).format("YYYY-MM-DD");
        setAvailableMechanics(
          availability.filter((mechanic) =>
            Object.keys(mechanic.schedule).includes(formatted)
          )
        );
        setCurrentDay(val);
      }}
    >
      {index < 7 && (
        <p
          className={
            (moment(val).isSame(moment(currentDay), "day") ||
            moment(currentDay).isSame(moment(val).add(7, "days"), "day")
              ? "text-fmc-primary"
              : null) + " font-inter text-xs pb-1 flex-1"
          }
        >
          {moment(val).format("dd")[0]}
        </p>
      )}
      <div
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 10,
          width: 40,
          height: 40,
        }}
        className={
          isCurrent
            ? "bg-fmc-darker"
            : isToday
            ? "border-2 border-fmc-primary"
            : null
        }
      >
        <p
          style={{ flex: 4 }}
          className={
            (isCurrent
              ? "text-white"
              : isToday
              ? "text-fmc-primary"
              : moment(val).isAfter(today, "day")
              ? "text-fmc-calendar-text-new"
              : "text-fmc-calendar-text-old") + " p-1 font-inter text-xl"
          }
        >
          {val.getDate()}
        </p>
        <Dot exists={isEvent.length} />
      </div>
    </button>
  );
};

export default DayButton;
