import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  Box,
  Container,
  CircularProgress,
  Autocomplete,
  Typography,
  FormHelperText,
  Divider,
  FormControlLabel,
  Switch,
  Checkbox,
  Card,
} from "@material-ui/core";
import { apiGetAppointment } from "../../../_apis";
import { BILLINGS, MEMBER_PLANS } from "../../../_helpers/membership";
import { callApiV2 } from "../../../_apis/api_base";
import { apiGetUserAdmin } from "../../../_apis/user";
import VehicleModal from "./components/VehicleModal";
import {
  apiCreateCarForCustomer,
  apiGetCardOnFile,
} from "../../../_apis/mechanics/customers";
import CreditCardSimple from "../AdminCalendar/components/CreditCardSimple";

const AdminMembership = ({
  appointment_id,
  user_id,
  admin_user_id,
  after_service_call = false,
}) => {
  const [appointment, setAppointment] = useState(null);
  const [user, setUser] = useState();
  const [plan, setPlan] = useState(MEMBER_PLANS[0]);
  const [existingPrice, setExistingPrice] = useState();
  const [interval, setInterval] = useState({
    count: null,
    name: null,
  });
  const [vehicles, setVehicles] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [creatingMembership, setCreatingMembership] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [billing, setBilling] = useState(BILLINGS[0]);
  const [open, setOpen] = useState();
  const [carAdded, setCarAdded] = useState(carAdded);
  const [membership, setMembership] = useState();
  const [canceled, setCanceled] = useState();
  const [creditToMechanic, setCreditToMechanic] = useState(false);
  const [mechanics, setMechanics] = useState([]);
  const [selectedMechanic, setSelectedMechanic] = useState({
    id: "",
    name: "",
  });
  const [mechanicSelected, setMechanicSelected] = useState(false);
  const [isCardOnFile, setIsCardOnFile] = useState(false);
  const [lastFour, setLastFour] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [errorCardOnFile, setErrorCardOnFile] = useState(null);
  const [prevCardSelected, setPrevCardSelected] = useState(false);
  const [hasCC, setHasCC] = useState(false);
  const [free, setFree] = useState(false);

  const fetchMechanics = () => {
    callApiV2({
      url: "/mechanics/active",
      method: "GET",
    })
      .then((response) => {
        setMechanics(response);
      })
      .catch((error) => {
        console.error("Error fetching mechanics:", error);
      });
  };

  const handleSwitchChange = () => {
    if (creditToMechanic) {
      setSelectedMechanic({ id: "", name: "" });
      setMechanicSelected(false);
    }

    setCreditToMechanic((prevCredit) => !prevCredit);
  };

  const handleMechanicSelect = (event) => {
    const selectedMechanicId = event.target.value;
    const selectedMechanicData = mechanics.find(
      (mechanic) => mechanic.id === selectedMechanicId
    );

    setSelectedMechanic(selectedMechanicData);
    setMechanicSelected(!!selectedMechanicData);
  };

  const planPrice = () => {
    const len = vehicles.length;

    const price = membership && existingPrice ? existingPrice : plan.price;

    let ogPrice;

    if (existingPrice) {
      ogPrice = membership.price / (1 + (membership.vehicles.length - 1) * 0.5);
    } else {
      ogPrice = price;
    }

    let result = 1 * ogPrice + (len - 1) * (ogPrice * 0.5);

    if (
      interval.name == "year" &&
      (!membership || membership.interval !== "year")
    ) {
      const yearlyTotal = price * len * (12 / (interval.name == "year" ? 12 : membership.interval_count));

      result = yearlyTotal * 0.8;
    }

    return parseFloat(result).toFixed(2);
  };

  useEffect(() => {
    const func = async () => {
      if (appointment_id) {
        const rsp = await apiGetAppointment(appointment_id);

        setAppointment({
          ...rsp.data.attributes,
          id: appointment_id,
        });

        const membership = rsp.data.attributes.membership;

        if (membership) {
          setMembership(membership);
          setVehicles(membership.vehicles);
          setExistingPrice(membership.price);
          setInterval({
            ...interval,
            count: membership.interval_count,
            name: membership.interval,
          });
        }

        const list = rsp.data.attributes.user_cars_list.sort() || [];
        list.push("+ Add New");
        setAllVehicles(list);
      } else if (user_id) {
        const rsp = await apiGetUserAdmin(user_id);

        setUser(rsp.user);

        const membership = rsp.user.membership;

        if (membership) {
          setMembership(membership);
          setVehicles(membership.vehicles);
          setExistingPrice(membership.price);
          setInterval({
            ...interval,
            count: membership.interval_count,
            name: membership.interval,
          });
        }

        const list = rsp.user.user_cars_list.sort() || [];
        list.push("+ Add New");
        setAllVehicles(list);
      }
    };
    func();
  }, [appointment_id]);

  useEffect(() => {
    if (appointment !== null || user_id) {
      const userId = appointment ? appointment.user_id : user_id;

      const fetchCardOnFile = async () => {
        if (userId) {
          try {
            const response = await apiGetCardOnFile(userId);
            if (response.errors && response.errors.length > 0) {
              setErrorCardOnFile(
                "An error occurred while processing your request."
              );
            } else if (
              response.card_on_file?.card_brand &&
              response.card_on_file?.last_4_digits
            ) {
              setIsCardOnFile(true);
              setLastFour(response.card_on_file?.last_4_digits);
              setCardBrand(response.card_on_file?.card_brand);
              setHasCC(appointment.credit_card_present);
            }
          } catch (error) {
            setErrorCardOnFile(
              "An error occurred while processing your request."
            );
          }
        }
      };
      fetchCardOnFile();
    }
  }, [appointment, hasCC]);

  useEffect(() => {
    fetchMechanics();
  }, []);

  useEffect(() => {
    if (hasCC) {
      setIsCardOnFile(true);
      setPrevCardSelected(true);
    }
  }, [hasCC]);

  const createCar = (data) => {
    apiCreateCarForCustomer(data).then((res) => {
      if (!res.errors) {
        const cars = res.data.attributes.user_cars_list;

        setVehicles([...vehicles, cars.slice(-1)[0]]);

        const list = cars.sort() || [];
        list.push("+ Add New");

        setAllVehicles(list);

        setCarAdded(true);

        setTimeout(() => {
          setCarAdded();
        }, 4000);
      }
    });
  };

  const handleCancel = () => {
    setCreatingMembership(true);

    callApiV2({
      url: `/memberships/cancel/${membership.id}`,
      method: "POST",
    }).then((e) => {
      if (e.errors && e.errors.length > 0) {
        setError(e.errors.join(", "));
      } else {
        setCanceled(true);
      }

      setCreatingMembership();

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  const handleCheckboxChange = () => {
    setPrevCardSelected(!prevCardSelected);
  };

  const getStatusTag = (status) => {
    if (status == "active") {
      return (
        <span
          style={{
            padding: "10px",
            background: "#005959",
            color: "#fff",
            borderRadius: "4px",
            marginLeft: "4px",
          }}
        >
          ACTIVE
        </span>
      );
    }

    if (status == "canceled") {
      return (
        <span
          style={{
            padding: "10px",
            background: "#FF0000",
            color: "#fff",
            borderRadius: "4px",
            marginLeft: "4px",
          }}
        >
          CANCELED
        </span>
      );
    }

    return (
      <span
        style={{
          padding: "10px",
          background: "#ddd",
          color: "#000",
          borderRadius: "4px",
          marginLeft: "4px",
        }}
      >
        {status.toUpperCase()}
      </span>
    );
  };

  if (!appointment && !user_id) {
    return !appointment_id ? (
      <Box style={{ marginBottom: "100px" }}>
        <Container>
          <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
            <h2>No Customer or Job ID</h2>
            <br />
            <Typography>
              To use this feature, please provide a valid job ID or customer ID.
              You can navigate to the corresponding customer profile or job, and
              click on the 'Add Membership' link to return to this page and
              proceed with adding a membership.
            </Typography>
          </div>
        </Container>
      </Box>
    ) : (
      <Box pt={14} pb={15} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box style={{ marginBottom: "100px" }}>
      <Container>
        <div style={{ margin: "30px 0 10px 0", fontSize: "16px" }}>
          <h2>{membership ? "Edit" : "Add"} Membership</h2>

          {membership && (
            <>
              <h2 style={{ margin: "10px 0" }}>
                ${membership.price} /{" "}
                {membership.interval_count > 1
                  ? `${membership.interval_count} `
                  : ""}
                {membership.interval == "year" ? "yr" : "month(s)"}
              </h2>

              <h2 style={{ margin: "10px 0" }}>
                {getStatusTag(membership.current_status)}
              </h2>
            </>
          )}

          {appointment && (
            <>
              <h2 style={{ margin: "10px 0" }}>
                <a href={`/admin/customers/${appointment.user_id}`}>
                  {appointment.customer_name}
                </a>
              </h2>
              <h2 style={{ margin: "10px 0" }}>{appointment.customer_phone}</h2>
            </>
          )}

          {user && (
            <>
              <h2 style={{ margin: "10px 0" }}>
                <a href={`/admin/customers/${user_id}`}>{user.name}</a>
              </h2>
              <h2 style={{ margin: "10px 0" }}>{user.phone}</h2>
            </>
          )}
        </div>
      </Container>
      <Container>
        {(appointment && !appointment.member_type) || (user && !membership) ? (
          <>
            <Divider />
            <Box style={{ margin: "10px", width: "60%" }}>
              <FormHelperText>Price</FormHelperText>
              {vehicles.length > 0 ? (
                <Typography color="primary">
                  <span style={{ fontWeight: "bold" }}>${planPrice()}</span> /{" "}
                  {plan.billing}
                </Typography>
              ) : (
                <Typography color="error">
                  Select vehicles for price!
                </Typography>
              )}
            </Box>

            <br />

            {allVehicles && (
              <FormControl style={{ margin: "10px", width: "60%" }}>
                <Autocomplete
                  multiple
                  options={allVehicles}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vehicles"
                      variant="outlined"
                    />
                  )}
                  onChange={(_, newValue) => {
                    if (newValue.includes("+ Add New")) {
                      setOpen(true);
                    } else {
                      setVehicles(newValue);
                    }
                  }}
                  value={vehicles || []}
                />

                <VehicleModal
                  open={open}
                  onClose={() => setOpen()}
                  userId={user_id || appointment.user_id}
                  nextSteps={createCar}
                />

                {carAdded && (
                  <Typography className="my-3" color="green">
                    Car successfully added
                  </Typography>
                )}
              </FormControl>
            )}
            <>
              <FormControl style={{ margin: "10px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={interval.name == "year"}
                      onChange={() => {
                        setPlan(MEMBER_PLANS[1])
                        setBilling(BILLINGS[1])
                        setInterval({
                          ...interval,
                          name:
                            interval.name == "year" ? "month" : "year",
                          count:
                            interval.name == "year"
                              ? 6
                              : 12,
                        });
                      }}
                    />
                  }
                  label="Yearly (10% off)"
                />
              </FormControl>
              <br />
            </>

            <br />

            <div style={{ margin: 10 }}>
              {isCardOnFile ? (
                <Card
                  elevation={0}
                  style={{ background: "#E6EEEE", padding: 20, width: "61%" }}
                >
                  <Typography style={{ fontFamily: "Inter", fontWeight: 600 }}>
                    Use card on file
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={prevCardSelected}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label={`${cardBrand} ending ${lastFour}`}
                  />
                </Card>
              ) : (
                <CreditCardSimple
                  appointment_id={appointment_id}
                  setCC={setHasCC}
                  userId={user_id}
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={creditToMechanic}
                    onChange={handleSwitchChange}
                  />
                }
                label={"Credit a technician"}
              />
              {creditToMechanic && (
                <div>
                  <Typography variant="body2" style={{ margin: 5 }}>
                    Select technician
                  </Typography>
                  <Select
                    variant="outlined"
                    value={selectedMechanic.id}
                    onChange={handleMechanicSelect}
                    style={{ width: 300 }}
                  >
                    {mechanics.map((mechanic) => (
                      <MenuItem key={mechanic.id} value={mechanic.id}>
                        {mechanic.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            </div>

            <FormControl style={{ margin: "10px", width: "60%" }}>
              <Button
                disabled={
                  !plan ||
                  vehicles.length === 0 ||
                  creatingMembership ||
                  !prevCardSelected
                }
                onClick={() => {
                  setCreatingMembership(true);
                  const membership_params = {
                    user_id: appointment ? appointment.user_id : user_id,
                    plan_name: plan.name,
                    billing_interval: billing.interval,
                    billing_count: billing.count,
                    price_sold: planPrice(),
                    sold_by: mechanicSelected ? "Mechanic" : "Admin",
                    upsold_by_mechanic_id:
                      mechanicSelected && selectedMechanic.id,
                    free_membership: free,
                    vehicles,
                    admin_user_id,
                    after_service_call,
                  };

                  callApiV2({
                    url: "/memberships/create_for_existing_user",
                    method: "POST",
                    data: {
                      membership: membership_params,
                    },
                  }).then((e) => {
                    if (e.errors) {
                      setError(e.errors.join(", "));
                    } else {
                      setSuccess(true);
                    }

                    setCreatingMembership();
                  });
                }}
                variant="contained"
                color="primary"
              >
                Add Membership
              </Button>

              <Box style={{ margin: "10px" }}>
                {error && <Typography color="error">{error}</Typography>}
                {success && (
                  <Typography color="green">Membership created</Typography>
                )}
              </Box>
            </FormControl>
          </>
        ) : (
          <FormControl style={{ margin: "10px", width: "60%" }}>
            {membership ? (
              membership.current_status === "canceled" ? (
                <Box style={{ margin: "10px" }}>
                  <Typography>
                    Customer has a canceled membership. Click below to refresh
                    the system and create a new one.
                  </Typography>

                  <Button
                    className="my-6"
                    disabled={creatingMembership}
                    onClick={() => handleCancel(true)}
                    variant="contained"
                    color="primary"
                  >
                    Refresh System
                  </Button>

                  {canceled && (
                    <Typography color="red">
                      Membership refreshed, reloading...
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box style={{ margin: "10px" }}>
                  <Box style={{ margin: "10px", width: "60%" }}>
                    <FormHelperText>Price</FormHelperText>
                    {vehicles.length > 0 ? (
                      <Typography color="primary">
                        <span style={{ fontWeight: "bold" }}>
                          ${planPrice()}
                        </span>{" "}
                        / {interval.count == 1 ? "" : `${interval.count} `}
                        {interval.name}
                      </Typography>
                    ) : (
                      <Typography color="error">
                        Select vehicles for price!
                      </Typography>
                    )}
                  </Box>

                  {membership.interval != "year" && (
                    <>
                      <FormControl style={{ margin: "10px" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={interval.name == "year"}
                              onChange={() => {
                                setInterval({
                                  ...interval,
                                  name:
                                    interval.name == "year" ? "month" : "year",
                                  count:
                                    interval.name == "year"
                                      ? membership.interval_count
                                      : 1,
                                });
                              }}
                            />
                          }
                          label="Yearly (10% off)"
                        />
                      </FormControl>
                      <br />
                    </>
                  )}

                  <FormControl style={{ margin: "10px", width: "60%" }}>
                    <Autocomplete
                      multiple
                      options={allVehicles}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Add Vehicles"
                          variant="outlined"
                        />
                      )}
                      onChange={(_, newValue) => {
                        if (newValue.includes("+ Add New")) {
                          setOpen(true);
                        } else {
                          setVehicles(newValue);
                        }
                      }}
                      value={vehicles || []}
                    />

                    <VehicleModal
                      open={open}
                      onClose={() => setOpen()}
                      userId={user_id || appointment.user_id}
                      nextSteps={createCar}
                    />

                    {carAdded && (
                      <Typography className="my-3" color="green">
                        Car successfully added
                      </Typography>
                    )}
                  </FormControl>
                  <br />
                  <FormControl style={{ margin: "10px", width: "60%" }}>
                    <Button
                      disabled={vehicles.length === 0 || creatingMembership}
                      onClick={() => {
                        setCreatingMembership(true);

                        const membership_params = {
                          price_sold: planPrice(),
                          billing_interval: interval.name,
                          billing_count: interval.count,
                          vehicles,
                          admin_user_id,
                          after_service_call,
                        };

                        callApiV2({
                          url: `/memberships/${membership.id}`,
                          method: "PUT",
                          data: {
                            membership: membership_params,
                          },
                        }).then((e) => {
                          if (e.errors) {
                            setError(e.errors.join(", "));
                          } else {
                            setSuccess(true);
                          }

                          setCreatingMembership();
                        });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Edit Membership
                    </Button>

                    <Button
                      className="my-6"
                      disabled={creatingMembership}
                      onClick={handleCancel}
                      variant="contained"
                      color="error"
                    >
                      Cancel Membership
                    </Button>

                    <Box style={{ margin: "10px" }}>
                      {error && <Typography color="error">{error}</Typography>}
                      {success && (
                        <Typography color="green">Membership edited</Typography>
                      )}
                      {canceled && (
                        <Typography color="red">Membership canceled</Typography>
                      )}
                    </Box>
                  </FormControl>
                </Box>
              )
            ) : (
              <Typography>Customer is already a member</Typography>
            )}
          </FormControl>
        )}
      </Container>
    </Box>
  );
};

export default AdminMembership;
