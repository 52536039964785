export function isEmail(email) {
  const regularExpression = /\S+@\S+\.\S+/;
  return regularExpression.test(email);
}

export const jobTypes = [
  {
    enum: "diagnosis",
    description_field: "diagnosis_input",
    level: "A",
    label: "Diag",
  },
  {
    enum: "repair",
    description_field: "services",
    level: "B",
    label: "Repair",
  },
];

export const newCustomerFields = [
  {
    label: "Name",
    type: "string",
    field: "name",
  },
  {
    label: "Phone",
    type: "number",
    field: "phone",
  },
  {
    label: "Email",
    type: "string",
    field: "email",
  },
  {
    label: "Zip Code",
    type: "string",
    field: "zip",
  },
];

export const searchTypes = ["Phone", "Email", "Name"];

export const VEHICLE_YEAR_OPTIONS = [
  1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
  2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
  2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
];

export function isCustomerInfoValid(inputs) {
  const { name, phone, email, zip } = inputs;
  let errors = {};
  let valid = true;

  if (email.length === 0 || email === "") {
    valid = false;
    errors["email"] = "Email must be filled out";
  }

  if (email.length > 0 && !isEmail(email)) {
    valid = false;
    errors["email"] = "Invalid email";
  }

  if (name.length === 0 || name === "") {
    valid = false;
    errors["name"] = "Name must be filled out";
  }

  if (phone.length === 0 || phone === "") {
    valid = false;
    errors["phone"] = "Phone must be filled out";
  }

  if (phone.length > 0 && phone.length !== 10) {
    valid = false;
    errors["phone"] = "Phone must be valid 10 digit number";
  }

  if (zip.length === 0 || zip === "") {
    valid = false;
    errors["zip"] = "Zip must be filled out";
  }

  if (zip.length > 0 && zip.length !== 5) {
    valid = false;
    errors["zip"] = "Zip must be valid 5 digit zip code";
  }

  return { valid, errors };
}
