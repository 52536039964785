import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from "redux-persist";
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../_reducers';
import apiActionMiddleware from '../_middlewares/apiActionMiddleware';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
	rootReducer,
	composeEnhancers(
		applyMiddleware(
			apiActionMiddleware,
			thunkMiddleware
		)
	)
);

export const persistor = persistStore(store)

export default { store, persistor }
