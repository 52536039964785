import callApi from "./api_base";

// **********************
// * Auth For Mechanics *
// **********************

// Mechanic login
export const apiMechanicLogin = async (email, password) => {
  return callApi({
    url: "/mechanic/sign_in",
    method: "POST",
    data: {
      mechanic: {
        email,
        password,
      },
    },
  });
};

// Mechanic registration
export const apiMechanicSignUp = async (mechanic) => {
  return callApi({
    url: "/mechanic/sign_up",
    method: "POST",
    data: { mechanic },
  });
};

// Mechanics submit phone number to get 2FA code
export const apiMechanicSubmitPhoneNumber = (phone) => {
  return callApi({
    url: "/mechanic/auth",
    method: "POST",
    data: {
      mechanic: { phone },
    },
  });
};

// Mechanic login with 2FA code
export const apiMechanicLoginWith2FA = (code) => {
  return callApi({
    url: "/mechanic/sign_in_with_code",
    method: "POST",
    data: {
      mechanic: { code },
    },
  });
};

// Mechanic login with google
export const apiMechanicLoginWithGoogle = (token) => {
  return callApi({
    url: "/mechanic/sign_in_with_google",
    method: "POST",
    data: {
      token,
    },
  });
};

// Mechanic logout
export const apiMechanicLogout = () => {
  return callApi({
    url: "/mechanic/sign_out",
    method: "POST",
  });
};

// Get Mechanic Data
export const apiGetMechanic = () => {
  return callApi({
    url: "/mechanic",
    method: "GET",
  });
};
