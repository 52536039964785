import React, { useCallback, useState } from "react";
import {
  Container,
  Collapse,
  CardHeader,
  IconButton,
  Card,
  Dialog,
  DialogActions,
  Alert,
} from "@material-ui/core";
import IconExpand from "@material-ui/icons/ExpandMore";
import IconShrink from "@material-ui/icons/ExpandLess";
import ViewEstimateModal from "./ViewEstimateModal";
import VehicleForm from "../../Admin/AdminNewCustomer/components/VehicleForm";
import { Close, Edit } from "@material-ui/icons";
import { apiUpdateCarAppointment } from "../../../_apis/mechanics/customers";
import CreditCardModal from "./CreditCardModal";

const JobDetails = ({
  jobInfo,
  appointmentTime,
  job_id,
  expand,
  hideBack = null,
}) => {
  const [expanded, setExpanded] = useState(expand);
  const [reload, setNeedReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [editCarModalOpen, setEditCarModalOpen] = useState(false);
  const [editCardModalOpen, setEditCardModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const {
    member_job,
    customer_name,
    car,
    estimate,
    exact_address,
    appointment_type,
    diagnosis_input,
    arrival_instructions,
    notes_from_office,
    apartment_number,
  } = jobInfo.attributes;

  const handleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const initialCarDetails = {
    year: "",
    make: "",
    model: "",
    engine_size: "",
  };

  const [carDetails, setCarDetails] = useState(initialCarDetails);
  const [tempCarDetails, setTempCarDetails] = useState(initialCarDetails);

  const ifCarDetailsEmpty =
    tempCarDetails.year === "" ||
    tempCarDetails.make === "" ||
    tempCarDetails.model === "" ||
    tempCarDetails.engine_size === "";

  const handleContinueAfterCarSelection = async () => {
    await apiUpdateCarAppointment(car.id, carDetails);
    setTempCarDetails(carDetails);
    setCarDetails(initialCarDetails);
  };

  const handleSuccess = () => {
    setEditCardModalOpen(false);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 3500);
  };

  return (
    <Container>
      {showSuccessAlert && (
        <Alert severity="success" variant="outlined">
          Card updated!
        </Alert>
      )}

      <Card
        style={{
          boxShadow: "none",
          padding: "2px",
        }}
      >
        <CardHeader
          style={{ maxHeight: 80 }}
          action={
            <IconButton onClick={handleExpand}>
              {expanded ? <IconShrink /> : <IconExpand />}
            </IconButton>
          }
          title={`${member_job ? `Member ` : ``}Job #${job_id} Details`}
        />

        <Collapse in={expanded}>
          <Container>
            {member_job && (
              <h3 className="font-tiempos text-xl text-fmc-dark-green">
                Member Job
              </h3>
            )}
            <div className="flex py-2">
              <p className="flex-1 font-bold">Customer: </p>
              <p className="flex-1">{customer_name}</p>
            </div>
            <div className="flex py-2">
              <p className="flex-1 font-bold">Car: </p>

              <p className="flex-1">
                {" "}
                {ifCarDetailsEmpty
                  ? car.one_string
                  : `${tempCarDetails.year} ${tempCarDetails.make} ${tempCarDetails.model} ${tempCarDetails.engine_size}`}
              </p>

              <Edit onClick={() => setEditCarModalOpen(true)} />
            </div>
            {error && (
              <p className="flex-1" style={{ color: "red" }}>
                Error updating car Details, try again later
              </p>
            )}
            {car && car.vin && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">VIN: </p>
                <p className="flex-1">{car.vin}</p>
              </div>
            )}

            {notes_from_office && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">Notes From Office: </p>
                <p className="flex-1">{notes_from_office}</p>
              </div>
            )}

            {appointment_type === "diagnosis" && diagnosis_input && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">Diagnosis Issue: </p>
                <p className="flex-1">{diagnosis_input}</p>
              </div>
            )}

            {estimate && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">Estimate: </p>
                <a
                  onClick={() => {
                    setNeedReload(true);
                    setOpen(true);
                  }}
                  className="flex-1 text-blue-600 cursor-pointer"
                >
                  View Estimate
                </a>

                <ViewEstimateModal
                  open={open}
                  appointment_id={job_id}
                  className=""
                  onClose={setOpen}
                  reload={reload}
                  setReload={setNeedReload}
                  handleTextEstimate={() => {}}
                  textedEstimate={false}
                  afterText="Back To Job"
                  afterTextAction={() => setOpen(false)}
                />
              </div>
            )}
            <div className="flex py-2">
              <p className="flex-1 font-bold">Address: </p>
              <a
                className="flex-1 text-blue-600"
                href={`https://maps.google.com/?q=${exact_address}`}
              >
                <p>{exact_address}</p>
              </a>
            </div>

            {apartment_number && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">Apartment #: </p>
                <p className="flex-1">{apartment_number}</p>
              </div>
            )}

            {arrival_instructions && (
              <div className="flex py-2">
                <p className="flex-1 font-bold">Arrival instructions: </p>
                <p className="flex-1">{arrival_instructions}</p>
              </div>
            )}

            <div className="flex py-2">
              <p className="flex-1 font-bold">Time: </p>
              <p className="flex-1">{appointmentTime}</p>
            </div>
            <div className="flex py-2">
              <p className="flex-1 font-bold">Card on File: </p>

              <a
                className="flex-1 font-bold"
                style={{ color: "#3182ce" }}
                onClick={() => setEditCardModalOpen(true)}
              >
                Edit Credit Card
              </a>
              <Edit onClick={() => setEditCardModalOpen(true)} />
            </div>
          </Container>
        </Collapse>
      </Card>
      {editCarModalOpen && (
        <Dialog
          open={editCarModalOpen}
          onClose={() => setEditCarModalOpen(false)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px 30px 0px 30px",
            }}
          >
            <div>Edit Car</div>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setEditCarModalOpen(false)}
              aria-label="close"
              style={{ paddingTop: -20 }}
            >
              <Close />
            </IconButton>
          </div>
          <DialogActions
            style={{
              justifyContent: "flex-end",
              padding: "0px 30px 30px 30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <VehicleForm
              car={carDetails}
              setCar={setCarDetails}
              nextStep={() => {
                handleContinueAfterCarSelection();
                setEditCarModalOpen(false);
              }}
            />
          </DialogActions>
        </Dialog>
      )}
      <CreditCardModal
        open={editCardModalOpen}
        onClose={() => setEditCardModalOpen(false)}
        jobId={job_id}
        handleSuccess={handleSuccess}
        errors={error}
      />
    </Container>
  );
};

export default JobDetails;
