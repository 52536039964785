export const stateList = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const DUE_IN_TYPES = {
  MILEAGE: "MILEAGE",
  TIME: "TIME",
  BOTH: "TIME & MILEAGE",
  NONE: "NONE",
};

const GOOD_OK_URGENT = [
  {
    label: "Good",
    color: "success",
  },
  {
    label: "OK",
    color: "warning",
  },
  {
    label: "Urgent",
    color: "error",
  },
];

export const step1FieldToLabelMap = {
  lights_on_dash: {
    label: "Any warning lights?",
    options: [
      {
        label: "Yes",
        color: "error",
      },
      {
        label: "No",
        color: "success",
      },
    ],
    moreInfoAnswer: ["Yes"],
    textBox: false,
    dueInType: DUE_IN_TYPES.NONE,
    tags: {
      label: "Select lights",
      tags: [
        "No power",
        "No light",
        "Check engine light",
        "Tire pressure",
        "Battery",
        "Brakes",
        "Air bag",
        "Temperature",
        "ABS",
        "Traction control",
        "Maintenance",
        "Theft",
      ],
    },
  },
};

export const step2FieldToLabelMap = {
  engine: {
    label: "Does the engine run normally?",
    options: [
      {
        label: "Yes",
        color: "success",
      },
      {
        label: "No",
        color: "error",
      },
    ],
    moreInfoAnswer: ["No"],
    textBox: false,
    dueInType: DUE_IN_TYPES.NONE,
    photos: false,
    tags: {
      label: "Select condition",
      tags: ["Rough idle", "No crank, no start", "Crank, no start"],
    },
  },
};

export const step3FieldToLabelMap = {
  exterior_lights: {
    label: "Exterior Lights",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "Issues",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Issues", "OK"],
  },
  interior_lights: {
    label: "Interior Lights",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "Issues",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Issues", "OK"],
  },
  wipers: {
    label: "Wipers + Nozzles",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "Issues",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Issues"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
  },
  windshield: {
    label: "Windshield",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "Cracks",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Cracks"],
  },
  window_glass: {
    label: "Window Glass",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "Cracks",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Cracks"],
  },
};

export const step4FieldToLabelMap = {
  coolant: {
    label: "Coolant",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Drain And Fill Cooling System",
    photos: false,
  },
  brake_fluid: {
    label: "Brake Fluid",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Drain And Fill Brake System",
    photos: false,
  },
  power_steering: {
    label: "Power Steering Fluid",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
    photos: false,
  },
  oil_level: {
    label: "Engine Oil",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Synthetic Oil Change",
    photos: false,
  },
  hoses: {
    label: "Hoses",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
  },
  serpentine_belt: {
    label: "Belts",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
  },
  engine_air_filter: {
    label: "Engine Air Filter",
    textBox: false,
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Engine Air Filter",
  },
  battery: {
    label: "Battery + Connections",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Issues", "OK"],
    tags: {
      label: "Select condition",
      tags: [
        "Cables corroded",
        "Terminals corroded",
        "Leaks",
        "Bad battery",
        "Other",
      ],
    },
  },
};

export const step5FieldToLabelMap = {
  fluid_leaks: {
    label: "Fluid Leaks?",
    options: [
      {
        label: "None",
        color: "success",
      },
      {
        label: "Issues",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
    ],
    moreInfoAnswer: ["Issues", "Urgent"],
    dueInType: DUE_IN_TYPES.TIME,
    tags: {
      label: "Select leaks",
      tags: [
        "Valve cover gasket",
        "Front crank seal",
        "Distributor seal",
        "Timing cover gasket",
        "Oil filter adapter",
        "Oil pan gasket",
        "Rear main seal",
        "Oil cooler lines",
        "Intake manifold gasket",
        "Oil sending unit",
        "Oil filter",
        "Drain plug gasket",
      ],
    },
  },
  front_struts: {
    label: "Front Struts/Shocks",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
  },
  rear_struts: {
    label: "Rear Struts/Shocks",
    options: GOOD_OK_URGENT,
    moreInfoAnswer: ["Urgent", "OK"],
    dueInType: DUE_IN_TYPES.TIME,
  },
};

export const step6FieldToLabelMap = {
  front_pads: {
    label: "Front Pads",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
      {
        label: "N/A",
        color: "warning",
      },
    ],
    moreInfoAnswer: ["OK", "Urgent"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Front Pads & Rotors",
  },
  front_rotors: {
    label: "Front Rotors",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
      {
        label: "N/A",
        color: "warning",
      },
    ],
    moreInfoAnswer: ["OK", "Urgent"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Front Pads & Rotors",
  },
  rear_pads: {
    label: "Rear Pads",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
      {
        label: "N/A",
        color: "warning",
      },
    ],
    moreInfoAnswer: ["OK", "Urgent"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Rear Pads & Rotors",
  },
  rear_rotors: {
    label: "Rear Rotors",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
      {
        label: "N/A",
        color: "warning",
      },
    ],
    moreInfoAnswer: ["OK", "Urgent"],
    dueInType: DUE_IN_TYPES.TIME,
    add_to_quote: true,
    serviceNameToAdd: "Rear Pads & Rotors",
  },
};

export const step7FieldToLabelMap = {
  front_left_tire_tread: {
    label: "Front Left Tread Depth",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
    ],
  },
  front_right_tire_tread: {
    label: "Front Right Tread Depth",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
    ],
  },
  rear_left_tire_tread: {
    label: "Rear Left Tread Depth",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
    ],
  },
  rear_right_tire_tread: {
    label: "Rear Right Tread Depth",
    options: [
      {
        label: "Good",
        color: "success",
      },
      {
        label: "OK",
        color: "warning",
      },
      {
        label: "Urgent",
        color: "error",
      },
    ],
  },
};
