import React from "react";
import { Typography, Box, Container, Grid } from "@material-ui/core";
import {
  LocationOn,
  AssignmentTurnedIn,
  DriveEta,
  ContactMail,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    backgroundColor: "#fff",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    marginBottom: "2px",
  },
}));

function InfoPanel({ step, setStep }) {
  const classes = useStyles();

  const data = [
    { label: "Zip", icon: "LocationOn", step: 1 },
    { label: "Service", icon: "AssignmentTurnedIn", step: 2 },
    { label: "Vehicle", icon: "DriveEta", step: 3 },
    { label: "Contact Info", icon: "ContactMail", step: 4 },
  ];

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Grid container>
          {data.map((item) => (
            <Grid
              item
              xs={6}
              sm={3}
              key={item.step}
              className={classes.item}
              style={{ color: step >= item.step ? "primary" : "#ddd" }}
            >
              {item.icon === "LocationOn" && (
                <LocationOn
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "AssignmentTurnedIn" && (
                <AssignmentTurnedIn
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "DriveEta" && (
                <DriveEta
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              {item.icon === "ContactMail" && (
                <ContactMail
                  fontSize="large"
                  color={step >= item.step ? "primary" : "#ddd"}
                  className={classes.icon}
                />
              )}
              <Typography
                style={{
                  cursor: "pointer",
                  textDecoration: step > item.step ? "underline" : "",
                }}
                onClick={() => {
                  if (step > item.step) {
                    setStep(item.step);
                  }
                }}
                variant="subtitle1"
              >
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default InfoPanel;
