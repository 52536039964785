import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  CircularProgress,
  Tabs,
  Tab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { apiArrived } from "../../../_apis";
import { apiSendEstimateNotification } from "../../../_apis/mechanics/estimates";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import JobDetails from "../components/JobDetails";
import TaskListItem from "./components/TaskListItem";
import clsx from "clsx";
import ChangeTypeModal from "./components/ChangeTypeModal";
import callApi, { callApiV2 } from "../../../_apis/api_base";
import ContactInfo from "../components/ContactInfo";
import { a11yProps, TabPanel } from "../../../_components/Tabs";
import { ExpandMore } from "@material-ui/icons";
import RescheduleModal from "../components/RescheduleModal";
import VehicleHistory from "./components/VehicleHistory";

const JobPage = () => {
  const [taskList, setTaskList] = useState();
  const { job_id } = useParams();
  const [jobInfo, setJobInfo] = useState();
  const [appointmentTime, setAppointmentTime] = useState();
  const [texting, setTextingEstimate] = useState(false);
  const [changeTypeOpen, setChangeTypeOpen] = useState(false);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);
  const [requestArrived, setRequestArrived] = useState(false);
  const [vehicleHistory, setVehicleHistory] = useState();
  const [carfaxHistory, setCarfaxHistory] = useState();
  const [historyLoading, setHistoryLoading] = useState();
  const [value, setValue] = useState(0);
  const [notArrived, setNotArrived] = useState();

  const handleChange = (event, newValue) => {
    if (newValue == 2 && !vehicleHistory) {
      setHistoryLoading(true);

      callApiV2({
        method: "GET",
        url: `/mechanic/appointments/vehicle_history/${job_id}`,
      })
        .then((res) => {
          setHistoryLoading();
          setVehicleHistory(res.goodhood);
          setCarfaxHistory(res.carfax);
        })
        .catch(() => {});
    }

    setValue(newValue);
  };

  useEffect(() => {
    callApi({
      url: `mechanic/appointments/${job_id}/show_and_tasks`,
      method: "GET",
    }).then((res) => {
      setJobInfo({ attributes: res.appointment });

      setTaskList(res.tasks);
      setNotArrived(Object.entries(res.tasks).length === 1);
      setAppointmentTime(
        `${res.appointment.appointment_day} ${res.appointment.time_for_mechanic}`
      );
    });
  }, []);

  return (
    <>
      <Box className="px-4 pt-24 mb-8">
        {!jobInfo && (
          <Box style={{ textAlign: "center", marginTop: "40px" }}>
            <CircularProgress />
          </Box>
        )}
        {jobInfo && (
          <>
            <div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Task List" {...a11yProps(0)} />
                  <Tab label="Details" {...a11yProps(1)} />
                  <Tab label="Vehicle History" {...a11yProps(2)} />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                {taskList && (
                  <Container>
                    <Box
                      className={clsx("px-4", !jobInfo && "pt-32")}
                      style={{ marginBottom: "8rem" }}
                    >
                      {jobInfo.attributes.status !== "completed" && (
                        <>
                          <Box className="my-6">
                            <ContactInfo
                              jobId={job_id}
                              exactAddress={jobInfo.attributes.exact_address}
                              fleet={jobInfo.attributes.fleet_job}
                              showOnWay={notArrived}
                            />
                          </Box>
                        </>
                      )}
                      <Typography
                        className="text-center mt-6 mb-3"
                        variant="h3"
                      >
                        Job #{job_id}
                      </Typography>

                      {notArrived ? (
                        <FmcFullWidthButton
                          variant="contained"
                          onClick={() => {
                            setRequestArrived(true);
                            apiArrived(job_id)
                              .then(() => {
                                const geo = navigator.geolocation;

                                if (geo) {
                                  geo.getCurrentPosition(function (location) {
                                    callApiV2({
                                      url: `/appointments/locations/${job_id}/track_arrived_location`,
                                      method: "PUT",
                                      data: {
                                        arrival_coordinates: [
                                          location.coords.latitude,
                                          location.coords.longitude,
                                        ],
                                      },
                                    })
                                      .then(() => window.location.reload())
                                      .catch(() => window.location.reload());
                                  });

                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 5000);
                                } else {
                                  window.location.reload();
                                }
                              })
                              .catch((error) => {
                                window.location.reload();
                              });
                          }}
                          text={
                            requestArrived
                              ? "Texting your customer"
                              : "I've Arrived"
                          }
                          disabled={requestArrived}
                        />
                      ) : (
                        Object.entries(taskList).map(([key, val]) => (
                          <TaskListItem task={val} index={key} jobId={job_id} />
                        ))
                      )}

                      <Accordion className="my-6">
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>More Actions</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="my-3">
                            {jobInfo.attributes.status !== "completed" && (
                              <>
                                <FmcFullWidthButton
                                  text={
                                    jobInfo.attributes.estimate
                                      ? "Edit Estimate"
                                      : "Create Estimate"
                                  }
                                  onClick={() => {
                                    if (jobInfo.attributes.estimate) {
                                      window.location.href = `/mechanic/estimate/edit/${jobInfo.attributes.estimate.id}`;
                                    } else {
                                      window.location.href = `/mechanic/estimate/new/${job_id}`;
                                    }
                                  }}
                                  variant="outlined"
                                />

                                {jobInfo.attributes.estimate &&
                                  jobInfo.attributes.status !== "completed" && (
                                    <Box className="my-3">
                                      <FmcFullWidthButton
                                        variant="outlined"
                                        disabled={texting}
                                        onClick={() => {
                                          setTextingEstimate(true);

                                          apiSendEstimateNotification(
                                            jobInfo.attributes.estimate.id
                                          )
                                            .then(() => {
                                              setTextingEstimate(false);
                                              alert(
                                                "Customer texted your estimate and they can also view it in the app. Reminder, if you can order the parts from another supplier and deliver to your house (Amazon, Rock Auto, AutoZone, etc.), we can make your job easier."
                                              );
                                            })
                                            .catch(() => {
                                              setTextingEstimate(false);
                                              alert("Error sending.");
                                            });
                                        }}
                                        text="Text Customer Estimate"
                                      />
                                    </Box>
                                  )}
                                <FmcFullWidthButton
                                  variant="outlined"
                                  onClick={() => setRescheduleOpen(true)}
                                  text="Reschedule"
                                />

                                <RescheduleModal
                                  open={rescheduleOpen}
                                  onClose={() => setRescheduleOpen()}
                                  appointment_id={job_id}
                                />

                                {(jobInfo.attributes.appointment_type ===
                                  "repair" ||
                                  jobInfo.attributes.appointment_type ===
                                    "diagnosis") && (
                                  <>
                                    <ChangeTypeModal
                                      open={changeTypeOpen}
                                      onClose={setChangeTypeOpen}
                                      isDiag={
                                        jobInfo.attributes.appointment_type ===
                                        "repair"
                                      }
                                      id={job_id}
                                    />

                                    <FmcFullWidthButton
                                      variant="outlined"
                                      onClick={() => setChangeTypeOpen(true)}
                                      text={
                                        jobInfo.attributes.appointment_type ===
                                        "repair"
                                          ? "Add a Diagnosis"
                                          : "Remove Diagnosis"
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Container>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box className="mt-6 mb-32">
                  <JobDetails
                    jobInfo={jobInfo}
                    appointmentTime={appointmentTime}
                    job_id={job_id}
                    expand
                    hideBack
                  />
                </Box>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <VehicleHistory
                  vehicleHistory={vehicleHistory}
                  historyLoading={historyLoading}
                  carfaxHistory={carfaxHistory}
                />
              </TabPanel>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export { JobPage };
