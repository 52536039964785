import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { apiGetPointsLeaderboard } from "../../../../_apis";

const points_columns = [
  { id: "name", label: "Technician" },
  { id: "week", label: "Current Week" },
  { id: "all_time", label: "All Time" },
];

const Points = () => {
  const [leaderboard, setLeaderboard] = useState();

  useEffect(() => {
    if (!leaderboard) {
      apiGetPointsLeaderboard().then((res) => {
        setLeaderboard(res);
      });
    }
  }, [leaderboard]);

  return (
    <Box className="px-4 pt-8 mb-10 pb-24">
      <Container style={{ width: "95%", margin: "0 auto" }}>
        <Box className="my-6">
          <Typography variant="h4" className="text-center">
            Points Leaderboard
          </Typography>

          <Box>
            {leaderboard && (
              <>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {points_columns.map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leaderboard.map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell key={`technician-${i}`}>
                              {row[0]}
                            </TableCell>
                            <TableCell key={`points-${i}`}>{row[1]}</TableCell>
                            <TableCell key={`all-points-${i}`}>
                              {row[2]}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Points;
