import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import SVG from "react-inlinesvg";
import newLogo from "./../../src/images/goodhood-logos/icon.svg";
import {
  AccountCircle,
  Add,
  Build,
  ExitToApp,
  MessageOutlined,
  Search,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { Badge } from "@material-ui/core";
import { getAllMessages } from "../_apis/mechanics/appointments";
import { apiMechanicLogout } from "../_apis";
import { authTypes } from "../_types";
import { useDispatch } from "react-redux";

const NAV_LINK_DATA = [
  {
    url: "/mechanic/jobs",
    linkName: "Jobs",
    icon: (
      <Build
        className={
          location.pathname.includes("/jobs") ? "mb-2 text-fmc-primary" : "mb-2"
        }
      />
    ),
  },
  {
    url: "/mechanic/search",
    linkName: "Search",
    icon: (
      <Search
        className={
          location.pathname.includes("/search")
            ? "mb-2 text-fmc-primary"
            : "mb-2"
        }
      />
    ),
  },
  {
    url: "/mechanic/add-job",
    linkName: "Add Job",
    icon: (
      <Add
        className={
          location.pathname.includes("mechanic/add-job")
            ? "mb-2 text-fmc-primary"
            : "mb-2"
        }
      />
    ),
  },
  {
    url: "/mechanic/account",
    linkName: "Account",
    icon: (
      <AccountCircle
        className={
          location.pathname.includes("mechanic/account")
            ? "mb-2 text-fmc-primary"
            : "mb-2"
        }
      />
    ),
  },
];

const MechanicHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const history = useHistory();
  const dispatch = useDispatch();

  const [unreadMessagesPresent, setUnreadMessagesPresent] = useState(false);

  const fetchAllMessages = async () => {
    getAllMessages().then((res) => {
      setUnreadMessagesPresent(res.has_unread);
    });
  };

  const handleLogout = () => {
    apiMechanicLogout().then(() => {
      dispatch({ type: authTypes.MECHANIC_LOGOUT });
      window.location.href = "/";
    });
  }; 

  useEffect(() => {
    fetchAllMessages();
  }, []);

  const navLinks = () => {
    return (
      <div style={{ maxHeight: "5rem" }} className="flex flex-row bg-white">
        {NAV_LINK_DATA.map((i) => {
          return (
            <a
              className="flex-1 flex flex-col items-center mx-3 my-4"
              href={i.url}
            >
              {i.icon}
              <p
                className={
                  location.pathname.includes(i.url)
                    ? "flex-1 text-sm font-tiempos text-fmc-primary"
                    : "flex-1 text-sm font-tiempos"
                }
              >
                {i.linkName}
              </p>
            </a>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full border-b bg-white fixed z-50">
      <nav
        style={{ maxHeight: "5rem" }}
        className="flex flex-row justify-between items-center"
      >
        <div style={{ flex: 1 }}>
         
          <button
            className="text-left pl-2"
            onClick={() => history.push("/mechanic/messages")}
          >
            {!unreadMessagesPresent ? (
              <MessageOutlined />
            ) : (
              <Badge color="error" variant="dot">
                <MessageOutlined />
              </Badge>
            )}
          </button>
        </div>

        <a href="/mechanic" className="mx-1" style={{ height: "auto" }}>
          <SVG
            src={newLogo}
            className="w-12 my-6 mx-2"
            style={{ maxHeight: "5rem" }}
          />
        </a>
        <nav
          className={
            isTabletOrMobile
              ? "w-full fixed bottom-0 justify-around bg-white border-2 py-1"
              : null
          }
          style={{ flex: 5 }}
        >
          {navLinks()}
        </nav>

      

        <div style={{ flex: 1, textAlign: "right" }}>
          
          <button
            className="text-right pr-2"
            onClick={handleLogout}
          >
           <ExitToApp />
          </button>
        </div>
      </nav>
    </div>
);


};

export { MechanicHeader };
