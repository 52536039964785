import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  OutlinedInput,
  Container,
  Button,
} from "@material-ui/core";
import { mechanicUpdatePassword } from "../../../../_actions/mechanics";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [submitting, setIsSubmitting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const dispatch = useDispatch();

  const updatePasswordData = (value) => {
    setPassword(value);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    if (password.length < 7) {
      setPasswordError("Must be at least 7 characters");
      setIsSubmitting(false);
      return;
    }

    dispatch(mechanicUpdatePassword(password)).then(() => {
      setResetSuccess(true);
      setPassword("");
      setPasswordError();
      setIsSubmitting(false);
    });
  };

  return (
    <Box className="my-6">
      <Typography variant="h4" className="text-center">
        Reset Password
      </Typography>
      {resetSuccess && (
        <Box className="m-3" display="block" justifyContent="center">
          <Typography className="m-3">Your password has been reset</Typography>
        </Box>
      )}

      <Container style={{ width: "95%", margin: "5px auto" }}>
        <Box display="block" justifyContent="center">
          <OutlinedInput
            type="password"
            placeholder="New Password"
            fullWidth
            required
            value={password}
            onChange={(ev) => updatePasswordData(ev.target.value)}
            className="m-3"
          />
        </Box>
        <Grid className="m-3" item xs={12}>
          {passwordError !== undefined && (
            <p className="my-1 font-inter font-heavy text-sm text-red-600">
              {passwordError}
            </p>
          )}
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleSubmit}
              disabled={submitting}
              loading={submitting}
              variant="contained"
            >
              Reset
            </Button>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default ResetPassword;
