import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Container,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import EstimateForm from "./EstimateForm";
import EstimateReview from "./EstimateReview";
import { apiGetAppointment } from "../../../_apis";
import {
  apiCreateEstimate,
  apiSendEstimateNotification,
} from "../../../_apis/mechanics/estimates";
import ViewEstimateModal from "../components/ViewEstimateModal";
import JobDetails from "../components/JobDetails";
import TextEstimateModal from "./components/TextEstimateModal";

const EstimateNew = () => {
  const { appointment_id } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [estimate_id, setEstimateId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [reload, setNeedReload] = useState(false);
  const [textedEstimate, setTextedEstimate] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [textOpen, setTextOpen] = useState(false);

  const func = async () => {
    if (appointment_id) {
      const rsp = await apiGetAppointment(appointment_id);
      setAppointment({
        ...rsp.data.attributes,
        id: appointment_id,
      });
    }
  };

  useEffect(() => {
    func();
  }, [appointment_id]);

  const serviceForLater = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get("service_for_later") === "true";
  }, [location]);

  const handleSubmit = useCallback((data) => {
    handleFinalSubmit(data, true);
  }, []);

  const handleFinalSubmit = useCallback(
    (data, needsReview = false) => {
      setIsSaving(true);
      apiCreateEstimate({
        ...data,
        appointment_id: appointment_id,
        service_for_later: serviceForLater,
      })
        .then((res) => {
          setEstimateId(res.data.id);

          if (needsReview) {
            setShowReview(true);
            setNeedReload(true);
          } else {
            setOpen(true);
            setNeedReload(true);
          }
        })
        .catch(() => {
          alert("Error creating an estimate, contact the office.");
          setIsSaving(false);
        });
    },
    [appointment_id, serviceForLater, appointment]
  );

  const handleTextCustomer = useCallback(() => {
    apiSendEstimateNotification(estimate_id, serviceForLater)
      .then(() => {
        setTextedEstimate(true);

        setTimeout(() => {
          setTextedEstimate(false);
        }, 3000);
      })
      .catch(() => alert("Error sending."));
  }, [estimate_id]);

  if (!appointment) {
    return (
      <Box pt={40} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box pt={14} pb={15}>
      <Container>
        {appointment && (
          <JobDetails
            jobInfo={{ attributes: appointment }}
            appointmentTime={`${appointment.appointment_day} ${appointment.time_for_mechanic}`}
            job_id={appointment.id}
            expand={false}
          />
        )}

        <Box mb={3}>
          <Typography variant="h4" align="center">
            {showReview ? "Review Estimate" : "Create Estimate"}
          </Typography>
        </Box>
      </Container>
      {showReview ? (
        <EstimateReview
          onNext={() => setTextOpen(true)}
          appointment={appointment}
          reload={reload}
          setReload={setNeedReload}
          onBack={() => {
            func();
            setShowReview(false);
          }}
        />
      ) : (
        <EstimateForm
          initialData={{}}
          appointment={appointment}
          serviceForLater={serviceForLater}
          isSubmitting={isSaving}
          onSubmit={handleSubmit}
        />
      )}
      <ViewEstimateModal
        open={open}
        appointment_id={appointment.id}
        className=""
        onClose={setOpen}
        reload={reload}
        setReload={setNeedReload}
        handleTextEstimate={handleTextCustomer}
        textedEstimate={textedEstimate}
        afterText={serviceForLater ? "Go To Job" : "Back To Job"}
        afterTextAction={() =>
          (window.location.href = `/mechanic/job/${appointment.id}`)
        }
        shouldTextEstimate={true}
      />

      <TextEstimateModal
        open={textOpen}
        appointment_id={appointment.id}
        estimate_id={estimate_id}
        onClose={() => setTextedEstimate(false)}
        handleTextEstimate={handleTextCustomer}
        afterText="Back To Edit Estimate"
        afterTextAction={() => setTextOpen(false)}
      />
    </Box>
  );
};

export default EstimateNew;
