import React from 'react';
import { styled } from '@material-ui/styles';
import { Button, CircularProgress } from '@material-ui/core';

const StyledButton = styled(Button)(({theme}) => ({
  root: {
    backgroundColor: '#7C79D1',
    color: 'white',
    borderRadius: 50,
    minWidth: 100,
    '&:hover, &:active, &:focus': {
      backgroundColor: '#A39CEF',
      color: 'white',
      boxShadow: '0px 5px 3px 0px rgba(0, 0, 0, 0.2)'
    }
  }
}));

const PrimaryButton = ({ loading, children, ...rest }) => (
  <StyledButton {...rest}>
    {loading
      ? <CircularProgress size={20} />
      : children}
  </StyledButton>
);

export default PrimaryButton;
