import React from "react";
import { Box, Container, Divider, Typography } from "@material-ui/core";

const Profile = ({ mechanic }) => {
  return (
    <Box className="">
      <Typography variant="h4" className="text-center">
        Profile
      </Typography>

      <Container style={{ width: "95%", margin: "5px auto" }}>
        <div className="flex py-2">
          <p className="flex-1 font-bold">Name: </p>
          <p className={"flex-1 text-right"}>{mechanic.name}</p>
        </div>

        <div className="flex py-2">
          <p className="flex-1 font-bold">Email: </p>
          <p
            style={{ maxWidth: "200px", overflowX: "scroll" }}
            className={"flex-1 text-right"}
          >
            {mechanic.email}
          </p>
        </div>

        <div className="flex py-2">
          <p className="flex-1 font-bold">Phone: </p>
          <p
            style={{ maxWidth: "200px", overflowX: "scroll" }}
            className={"flex-1 text-right"}
          >
            {mechanic.phone}
          </p>
        </div>
      </Container>
    </Box>
  );
};

export default Profile;
