import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Step0 } from "./screens/step-0";
import { Step1And2 } from "./screens/step-1-and-2";
import { Step3 } from "./screens/step-3";
import { Step4 } from "./screens/step-4";
import { Step5 } from "./screens/step-5";
import { Step6 } from "./screens/step-6";
import { Final } from "./screens/final";
import { Alert } from "@material-ui/core";
import colors from "../../../../src/constants/colors";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import callApi from "../../../_apis/api_base";
import { buildFormData } from "../../../_apis/utils";
import { Step7 } from "./screens/step-7";

const useStyles = makeStyles({
  root: {
    paddingTop: 100,
    textAlign: "center",
    paddingRight: 30,
    paddingLeft: 30,
    marginBottom: 120,
  },
  image: {
    width: 180,
    height: 180,
    display: "inline",
    marginBottom: 20,
  },
  heading: {
    fontSize: 18,
    fontFamily: "Inter",
    fontWeight: 700,
  },
  buttonHeading: {
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 700,
    color: "white",
  },
  buttonSubText: {
    fontSize: 16,
    fontFamily: "Tiempos",
    fontWeight: 500,
    color: "white",
  },
  subText: {
    fontFamily: "Tiempos",
    fontWeight: 500,
    fontSize: 16,
  },
  button: {
    padding: 15,
    backgroundColor: colors.buttonPrimary,
    borderRadius: 10,
    marginTop: 100,
    paddingLeft: 50,
    paddingRight: 50,
  },
  ghostButton: {
    padding: 20,
    marginTop: 2,
    color: colors.primary,
  },
  textField: {
    marginTop: 20,
    marginBottom: 20,
    width: "90%",
  },
  width90: {
    width: "90%",
  },
  questionGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingBottom: 10,
    width: "90%",
    margin: "5px auto",
  },
  radioGroup: {
    display: "flex",
  },
  radioButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  itemContainer: {
    alignContent: "center",
    marginTop: 20,
  },
  outlineBtn: {
    fontSize: 16,
    fontFamily: "Inter",
    fontWeight: 700,
    textTransform: "none",
    paddingTop: 10,
    paddingBottom: 10,
    color: colors.buttonPrimary,
  },
  date: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 40,
  },
});

const InspectionReport = () => {
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const [inspection, setInspection] = useState({
    appointment_id: id,
    status: "in_progress",
    report_type: query.get("additional_car") ? "additional_car" : "default",
    general_notes: "",
    car_turn: "",
    car_start: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [error, setError] = useState();
  const [isSubmitting, setIsSubmitting] = useState();
  const jobs = useSelector((state) => state.mechanic_appointments.jobs);
  const jobInfo = jobs.filter((job) => job.id === id)[0];
  const [job, setJob] = useState(jobInfo);
  const [saved, setSaved] = useState("");

  const [index, setIndex] = useState(0);

  const startTimerAuto = (data) => {
    callApi({
      url: `/inspection_reports/${id}/start`,
      method: "POST",
      data,
    }).then(() => setIndex(1));
  };

  useEffect(() => {
    setIsLoading(true);
    setVehicle(jobInfo?.attributes?.car?.one_string);

    callApi({
      url: `/inspection_reports/${id}?report_type=${
        query.get("additional_car") ? "additional_car" : "default"
      }`,
      method: "GET",
    }).then((res) => {
      if (!res.errors) {
        setInspection({ ...res });

        setIndex(res.index || 1);
      }
    });
  }, []);

  useEffect(() => {
    if (job && job.attributes.car.vin && index == 0) {
      startTimerAuto({
        inspection_report: {
          report_type: "default",
        },
      });
    }
  }, [job]);

  useEffect(() => {
    if (query.get("additional_car") && index == 0) {
      startTimerAuto({
        inspection_report: {
          report_type: "additional_car",
          car_id: query.get("car_id"),
        },
      });
    }
  }, [query]);

  const styles = useStyles();

  const updateInspectionData = (field, value) => {
    setInspection({
      ...inspection,
      [field]: value,
    });
  };

  const handleNext = () => {
    try {
      handleSave();
    } catch (error) {}
    setIndex(index + 1);
  };

  const goBack = () => setIndex(index - 1);

  const hideSaved = () => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  };

  const hideError = () => {
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSave = (status = { status: "in_progress" }) => {
    if (status.status === "completed") {
      setIsSubmitting(true);
    }

    let data = inspection;

    data.status = status.status;

    data.index = index + 1;

    callApi({
      url: `/inspection_reports/${id}`,
      method: "PUT",
      data: buildFormData(data, "inspection_report"),
    }).then((res) => {
      setInspection({ ...res });
      setSaved(true);

      setIsSubmitting(false);

      if (status.status === "completed") {
        setIndex(7);
      } else {
        hideSaved();
      }
    });
  };

  const steps = [
    <Step0 styles={styles} handleNext={handleNext} vehicle={vehicle} id={id} />,

    <Step1And2
      styles={styles}
      handleNext={handleNext}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
    />,

    <Step3
      styles={styles}
      handleNext={handleNext}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
    />,

    <Step4
      styles={styles}
      handleNext={handleNext}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
    />,

    <Step5
      styles={styles}
      handleNext={handleNext}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
    />,

    <Step6
      styles={styles}
      handleNext={handleNext}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
    />,

    <Step7
      styles={styles}
      onUpdate={updateInspectionData}
      inspection={inspection}
      goBack={goBack}
      submitting={isSubmitting}
      handleSave={handleSave}
    />,

    <Final styles={styles} id={id} inspection={inspection} goBack={goBack} />,
  ];

  return (
    <div className={styles.root}>
      {steps[index]}
      {saved && (
        <Alert severity="success">The inspection has been saved </Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
};

export { InspectionReport };
