import React from "react";
import { Box, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import Profile from "./Profile";
import ResetPassword from "./ResetPassword";
import Payroll from "./Payroll";

const AccountPage = () => {
  const mechanic = useSelector((state) => state.auth.user_data);

  return (
    <Box className="px-4 pt-8 mb-10 pb-24">
      {mechanic && <Profile mechanic={mechanic} />}

      <Divider />

      {mechanic && <Payroll />}

      <Divider />

      {mechanic && <ResetPassword />}
    </Box>
  );
};

export default AccountPage;
