import callApi from '../api_base';

export const apiMechanicUpdatePassword = async (password) => {
  return callApi({
    url: '/mechanic/password_reset',
    method: 'POST',
    data: {
      mechanic: { password }
    }
  });
};
