import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/lab";
import { SELECT_TIME_OPTIONS } from "../../utility";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { apiMechanicCreateJob } from "../../../../_apis/mechanics/appointments";
import NewJobModal from "./NewJobModal";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function ScheduleNewJob({ job, setJob }) {
  const [showJobModal, setShowJobModal] = useState(false);
  const [newJobId, setNewJobId] = useState();
  const [exactAddress, setExactAddress] = useState();
  const [errors, setErrors] = useState();

  const submitJob = () => {
    const jobParams = {
      appointment: {
        appointment_day: job.appointment_day.format("YYYY-MM-DD"),
        appointment_time: job.appointment_time,
        appointment_type: job.appointment_type,
        address: job.address,
        name: job.name,
        email: job.email,
        phone: job.phone,
        user_id: job.user_id,
        level: job.level,
        car_id: job.car_id,
        exact_address: exactAddress?.label,
      },
    };

    if (job.appointment_type === "diagnosis") {
      jobParams.appointment.appointment_type = "diagnosis";
      jobParams.appointment.diagnosis_input = job.diagnosis_input;
    } else {
      jobParams.appointment.appointment_type = "repair";
      jobParams.appointment.services = [job.services];
    }

    apiMechanicCreateJob(jobParams).then((res) => {
      if (!res.errors) {
        setNewJobId(res.data.id);
        setShowJobModal(true);
      } else {
        setErrors(res.errors.join(", "));
      }
    });
  };

  return (
    <Box className="my-6">
      <Typography variant="h4" className="my-3">
        Schedule
      </Typography>

      <div className="my-3">
        <DatePicker
          label="Select Date"
          value={job.appointment_day}
          onChange={(newValue) => {
            setJob({
              ...job,
              appointment_day: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField style={{ minWidth: "100%" }} {...params} />
          )}
        />
      </div>
      <div className="my-3">
        <FormControl fullWidth>
          <InputLabel id="time-select-label">Select Time</InputLabel>
          <Select
            id="time-select-label"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setJob({
                ...job,
                appointment_time: e.target.value,
              })
            }
          >
            {SELECT_TIME_OPTIONS.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="my-3">
        <GooglePlacesAutocomplete
          selectProps={{
            value: exactAddress,
            onChange: setExactAddress,
            placeholder: "Address",
          }}
          apiKey="AIzaSyAz4WaDB8lmLBWKuPF0RGJszY2Hf7tcAhk"
          debounce={300}
        />
      </div>

      {errors && (
        <div className="my-3">
          <Typography color="error">{errors}</Typography>
        </div>
      )}

      <FmcFullWidthButton
        text="Add Job"
        onClick={() => submitJob()}
        variant="contained"
        disabled={!job.appointment_day || !job.appointment_time}
      />

      <NewJobModal
        open={showJobModal}
        onClose={setShowJobModal}
        name={job.name}
        id={newJobId}
      />
    </Box>
  );
}

export { ScheduleNewJob };
