import React from 'react';
import { useMediaQuery } from 'react-responsive';

const MechanicAuthLayout = ({children}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={`sm:flex mechanic-authorization-background ${isTabletOrMobile ? "bg-fmc-dirty-white" : "" }`}>
      {isTabletOrMobile && <div className="pt-10" />}
      {children}
    </div>
  );
};

export default MechanicAuthLayout;
