import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import FmcFullWidthButton from "./FmcFullWidthButton";
import { DatePicker } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import { callApiV2 } from "../../../_apis/api_base";
import moment from "moment";

export default function RescheduleModal({ open, onClose, appointment_id }) {
  const [scheduleReason, setScheduleReason] = useState();
  const [partsDate, setPartsDate] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [requesting, setRequesting] = useState();
  const [estimatedTimePossible, setEstimatedTimePossible] = useState();

  const handleOptionChange = (event) => {
    setEstimatedTimePossible(event.target.value);
  };

  const handleSubmit = () => {
    setRequesting(true);

    callApiV2({
      url: "/reschedule_requests",
      method: "POST",
      data: {
        reschedule_request: {
          appointment_id,
          reason: scheduleReason,
          parts_not_avail_at_suppliers: estimatedTimePossible === "Yes",
          parts_avail_date: partsDate
            ? moment(partsDate).format("YYYY-MM-DD")
            : null,
        },
      },
    })
      .then((res) => {
        if (res.errors) {
          setError(res.errors.join(", "));

          setTimeout(() => {
            setError();
          }, 4000);
        } else {
          setSuccess(true);
        }
      })
      .finally(() => setRequesting());
  };

  return (
    <Dialog
      styles={{ padding: "10px" }}
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <IconButton
          style={{
            padding: "2px",
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>{" "}
        Job #{appointment_id} Reschedule
      </DialogTitle>

      <DialogContent>
        <Box className="my-6">
          <p>What's the reason for this reschedule?</p>
          <Select
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setScheduleReason(e.target.value);
            }}
          >
            {[
              "Customer wants to reschedule",
              "Bad weather",
              "I have an emergency",
              "Parts availability",
            ].map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {scheduleReason === "Parts availability" && (
          <>
            <Box className="my-6">
              <Typography>
                Are the parts available at typical suppliers?
              </Typography>

              <RadioGroup
                row
                value={estimatedTimePossible}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio color="primary" />}
                  label="No"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Box>

            {estimatedTimePossible === "Yes" && (
              <Box className="my-6">
                <p>What's an estimated date that parts will be available?</p>
                <div className="my-6">
                  <DatePicker
                    label="Select Date"
                    value={partsDate}
                    onChange={(newValue) => {
                      setPartsDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField style={{ minWidth: "100%" }} {...params} />
                    )}
                  />
                </div>
              </Box>
            )}
          </>
        )}

        <Box style={{ margin: "10px 0" }}>
          <FmcFullWidthButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            text="Reschedule"
            disabled={
              requesting ||
              !scheduleReason ||
              (scheduleReason === "Parts availability" &&
                estimatedTimePossible === "Yes" &&
                !partsDate)
            }
          />

          {success && (
            <Typography style={{ margin: "10px 0" }}>
              Reschedule request submitted
            </Typography>
          )}

          {error && (
            <Typography color="error" style={{ margin: "10px 0" }}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
