import React from "react";
import {
  OutlinedInput,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  Dialog,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from "moment";
import CreditCard from "./CreditCard";

const BookModal = ({
  open,
  onClose,
  jobId,
  currentDay,
  appointment_time,
  exactAddress,
  setExactAddress,
  aptNumber,
  setAptNumber,
  arrivalInstructions,
  setArrivalInstructions,
  member_plan,
  checkedPlan,
  setCheckedPlan,
  hasCC,
  setHasCC,
  fleet,
  success,
  error,
  onSubmit,
}) => {
  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="md">
      <DialogContent style={{ fontSize: "18px" }} dividers>
        <Card>
          <CardHeader title={`Book Job ${jobId}`} />

          <CardContent>
            <Typography>{moment(currentDay).format("YYYY-MM-DD")}</Typography>
            <Typography>{appointment_time}</Typography>

            <Box className="my-3">
              <Box className="my-3">
                <Typography>
                  Address:{" "}
                  {exactAddress && Object.keys(exactAddress).includes("label")
                    ? exactAddress.label
                    : exactAddress}
                </Typography>
              </Box>

              <GooglePlacesAutocomplete
                selectProps={{
                  value: exactAddress,
                  onChange: setExactAddress,
                  placeholder: "Edit address",
                }}
                apiKey="AIzaSyAz4WaDB8lmLBWKuPF0RGJszY2Hf7tcAhk"
                debounce={300}
              />

              <Box className="my-3">
                <OutlinedInput
                  value={aptNumber}
                  onChange={(e) => setAptNumber(e.target.value)}
                  placeholder="Apartment #"
                  fullWidth
                />
              </Box>

              <Box className="my-3">
                <OutlinedInput
                  value={arrivalInstructions}
                  onChange={(e) => setArrivalInstructions(e.target.value)}
                  placeholder="Arrival Instructions"
                  fullWidth
                />
              </Box>
            </Box>

            {member_plan && (
              <Box className="my-3">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setCheckedPlan(checkedPlan === 180 ? null : 180)
                        }
                        checked={checkedPlan === 180}
                      />
                    }
                    label="Setup membership for tech to charge on-site ($180)"
                  />
                </FormGroup>
              </Box>
            )}

            {!hasCC && (
              <CreditCard
                appointment_id={jobId}
                text={`Add Credit Card${fleet ? " (optional)" : ""}`}
                setCC={setHasCC}
                expanded={true}
              />
            )}
          </CardContent>
        </Card>
      </DialogContent>

      {success && (
        <Box style={{ margin: "10px" }}>
          <h3
            style={{
              fontWeight: 600,
              fontSize: "16px",
              color: "#24CF93",
            }}
          >
            Job booked and mechanic notified!
          </h3>
        </Box>
      )}
      <DialogActions>
        {success ? (
          <Button
            variant="contained"
            disabled={!exactAddress || (!fleet && !hasCC)}
            onClick={() =>
              (window.location.href = `/admin/appointments/${jobId}`)
            }
          >
            Go to Job
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={!exactAddress || (!fleet && !hasCC)}
            onClick={onSubmit}
          >
            Book Job
          </Button>
        )}

        {error && <Typography color="error">{error}</Typography>}
      </DialogActions>
    </Dialog>
  );
};

export default BookModal;
