import moment from "moment";

function getTimes() {
  let windows = [];
  for (let hour = 7; hour <= 18; hour++) {
    let startTime = moment({ hour });
    let endTime = moment({ hour }).add(3, "hours");
    let timeWindow = `${startTime.format("hh:mma")} - ${endTime.format(
      "hh:mma"
    )}`;

    windows.push(timeWindow);
  }
  return windows;
}
export const SELECT_TIME_OPTIONS = getTimes();
