import callApi from "../api_base";

export const apiSearchJobCodes = async (search_term) => {
  return callApi({
    url: "/epicor_searches",
    method: "POST",
    data: {
      epicor_search: { search_term },
    },
  });
};

export const apiPartsLookup = async (
  appointment_id,
  job_codes,
  supplier,
  qry_string,
  for_mechanic
) => {
  return callApi({
    url: "/epicor_searches/lookup",
    method: "POST",
    data: {
      job_codes,
      qry_string,
      supplier,
      id: appointment_id,
      for_mechanic,
    },
  });
};

export const apiPartsListLookup = async (searchTerm, appointment_id) => {
  return callApi({
    url: "/epicor_searches/parts_list",
    method: "POST",
    data: {
      search_term: searchTerm,
      appointment_id,
    },
  });
};

export const apiPartOnlyLookup = async (
  code,
  text,
  appointment_id,
  query_string
) => {
  return callApi({
    url: "/epicor_searches/lookup_part",
    method: "POST",
    data: {
      appointment_id,
      code,
      text,
      query_string,
    },
  });
};
