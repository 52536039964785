import React, { useState } from "react";
import { Typography, Box, TextField } from "@material-ui/core";
import { step1FieldToLabelMap, step2FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import ImageList from "../../components/ImageList";

const Step1And2 = ({ styles, handleNext, onUpdate, inspection, goBack }) => {
  const [step2Fields, setStep2Fields] = useState({
    engine: inspection.engine || "",
    lights_on_dash: inspection.lights_on_dash || "",
    lights_on_dash_likely_approve_service:
      inspection.lights_on_dash_likely_approve_service || "",
    lights_on_dash_tags: inspection.lights_on_dash_tags || [],
    lights_on_dash_text_describe: inspection.lights_on_dash_text_describe || "",
  });
  const [odometer, setOdometer] = useState(inspection.odometer || "");
  const [general_notes, setGeneralNotes] = useState(
    inspection.general_notes || ""
  );

  const updateField = (field, value) => {
    setStep2Fields({
      ...step2Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  const done =
    odometer !== "" &&
    parseInt(odometer) > 5 &&
    step2Fields.engine !== "" &&
    step2Fields.lights_on_dash !== "" &&
    inspection.odometer_picture !== []

  return (
    <Box>
      <Typography className={styles.heading}>Interior</Typography>

      <TextField
        value={odometer}
        onChange={(event) => {
          onUpdate("odometer", event.target.value);
          setOdometer(event.target.value);
        }}
        label="Odometer reading"
        variant="outlined"
        className={styles.textField}
        type="number"
      />
      {odometer && parseInt(odometer) < 5 && (
        <Typography color="error">
          Please enter an accurate odometer reading
        </Typography>
      )}

      {inspection.odometer_picture && inspection.odometer_picture[0]?.url ? (
        <></>
      ) : (
        <>
          <Typography className="text-sm italic" color="error">
            Upload picture of dash w/ odometer and lights (Required)
          </Typography>
          <ImageList
            value={inspection.odometer_picture || []}
            onChange={(d) => onUpdate("odometer_picture", d)}
            required={true}
            error={false}
          />
        </>
      )}

      {Object.keys(step1FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step1FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step2Fields}
          />
        );
      })}

      {Object.keys(step2FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step2FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step2Fields}
          />
        );
      })}

      <TextField
        value={general_notes}
        onChange={(event) => {
          onUpdate("general_notes", event.target.value);
          setGeneralNotes(event.target.value);
        }}
        label="Any general concerns? (optional)"
        variant="outlined"
        className={styles.textField}
        type="text"
      />

      <FmcFullWidthButton
        text="Save & Next"
        onClick={handleNext}
        variant="contained"
        disabled={!done}
      />

      <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
    </Box>
  );
};

export { Step1And2 };
