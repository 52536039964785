import { authTypes } from "../_types";
import { USER_TYPES } from "../_constants/constants";

const initialState = {
  auth_token: null,
  user_type: null,
  user_data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.MECHANIC_REGISTRATION:
    case authTypes.MECHANIC_LOGIN_WITH_2FA:
    case authTypes.MECHANIC_LOGIN:
    case authTypes.MECHANIC_LOGIN_WITH_GOOGLE: {
      const { payload } = action;
      return {
        ...state,
        auth_token: payload.auth_token,
        user_type: USER_TYPES.MECHANIC,
        user_data: payload.mechanic,
        rate: payload.rate,
      };
    }
    case authTypes.GET_MECHANIC_DATA: {
      return {
        ...state,
        user_data: action.payload.data.attributes,
      };
    }
    case authTypes.MECHANIC_LOGOUT: {
      return {
        auth_token: null,
        user_type: null,
        user_data: null,
      };
    }
    default:
      return state;
  }
};
