import React, { useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  List,
  ListItem,
  TableHead,
} from "@material-ui/core";
import {
  CheckBoxRounded,
  DirectionsCarRounded,
  ExpandMore,
  Receipt,
} from "@material-ui/icons";
import ViewInspectionModal from "./ViewInspectionModal";

const VehicleHistory = ({ vehicleHistory, historyLoading, carfaxHistory }) => {
  const [viewInspection, setViewInspection] = useState();

  if (historyLoading) {
    return <CircularProgress />;
  }

  return (
    <Box className="my-3">
      {carfaxHistory && (
        <Accordion className="my-3">
          <AccordionSummary expandIcon={<ExpandMore />}>
            <strong>Carfax</strong>
          </AccordionSummary>
          <AccordionDetails className="my-3">
            <Box style={{ maxHeight: 350, overflow: "auto" }}>
              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ backgroundColor: "#f2f2f2" }}>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Date
                        </Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#f2f2f2" }}>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Odometer
                        </Typography>
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#f2f2f2" }}>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Services
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {carfaxHistory.serviceHistory?.displayRecords
                      .reverse()
                      .map((record, index) => (
                        <TableRow key={index}>
                          <TableCell>{record.displayDate}</TableCell>
                          <TableCell>
                            {record.odometer ? `${record.odometer} miles` : "-"}
                          </TableCell>
                          <TableCell>
                            <List>
                              {record.text.map((text, idx) => (
                                <ListItem key={idx}>{text}</ListItem>
                              ))}
                            </List>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion className="my-3">
        <AccordionSummary expandIcon={<ExpandMore />}>
          <strong>Goodhood</strong>
        </AccordionSummary>
        <AccordionDetails className="mb-24">
          {Object.keys(vehicleHistory).length == 0 ||
          vehicleHistory.length == 0 ? (
            <Typography>This is our first visit to this vehicle.</Typography>
          ) : (
            vehicleHistory.map((i) => {
              return (
                <Accordion className="my-3">
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>{i.date}</strong>
                  </AccordionSummary>
                  <AccordionDetails className="mb-24">
                    <Box className="my-3">
                      <Box className="p-3">
                        <Typography variant="h4" className="my-3">
                          <DirectionsCarRounded color="success" /> Vehicle
                        </Typography>

                        {i.car.vin && (
                          <>
                            <Typography className="text-lg mt-2">
                              VIN
                            </Typography>
                            <p className="p-3">{i.car.vin}</p>
                          </>
                        )}

                        {i.car.year &&
                          i.car.make &&
                          i.car.model &&
                          i.car.engine_size && (
                            <>
                              <Typography className="text-lg mt-2">
                                YMME
                              </Typography>
                              <p className="p-3">
                                {i.car.year} {i.car.make} {i.car.model}
                              </p>

                              <p className="p-3">{i.car.engine_size}</p>
                            </>
                          )}

                        {i.mileage_at_service && (
                          <>
                            <Typography className="text-lg mt-2">
                              Mileage at Service
                            </Typography>
                            <p className="p-3">{i.mileage_at_service}</p>
                          </>
                        )}

                        {i.inspection_url && (
                          <>
                            <Typography className="text-lg mt-2">
                              Inspection
                            </Typography>

                            <Button onClick={() => setViewInspection(true)}>
                              Open
                            </Button>

                            <ViewInspectionModal
                              open={viewInspection}
                              onClose={setViewInspection}
                              url={i.inspection_url}
                            />
                          </>
                        )}
                      </Box>

                      {i.diag.diag_done && (
                        <Box className="p-3">
                          <Typography variant="h4" className="my-3">
                            <CheckBoxRounded color="success" /> Diagnostic
                            Performed
                          </Typography>

                          <Typography className="text-lg mt-2">
                            Customer Concern
                          </Typography>
                          <p className="p-3">{i.diag.customer_concern}</p>

                          <Typography className="text-lg mt-2">
                            Symptom
                          </Typography>
                          <p className="p-3">{i.diag.symptom}</p>

                          <Typography className="text-lg mt-2">
                            Result
                          </Typography>
                          <p className="p-3">{i.diag.result}</p>
                        </Box>
                      )}

                      {(i.repair.services_done !== "" ||
                        i.repair.services_quoted !== "") && (
                        <Box className="p-3">
                          {i.repair.services_done !== "" && (
                            <>
                              <Typography variant="h4" className="my-3">
                                <CheckBoxRounded color="success" /> Repairs
                                Performed
                              </Typography>

                              <p className="p-3">{i.repair.services_done}</p>
                            </>
                          )}

                          {i.repair.services_quoted !== "" && (
                            <>
                              <Typography variant="h4" className="my-3">
                                <Receipt color="warning" /> Repairs Quoted
                              </Typography>

                              <p className="p-3">{i.repair.services_quoted}</p>
                            </>
                          )}
                        </Box>
                      )}

                      {i.warranty.warrantied_item !== "" && (
                        <Box className="p-3">
                          <Typography variant="h4" className="my-3">
                            <CheckBoxRounded color="success" /> Warranty
                            Performed
                          </Typography>

                          <p className="p-3">{i.warranty.warrantied_item}</p>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default VehicleHistory;
