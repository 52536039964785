import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { step5FieldToLabelMap } from "../utility";
import { InspectionQuestion } from "../components/InspectionQuestion";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";

const Step5 = ({ styles, handleNext, onUpdate, inspection, goBack }) => {
  const [step5Fields, setStep5Fields] = useState({
    fluid_leaks: inspection.fluid_leaks || "",
    front_struts: inspection.front_struts || "",
    front_struts_text_describe: inspection.front_struts_text_describe || "",
    rear_struts: inspection.rear_struts || "",
    rear_struts_text_describe: inspection.rear_struts_text_describe || "",
    fluid_leaks_tags: inspection.fluid_leaks_tags || [],
    fluid_leaks_text_describe: inspection.fluid_leaks_text_describe || "",
    fluid_leaks_service_due: inspection.fluid_leaks_service_due || "",
    fluid_leaks_likely_approve_service:
      inspection.fluid_leaks_likely_approve_service || "",
    tires_off_photos: inspection.tires_off_photos || [],
  });

  const done =
    step5Fields.fluid_leaks !== "" &&
    step5Fields.front_struts !== "" &&
    step5Fields.rear_struts !== "";

  const updateField = (field, value) => {
    setStep5Fields({
      ...step5Fields,
      [field]: value,
    });

    onUpdate(field, value);
  };

  return (
    <Box>
      <Typography className={styles.heading}>Underneath The Car</Typography>

      {Object.keys(step5FieldToLabelMap).map((field) => {
        return (
          <InspectionQuestion
            labelMap={step5FieldToLabelMap}
            styles={styles}
            onUpdate={updateField}
            field={field}
            fieldState={step5Fields}
          />
        );
      })}

      <FmcFullWidthButton
        text="Save & Next"
        onClick={handleNext}
        variant="contained"
        disabled={!done}
      />

      <FmcFullWidthButton text="Back" onClick={goBack} variant="outlined" />
    </Box>
  );
};

export { Step5 };
