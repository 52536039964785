import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/styles";

const StyledFormControl = withStyles({
  root: {
    border: 'solid 1px #E3E3E3',
    padding: '18px 22px'
  }
})(FormControl);

const FmcFormControl = ({fullWidth, ...props}) => {
  return (
    <StyledFormControl
      fullWidth={fullWidth || true}
      {...props}
    />
  )
};

export default FmcFormControl;
