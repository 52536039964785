import callApi from "../api_base";
import { buildFormData } from "../utils";

export const apiUpdateMechanicNote = async (id, note) => {
  return callApi({
    url: `/mechanic/notes/${id}`,
    method: "PUT",
    data: buildFormData(note, "note"),
  });
};

export const apiCreateMechanicWarrantyNote = async (note) => {
  return callApi({
    url: "mechanic/warranty_notes",
    method: "POST",
    data: buildFormData(note, "warranty_note"),
  });
};

export const apiUpdateMechanicWarrantyNote = async (note) => {
  return callApi({
    url: "mechanic/warranty_notes/update_or_create",
    method: "POST",
    data: buildFormData(note, "warranty_note"),
  });
};
