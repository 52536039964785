import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { apiGetPayrolls } from "../../../../_apis";

const payroll_columns = [
  { id: "date", label: "Payroll Date" },
  { id: "summary", label: "PDF" },
];

const Payroll = () => {
  const [payrolls, setPayrolls] = useState();

  useEffect(() => {
    if (!payrolls) {
      apiGetPayrolls().then((res) => {
        setPayrolls(res.data);
      });
    }
  }, [payrolls]);

  return payrolls && payrolls.length > 0 ? (
    <Box className="my-6">
      <Typography variant="h4" className="text-center">
        Payroll Files
      </Typography>

      <Container
        style={{
          width: "95%",
          margin: "5px auto",
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {payroll_columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payrolls.map((pay, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    <TableCell key={`technician-${i}`}>
                      {pay.attributes.payroll_date}
                    </TableCell>
                    <TableCell key={`memberships-${i}`}>
                      <a
                        href={pay.attributes.payroll_pdf_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  ) : (
    <></>
  );
};

export default Payroll;
