import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { WeekCalender } from "./components/WeekCalendar";
import { DateSelector } from "./components/DateSelector";
import { TodayJobCard } from "./components/TodayJobCard";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FMCTheme } from "../../../_constants/theme";
import { getMechanicJobs } from "../../../_actions/mechanics";
import AvailableToday from "../components/AvailableToday";
import { apiMechanicGetSameDayJobs } from "../../../_apis/mechanics/appointments";
import { store } from "../../../_helpers";
import { Rewards } from "./components/Rewards";
import { apiGetRewards } from "../../../_apis";

const JobsDashboard = () => {
  const jobs = useSelector((state) => state.mechanic_appointments.jobs);
  const [value, setValue] = useState(1);
  const [todayJobs, setTodayJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [sameDayJobs, setSameDayJobs] = useState();
  const [rewards, setRewards] = useState();

  useEffect(() => {
    setIsLoading(true);
    store.dispatch(getMechanicJobs).then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (jobs) {
      const todays = jobs
        .filter((job) =>
          moment(selectedDay).isSame(
            moment(job.attributes.appointment_time_as_datetime),
            "day"
          )
        )
        .sort(
          (a, b) =>
            moment(a.attributes.appointment_time_as_datetime) -
            moment(b.attributes.appointment_time_as_datetime)
        );

      setTodayJobs(todays);
    }
  }, [jobs, selectedDay]);

  useEffect(() => {
    if (!sameDayJobs) {
      apiMechanicGetSameDayJobs().then((res) => setSameDayJobs(res));
    }
  }, [sameDayJobs]);

  useEffect(() => {
    if (!rewards) {
      apiGetRewards().then((res) => setRewards(res));
    }
  }, [rewards]);

  return (
    <ThemeProvider theme={FMCTheme}>
      <div className="pt-20 w-full mb-32">
        {sameDayJobs && sameDayJobs.data.length > 0 ? (
          <AvailableToday jobs={sameDayJobs.data} />
        ) : (
          <></>
        )}
        {rewards && <Rewards rewards={rewards} />}
        <WeekCalender
          currentDay={selectedDay}
          onPress={(newDay) => {
            setSelectedDay(newDay);
            setValue(1);
          }}
          jobs={jobs}
        />

        <DateSelector
          date={selectedDay}
          onPress={(newDay) => {
            setSelectedDay(newDay);
          }}
          tabVal={value}
        />
        {isLoading ? (
          <div className="w-full h-full justify-center items-center flex p-40">
            <CircularProgress color="primary" className="flex-1" size="sm" />
          </div>
        ) : todayJobs && todayJobs.length > 0 ? (
          todayJobs.map((job) => {
            return <TodayJobCard job={job} />;
          })
        ) : (
          <div className="text-center pt-16">
            <p>No jobs assigned for this day.</p>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export { JobsDashboard };
