import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  MechanicAuth,
  DiagnosisNote,
  Policies,
  JobsDashboard,
  JobPage,
  SearchScreen,
  InspectionReport,
  WarrantyNote,
  MechanicAddJob,
} from "../Pages";
import routes from "./routes";
import ScrollToTop from "../_helpers/ScrollToTop";
import Layout, { MechanicLayout } from "../_layouts"; 
import { Messages } from "../Pages/Mechanic/Messages"; 
import MessageByID from "../Pages/Mechanic/MessagesById";
import { EstimateEdit, EstimateNew } from "../Pages/Mechanic/Estimates";
import { CreditCard, Payments } from "../Pages/Mechanic/Payments";
import { Account } from "../Pages/Mechanic/Account";
import WarrantyClaimNote from "../Pages/Mechanic/WarrantyClaimNote";
import { useDispatch, useSelector } from "react-redux";
import { authTypes } from "../_types";
import { USER_TYPES } from "../_constants/constants";
import { doMechanicLogout, getMechanicData } from "../_actions/mechanics";
import { isApiCalling } from "../_helpers/utils";
import { Box, CircularProgress } from "@material-ui/core";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = !!useSelector((state) => state.auth.auth_token);
  const user_type = useSelector((state) => state.auth.user_type);
  const isLoadingMechanic = useSelector(
    isApiCalling(authTypes.GET_MECHANIC_DATA)
  );
  const isLoading = isLoadingMechanic;

  useEffect(() => {
    if (isAuthenticated) {
      if (user_type === USER_TYPES.MECHANIC) {
        dispatch(getMechanicData(() => dispatch(doMechanicLogout())));
      }
    }
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" pt={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ScrollToTop>
        <Switch>
          <Route
            path={routes.MECHANIC.SIGN_UP()}
            render={(props) => <MechanicAuth {...props} type="SIGN_UP" />}
          />

          <Route
            path={routes.MECHANIC.SIGN_IN()}
            render={(props) => <MechanicAuth {...props} type="SIGN_IN" />}
          />

          {/* Authenticated Routes for Mechanics */}
          <Layout
            path={routes.MECHANIC.ALL_MESSAGES()}
            component={Messages}
          />  
          <Layout
            path={routes.MECHANIC.MESSAGES_BY_ID()}
            component={MessageByID}
          /> 

          <Layout 
           path={routes.MECHANIC.ACCOUNT()}
           component={Account}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.DASHBOARD()}
            component={JobsDashboard}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.JOB_PAGE()}
            component={JobPage}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.SEARCH_SCREEN()}
            component={SearchScreen}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.DIAG_NOTE()}
            component={DiagnosisNote}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.WARRANTY_NOTE()}
            component={WarrantyNote}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.NEW_ESTIMATE()}
            component={EstimateNew}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.EDIT_ESTIMATE()}
            component={EstimateEdit}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.NEW_PAYMENT()}
            component={Payments}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.UPDATE_CREDIT_CARD()}
            component={CreditCard}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.FAQS()}
            component={Policies}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.INSPECTION()}
            component={InspectionReport}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.NEW_JOB()}
            component={MechanicAddJob}
            layout={MechanicLayout}
          />

          <Layout
            path={routes.MECHANIC.WARRANTY_CLAIM()}
            component={WarrantyClaimNote}
            layout={MechanicLayout}
          />

          <Redirect from="*" to="/mechanic/sign_in" />
        </Switch>
      </ScrollToTop>
    </>
  );
}

export { App };
