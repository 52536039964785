import React, { useState, useEffect } from "react";
import { apiGetSchedule } from "../../../_apis";
import { makeStyles } from "@material-ui/styles";
import { Button, Box, Typography } from "@material-ui/core";
import { RecurringSchedule } from "./components/RecurringSchedule";
import { DayOffModal } from "./components/DayOffModal";

const useStyles = makeStyles({
  modal: {
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    backgroundColor: "white",
    border: "1px solid #E8E8E8",
    padding: "2rem",
    boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.02)",
    borderRadius: 10,
  },
});

const Scheduling = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recurringSchedule, setRecurringSchedule] = useState([]);
  const [dayOffOpen, setDayOffOpen] = useState();

  useEffect(() => {
    setIsLoading(true);

    const schedule = async () => {
      const sched = await apiGetSchedule();
      setRecurringSchedule(sched.recurring_schedule);
    };

    schedule().then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Box className="px-4 pt-8 mb-10 pb-24">
      <Typography variant="h4" className="text-center">
        Availability
      </Typography>

      <div className="my-6 text-center">
        <Button
          className="flex-1 my-3"
          variant="outlined"
          onClick={() => setDayOffOpen(true)}
        >
          Request time off
        </Button>

        <DayOffModal
          className={classes.modal}
          boxClassNames={classes.paper}
          open={dayOffOpen}
          onClose={() => setDayOffOpen(false)}
        />

        <br />

        <RecurringSchedule recurringItems={recurringSchedule} />
      </div>
    </Box>
  );
};

export { Scheduling };
