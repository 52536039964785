import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import useStyles from "../../components/styles";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import { currencyFormatter } from "../utility";
import { Close, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";

const TakePayment = ({
  payment,
  handleSubmit,
  isSubmitting,
  paymentType,
  member,
  freeDiag,
  goBack,
  plan,
  removeMembership,
  applyDiagnosticFee,
  estimate,
  fleetNetTerms,
}) => {
  const classes = useStyles();
  const [services, setServices] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const func = async () => {
      setServices(estimate?.services);
    };

    if (!services && estimate) {
      func();
    }
  }, [services, estimate]);

  const totalPrice = () => {
    if (freeDiag && paymentType === "0") {
      return 0;
    }
    let price = parseFloat(payment.total_price);

    if (plan) {
      price += plan;
    }

    return price;
  };

  const totalWords = () => {
    if (freeDiag && paymentType === "0") {
      return "Free Diagnostic";
    }

    if (paymentType === "1" && totalPrice() === 0) {
      return "Free Membership Service";
    }

    return "Total Charge";
  };

  return (
    <Card variant="outlined" className={classes.cardBox}>
      {paymentType === "1" && estimate && services && (
        <>
          <Box>
            <Box className="my-6">
              <Typography className="my-3">Services</Typography>
            </Box>
            {Object.keys(services).map((service) => {
              return (
                <Accordion
                  className="my-6"
                  style={{
                    border: "2px solid var(--Emerald-Green, #005959)",
                    borderRadius: 8,
                    marginTop: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={
                      isMobile
                        ? "flex my-1 text-sm font-heavy "
                        : "flex my-1 text-lg font-heavy"
                    }
                  >
                    <Typography
                      className={clsx(
                        "text-sm",
                        services[service].status === "rejected" &&
                          "line-through"
                      )}
                    >
                      {services[service].status === "rejected" && (
                        <Close color="error" />
                      )}{" "}
                      {service}
                      {services[service].is_warranty && (
                        <>
                          <br />
                          <small className="italic">{` (Potential Warranty)`}</small>
                        </>
                      )}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {services[service].parts.length > 0 && (
                      <Typography
                        className="text-sm"
                        style={{
                          color: "#A5A7A7",
                          fontFamily: "Inter",
                          marginBottom: 5,
                        }}
                      >
                        PARTS
                      </Typography>
                    )}
                    {services[service].parts.length > 0 ? (
                      services[service].parts.map((part) => {
                        return (
                          <div className="my-2">
                            <div className="flex">
                              <p className="flex-1 text-sm text-left">
                                {part.name}
                                {part.part_number && ` (${part.part_number})`}
                              </p>
                              <p className="flex-2 text-sm">
                                ${part.unit_price}
                                {part.quantity && ` (x ${part.quantity})`}
                              </p>
                            </div>
                            <hr
                              style={{
                                background: " #D4D5D5",

                                height: 1,
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}

                    <Typography
                      className="text-sm"
                      style={{
                        color: "#A5A7A7",
                        fontFamily: "Inter",
                        marginBottom: 5,
                      }}
                    >
                      LABOR
                    </Typography>
                    <div className="my-2">
                      <div className="flex">
                        <p className="flex-1 text-sm text-left">Labor hours:</p>
                        <p className="flex-2  text-sm">
                          {services[service].labor_hours}
                        </p>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="flex">
                        <p className="flex-1 text-sm text-left">Labor price:</p>
                        <p className="flex-2 text-sm ">
                          ${services[service].labor_price}
                        </p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </>
      )}

      {paymentType === "1" && estimate && (
        <Container>
          <hr
            style={{
              background: " #D4D5D5",
              marginBottom: 15,
              height: 1,
              marginTop: 10,
              textAlign: "left",
            }}
          />

          <div
            className={
              isMobile
                ? "flex my-1 text-sm font-heavy "
                : "flex my-1 text-lg font-heavy"
            }
          >
            <p className="flex-1 text-left">Service Total:</p>
            <p className="flex-2">${totalPrice()}</p>
          </div>
        </Container>
      )}

      <CardContent>
        <Box mb={3}>
          <CardContent>
            {paymentType === "0" && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  className="my-3"
                  variant="h5"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Diagnosis Price
                </Typography>
                <Typography className="my-3" style={{ textAlign: "right" }}>
                  {currencyFormatter.format(payment.total_price)}
                </Typography>
              </div>
            )}
            {plan && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  className="my-3"
                  style={{ textAlign: "left", fontWeight: "bold" }}
                >
                  Membership Fee
                </Typography>
                <Typography
                  className="my-3"
                  variant="h5"
                  style={{ textAlign: "right" }}
                >
                  {applyDiagnosticFee
                    ? currencyFormatter.format(plan - 90)
                    : currencyFormatter.format(plan)}
                </Typography>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className="my-3"
                variant="h5"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {totalWords()}
              </Typography>
              <Typography
                className="my-3"
                variant="h5"
                style={{
                  textAlign: "right",
                  textDecoration: totalPrice() === 0 ? "line-through" : "none",
                }}
              >
                {applyDiagnosticFee
                  ? currencyFormatter.format(totalPrice() - 90)
                  : currencyFormatter.format(totalPrice())}
              </Typography>
            </div>

            <FmcFullWidthButton
              text={
                totalPrice() === 0 || fleetNetTerms
                  ? "Close Job"
                  : "Charge Customer"
              }
              onClick={handleSubmit}
              variant="contained"
              disabled={isSubmitting}
            />

            {fleetNetTerms && (
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  fontStyle: "italic",
                }}
              >
                * This fleet pays on net terms, you're not charging them now,
                you're just closing the job for record keeping purposes.
              </Typography>
            )}

            <FmcFullWidthButton
              text="Back"
              onClick={goBack}
              variant="outlined"
            />

            {!member && plan && (
              <FmcFullWidthButton
                text="Remove Membership"
                onClick={removeMembership}
                color="error"
                variant="contained"
              />
            )}
          </CardContent>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TakePayment;
