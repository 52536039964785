import { callApiV2 } from "./api_base";

export const apiGetUserAdmin = async (id) => {
  return callApiV2({
    url: `/user/admin_show/${id}`,
    method: "GET",
  });
};

export const apiGetUserMembership = async (id) => {
  return callApiV2({
    url: `/memberships/${id}`,
    method: "GET",
  });
};
