import React from "react";
import { useSelector } from "react-redux";
import { Container, Box, Typography } from "@material-ui/core";
import {
  signUpFields,
  signInFields,
  authTypes,
  authTypeHeadName,
} from "./utility";
import { MechanicLogin } from "./MechanicLogin";
import { MechanicRegistration } from "./MechanicRegistration";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  centerAuth: {
    margin: '10vh auto',
    paddingLeft: '2px',
    paddingRight: '2px'
  }
});

function MechanicAuth({ type }) {
  const classes = useStyles();
  const mechanicUser = useSelector((state) => state.auth.user_data);

  return (
    <Container className={classes.centerAuth}>
      <Typography className="text-center text-xl">
        Mechanic {authTypeHeadName[type]}
      </Typography>
      <Box>
        {type === authTypes[0] && (
          <MechanicLogin
            mechanicUser={mechanicUser}
            inputFieldsArray={signInFields}
          />
        )}
        {type === authTypes[1] && (
          <MechanicRegistration
            mechanicUser={mechanicUser}
            inputFieldsArray={signUpFields}
          />
        )}
      </Box>
    </Container>
  );
}

export { MechanicAuth };
