import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import colors from "../../../../../src/constants/colors";

const WeekCalender = (props) => {
  const { currentDay, jobs, onPress } = props;
  const days = [];
  const selectDay = moment(currentDay);
  const today = moment();
  const [sunday, setSunday] = useState(selectDay.startOf("week").toDate());

  useEffect(() => {
    setSunday(selectDay.startOf("week").toDate());
  }, [currentDay]);

  const [iterate, setIterate] = useState(0);

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  for (var i = 0; i < 7; i++) {
    days.push(sunday.addDays(i));
  }

  const renderDot = (jobsOnDay) => {
    let color;

    if (jobsOnDay.filter((i) => i.attributes.not_payable).length) {
      color = "#D4E6B5";
    } else {
      color = colors.secondaryDarker;
    }

    return jobsOnDay.length ? (
      <div
        style={{
          width: 6,
          height: 6,
          flex: 2,
          margin: "auto",
          backgroundColor: color,
          borderRadius: 10,
        }}
      ></div>
    ) : (
      <div
        style={{
          width: 6,
          height: 6,
          flex: 2,
          margin: "auto",
          borderRadius: 10,
        }}
      ></div>
    );
  };

  const isSelected = (val, ref) => {
    return moment(val).isSame(moment(ref), "day");
  };

  const renderDay = (val, jobsOnDay) => {
    const isCurrent = isSelected(val, currentDay);
    const isToday = isSelected(val, today);

    return (
      <button
        className="p-0.5 focus:outline-none"
        style={{ WebkitTapHighlightColor: "transparent" }}
        onClick={() => onPress(val)}
      >
        <p
          className={
            (isCurrent ? "text-fmc-primary" : null) + " font-inter text-xs pb-1"
          }
        >
          {moment(val).format("dd")[0]}
        </p>
        <div
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            width: 40,
            height: 40,
          }}
          className={
            isCurrent
              ? "bg-fmc-darker"
              : isToday
              ? "border-2 border-fmc-primary"
              : null
          }
        >
          <p
            style={{ flex: 4 }}
            className={
              (isCurrent
                ? "text-white"
                : isToday
                ? "text-fmc-primary"
                : moment(val).isAfter(today, "day")
                ? "text-fmc-calendar-text-new"
                : "text-fmc-calendar-text-old") + " p-1 font-inter text-xl"
            }
          >
            {val.getUTCDate()}
          </p>
        </div>
        {renderDot(jobsOnDay)}
      </button>
    );
  };

  return (
    <div className="w-full flex flex-row justify-around px-2 py-2 pb-4 mb-2">
      <button
        style={{ WebkitTapHighlightColor: "transparent" }}
        className="focus:outline-none"
        disabled={iterate < -2}
        onClick={() => {
          setSunday(moment(sunday).subtract(1, "week").toDate());
          setIterate(iterate - 1);
        }}
      >
        <ArrowBackIosIcon color="secondary" />
      </button>
      {days.map((val, index) => {
        const eventDay = jobs.filter((job) => {
          return moment(val).isSame(
            moment(job.attributes.appointment_time_as_datetime),
            "day"
          );
        });

        return renderDay(val, eventDay);
      })}
      <button
        style={{ WebkitTapHighlightColor: "transparent" }}
        className="focus:outline-none"
        disabled={iterate > 2}
        onClick={() => {
          setSunday(moment(sunday).add(1, "week").toDate());
          setIterate(iterate + 1);
        }}
      >
        <ArrowForwardIosIcon color="secondary" />
      </button>
    </div>
  );
};

export { WeekCalender };
