export const mechanicUserConstants = {
  LOGIN_SUCCESS: "MECHANIC_LOGIN_SUCCESS",
  LOGIN_REQUEST: "MECHANIC_LOGIN_REQUEST",
  LOGIN_FAILED: "MECHANIC_LOGIN_FAILED",

  REGISTER_SUCCESS: "MECHANIC_REGISTER_SUCCESS",
  REGISTER_REQUEST: "MECHANIC_REGISTER_REQUEST",
  REGISTER_FAILED: "MECHANIC_REGISTER_FAILED",

  SET_MECHANIC_AUTH_DATA: "SET_MECHANIC_AUTH_DATA",
  RESET: 'RESET',
  RESET_ERRORS: 'RESET_ERRORS',
  LOGOUT: 'MECHANIC_LOGOUT',
};
