import React, { useState } from "react";
import config from "config";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Link, Hidden, Button } from "@material-ui/core";
import { callApiV2 } from "../../../../_apis/api_base";

const CheckoutForm = ({ appointment_id, setCC }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState();
  const [requestInProgress, setRequestInProgress] = useState(false);

  const handleSubmit = async () => {
    if (requestInProgress) {
      return false;
    }

    setRequestInProgress(true);
    setErrors(null);
    setSuccess(null);

    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement)
    );

    if (token) {
      callApiV2({
        url: "/appointments/admin_cc",
        method: "POST",
        data: { appointment_id, token: token.id },
      })
        .then((res) => {
          setRequestInProgress(false);
          if (res.errors) {
            setErrors(res.errors.join(". "));
          } else {
            setCC(true);
            setSuccess("Card updated");
          }
        })
        .catch((e) => {
          setRequestInProgress(false);
          setErrors("Invalid credit card");
        });
    } else {
      setErrors(error.message);
      setRequestInProgress(false);
    }

    return true;
  };

  return (
    <Box className="w-full text-center">
      <CardElement className="w-full border rounded-md px-2 py-5 border-fmc-blue-dark" />
      <p className="w-full mx-auto text-sm text-center text-fmc-red my-1 sm:my-2">
        {errors}
      </p>
      <p className="w-full mx-auto text-sm text-center text-fmc-dark-green my-1 sm:my-2">
        {success}
      </p>

      <Button
        onClick={() => handleSubmit()}
        variant="contained"
        disabled={requestInProgress}
      >
        Update
      </Button>
    </Box>
  );
};

const CreditCard = ({ appointment_id, text, setCC, expanded }) => {
  const stripePromise = loadStripe(config.stripe.pk);
  const [changeCard, setChangeCard] = useState(expanded);

  return (
    <>
      <Box style={{ margin: "10px 0px" }}>
        <Link
          href="#"
          className="m-3"
          onClick={() => setChangeCard(!changeCard)}
        >
          {text}
        </Link>
      </Box>
      <Hidden xsUp={!changeCard}>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            appointment_id={parseInt(appointment_id)}
            setCC={setCC}
          />
        </Elements>
      </Hidden>
    </>
  );
};

export default CreditCard;
