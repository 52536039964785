import callApi, { callApiV2 } from "../api_base";

export const apiGetSchedule = async () => {
  return callApi({
    url: `/mechanic/free_days`,
    method: "GET",
  });
};

export const apiGetLiveSchedule = async () => {
  return callApi({
    url: `/mechanic/free_days/live_schedule`,
    method: "GET",
  });
};

export const apiGetMechanicJobs = async () => {
  return callApi({
    url: "/mechanic/appointments",
    method: "GET",
  });
};

export const apiOnMyWay = async (id, eta) => {
  return callApi({
    url: `/mechanic/appointments/on_my_way/${id}`,
    method: "POST",
    data: {
      appointment: {
        on_my_way_button_clicked: true,
        eta,
      },
    },
  });
};

export const apiArrived = async (id) => {
  return callApi({
    url: `/mechanic/appointments/arrived/${id}`,
    method: "POST",
  });
};

export const apiSetSchedule = async (data) => {
  return callApiV2({
    url: `/mechanic/recurring_schedules`,
    method: "POST",
    data: data,
  });
};

export const apiDeleteSchedule = async (data) => {
  return callApi({
    url: `/mechanic/free_days/${data.id}`,
    method: "DELETE",
  });
};

export const apiDeleteRecurringDay = async (id) => {
  return callApiV2({
    url: `/mechanic/recurring_schedules/${id}`,
    method: "DELETE",
  });
};

export const apiUpdateSchedule = async (id, data) => {
  return callApi({
    url: `/mechanic/free_days/${id}`,
    method: "PUT",
    data,
  });
};

export const apiCreateFreeDay = async (data) => {
  return callApi({
    url: `/mechanic/free_days`,
    method: "POST",
    data,
  });
};

export const apiGetJob = async (id) => {
  return callApi({
    url: `/mechanic/appointments/${id}`,
    method: "GET",
  });
};
