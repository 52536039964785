import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  FormControlLabel,
  OutlinedInput,
  Radio,
  Typography,
} from "@material-ui/core";
import { searchTypes } from "./utility";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import { apiSearchCustomer } from "../../../_apis/mechanics/customers";
import { NewJobForm } from "./components/NewJobForm";
import { CustomerInfo } from "./components/CustomerInfo";

function MechanicAddJob() {
  const [user, setUser] = useState();
  const [searchBy, setSearchBy] = useState(searchTypes[0]);
  const [searchInput, setSearchInput] = useState("");
  const [foundUsers, setFoundUsers] = useState([]);
  const [customerExists, setCustomerExists] = useState();
  const [newUser, setNeedNewUser] = useState();
  const [addCustomer, setAddCustomer] = useState();
  const [newJobType, setNewJobType] = useState();
  const [searching, setSearching] = useState();

  const searchCustomer = () => {
    setSearching(true);

    const data = { search: {} };

    if (searchBy === "Phone") {
      data.search.phone = searchInput;
    }

    if (searchBy === "Email") {
      data.search.email = searchInput;
    }

    if (searchBy === "Name") {
      data.search.name = searchInput;
    }

    apiSearchCustomer(data)
      .then((res) => {
        if (res.data) {
          if (res.data.length === 0) {
            setNeedNewUser(true);
          }
          setFoundUsers(res.data);
        }
      })
      .finally(() => setSearching());
  };

  return (
    <Container className="px-4 pt-24 mb-32">
      <Typography variant="h3">Add Job</Typography>

      {!newJobType ? (
        <>
          <FmcFullWidthButton
            text="For existing customer"
            onClick={() => {
              setAddCustomer();
              setNewJobType("search");
            }}
            variant="contained"
          />

          <FmcFullWidthButton
            text="For new customer"
            onClick={() => {
              setNewJobType("add");
              setAddCustomer(true);
            }}
            variant="contained"
          />
        </>
      ) : (
        <>
          <FmcFullWidthButton
            text="Reset"
            onClick={() => {
              setNewJobType();
              setAddCustomer();
              setUser();
            }}
            variant="outlined"
          />

          {user || addCustomer ? (
            <>
              {user && <NewJobForm user={user} />}
              {addCustomer && (
                <CustomerInfo
                  setFoundUser={setFoundUsers}
                  resetForm={() => {
                    setAddCustomer();
                    setCustomerExists(true);
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Box className="my-6">
                <div className="my-6 flex">
                  <p>Search by: </p>
                  {searchTypes.map((searchType) => {
                    return (
                      <FormControlLabel
                        className="mx-5"
                        checked={searchBy === searchType}
                        onChange={(event) => {
                          setFoundUsers([]);
                          setNeedNewUser(false);
                          setSearchInput("");
                          setSearchBy(event.target.value);
                        }}
                        value={searchType}
                        control={<Radio color="primary" />}
                        label={searchType}
                        labelPlacement="top"
                      />
                    );
                  })}
                </div>
                <div className="m-3">
                  <OutlinedInput
                    fullWidth
                    value={searchInput}
                    onChange={(ev) => setSearchInput(ev.target.value)}
                    placeholder={`Search ${searchBy}`}
                    type={searchBy === "Phone" ? "number" : "text"}
                  />

                  <FmcFullWidthButton
                    text="Search"
                    onClick={() => searchCustomer()}
                    variant="contained"
                    disabled={searchInput === "" || searching}
                  />

                  {customerExists && (
                    <Typography className="my-6 text-fmc-dark-green">
                      We found a customer with that information
                    </Typography>
                  )}
                </div>
              </Box>

              {searching && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              )}

              {foundUsers.length > 0 ? (
                <Box className="m-3">
                  {foundUsers.map((user) => {
                    const { name } = user.attributes;

                    return (
                      <Box
                        style={{
                          border: "solid 1px #dee2e6",
                          boxShadow: "none",
                          padding: "10px",
                        }}
                      >
                        <Typography>{name}</Typography>
                        <FmcFullWidthButton
                          text="Select this customer"
                          onClick={() => {
                            setUser(user);
                            setAddCustomer();
                          }}
                          variant="outlined"
                        />
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <></>
              )}

              {newUser && (
                <Box className="m-3">
                  <Typography>No customers found.</Typography>

                  <FmcFullWidthButton
                    text="Add Customer Info"
                    onClick={() => setAddCustomer(true)}
                    variant="contained"
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export { MechanicAddJob };
