import { apiTypes } from '../_types';

export default (state = {}, action) => {
  switch (action.type) {
    case apiTypes.API_CALL_START: {
      return {
        ...state,
        [action.payload.api]: {
          inProgress: true,
          error: ''
        }
      };
    }
    case apiTypes.API_CALL_SUCCESS: {
      return {
        ...state,
        [action.payload.api]: {
          inProgress: false,
          error: '',
          response: action.payload?.response,
        }
      };
    }
    case apiTypes.API_CALL_FAILURE: {
      return {
        ...state,
        [action.payload.api]: {
          inProgress: false,
          error: action.error
        }
      };
    }
    default:
      return state;
  }
};
