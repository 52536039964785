import React from "react";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import UpdateCreditCard from "../Payments/components/UpdateCreditCard";

const CreditCardModal = ({ open, onClose, jobId, handleSuccess, errors }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "800px",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px 30px 0px 30px",
        }}
      >
        <div>Edit Credit Card</div>

        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setEditCardModalOpen(false)}
          aria-label="close"
          style={{ paddingTop: -20 }}
        >
          <Close />
        </IconButton>
      </div>
      <div style={{ padding: 20 }}>
        <Typography color="error">{errors}</Typography>
      </div>

      <UpdateCreditCard appointment_id={jobId} onSuccess={handleSuccess} />
    </Dialog>
  );
};

export default CreditCardModal;
