import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
} from "@material-ui/core";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import callApi from "../../../../_apis/api_base";

const ChangeTypeModal = ({ open, onClose, isDiag, id }) => {
  const [diagnosis_input, setDiagInput] = useState();
  const [success, setSuccess] = useState();

  return (
    <Dialog className="" open={open} onClose={() => onClose(false)} fullWidth>
      <Box className="text-center">
        <DialogTitle>
          {isDiag ? "What are we diagnosing?" : "Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <Box m={3}>
            {isDiag ? (
              <OutlinedInput
                value={diagnosis_input}
                onChange={(e) => setDiagInput(e.target.value)}
                fullWidth
                placeholder="Please give some details on what is being diagnosed"
                multiline
                minRows={3}
              />
            ) : (
              <p className="text-center my-3">
                This will remove the diagnosis from your task list.
              </p>
            )}
          </Box>

          <FmcFullWidthButton
            text={isDiag ? "Add a Diagnosis" : "Remove Diagnosis"}
            onClick={() => {
              const data = {
                appointment_type: isDiag ? "diagnosis" : "repair",
              };

              if (isDiag) {
                data.diagnosis_input = diagnosis_input;
              }

              const url = isDiag
                ? `/mechanic/appointments/${id}/change_to_diag`
                : `/mechanic/appointments/${id}/change_to_repair`;

              callApi({
                url,
                method: "POST",
                data,
              }).then(() => {
                setSuccess(true);

                setTimeout(() => {
                  window.location.reload();
                }, 4000);
              });
            }}
            variant="outlined"
          />

          {success && (
            <p className="text-fmc-dark-green my-3 text-center">
              Success! Reloading job...
            </p>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ChangeTypeModal;
