import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import useStyles from "../../components/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Part from "./Part";
import FmcFullWidthButton from "../../components/FmcFullWidthButton";
import PartsLookupDialog from "./PartsLookupDialog";

const PartList = ({ serviceId, EstimateFormContext }) => {
  const classes = useStyles();
  const [partsLookupOpen, setPartsLookupOpen] = React.useState(false);

  const { data, appointment } = useContext(EstimateFormContext);
  const parts = useMemo(
    () => data.services[serviceId].parts || [],
    [data, serviceId]
  );

  return (
    <>
      <Card className={classes.noBorderCard}>
        <FmcFullWidthButton
          text="Search Parts & Labor"
          onClick={() => setPartsLookupOpen(true)}
          variant="outlined"
        />

        {parts.length > 0 ? (
          <>
            <CardHeader title="Parts" sx={{ marginBottom: 0 }} />
            <CardContent>
              {parts.map((_, index) => (
                <Part
                  key={index}
                  serviceId={serviceId}
                  partId={index}
                  EstimateFormContext={EstimateFormContext}
                />
              ))}
            </CardContent>
          </>
        ) : (
          <></>
        )}
      </Card>

      <PartsLookupDialog
        serviceId={serviceId}
        open={partsLookupOpen}
        onCancel={() => setPartsLookupOpen(false)}
        EstimateFormContext={EstimateFormContext}
      />
    </>
  );
};

PartList.propTypes = {
  serviceId: PropTypes.number.isRequired,
};

export default PartList;
