import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Close, MessageOutlined, NavigationOutlined } from "@material-ui/icons";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import { callApiV2 } from "../../../_apis/api_base";
import { apiOnMyWay } from "../../../_apis";
import FmcFullWidthButton from "./FmcFullWidthButton";

const ContactInfo = ({ jobId, exactAddress, fleet, showOnWay = true }) => {
  const [requestOnWay, setRequestOnWay] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [open, setOpen] = useState();
  const [eta, setEta] = useState();

  const handleContact = (contactType) => {
    callApiV2({
      url: `/mechanic/appointments/contact_customer/${jobId}`,
      method: "GET",
    }).then((res) => {
      if (contactType === "SMS") {
        window.location.assign(`sms:${res.number}`);
      } else {
        window.location.assign(`tel:${res.number}`);
      }
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("eta") && searchParams.get("eta") === "open") {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <Box
        style={{
          padding: "20px",
        }}
      >
        <div className="flex text-center flex-row flex-1 justify-between">
          <div
            onClick={() => {
              if (fleet) {
                handleContact("SMS");
              } else window.location.href = `/mechanic/chat/${jobId}`;
            }}
          >
            <div
              style={{
                padding: 10,
                borderRadius: "50%",
                backgroundColor: "#E6EEEE",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <MessageOutlined color="primary" />
            </div>
            <p className="my-3">Chat</p>
          </div>

          <div onClick={() => handleContact("CALL")}>
            <div
              style={{
                padding: 10,
                borderRadius: "50%",
                backgroundColor: "#E6EEEE",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <CallRoundedIcon color="primary" />
            </div>
            <p className="my-3">Call</p>
          </div>

          {!exactAddress ? (
            <p className="my-3">No Address</p>
          ) : (
            <div
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${exactAddress}`,
                  "_blank"
                )
              }
            >
              <div
                style={{
                  padding: 10,
                  borderRadius: "50%",
                  backgroundColor: "#E6EEEE",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
              >
                <NavigationOutlined color="primary" />
              </div>
              <p className="my-3">Directions</p>
            </div>
          )}
        </div>
        {showOnWay && (
          <>
            <div className="flex text-center flex-row flex-1 justify-between">
              <FmcFullWidthButton
                variant="outlined"
                text="On My Way"
                onClick={() => setOpen(true)}
              />
            </div>

            <Dialog
              styles={{ padding: "10px" }}
              open={open}
              onClose={() => setOpen()}
              fullWidth
            >
              <DialogTitle>
                <IconButton
                  style={{
                    padding: "2px",
                  }}
                  onClick={() => setOpen()}
                >
                  <Close />
                </IconButton>{" "}
                ETA
              </DialogTitle>

              <DialogContent>
                <Box className="my-6">
                  <p>
                    What's your estimated ETA so we can let the customer know?
                  </p>
                </Box>
                <Select
                  className="my-3"
                  variant="outlined"
                  onChange={(e) => setEta(e.target.value)}
                  value={eta}
                  fullWidth
                >
                  {[
                    "30",
                    "60",
                    "90",
                    "2 hours",
                    "3 hours",
                    "Not sure yet, will send ETA when I can",
                  ].map((i, index) => {
                    let displayText = i;

                    if (i === "30" || i === "60" || i === "90") {
                      displayText = `Within ${i} min(s)`;
                    }

                    return (
                      <MenuItem key={index} value={i}>
                        {displayText}
                      </MenuItem>
                    );
                  })}
                </Select>

                <Button
                  style={{
                    padding: "15px 35px",
                    backgroundColor: "#005959",
                    borderRadius: 26,
                    width: "100%",
                    marginTop: 20,
                    cursor: "pointer",
                    color: "#EDF5E0",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  onClick={() => {
                    setRequestOnWay(true);
                    apiOnMyWay(jobId, eta)
                      .then(() => {
                        setSuccess(true);

                        setTimeout(() => {
                          setSuccess();
                          setOpen();
                        }, 3000);
                      })
                      .finally(() => setRequestOnWay(false));
                  }}
                  disabled={requestOnWay || success}
                >
                  On my Way
                </Button>
              </DialogContent>

              {(success || error) && (
                <Box style={{ padding: "0px 12px 20px 12px" }}>
                  {success && (
                    <Typography color="primary">
                      Great, your customer has been notified of your ETA.
                    </Typography>
                  )}

                  {error && (
                    <Typography color="red">
                      Error notifying customer, contact the office.
                    </Typography>
                  )}
                </Box>
              )}
            </Dialog>
          </>
        )}
      </Box>
      <Divider light />
    </>
  );
};

export default ContactInfo;
