import moment from "moment";

export const pushSchedule = (mechanics) => {
  return Array.from(
    mechanics.reduce((uniqueDays, m) => {
      Object.keys(m.schedule).forEach((day) => uniqueDays.add(day));
      return uniqueDays;
    }, new Set())
  );
};

export const pushCurrentAppointments = (mechanics) => {
  return mechanics.reduce((groupedAppointments, mechanic) => {
    mechanic.current_appointments.forEach((appointment) => {
      const day = appointment.appointment_day;

      if (!groupedAppointments[day]) {
        groupedAppointments[day] = [];
      }

      groupedAppointments[day].push(appointment);
    });

    return groupedAppointments;
  }, {});
};

export const sortScheduled = (scheduled) => {
  return scheduled.sort((a, b) => {
    const aStartTime = a.appointment_time.split(" - ")[0];
    const bStartTime = b.appointment_time.split(" - ")[0];

    const aMoment = moment(aStartTime, "hh:mma");
    const bMoment = moment(bStartTime, "hh:mma");

    return aMoment - bMoment;
  });
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export const createDayArray = (minDay) => {
  const days = [];

  for (var i = 0; i < 14; i++) {
    days.push(minDay.addDays(i));
  }

  return days;
};
